






























































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*  eslint-disable prefer-destructuring */
import FillStyleDefinition from '@/components/layers/style/FillStyleDefinition.vue';
import PointStyleDefinition from '@/components/layers/style/PointStyleDefinition.vue';
import StrokeStyleDefinition from '@/components/layers/style/StrokeStyleDefinition.vue';
import TextStyleDefinition from '@/components/layers/style/TextStyleDefinition.vue';
import VectorLegend from '@/components/plugins/legend/VectorLegend.vue';
import ColorPalettePanel from '@/components/shared/ColorPalettePanel.vue';
import FormComboInput from '@/components/shared/FormComboInput.vue';
import FormNumberInput from '@/components/shared/FormNumberInput.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import ColorPalette from '@/models/ColorPalette';
import GeometryType from '@/models/map-context/GeometryType';
import LayerAttribute from '@/models/map-context/LayerAttribute';
import LayerConfig from '@/models/map-context/LayerConfig';
import LayerStyle from '@/models/map-context/LayerStyle';
import LayerStyleComplex from '@/models/map-context/LayerStyleComplex';
import StyleRule from '@/models/map-context/style/StyleRule';
import axios from 'axios';
import geostats from 'geostats';
import { GeoJSON } from 'ol/format';
import {
Observable,
from,
of,
} from 'rxjs';
import {
map,
} from 'rxjs/operators';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';
import SingleStyleDefinition from '../style/SingleStyleDefinition.vue';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
    FormNumberInput,
    FormComboInput,
    PointStyleDefinition,
    FillStyleDefinition,
    StrokeStyleDefinition,
    TextStyleDefinition,
    VectorLegend,
    SingleStyleDefinition,
    ColorPalettePanel,
  },
})
export default class SingleLayerComplexStyle extends BaseComponent {
  layer!: LayerConfig;

  newAnalyse = false;

  editRules = false;

  analyseType= 'categorized';

  modePointSize = false;

  reversePalette = false;

  minPointSize = 3;

  maxPointSize = 50;

  analyseTypes= [
    { text: 'Catégorisé', value: 'categorized' },
    { text: 'Gradué', value: 'graduated' },
  ];

  graduatedModes = [
    // { text: 'Nombre égal (Quantile )', value: 'quantile' },
    { text: 'Nombre égal (Quantile)', value: 'quantile_geostats' },
    { text: 'Ecart Type', value: 'stddev_geostats' },
    { text: 'Ruptures naturelles (jenks)', value: 'jenks' },
    { text: 'Echelle logarithmique', value: 'logaritmic' },
    { text: 'Progression arithmétique', value: 'arithmeticProgression_geostats' },
    { text: 'Progression géométrique', value: 'geometricProgression_geostats' },
    { text: 'Intervalle égal', value: 'equalInterval' },
    // { text: 'Intervalle égal (Geostat)', value: 'equalInterval_geostats' },
  ];

  graduatedMode = 'quantile_geostats';

  attribute:LayerAttribute|null=null;

  colorPalette:ColorPalette|null=null;

  colorPalettes:ColorPalette[]=[];

  errorTxt:string|null=null;

  valueList:string|null=null;

  complexStyle=new LayerStyleComplex();

  showMsgExistingRules = false;

  valuesToAdd:Array<any>=[];

  nbColors=10;

  minValue=0;

  maxValue=0;

  nbClasses=5;

  geometryTypeList = [GeometryType.UNDEFINED,
    GeometryType.LINE, GeometryType.POINT, GeometryType.POLYGON];

  mounted() {
    this.colorPalettes = this.contextService.colorPalettes;
    console.log(this.colorPalettes);
    if (this.layer.complexStyle) {
      this.complexStyle = this.layer.complexStyle;
    }
    if (!this.layer.hasRules()) {
      this.newAnalyse = true;
    }
  }

  hasRules():boolean {
    return this.layer.hasRules();
  }

  get geometryTypeNotDefined():boolean {
    return this.layer.geometryTypeNotDefined();
  }

  changeMode() {
    if (this.modePointSize) {
      this.analyseType = 'graduated';
    }
  }

  getColorPalette(x:any) {
    return new ColorPalette(x);
  }

  symboChange(rule:StyleRule) {
    rule.refreshToken += 1;
  }

  addRule() {
    const style = new LayerStyle();
    style.setBaseStyle(this.layer.style.getMergedBaseStyle());
    const color:any = 'rgb(0,255,0)';
    style.applyRuleColor(color);
    const filter = `attribute='value_${this.layer.complexStyle.styleRules.length}'`;
    const newRule = new StyleRule({ label: 'newRule', value: filter, style });
    newRule.style = style;
    if (this.layer.complexStyle.styleRules.find((x) => x.value === filter) === undefined) {
      this.layer.complexStyle.styleRules.push(newRule);
    }
  }

  endEdition() {
    this.editRules = false;
  }

  removeRule(rule:StyleRule) {
    const ruleIndex = this.layer.complexStyle.styleRules.indexOf(rule);
    this.layer.complexStyle.styleRules.splice(ruleIndex, 1);
  }

  deleteRules() {
    if (this.selectedRules.length > 0) {
      // on filtre pour ne récupérer que ceux qui ne sont pas sélectionnés
      const newList:StyleRule[] = [];
      this.layer.complexStyle.styleRules.forEach((x) => {
        if (this.selectedRules.indexOf(x) < 0) {
          newList.push(x);
        }
      });
      // .filter((x) => this.selectedRules.indexOf(x) < 0);
      this.layer.complexStyle.styleRules = newList;
    } else {
      this.layer.complexStyle.styleRules = [];
      this.editRules = false;
    }
  }

  get selectedRules():StyleRule[] {
    let selected:StyleRule[] = [];
    if (this.layer.complexStyle && this.layer.complexStyle.styleRules) {
      selected = this.layer.complexStyle.styleRules.filter((x) => x.checked);
    }

    return selected;
  }

  addGraduatedRules() {
    this.newAnalyse = false;
    const plageValues = this.maxValue - this.minValue;
    const equalinterval = plageValues / this.nbClasses;
    const quantilesInterval = 1 / this.nbClasses;
    this.getValues().subscribe((values) => {
      const graduatedRule = [];
      let res = [];
      if (this.graduatedMode === 'logaritmic') {
        values = values.map((x) => Math.log(x));
      }
      // eslint-disable-next-line new-cap
      const geo = new geostats(values);
      geo.setPrecision(6);
      if (this.graduatedMode === 'jenks') {
        res = geo.getClassJenks(this.nbClasses);
      } else if (this.graduatedMode === 'quantile_geostats') {
        res = geo.getClassQuantile(this.nbClasses);
      } else if (this.graduatedMode === 'stddev_geostats') {
        res = geo.getClassStdDeviation(this.nbClasses);
      } else if (this.graduatedMode === 'equalInterval_geostats') {
        res = geo.getClassEqInterval(this.nbClasses);
      } else if (this.graduatedMode === 'arithmeticProgression_geostats') {
        res = geo.getClassArithmeticProgression(this.nbClasses);
      } else if (this.graduatedMode === 'geometricProgression_geostats') {
        res = geo.getClassGeometricProgression(this.nbClasses);
      } else if (this.graduatedMode === 'logaritmic') {
        res = geo.getClassQuantile(this.nbClasses);
      }

      for (let index = 0; index < this.nbClasses; index += 1) {
        if (this.graduatedMode === 'quantile') {
          const minQuantile = Number(this.quantile(values, 0 + index * quantilesInterval).toFixed(2));
          const maxQuantile = Number(this.quantile(values, 0 + (index + 1) * quantilesInterval).toFixed(2));
          graduatedRule.push({
            min: minQuantile,
            max: maxQuantile,
          });
        } else if (this.graduatedMode === 'jenks'
          || this.graduatedMode === 'quantile_geostats'
          || this.graduatedMode === 'equalInterval_geostats'
          || this.graduatedMode === 'arithmeticProgression_geostats'
          || this.graduatedMode === 'geometricProgression_geostats'
          || this.graduatedMode === 'stddev_geostats') {
          graduatedRule.push({
            min: Number(res[index].toFixed(2)),
            max: Number(res[(index + 1)].toFixed(2)),
          });
        } else if (this.graduatedMode === 'logaritmic') {
          graduatedRule.push({
            min: Number(Math.exp(res[index]).toFixed(2)),
            max: Number(Math.exp(res[(index + 1)]).toFixed(2)),
          });
        } else {
          graduatedRule.push({
            min: Number((this.minValue + index * equalinterval).toFixed(2)),
            max: Number((this.minValue + (index + 1) * equalinterval).toFixed(2)),
          });
        }
      }
      this.valuesToAdd = graduatedRule;
      if (this.layer.complexStyle && this.layer.complexStyle.styleRules.length > 0) {
        this.showMsgExistingRules = true;
      } else {
        this.finalizeAddRulesForValues();
      }
    });
  }

  addRulesFromDatas() {
    this.errorTxt = 'Calculs en cours....';
    this.getDistinctValues().subscribe((values) => {
      this.errorTxt += `${values.length} valeurs distinctes trouvées...`;
      //CHECK IF NUMBER and sort if number
      if(values.find((x:any)=>isNaN(x))==undefined){//aucune valeur n'est pas un nombre
        const asc = (arr:any) => arr.sort((a:any, b:any) => Number(a) - Number(b));
        values=asc(values);
        
      }
      this.addRulesForValues(values);
      this.newAnalyse = false;
    });
  }

  addRules() {
    this.finalizeAddRulesForValues();
  }

  replaceRules() {
    this.layer.complexStyle.styleRules = [];
    this.finalizeAddRulesForValues();
  }

  addRulesFromList() {
    if (this.valueList === null || this.valueList.length === 0) {
      this.errorTxt = 'Vous devez renseigner une liste valeurs séparées par des virgules';
    } else {
      this.addRulesForValues(this.valueList.split(','));
      this.newAnalyse = false;
    }
  }

  getMaxValue(values:number[]):number {
    const arrMax = (arr:any) => Math.max(...arr);
    return arrMax(values);
  }

  getMinValue(values:number[]):number {
    const arrMin = (arr:any) => Math.min(...arr);
    return arrMin(values);
  }

  quantile(arr:any, q:number) {
    const sorted = arr;
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    }
    return sorted[base];
  }

  /**
   * retourne la liste des valeurs des objets de la carte triée par ordre croissant
   * pour un attribut numérique
   */
  getValues():Observable<number[]> {
    const asc = (arr:any) => arr.sort((a:any, b:any) => a - b);
    return this.getDataValues().pipe(map((vals:any) => asc(vals.map((x:any) => Number(x)))));
  }

  /**
   * retourne la liste des valeurs des objets de la carte
   * pour un attribut numérique
   */
  getDataValues():Observable<any[]> {
    let values = [];

    if (this.layer.isWMS() && this.layer.hasWFSCapacity()) {
      const urlGetFeature = this.layer.wmsUrlToWfs(this.layer.getFeatureWFSUrl());
      const maxFeatures = this.layer.maxFeatures;
      const url = `${urlGetFeature
      }&maxfeatures=${maxFeatures
      }&count=${maxFeatures
      }&typenames=${this.layer.layername}`
        + `&typename=${this.layer.layername}&`
        + 'outputFormat=json&srsname=EPSG:3857';
      return from(axios.get(url)).pipe(
        map((reponse:any) => {
          const allFeatures = new GeoJSON().readFeatures(reponse.data);
          if (!this.attribute) {
            return [];
          }
          return allFeatures.map((x:any) => x.get(this.attribute!.name));
        }),
      );
    }
    if (this.layer.getLayerSource().getFeaturesInExtent) {
      const size = /** @type {ol.Size} */ (this.getOpenLayersMapService().map.getSize());
      const extent = this.getOpenLayersMapService().map.getView().calculateExtent(size);
      values = this.layer.getLayerSource()
        .getFeaturesInExtent(extent).map((x:any) => x.get(this.attribute!.name));
    } else {
      values = this.layer.getLayerSource().getFeatures()
        .map((x:any) => x.get(this.attribute!.name));
    }
    console.log(values);
    return of(values);
  }

  getDistinctValues():Observable<number[]> {
    const distinct=this.getDataValues().pipe(
      map((x:any) => x.filter((v:any, i:any, a:any) => a.indexOf(v) === i)),
    ); // to get distinct values)); // to get distinct values
    return distinct;
  }

  changeAttribute() {
    console.log(this.attribute!.isNumber());
    this.getValues().subscribe((values) => {
      this.maxValue = this.getMaxValue(values);
      this.minValue = this.getMinValue(values);
    });
  }

  numberAttributeSelected():boolean {
    return this.attribute !== null && this.attribute.isNumber();
  }

  addRulesForValues(values:Array<any>) {
    this.valuesToAdd = values;
    console.log(this.valuesToAdd);
    if (this.layer.complexStyle && this.layer.complexStyle.styleRules.length > 0) {
      this.showMsgExistingRules = true;
    } else {
      this.finalizeAddRulesForValues();
    }
  }

  finalizeAddRulesForValues() {
    this.showMsgExistingRules = false;
    const values = this.valuesToAdd;
    for (let i = 0; i < values.length; i += 1) {
      const style = new LayerStyle();
      style.setBaseStyle(this.layer.style.getMergedBaseStyle());
      if (this.modePointSize) {
        const range = this.maxPointSize - this.minPointSize;
        const interval = Math.floor(range / values.length);
        style.pointSymbol.size = this.minPointSize + i * interval;
      } else {
        const color:any = this.colorPalette!.getColorValue(i, this.nbColors,this.reversePalette); // .values[i % this.colorPalette?.values.length];
        style.applyRuleColor(color);
      }

      let filter = '';
      let label = '';
      if (this.analyseType === 'graduated') {
        filter = `${this.attribute!.name}>=${values[i].min} and ${this.attribute!.name}<${values[i].max}`;
        if (i === values.length - 1) {
          filter = `${this.attribute!.name}>=${values[i].min} and ${this.attribute!.name}<=${values[i].max}`;
        }
        label = `${this.attribute!.name} entre ${values[i].min} et ${values[i].max}`;
      } else {
        if (values[i] != null && values[i].indexOf && values[i].indexOf("'") > 0) {
          const replaceValue = values[i].replace(new RegExp('\'', 'g'), '%');
          filter = `${this.attribute!.name} like '${replaceValue}'`;
        } else {
          filter = `${this.attribute!.name}='${values[i]}'`;
        }

        label = values[i];
      }
      const newRule = new StyleRule({ label, value: filter, style });
      newRule.style = style;
      if (this.layer.complexStyle.styleRules.find((x) => x.value === filter) === undefined) {
        this.layer.complexStyle.styleRules.push(newRule);
      }
    }
    this.valuesToAdd = [];
  }
}
