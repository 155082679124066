

















/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormTextInput from '@/components/shared/FormTextInput.vue';
import GeometryType from '@/models/map-context/GeometryType';
import LayerConfig from '@/models/map-context/LayerConfig';
import PointStyleDefinition from '@/components/layers/style/PointStyleDefinition.vue';
import FillStyleDefinition from '@/components/layers/style/FillStyleDefinition.vue';
import StrokeStyleDefinition from '@/components/layers/style/StrokeStyleDefinition.vue';
import TextStyleDefinition from '@/components/layers/style/TextStyleDefinition.vue';
import Component from 'vue-class-component';

import LayerStyle from '@/models/map-context/LayerStyle';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    layer: LayerConfig,
    styleDefinition: LayerStyle,
  },
  components: {
    FormTextInput,
    PointStyleDefinition,
    FillStyleDefinition,
    StrokeStyleDefinition,
    TextStyleDefinition,
  },
})
export default class SingleStyleDefinition extends BaseComponent {
  layer!: LayerConfig;

  styleDefinition!:LayerStyle;

  mounted() {
    console.log('Style');
  }

  get geometryTypeNotDefined():boolean {
    return this.layer.geometryTypeNotDefined();
  }

  get showPointStyle():boolean {
    return this.geometryTypeNotDefined
    || this.layer.geometryType === GeometryType.POINT;
  }

  get showStrokeStyle():boolean {
    return this.geometryTypeNotDefined
    || this.layer.geometryType !== GeometryType.POINT;
  }

  get showFillStyle():boolean {
    return this.geometryTypeNotDefined
    || this.layer.geometryType === GeometryType.POLYGON;
  }

  get showTextStyle():boolean {
    return this.geometryTypeNotDefined
    || this.layer.geometryType === GeometryType.POINT && this.is3DView()
    || !this.is3DView();
  }

  symboChange() {
    this.$emit('symboChange', '');
  }
}
