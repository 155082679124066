












/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    label: String,
    cols: { default: 8 },
    value: {},
    min: Number,
    max: Number,
  },
})
export default class FormNumberInput extends BaseComponent {
  label!:string;

  value:any;

  cols!:number;

  min?:number;

  max?:number;

  // model:any=null;
  get inputValue() {
    return this.value;
  }

  set inputValue(val) {
    this.$emit('input', parseFloat(val));
  }
}
