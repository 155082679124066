


















/* eslint-disable class-methods-use-this */
import PluginComponent from '@/components/plugins/PluginComponent';
import AlertMessage from '@/models/shared/AlertMessage';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';

@Component({
  props: {
  },
  components: {
  },
})
export default class CesiumPlugin extends PluginComponent {
    static ID='CesiumPlugin';

    onlycesium = false;

    mounted():void {
      console.log('plugin mounted');

      this.onlycesium = this.plugin.getParameterValue('onlycesium');

      if (this.onlycesium && !this.is3DView()) {
        this.onClick();
      }
      if(this.is3DView()){
        this.getCesiumMapService().imgNord=this.$refs.imgNord;
      }
    }

    onClick():void {
      if (this.checkEditionNotActive() && this.$router) {
        this.$router.push({ query: { '3d': 'true' }, append: true });
      }
    }

    onClickOL():void {
      if (this.checkEditionNotActive() && this.$router) {
        this.$router.push({ query: { '3d': 'false' }, append: true });
      }
    }

    checkEditionNotActive():boolean {
      if (this.contextService.isEditionActive) {
        const message = 'La carte est en édition, veuillez sauver ou annuler les modifications avant de changer de mode';
        EventBus.$emit(EVENTS.WARNING, new AlertMessage({ label: message, type: 'warning', autoHide: true }));
        return false;
      }
      return true;
    }

    onClickNord():void {
      this.getMapService().setNord();
    }
}
