































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import { adminServiceInstance, AdminService } from '@/services/AdminService';
import User from '@/models/user/User';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import { EventBus, EVENTS } from '@/services/EventBus';
import ActionButton from '@/components/shared/ActionButton.vue';
import Group from '@/models/user/Group';
import QueryParams from '@/models/crud/QueryParams';
import BaseComponent from '../../BaseComponent';
import UserListTable from './ListUserTable.vue';
import FileExplorer from '@/components/shared/FileExplorer.vue';

@Component({
  props: {
  },
  components: {
    FormTextInput,
    FileExplorer,
    ActionButton,
    UserListTable,
  },
})
export default class GroupPanel extends BaseComponent {
  adminService=adminServiceInstance;

  group:Group=new Group();

  newGroup = true;

  showAddUser = false;

  idGroup = '';

  baseRoute = '/groups';



  mounted() {
    this.idGroup = this.$route.params.id;
    if (this.idGroup) {
      this.adminService.getGroup(this.idGroup).then((reponse) => {
        console.log(reponse);
        
        this.group = new Group(reponse.data);
        this.newGroup = false;
        const t:any = this.$refs.userList;
        t.refresh();
        // EventBus.$emit(EVENTS.INFO, 'OK');
      });
    }
    this.$watch(
      () => this.$route.path,
      (newVal, oldVal) => {
        this.idGroup = this.$route.params.id;
        if (this.idGroup) {
          this.adminService.getGroup(this.idGroup).then((reponse) => {
            console.log(reponse);
            
            this.group = new Group(reponse.data);
            this.newGroup = false;
            const t:any = this.$refs.userList;
            t.refresh();
            // EventBus.$emit(EVENTS.INFO, 'OK');
          });
        }
      },
    );
  }



  create() {
    console.log('create User');
    this.adminService.createOrUpdateGroup(this.group).then((reponse:any) => {
      this.newGroup = false;
      this.idGroup = reponse.data.id;
      this.group = new Group(reponse.data);
      if (reponse.statusText === 'Created') {
        this.$router.push({ path: `/groups/${reponse.data.id}` });
        EventBus.$emit(EVENTS.INFO, 'Sauvegarde effectuée');
      } else {
        this.$router.push({ path: '/groups' });
        EventBus.$emit(EVENTS.INFO, 'Sauvegarde effectuée');
      }
      // this.$router.push({ path: `${this.baseRoute}/${reponse.data.id}` });
    });
  }

  addUser() {
    this.showAddUser = true;
  }

  selectUser(item:User) {
    this.adminService.addUserToGroup(this.group, item).then((reponse) => {
      EventBus.$emit(EVENTS.INFO, 'Ajout effectuée');
      const t:any = this.$refs.userList;
      t.refresh();
    });
  }

  removeUserFromGroup(item:User) {
    this.adminService.removeUserFromGroup(this.group, item).then((reponse) => {
      console.log(reponse);
      EventBus.$emit(EVENTS.INFO, 'User retiré');
      const t:any = this.$refs.userList;
      t.refresh();
    });
  }

  retourPagePrecedente() {
    this.$router.push({ path: '/groups' });
  }

  remoteListProvider(ctx:any) {
    if (this.idGroup) {
      const cp = new QueryParams(ctx);
      cp.filterOn = ['name', 'email'];
      return this.adminService.getUsersFromGroup(this.idGroup, cp).then((res:any) => {
        console.log(res);
        let list:any = [];

        if (res.data && res.data.count > 0) {
          list = res.data.results;
        } else {
          list = [];
        }
        return list;
      });
    }
    return [];
  }
}
