export default class AuthenticationInfos {
  type!: string;

  login!: string;

  password!: string;

  store!: boolean;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }

  isRequired():boolean {
    return this.type === 'basic';
  }

  isFilled():boolean {
    return this.login !== undefined && this.login.length > 0
        && this.password !== undefined && this.password.length > 0;
  }

  getBasicAutorisationValue() :string {
    return `Basic ${window.btoa(`${this.login}:${this.password}`)}`;
  }
}
