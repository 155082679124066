import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import UserListPanel from '../components/admin/users/UserListPanel.vue';
import UserPanel from '../components/admin/users/UserPanel.vue';
import GroupPanel from '../components/admin/users/GroupPanel.vue';
import GroupStoragePanel from '../components/admin/users/GroupStoragePanel.vue';
import ConfirmUserPanel from '../components/admin/users/ConfirmUserPanel.vue';
import MapUserList from '../components/maplist/MapUserList.vue';
import GroupListPanel from '../components/admin/users/GroupListPanel.vue';
import  { ContextService,contexteServiceInstance } from '@/services/ContextService';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: to => {
      if(contexteServiceInstance.forceLogin()){
        return '/login';
      }
      return '/maps';
    },
    component: Home,
  },
  {
    path: '/map/new',
    name: 'Map',
    component: Home,
  },
  {
    path: '/map/:id',
    name: 'MapFromId',
    component: Home,
  },
  {
    path: '/map/wmc/:wmc',
    name: 'MapFromWmc',
    component: Home,
  },
  {
    path: '/maps',
    name: 'MapList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MapList.vue'),
    children: [
      {
        path: '',
        component: MapUserList,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../components/admin/users/LoginUserPanel.vue'),
    children: [
      {
        path: '',
        component: MapUserList,
      },
    ],
  },
  {
    path: '/users',
    name: 'UserList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MapList.vue'),
    children: [
      {
        path: '',
        component: UserListPanel,
      },
      {
        path: 'new',
        component: UserPanel,
      },
      {
        path: 'signup',
        component: UserPanel,
      },
      {
        path: 'confirm',
        component: ConfirmUserPanel,
      },
      {
        path: ':id',
        component: UserPanel,
        props: true,
      },
    ],
  },
  {
    path: '/groups',
    name: 'GroupList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MapList.vue'),
    children: [
      {
        path: '',
        component: GroupListPanel,
      },
      {
        path: 'new',
        component: GroupPanel,
      },
      {
        path: ':id',
        component: GroupPanel,
      },
      {
        path: ':id/storage',
        component: GroupStoragePanel,
      },
    ],
  },
  {
    path: '/groups',
    name: 'GroupList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MapList.vue'),
    children: [
      {
        path: '',
        component: GroupListPanel,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
