






























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import VectorLegend from '@/components/plugins/legend/VectorLegend.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    VectorLegend,
  },
})
export default class LayerLegend extends BaseComponent {
  layer!:LayerConfig;

  legendUrl='';

  mounted() {
    this.legendUrl = this.layer.getLegendImageUrl();
    const maxUrlLength = 2000;
    if (this.legendUrl.length < maxUrlLength) {
      return;
    }
    const client = new XMLHttpRequest();
    const urlArray = this.legendUrl.split('?');
    const url = urlArray[0];
    const params = urlArray[1];
    client.open('POST', url, true);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    client.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    client.setRequestHeader('X-CSRFToken', this.contextService.getCsrfToken());
    client.responseType = 'arraybuffer';

    client.onload = function onload() {
      const arrayBufferView = new Uint8Array(this.response);
      const blob = new Blob([arrayBufferView], { type: 'image/png' });
      const urlCreator = window.URL || (window as any).webkitURL;
      const imageUrl = urlCreator.createObjectURL(blob);
      self.legendUrl = imageUrl;
    };
    client.addEventListener('error', (e) => {
      console.log('erreur');
    });
    client.send(params);
  }

  getLegendImageUrl() {
    return this.layer.getLegendImageUrl();
  }
}
