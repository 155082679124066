


















/* eslint-disable @typescript-eslint/no-this-alias */
import PluginComponent from '@/components/plugins/PluginComponent';
import { EVENTS, EventBus } from '@/services/EventBus';
import { Overlay } from 'ol';
import { unByKey } from 'ol/Observable';
import { Type } from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import { Draw } from 'ol/interaction';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { getArea, getLength } from 'ol/sphere';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Component from 'vue-class-component';

const formatArea = (polygon:Polygon) => {
  const area = getArea(polygon);
  if (area > 1000000) {
    return `${Math.round((area / 1000000) * 10) / 10} km<sup>2</sup>`;
  }
  if (area > 100) {
    return `${Math.round(area)} m<sup>2</sup>`;
  }
  return `${Math.round(area * 10) / 10} m<sup>2</sup>`;
};

const formatLength = (line:LineString) => {
  const length = getLength(line);
  if (length > 1000) {
    return `${Math.round((length / 1000) * 10) / 10} km`;
  }
  if (length < 10) {
    return `${Math.round(length * 100) / 100} m`;
  }
  return `${Math.round(length)} m`;
};

@Component
export default class MesurePlugin extends PluginComponent {
  private draw:any;

  private sketch:any;

  private helpTooltipElement!: HTMLElement;

  private helpTooltip!: Overlay;

  private measureTooltipElement!: HTMLElement;

  private measureTooltipElements: HTMLElement[] = [];

  private measureTooltip!: Overlay;

  private continueMsg = 'simple clic pour ajouter un point<br/>double clic pour terminer la mesure';

  private isAreaMode = true;

  private source :VectorSource<any>|null= null;

  private vector:BaseLayer|null = null;

  activeDistance = false;

  activeSurface = false;

  showDistanceButton = true;

  showSurfaceButton = true;

  pointerMoveListener = (e:any) => this.pointerMoveHandler(e);

  mounted(): void {
    console.log('Mesure Plugin Mounted');
    this.showDistanceButton = this.plugin.getParameterValue('distances');
    this.showSurfaceButton = this.plugin.getParameterValue('surfaces');
  }

  beforeDestroy(): void {
    if (this.activeSurface || this.activeDistance) {
      this.removeInteraction();
    }
  }

  onClickDistance(): void {
    if (this.activeDistance || this.activeSurface) {
      this.removeInteraction();
    }
    this.activeSurface = false;
    this.activeDistance = !this.activeDistance;
    if (this.activeDistance) {
      this.isAreaMode = false;
      this.addInteraction();
    }
  }

  onClickSurface(): void {
    if (this.activeDistance || this.activeSurface) {
      this.removeInteraction();
    }
    this.activeDistance = false;
    this.activeSurface = !this.activeSurface;
    if (this.activeSurface) {
      this.isAreaMode = true;
      this.addInteraction();
    }
  }

  private removeInteraction(): void {
    EventBus.$emit(EVENTS.ENABLE_SELECTION);
    this.removeHelpTooltip();
    this.removeMeasureTooltip();
    if (this.vector != null) {
      this.getOpenLayersMapService().map.removeLayer(this.vector);
    }

    this.getOpenLayersMapService().map.un('pointermove', this.pointerMoveListener);

    this.getOpenLayersMapService().map.removeInteraction(this.draw);
  }

  private addInteraction(): void {
    EventBus.$emit(EVENTS.DISABLE_SELECTION);

    this.source = new VectorSource();

    this.vector = new VectorLayer({
      source: this.source,
      zIndex: 1000,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(129, 219, 153,1)',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: 'rgba(129, 219, 153,1)',
          }),
        }),
      }),
    });

    this.getOpenLayersMapService().map.addLayer(this.vector);

    this.getOpenLayersMapService().map.on('pointermove', this.pointerMoveListener);

    const type:Type = this.isAreaMode ? 'Polygon' : 'LineString';

    this.draw = new Draw({
      source: this.source,
      type,
      style: new Style({
        fill: new Fill({
          color: 'rgba(230, 230, 230, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(95, 122, 103,0.5)',
          lineDash: [15, 10, 3, 3],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(95, 122, 103,0.5)',
          }),
          fill: new Fill({
            color: 'rgba(230, 230, 230, 0.2)',
          }),
        }),
      }),
    });

    this.getOpenLayersMapService().map.addInteraction(this.draw);

    this.createMeasureTooltip();
    this.createHelpTooltip();

    let listener:any;

    const self = this;

    this.draw.on('drawstart', (evt:any) => {
      self.sketch = evt.feature;

      let tooltipCoord = evt.coordinate;

      listener = self.sketch.getGeometry().on('change', (evnt:any) => {
        const geom = evnt.target;
        let output = '';
        if (geom instanceof Polygon) {
          output = formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        self.measureTooltipElement.innerHTML = output;
        self.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.draw.on('drawend', () => {
      self.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      self.measureTooltip.setOffset([0, -7]);

      // unset sketch
      self.sketch = null;

      // unset tooltip so that a new one can be created
      self.measureTooltipElements.push(self.measureTooltipElement);

      self.createMeasureTooltip(true);

      unByKey(listener);
    });
  }

  private pointerMoveHandler(evt:any) {
    if (evt.dragging) {
      return;
    }

    let helpMsg = 'Cliquez pour démarrer la mesure';

    if (this.sketch) {
      const geom = this.sketch.getGeometry();
      helpMsg = this.continueMsg;
    }

    this.helpTooltipElement.innerHTML = helpMsg;
    this.helpTooltip.setPosition(evt.coordinate);

    this.helpTooltipElement.classList.remove('hidden');
  }

  private removeMeasureTooltip() {
    if (this.measureTooltipElements.length) {
      this.measureTooltipElements.forEach((element) => {
        if (element && element.parentNode) {
          element.parentNode.removeChild(element);
        }
      });
    }

    if (this.measureTooltipElement && this.measureTooltipElement.parentNode) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
    }
  }

  private createMeasureTooltip(doNotRemove = false) {
    if (!doNotRemove) {
      this.removeMeasureTooltip();
    }

    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
    });
    this.getOpenLayersMapService().map.addOverlay(this.measureTooltip);
  }

  private removeHelpTooltip() {
    if (this.helpTooltipElement && this.helpTooltipElement.parentNode) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
  }

  private createHelpTooltip() {
    this.removeHelpTooltip();

    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'ol-tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left',
    });
    this.getOpenLayersMapService().map.addOverlay(this.helpTooltip);
  }
}
