





































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Application from '@/models/map-context/Application';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

// eslint-disable-next-line no-use-before-define
@Component<MapUserList>({
  props: {
    mapKeywordFilter: String,
  },
  watch: {
    mapKeywordFilter(layer: string) {
      this.loadMaps();
    },
  },
})
export default class MapUserList extends BaseComponent {
  mapKeywordFilter!: string;

  slide= 0;

  sliding = false;

  maps = [];

  carrousselMaps = [];

  searchTxt = '';

  ordering = {value:'creation_date', label:'Date de création (croissante)'};

  currentPage = 1;

  rows = 0;

  perPage = 6; // 6 cartes par page

  perLine = 3; // 3 cartes par ligne

  noResultsFound = false;

  controller!:AbortController|undefined;

  orderingList = [
 //   {value:'id', label:'Identifiant (croissant)'},
 //   {value:'-id', label:'Identifiant (décroissant)'},
    {value:'display_name', label:'Titre (croissant)'},
    {value:'-display_name', label:'Titre (décroissant)'},
    {value:'last_revision_date', label:'Date de modification (croissante)'},
    {value:'-last_revision_date', label:'Date de modification (décroissante)'},
    {value:'creation_date', label:'Date de création (croissante)'},
    {value:'-creation_date', label:'Date de création (décroissante)'},
  ]

  onSlideStart(slide:any) {
    this.sliding = true;
  }

  isMapOwner(map:Application) {
    if (!this.contextService.configuration.standalone) {
      return false;
    }
    if (this.contextService.user
        && this.contextService.user.id === map.ownerId) {
      return true;
    }

    return false;
  }

  isMapOfMyOrga(map:Application) {
    if (this.contextService.configuration.standalone) {
      return false;
    }

    const currentAppUserGroupId = map.usergroup;
    if (this.contextService.user
        && this.contextService.user.usergroup_roles
          .filter((x:any) => x.usergroup.id === currentAppUserGroupId).length > 0) {
      return true;
    }

    return false;
  }

  onSlideEnd(slide:any) {
    this.sliding = false;
  }

  pageChange(page:number) {
    this.currentPage = page;
    this.loadMaps();
  }

  listToMatrix(list:Array<any>, elementsPerSubArray:number) {
    const matrix :any = [];
    let i; let k;

    for (i = 0, k = -1; i < list.length; i += 1) {
      if (i % elementsPerSubArray === 0) {
        k += 1;
        matrix[k] = [];
      }

      matrix[k].push(list[i]);
    }

    return matrix;
  }

  loadMaps():void {
    document.title = "onegeo-maps"
    this.noResultsFound = false;
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();
    this.contextService.getMaps(this.searchTxt, this.mapKeywordFilter, this.currentPage, this.perPage, this.ordering.value, this.controller).then((res:any) => {
      console.log(res);
      this.controller = undefined;
      if (res.data && res.data.count > 0) {
        this.rows = res.data.count;
        this.maps = this.listToMatrix(res.data.results, this.perLine);
      } else {
        this.rows = 0;
        this.maps = [];
        this.noResultsFound = true;
      }
    });
  }

  getMaxHeight() {
    let headerAndOtherPageElementHeight = 350;
    if (this.contextService.configuration.customHeaderAndFooter && this.contextService.configuration.customHeaderAndFooter.header_height) {
      headerAndOtherPageElementHeight = 308 + this.contextService.configuration.customHeaderAndFooter.header_height;
    }
    return `max-height: calc(100vh - ${headerAndOtherPageElementHeight}px);`;
  }

  mounted() {
    console.log('map list');
    this.contextService.cancelEdition();
    this.loadMaps();

    this.$watch(
      () => this.contextService.user,
      (newUser, oldUser) => {
        this.loadMaps();

        // console.log('this.contextService.user a changé :', newUser, this.rows, this.maps);
      },
      { deep: true },
    );
  }

  removeApp(map:Application) {
    this.contextService.removeApp(map);
  }

  openApp(map:Application) {
    this.$router.push({ path: `/map/${map.id}` });
  }

  getThumbnailUrl(map:Application) :string {
    if (map.thumbnail != null) {
      return this.contextService.getThumbnailUrl(map);
    }

    return `https://picsum.photos/1024/480/?image=${map.id}`;
  }
}
