





























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiAdressePlugin from '@/components/plugins/ApiAdressePlugin.vue';
import MesurePlugin from '@/components/plugins/MesurePlugin.vue';
import LoadingInfosPlugin from '@/components/plugins/openlayers/LoadingInfosPlugin.vue';
import MousePositionPlugin from '@/components/plugins/openlayers/MousePositionPlugin.vue';
import ScalePlugin from '@/components/plugins/openlayers/ScalePlugin.vue';
import Plugin from '@/models/plugin/Plugin';
import { PluginLocation } from '@/models/plugin/PluginLocation';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import CesiumPlugin from '../plugins/CesiumPlugin.vue';
import BaseLayerSelectPlugin from '../plugins/BaseLayerSelectPlugin.vue';
import LocationPlugin from '../plugins/LocationPlugin.vue';
import DashBoardPlugin from '../plugins/DashBoardPlugin.vue';
import MapillaryPlugin from '../plugins/MapillaryPlugin.vue';
import ScaleListPlugin from '../plugins/ScaleListPlugin.vue';
import SearchLayerPlugin from '../plugins/SearchLayerPlugin.vue';
import NavigationPlugin from '../plugins/openlayers/NavigationPlugin.vue';
import FiltrePlugin from '../plugins/FiltrePlugin.vue';
import SwipePlugin from '../plugins/SwipePlugin.vue';

@Component({
  props: {
  },
  components: {
    ApiAdressePlugin,
    MousePositionPlugin,
    LoadingInfosPlugin,
    MesurePlugin,
    ScalePlugin,
    ScaleListPlugin,
    NavigationPlugin,
    MapillaryPlugin,
    CesiumPlugin,
    FiltrePlugin,
    DashBoardPlugin,
    SearchLayerPlugin,
    LocationPlugin,
    BaseLayerSelectPlugin,
    SwipePlugin,
  },
})
export default class MapPluginContainer extends BaseComponent {
    pluginsBR:Plugin[]=[];

    pluginsBL:Plugin[]=[];

    pluginsTL:Plugin[]=[];

    pluginsTR:Plugin[]=[];

    mounted() {
      // eslint-disable-next-line prefer-template
      console.log('Mount plugin components' + PluginLocation.TopLeft);
      this.pluginsTL = this.pluginService.plugins.filter(
        (x:Plugin) => x.configuration.position === PluginLocation.TopLeft,
      );

      this.pluginsTR = this.pluginService.plugins.filter(
        (x:Plugin) => x.configuration.position === PluginLocation.TopRight,
      );

      this.pluginsBR = this.pluginService.plugins.filter(
        (x:Plugin) => x.configuration.position === PluginLocation.BottomRight,
      );

      this.pluginsBL = this.pluginService.plugins.filter(
        (x:Plugin) => x.configuration.position === PluginLocation.BottomLeft,
      );
    }
}
