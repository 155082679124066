






















/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import LayerConfig from '@/models/map-context/LayerConfig';
import StyleRule from '@/models/map-context/style/StyleRule';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';
import VectorLegend from './VectorLegend.vue';

// eslint-disable-next-line no-use-before-define
@Component<LayerRuleFilterList>({
  name: 'LayerRuleFilterList',
  props: {
    layer: LayerConfig,
    switchCheckbox:{ default: true },
    refreshToken: Number,
  },
  components: {
    VectorLegend,
  },
  watch: {
  },
})
export default class LayerRuleFilterList extends BaseComponent {
  layer!:LayerConfig;

  legendUrl='';

  allactive = true;

  switchCheckbox !: boolean;

  showDownloadBtn = true;

  getLabel(rule:StyleRule) {
    if (rule.label) return rule.label;
    return rule.value;
  }

  switchAll() {
    // this.allactive = !this.allactive;
    this.layer.complexStyle.styleRules.forEach((x:any) => x.active = this.allactive);
    this.layer.updateStyle();
  }

  onFilterChange() {
    this.layer.updateStyle();
  }
}
