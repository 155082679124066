import axios, { AxiosResponse } from 'axios';
import { WKT } from 'ol/format';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import Filter from '../map-context/filter/Filter';

/* eslint-disable class-methods-use-this */
export default class AdministrativeArea {
    label='';

    wfsUrl='';

    simplifyTolerance = 1000;

    layer='';

    placeholder='Entrez une valeur';

    attributeSearch='';

    resultLabel='';

    autocomplete = false; // Si false on charge tout au départ, si true on attends que l'utilisateur renseigne les premiers caractères

    datas=[];

    choices=[];

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
      if (!this.autocomplete) {
        this.loadDatas().then((result) => {
          console.log(result.data);
          this.datas = result.data.features;
          this.choices = result.data.features.map((x:any) => x.properties[this.attributeSearch]);
        });
      }
    }

    loadDatas(filter = ''):Promise<AxiosResponse<any>> {
      let attributesToGet:any = this.attributeSearch;
      console.log('load data');
      if (this.resultLabel !== '') {
        attributesToGet = this.resultLabel.match(/\{(.*?)\}/g)?.map((x) => x.replaceAll('{', '').replaceAll('}', '')).join(',');
        console.log(attributesToGet);
      }

      let url = `${this.wfsUrl}&REQUEST=GetFeature&typenames=${this.layer}&typename=${this.layer}&srsName=EPSG:4326&outputFormat=application/json&propertyName=${attributesToGet}&sortBy=${this.attributeSearch}`;

      if (filter.length > 0) {
        const filterXml = new Filter();
        filterXml.fromCQL(`${this.attributeSearch} like '%${filter}%'`);
        const xmlFilter = filterXml.toSld();
        url += `&FILTER=${xmlFilter}`;
      }

      // &propertyName=nom,insee_reg

      return axios.get(url);
    }

    loadFeature(attributeValue:string):Promise<AxiosResponse<any>> {
      let url = `${this.wfsUrl}&REQUEST=GetFeature&typenames=${this.layer}&typename=${this.layer}&srsName=EPSG:3857&outputFormat=application/json`;
      const filterXml = new Filter();
      filterXml.fromCQL(`${this.attributeSearch} = '${attributeValue}'`);
      const xmlFilter = filterXml.toSld();
      url += `&FILTER=${xmlFilter}`;

      return axios.get(url);
    }

    getPlaceHolder():string {
      return this.placeholder;
    }

    getSimplifiedGeometry(selectedFeature:Feature) {
      const geom = selectedFeature.getGeometry();
      if (geom) {
        const simplified = geom.simplify(this.simplifyTolerance);
        return simplified;
      }
      return undefined;
    }

    getGeometryAsText(simplified:Geometry, dataProjection:string) {
      const reproj = simplified.clone().transform('EPSG:3857', dataProjection);
      let fProjection = dataProjection;
      if (fProjection === 'EPSG:4326') {
        fProjection = 'WGS84'; // pour faire une inversion lat/lon => lon/lat
      }
      const geomStr = new WKT().writeGeometry(reproj,
        {
          featureProjection: fProjection,
          dataProjection,
          decimals: 3,
        });
      return geomStr;
    }

    getGeometricFilterValue(val:Feature, dataProjection = 'EPSG:2154') {
      const simplifiedGeom = this.getSimplifiedGeometry(val);
      if (simplifiedGeom) {
        return this.getGeometryAsText(simplifiedGeom, dataProjection);
      }
      return undefined;
    }

    getBbox(val:string):[] {
      const zone:any = this.datas.find((x:any) => x.properties[this.attributeSearch] === val);
      if (zone && zone.bbox) {
        return zone.bbox;
      }
      if (zone && zone.properties.bbox) {
        return zone.properties.bbox;
      }
      return [];
    }

    getDisplayLabel(s:any):string {
      if (this.resultLabel !== '') {
        console.log(this.resultLabel);
        return this.resultLabel.replace(/\{(.*?)\}/g, (a, b) => {
          try {
            const value = s.properties[b];
            return value || b;
          } catch (e) {
            return b;
          }
        });
      }
      return s.properties[this.attributeSearch];
    }

    getChoices():string[] {
      return this.choices;
    }
}
