/* eslint-disable camelcase */
export interface IQueryParams {
    page: number;
    page_size: number;
    sort_by:string;
    sortOrder:string;
    filter:string;
    filterOn:string[];
}

export default class QueryParams implements IQueryParams {
    page!: number;

    page_size!: number;

    sort_by!:string;

    sortOrder!:string;

    filter!:string;

    filterOn!:string[];

    constructor(input?: any) {
      if (input) {
        if (input.filter && input.filter != null) {
          this.filter = input.filter;
        }

        this.page_size = input.perPage;
        this.page = input.currentPage;
        this.sort_by = input.sortBy;
        this.sortOrder = 'ASC';
        if (input.sortDesc) {
          this.sortOrder = 'DESC';
        }
      }
    }
}
