import chroma from 'chroma-js';

export interface IColorPalette {
    name: string;
    values: string[] ;
  }

export default class ColorPalette implements IColorPalette {
  name!: string;

  values!: string[];

  constructor(input?: unknown) {
    if (input) {
      Object.assign(this, input);
    }
  }

  getColorValue(i:number, totalColors:number,reverse=false):string {
    let colors = this.values;
    if (this.name !== undefined) {
      colors = chroma.scale(this.name).colors(totalColors);
    }
    if(reverse){
      colors=colors.reverse();
    }
    return colors[i % totalColors];
  }
}
