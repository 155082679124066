/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-this-alias */
import { IPluginParameter } from '@/models/plugin/IPluginParameter';
import PluginParameter from '@/models/plugin/parameter/PluginParameter';
import { ParameterType } from '@/models/plugin/ParameterType';
import Plugin from '@/models/plugin/Plugin';
import PluginConfig from '@/models/plugin/PluginConfig';
import { PluginJsonConfig } from '@/models/plugin/PluginJsonConfig';
import { PluginLocation } from '@/models/plugin/PluginLocation';
import axios, { AxiosResponse } from 'axios';

class PluginService {
    plugins: Plugin[] =
    [
      // ----- Navigation -------
      new Plugin('navigation',
        'Navigation',
        'Affichage des boutons de navigation',
        'NavigationPlugin',
        [
          new PluginParameter<boolean>('zoomInOut', 'Boutons Zoom + et -', ParameterType.Boolean, true),
          new PluginParameter<boolean>('zoomToMaxExtent', 'Bouton Vue Globale', ParameterType.Boolean, true),
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.TopRight })),
      // ----- Echelle -------
      new Plugin('scale',
        'Echelle',
        'Affichage de l\'échelle de la carte',
        'ScalePlugin',
        [
          new PluginParameter<boolean>('mode', 'Mode ScaleBar', ParameterType.Boolean, false),
          new PluginParameter<number>('nbBars', 'Nombre de Barres', ParameterType.Number, 2),
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.BottomLeft })),
      // ----- Liste d'Echelles -------
      new Plugin('scale-list',
        'Liste d\'Echelles',
        'Affichage d\'une liste d\'échelles',
        'ScaleListPlugin',
        [
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.BottomLeft })),
      // ----- Coordonnées de la souris -------
      new Plugin('coordonnes',
        'Coordonnées',
        'Affichage des coordonnées de la position de la souris.',
        'MousePositionPlugin',
        [
          new PluginParameter<string>('displayProj', 'Projection', ParameterType.Select, 'EPSG:2154', {
            choices: [
              { text: 'EPSG:2154', value: 'EPSG:2154' },
              { text: 'EPSG:4326', value: 'EPSG:4326' },
              { text: 'EPSG:3857', value: 'EPSG:3857' },
            ],
          }),
          // new PluginParameter<string>('displayProj',
          // 'Projection', ParameterType.Text, 'EPSG:2154'),
          new PluginParameter<string>('format', 'Format', ParameterType.Text, 'X:{x} Y:{y} (Lambert 93)'),
          new PluginParameter<number>('precision', 'Nombre de chiffres significatifs', ParameterType.Number, 2),
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.BottomLeft })),
      // ----- Recherche de lieux -------
      new Plugin('recherche-lieux',
        'Recherche de lieux',
        'Rechercher et localiser des adresses et lieux-dits.',
        'ApiAdressePlugin',
        [
          new PluginParameter<string>('service', 'Moteur de recherche', ParameterType.Select, 'datagouv', {
            choices: [
              { text: 'adresse.data.gouv.fr', value: 'datagouv' },
              { text: 'nominatim.openstreetmap.org', value: 'nominatim' },
            ],
            description: 'Permet de choisir quel est le moteur utilisé pour les recherches',
          }),
          new PluginParameter<number>('result-count', 'Nombre de résultats', ParameterType.Number, 5),
          new PluginParameter<boolean>('viewbox', 'Limiter les recherches sur l\'emprise courante', ParameterType.Boolean, false, {
            description: 'Permet de limiter les recherches d\'adresses sur l\'emprise courante',
            relatedId: 'service',
          }),
          new PluginParameter<string>('extra', 'Paramètres de supplémentaires', ParameterType.Text, '', {
            description: 'Permet d\'ajouter des paramètres au moteur de recherche, par exemple pour limiter par ville ou pays...\n\nExemple :\ncountrycodes=ci ou citycode=44162&city=Saint-Herblain',
          }),
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.TopRight })),
      // ----- Indicateur de chargement des tuiles -------
      new Plugin('loading-infos',
        'Indicateur de chargement',
        'Affiche une indication lorsque la carte n\'est pas complètement chargée.',
        'LoadingInfosPlugin',
        [],
        new PluginConfig({ isActive: true, position: PluginLocation.BottomRight })),
     // ----- Gestionnaire de couches -------
      new Plugin('layerList',
        'Couches de données',
        'Afficher le gestionnaire de couches',
        'LayerListPanel',
        [new PluginParameter<boolean>('showConfigBtn', 'Affichage du bouton de configuration',
          ParameterType.Boolean, true, {
            description: 'Affichage du bouton permettant de paramétrer une couche',
          }),
        new PluginParameter<boolean>('showDeleteBtn', 'Affichage du bouton de suppression',
          ParameterType.Boolean, true, {
            description: 'Affichage du bouton de suppression d\'une couche',
          }),
        new PluginParameter<boolean>('showAddLayerBtn', 'Ajouter des données',
          ParameterType.Boolean, true, {
            description: 'Affichage du bouton permettant d\'ajouter des données',
          })],
        new PluginConfig({ isActive: true, activeInEdition:true,position: PluginLocation.LeftToolBar, icon: 'layers' })),
      // ----- Légende -------
      new Plugin('legend',
        'Légende',
        'Afficher une légende',
        'LegendPlugin',
        [],
        new PluginConfig({ isActive: true, position: PluginLocation.LeftToolBar, icon: 'list-stars' })),
      // ----- Impression -------
      new Plugin('print',
        'Impression',
        'Imprimer la carte en pdf ou en png',
        'PrintPanelPlugin',
        [new PluginParameter<string>('logo', 'Url de l\'image du bandeau', ParameterType.Text, '', {
          description: 'Définir ici une url pour customiser le bandeau du haut pour l\'impression',
        })],
        new PluginConfig({ isActive: true, position: PluginLocation.LeftToolBar, icon: 'printer' })),
      // ----- Fiche Info -------
      new Plugin('feature-infos',
        'Fiche Information',
        'Affiche une fiche info lorsqu\'on clique sur un objet de la carte',
        'FeatureInfosPanel',
        [
          new PluginParameter<boolean>('movable', 'Activer le mode déplaçable au lancement de la carte', ParameterType.Boolean, false, {
            description: 'Activez cette option pour permettre le déplacement de la fiche d\'information dès le lancement de la carte. Si cette option n\'est pas activée, la fiche d\'information sera intégrée au menu.',
          }),
          new PluginParameter<boolean>('movableSwitch', 'Autoriser le passage entre le mode déplaçable et le mode menu', ParameterType.Boolean, true, {
            description: 'Activer pour autoriser le passage entre le mode déplaçable et le mode menu de la fiche d\'information',
          }),
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.LeftToolBar, icon: 'file-earmark-text' })),
      // ----- Mesure de distances -------
      new Plugin('measure',
        'Mesure de distances/surfaces',
        'Afficher les boutons de mesures de distances/surfaces.',
        'MesurePlugin',
        [
          new PluginParameter<boolean>('distances', 'Mesure de distances', ParameterType.Boolean, true),
          new PluginParameter<boolean>('surfaces', 'Mesure de surfaces', ParameterType.Boolean, true),
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.TopLeft })),
      // ----- Permalien -------
      new Plugin('permalink',
        'Permaliens',
        'Affiche un permalien pour partager la carte',
        'PermalinkPlugin',
        [
        ],
        new PluginConfig({ isActive: true, position: PluginLocation.LeftToolBar, icon: 'share-fill' })),
      new Plugin('add3dModel',
        'Ajout de modèles 3D',
        'Ajouter des modules 3D à la carte',
        'AddModelPlugin',
        [],
        new PluginConfig({
          isActive: false, only3d: true, position: PluginLocation.LeftToolBar, icon: 'box',
        })),
    ];

    constructor() {
      console.log('pluginService loaded');
      this.loadCustomPlugins().then((result) => {
        console.log(result.data);
        result.data.forEach((pluginCfg:any) => {
          const p = new Plugin(pluginCfg.id,
            pluginCfg.label,
            pluginCfg.description,
            pluginCfg.componentName,
            pluginCfg.parameters.map((x:IPluginParameter) => new PluginParameter(
              x.id, x.label, ParameterType[x.type as keyof typeof ParameterType], x.defaultValue, {
                min: x.min,
                max: x.max,
                choices: x.choices,
                multiple: x.multiple,
                description: x.description,
                relatedId: x.relatedId,
                hasAttributes: x.hasAttributes,
                hasFeatures: x.hasFeatures,
              },
            )),
            new PluginConfig(pluginCfg.configuration));
          if (pluginCfg.configuration.first === true) {
            this.plugins.unshift(p);
          } else {
            this.plugins.push(p);
          }
        });
      });
    }

    loadCustomPlugins(): Promise<AxiosResponse<any>> {
      const url = './config/custom_plugins.json';
      return axios.get(url);
    }

    getParameter(componentName: string, attributeName:string): any {
      const plugin = this.getByName(componentName);
      if (plugin) {
        return plugin.getParameterValue(attributeName);
      }
      return null;
    }

    getBooleanParameter(componentName: string, attributeName:string): boolean {
      const plugin = this.getByName(componentName);
      if (plugin) {
        return plugin.getParameterValue(attributeName) === true;
      }
      return false;
    }

    setBooleanParameter(componentName: string, attributeName:string, value: boolean): void {
      const plugin = this.getByName(componentName);
      if (plugin) {
        const parameter = plugin.parameters.find((v) => v.id === attributeName);
        if (parameter) {
          parameter.setValue(value);
        }
      }
    }

    isActive(pluginName:string): boolean {
      const p = this.plugins.find((x) => x.componentName === pluginName);
      if (p !== undefined) {
        return p.isActive;
      }
      return false;
    }

    getByName(pluginName:string): Plugin | undefined {
      return this.plugins.find((x) => x.componentName === pluginName);
    }

    loadPlugins(data: PluginJsonConfig[]): void{
      this.plugins.forEach((p) => p.reset());
      if (data && data.length) {
        console.log('load Plugins');
        data.forEach((d) => {
          const plugin = this.plugins.find((w) => w.id === d.plugin);
          if (plugin) {
            plugin.fromJson(d);
            plugin.init();
          }
        });
      }
    }
}
const pluginServiceInstance = new PluginService();
export default PluginService;
export { PluginService, pluginServiceInstance };
