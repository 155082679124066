<template>
  <div class="layer-selector">
    <div class="layer-list" v-if="listLayers">
      <div v-for="layer in visibleLayers" :key="layer.layername" class="layer-item" @click="selectLayer(layer)" :title="layer.title">
        <b-img thumbnail fluid :src="getImageForLayer(layer)" class="layer-icon"></b-img>
        <div class="text-center text-black truncate-text">{{ layer.title }}</div>
      </div>
    </div>
    <div class="selected-layer" @click="toggleVisibility" :title="layerSelected.title">
      <b-img thumbnail fluid :src="getImageForLayer(layerSelected)" class="layer-icon"></b-img>
      <div class="text-center text-black truncate-text">{{ layerSelected.title }}</div>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import PluginComponent from './PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';

@Component({
  props: {},
})
export default class BaseLayerSelectPlugin extends PluginComponent {
  static ID = 'BaseLayerSelectPlugin';
  visibleLayers = [];
  layerSelected = null;
  listLayers = false;
  layerGroupUid = []

  mounted() {
  EventBus.$on(EVENTS.LAYER_ADDED, () => {
    this.handleLayerEvent();
  });

  EventBus.$on(EVENTS.LAYER_ORDER_CHANGE, () => {
      this.handleLayerEvent();
    });
  }

  created() {
    this.handleLayerEvent();
  }

  handleLayerEvent() {
    this.layerGroupUid = this.plugin.getParameterValue('layerGroupUid');
    if (this.layerGroupUid) {
      const layersGroup = this.contextService.getCurrentContext().layers.find((v) => v.uid === this.layerGroupUid);
      if (layersGroup) {
        const baseLayers = layersGroup.childs;
        const activeLayerExists = baseLayers.some(layer => layer === this.layerSelected);

        if (!activeLayerExists || !baseLayers.some(layer => layer.visible)) {
          const firstVisibleLayer = baseLayers.find(layer => layer.visible);
          this.layerSelected = firstVisibleLayer;
        }

        if (!this.layerSelected) {
          this.layerSelected = baseLayers.find(layer => layer.visible);
        }

        if (!activeLayerExists && this.visibleLayers.length > 0) {
          this.layerSelected = this.visibleLayers[0];
        }

        if (this.layerSelected) {
          this.layerSelected.setVisible(true);
          this.visibleLayers = baseLayers.filter(layer => layer !== this.layerSelected);
        }
      }
    }
  }





  toggleVisibility() {
    this.listLayers = !this.listLayers;
  }

  getImageForLayer(layer) {
    const images = [
      { src: "img/basemaps/openmaptiles.png", layername: "openmaptiles", title: "Vecteur Tuilé OpenMaptiles"},
      { src: "img/basemaps/mvt_classique.png", layername: "plan_ign_classique", title: "Vecteur Tuilé Plan IGN (classique)"},
      { src: "img/basemaps/mvt_standard.png", layername: "plan_ign_standard", title: "Vecteur Tuilé Plan IGN (standard)"},
      { src: "img/basemaps/ortho.png", layername: "ORTHOIMAGERY.ORTHOPHOTOS", title: "Photographies aériennes"},
      { src: "img/basemaps/plan.png", layername: "GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2", title: "Plan IGN"},
      { src: "img/basemaps/mvt_gris.png", layername: "plan_ign_gris", title: "Vecteur Tuilé Plan IGN (Gris)"},
      { src: "img/basemaps/osm.png", layername: "OSM", title: "OSM"},
    ];

    let layerName = layer.layername;

    if(layerName === "plan_ign") {
        const style = layer.mbStyleUrl.split('/').pop().split('.')[0];
        layerName = layerName + "_" + style;
    }
    const image = images.find(img => img.layername === layerName);

    return image ? image.src : "img/basemaps/exemple.png";

  }

  selectLayer(layer) {
    this.visibleLayers.forEach(visibleLayer => {
      if (visibleLayer !== layer) {
        visibleLayer.setVisible(false);
      }
    });

    if (this.layerSelected) {
      // Vérifiez si le layer sélectionné est différent du layer actif
      if (this.layerSelected !== layer) {
        this.layerSelected.setVisible(false);
        this.visibleLayers.push(this.layerSelected);
      }
    }

    layer.setVisible(true);
    this.layerSelected = layer;
    this.listLayers = false;
    this.visibleLayers = this.visibleLayers.filter(item => item !== layer);
  }

}
</script>

<style scoped lang="less">
@import "../../styles/variables.less";

.layer-selector {
  display: flex;
  pointer-events: auto;
  position: absolute;
  right: 30px;
  bottom: 20px;
}

.layer-list {
  display: flex;
}

.layer-item {
  cursor: pointer;
  flex: auto;
  align-items: center;
  padding: 8px;
  max-width: 112px;
}

.layer-icon {
  border: 0 !important;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  transform: perspective(500px) rotateX(30deg);
}

.selected-layer {
  cursor: pointer;
  max-width: 112px;
  flex: auto;
  align-items: center;
  padding: 8px;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.text-black{
  text-shadow: 0px 0px 2px white;
}

.toggle-button {
  margin-top: 20px;
}
</style>
