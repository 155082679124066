/* eslint-disable @typescript-eslint/no-this-alias */
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import MapUtils from '../MapUtils';
import SymbolStyle from './SymbolStyle';

export default class FillStyle {
    private baseStyle!: FillStyle;

    fillSymbol: SymbolStyle;

    fillColor!: string;

    fillPattern = 'simple';

    fillPatternSize!: number;

    fillPatternMargin!: number;

    fillExtrudeHeight!: number;

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
        this.fillSymbol = new SymbolStyle(json.fillSymbol);
      } else {
        this.fillSymbol = new SymbolStyle();
      }
    }

    setBaseStyle(baseStyle: FillStyle):void {
      this.baseStyle = baseStyle;
      if (baseStyle !== undefined) this.fillSymbol.setBaseStyle(baseStyle.fillSymbol);
    }

    hasFillPattern() : boolean {
      const fillPattern = this.getFillPattern();
      return fillPattern !== undefined && fillPattern.length > 0 && fillPattern !== 'simple' && fillPattern !== 'none';
    }

    setDefaultValues() :void {
      this.fillSymbol = new SymbolStyle();
      this.fillSymbol.setDefaultValues();
      this.fillColor = 'rgba(51, 153, 204, 0.5)';
      this.fillPattern = 'simple';
      this.fillPatternMargin = 0;
      this.fillExtrudeHeight = 0;
    }

    toJson():any {
      return {
        fillSymbol: this.fillSymbol.toJson(),
        fillColor: this.fillColor,
        fillPattern: this.fillPattern,
        fillPatternMargin: this.fillPatternMargin,
        fillExtrudeHeight: this.fillExtrudeHeight,
      };
    }

    getFillPatternMargin(): number {
      return this.fillPatternMargin !== undefined
        ? this.fillPatternMargin : this.baseStyle.getFillPatternMargin();
    }

    getFillColor(): string {
      return this.fillColor ? this.fillColor : this.baseStyle.getFillColor();
    }

    toSld():string {
      if (this.getFillPattern() === 'none') {
        return '';
      }
      let fillContent = `
        <Fill>
            <CssParameter name="fill">${MapUtils.rgba2hex(this.getFillColor())}</CssParameter>
            <CssParameter name="fill-opacity">${MapUtils.rgba2opacity(this.getFillColor())}</CssParameter>
        </Fill>`;
      /*if (this.hasFillPattern()) {
        fillContent = `<Fill>${this.getSldGraphic()}</Fill>
                       <VendorOption name="graphic-margin">${this.getFillPatternMargin()} ${this.getFillPatternMargin()} ${this.getFillPatternMargin()} ${this.getFillPatternMargin()}</VendorOption>`;
      }*/
      return fillContent;
    }

    getFillPatternSld(): string {
      let fillContent = '';
      if (this.hasFillPattern()) {
        fillContent = `<Fill>${this.getSldGraphic()}</Fill>
                       <VendorOption name="graphic-margin">${this.getFillPatternMargin()} ${this.getFillPatternMargin()} ${this.getFillPatternMargin()} ${this.getFillPatternMargin()}</VendorOption>`;
      }
      return fillContent;
    }
    getFillPattern(): string {
      return this.fillPattern !== undefined ? this.fillPattern : this.baseStyle.getFillPattern();
    }

    getRenderFunction(): any {
      const fillPattern = this.getFillPattern();
      const img = this.fillSymbol.getImage();

      const canvas = document.createElement('canvas');
      const context:any = canvas.getContext('2d');
      const pixelRatio = DEVICE_PIXEL_RATIO;
      const self = this;
      const fillColor = (function () {
        if (['slash', 'backslash'].indexOf(fillPattern) >= 0) {
          canvas.width = 32;
          canvas.height = 16;
        } else if (['horline', 'vertline'].indexOf(fillPattern) >= 0) {
          canvas.width = 10;
          canvas.height = 10;
        } else if (['svg'].indexOf(fillPattern) >= 0) {
          canvas.width = Number(self.fillSymbol.getSize())
          + Number(self.getFillPatternMargin()) * 2;
          canvas.height = Number(self.fillSymbol.getSize())
          + Number(self.getFillPatternMargin()) * 2;
        } else {
          canvas.width = Number(self.fillSymbol.getSize())
          + Number(self.getFillPatternMargin()) * 2;
          canvas.height = Number(self.fillSymbol.getSize())
          + Number(self.getFillPatternMargin()) * 2;
        }

        const strokeColor = self.fillSymbol.getSymbolStrokeColor();
        context.strokeStyle = strokeColor;

        const backfillColor = self.getFillColor();
        context.fillStyle = backfillColor;
        if (backfillColor.length > 0) context.fillRect(0, 0, canvas.width, canvas.height);

        const sfillColor = self.fillSymbol.getSymbolFillColor();
        context.fillStyle = sfillColor;
        context.lineWidth = self.fillSymbol.getSymbolStrokeWidth();
        

        if (fillPattern === 'slash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
         
        } else if (fillPattern === 'backslash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.scale(-1, 1);
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
        } else if (fillPattern === 'cross') {
          context.scale(self.fillSymbol.getSize() / 10, self.fillSymbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (fillPattern === 'plus') {
          context.translate(Number(self.getFillPatternMargin()),
            Number(self.getFillPatternMargin()));
          context.scale(self.fillSymbol.getSize() / 10, self.fillSymbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (fillPattern === 'square') {
          context.scale(self.fillSymbol.getSize() / 10, self.fillSymbol.getSize() / 10);
          context.beginPath();
          context.moveTo(1, 1);
          context.lineTo(9, 1);
          context.lineTo(9, 9);
          context.lineTo(1, 9);
          context.lineTo(1, 1);
          if (strokeColor.length > 0) context.stroke();
          if (sfillColor.length > 0) context.fill();
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (fillPattern === 'dot') {
          context.scale(self.fillSymbol.getSize() / 10, self.fillSymbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 1, 0, 2 * Math.PI);
          if (strokeColor.length > 0) context.stroke();
          if (sfillColor.length > 0) context.fill();
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (fillPattern === 'circle') {
          context.scale(self.fillSymbol.getSize() / 10, self.fillSymbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 4, 0, 2 * Math.PI);
          if (strokeColor.length > 0) context.stroke();
          if (sfillColor.length > 0) context.fill();
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (fillPattern === 'horline') {
          context.beginPath();
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (fillPattern === 'vertline') {
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.stroke();
        } else if (fillPattern === 'svg') {
          if (img instanceof HTMLImageElement) {
            context.drawImage(img, self.getFillPatternMargin(),
              self.getFillPatternMargin(),
              self.fillSymbol.getSize(), self.fillSymbol.getSize());
          }
        }

        return context.createPattern(canvas, 'repeat');
      }());
      return fillColor;
    }

    getSldGraphic(type = 'fill') {
      let graphicType = 'GraphicFill';
      const fillcolor = MapUtils.rgba2hex(this.fillSymbol.getSymbolFillColor());
      const strokecolor = MapUtils.rgba2hex(this.fillSymbol.getSymbolStrokeColor());
      if (type === 'stroke') {
        graphicType = 'GraphicStroke';
        // color=this.rgba2hex(this.getSymbolStrokeColor());
      }
      const fillPattern = this.getFillPattern();
      let wkn = '';
      if (fillPattern === 'cross'
            || fillPattern === 'circle'
            || fillPattern === 'square'
            || fillPattern === 'triangle'
            || fillPattern === 'star'
            || fillPattern === 'x') {
        wkn = fillPattern;
      } else if (fillPattern === 'slash' || fillPattern === 'backslash' || fillPattern === 'plus'
            || fillPattern === 'horline' || fillPattern === 'vertline'
            || fillPattern === 'dot' || fillPattern === 'times'
            || fillPattern === 'oarrow' || fillPattern === 'carrow'
      ) {
        wkn = `shape://${fillPattern}`;
      }

      let retour = '';
      if (fillPattern === 'svg') {
        const url = this.fillSymbol.getSvgSymbolUrl();
        retour = `<${graphicType}><Graphic><ExternalGraphic>
                <OnlineResource
                  xlink:type="simple"
                  xlink:href="${url}" />
                <Format>image/svg</Format>
              </ExternalGraphic>
              <Size>${this.fillSymbol.getSize()}</Size>
            </Graphic></${graphicType}>
            `;
      } else {
        retour = `<${graphicType}><Graphic>
            <Mark>
                <WellKnownName>${wkn}</WellKnownName>
                <Stroke>
                    <CssParameter name="stroke">${strokecolor}</CssParameter>
                    <CssParameter name="stroke-width">${this.fillSymbol.getSymbolStrokeWidth()}</CssParameter>
                </Stroke>
                <Fill>
                    <CssParameter name="fill">${fillcolor}</CssParameter>
                    <CssParameter name="fill-opacity">${MapUtils.rgba2opacity(this.fillSymbol.getSymbolFillColor())}</CssParameter>
                </Fill>
            </Mark>
            <Size>${this.fillSymbol.getSize()}</Size>
          </Graphic></${graphicType}>`;
      }

      return retour;
    }
}
