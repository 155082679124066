





















import PluginComponent from '@/components/plugins/PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';
import axios, { Canceler } from 'axios';
import Component from 'vue-class-component';

export const NOMINATIM = 'nominatim';

@Component
export default class ApiAdressePlugin extends PluginComponent {
  addresses= [];

  addressSearch= '';

  selectedAddress:any= null;

  marker:any = null;

  service = '';

  baseURL = '';

  extraURL = '';

  limitMap = false;

  viewbox = '';

  bbox:number[] = [];

  private cancelRequest: Canceler | null = null;

  beforeDestroy(): void {
    EventBus.$off(EVENTS.MAP_CURRENT_BBOX_CHANGE, this.changeBbox);
  }

  mounted(): void {
    EventBus.$on(EVENTS.MAP_CURRENT_BBOX_CHANGE, this.changeBbox);
    this.changeBbox(this.getMapService().getMapBoundsWGS84());
    this.service = this.plugin.getParameterValue('service');
    this.baseURL = this.service === NOMINATIM
      ? 'https://nominatim.openstreetmap.org/search.php?format=geojson&q='
      : 'https://api-adresse.data.gouv.fr/search/?q=';
    this.limitMap = this.service === NOMINATIM && this.plugin.getParameterValue('viewbox');
    const limit = this.plugin.getParameterValue('result-count');
    this.extraURL = `&limit=${limit}`;
    const extra = this.plugin.getParameterValue('extra');
    if (extra) {
      this.extraURL += `&${extra}`;
    }
    this.changeViewbox();
  }

  changeViewbox():void {
    if (this.limitMap) {
      this.viewbox = `&viewbox=${this.bbox}&bounded=1`;
    } else {
      this.viewbox = '';
    }
  }

  changeBbox(bbox:any):void {
    const coords:number[] = [];
    coords[0] = parseFloat(bbox[0].toFixed(5));
    coords[1] = parseFloat(bbox[1].toFixed(5));
    coords[2] = parseFloat(bbox[2].toFixed(5));
    coords[3] = parseFloat(bbox[3].toFixed(5));
    this.bbox = coords;
    this.changeViewbox();
  }

  selectAddresse(event:any):void {
    console.log(event);
    this.selectedAddress = event;
    if (this.selectedAddress !== null && this.selectedAddress.geometry) {
      let zoomlevel = 14;
      const { type } = this.selectedAddress.properties;
      if (type === 'housenumber') {
        zoomlevel = 19;
      } else if (type === 'street') {
        zoomlevel = 16;
      } else if (type === 'locality') {
        zoomlevel = 16;
      } else if (type === 'municipality') {
        zoomlevel = 14;
      }
      // On fait le zoom
      this.getMapService().zoomTo(this.selectedAddress.geometry.coordinates, zoomlevel);
      // On ajoute un point pour localiser la ville
      this.marker = this.getMapService().addOverlay(this.selectedAddress.geometry.coordinates);
    }
  }

  getAddresses(query:string, loading:(monParametre: boolean) => void):void {
    console.log('get addresses');
    // Annulez la requête précédente si elle existe
    if (this.cancelRequest) {
      this.cancelRequest();
    }
    // Créez une nouvelle fonction d'annulation pour la nouvelle requête
    const { token, cancel } = axios.CancelToken.source();
    this.cancelRequest = cancel;
    loading(true);
    axios.get(`${this.baseURL}${query}${this.viewbox}${this.extraURL}`, {
      cancelToken: token, // Associez le token d'annulation à la requête
    })
      .then((retour:any) => {
        this.addresses = retour.data.features;
      }).catch((error) => {
        // Si la requête est annulée alors on ne met pas à jour les adresses
        if (!axios.isCancel(error)) {
          this.addresses = [];
        }
      }).finally(() => loading(false));
  }

  getOptionLabel(s:any):string {
    return this.service === NOMINATIM ? s.properties.display_name : s.properties.label;
  }
}
