

























































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SingleLayerAttributeInfos from '@/components/layers/single-layer-infos/SingleLayerAttributesInfos.vue';
import SingleLayerMainInfos from '@/components/layers/single-layer-infos/SingleLayerMainInfos.vue';
import SingleLayerPopupInfos from '@/components/layers/single-layer-infos/SingleLayerPopupInfos.vue';
import SingleLayerStyle from '@/components/layers/single-layer-infos/SingleLayerStyle.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import EventBus, { EVENTS } from '@/services/EventBus';
import FileSaver from 'file-saver';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import AuthenticationInfoPanel from '../shared/AuthenticationInfoPanel.vue';
import DatavizInfos from './single-layer-infos/DatavizInfos.vue';
import SingleLayerComplexStyle from './single-layer-infos/SingleLayerComplexStyle.vue';
import SingleLayerCoupe from './single-layer-infos/SingleLayerCoupe.vue';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
    AuthenticationInfoPanel,
    SingleLayerMainInfos,
    SingleLayerAttributeInfos,
    SingleLayerPopupInfos,
    SingleLayerStyle,
    SingleLayerComplexStyle,
    SingleLayerCoupe,
    DatavizInfos,
  },
})
export default class SingleLayerInfos extends BaseComponent {
  layer!: LayerConfig;

  activeTab=0;

  errorTxt:any=null;

  layerBackup!: LayerConfig;

  showAuthInfos=false;

  showTranformWFSMsg=false;

  showTranformWMSMsg=false;

  showTranformWMTSMsg=false;

  layerTypeChanged = false; // indique si on est passé de WMS a WFS par ex
  
  defaultSyle = false;

  styleName= '';

  mounted():void {
    // copie en cas d'annulation des modifications
    this.layerBackup = this.layer.toJson();
    // this.layerBackup = JSON.parse(JSON.stringify(this.layer));
    this.updateTransformMsg();
    EventBus.$on(EVENTS.OPEN_LAYER_CONFIG_TAB, this.onShowTab);

  }

  onShowTab(tabnumber: number){
    console.log('onShowTab '+tabnumber)
    this.activeTab=tabnumber;
  }

  updateTransformMsg(){
    this.showTranformWFSMsg = false;
    this.showTranformWMTSMsg = false;
    this.showTranformWMSMsg = false;

    if (this.layer.isWMS() && this.layer.hasWFSCapacity()) {
      this.showTranformWFSMsg = true;
    }
    if (this.layer.isWMS() && this.layer.hasWMTSCapacity()) {
      this.showTranformWMTSMsg = true;
    }
    if (this.layer.isWFS()) {
      this.showTranformWMSMsg = true;
    }
    if (this.layer.isWMTS() && this.layer.hasWMSCapacity()) {
      this.showTranformWMSMsg = true;
    }
  }
  isDatavizEnabled():boolean {
    return this.contextService.isDatavizEnabled();
  }

  isComplexStyleEnabled():boolean {
    return this.contextService.isComplexStyleEnabled();
  }

  updateLayer() {
    this.layer.resetErrors();
    const retour = this.getMapService().updateLayer(this.layer);
    if (!retour.success) {
      this.errorTxt = retour.message;
    } else {
      this.contextService.setCurrentAction('', []);
    }
    EventBus.$emit(EVENTS.LAYER_UPDATED,this.layer);
  }
  
  sendSld():void{
    this.showAuthInfos=false;
    this.contextService.sendSld(this.layer,this.styleName).then((reponse:any) => {
      console.log(reponse);
      if(reponse.status==201){
        this.contextService.assignSld(this.layer,this.styleName,this.defaultSyle).then((reponse:any) => {
          console.log(reponse);
          if(reponse.status==201){
            this.layer.styles.push(this.styleName);
            EventBus.$emit(EVENTS.INFO,"Style créé avec succès");
          }
          else{
            EventBus.$emit(EVENTS.ERROR,"Impossible d'associer le style à la couche");
          }
        
        }).catch((error) => {
          console.log(error.response);
          console.log(error);
          EventBus.$emit(EVENTS.ERROR,"Impossible d'associer le style à la couche: "+error.response.data);
        
        });
      }
      else{
          EventBus.$emit(EVENTS.ERROR,"Impossible de créer le style");
      }
      
    }).catch((error) => {
      console.log(error.response);
      console.log(error);
      EventBus.$emit(EVENTS.ERROR,"Impossible de créer le style: "+error.response.data);
     
    });
  }

  exportSld() :void{
    const sld = this.layer.styletoSld();
    const filename = `${this.layer.layername}.sld`;
    const blob = new Blob([sld], { type: 'text/xml;charset=utf-8;' });
    if ((window.navigator as any).msSaveBlob) {
      (window.navigator as any).msSaveBlob(blob, filename);
    } else {
      FileSaver.saveAs(blob, filename);
    }
  }

  updateStyle() :void{
    this.layer.updateStyle();
  }

  ok() :void{
    this.updateLayer();
  }

  toWFS():void{
    this.getMapService().removeLayerFromMap(this.layer);
    this.layer.type = 'WFS';
    this.layer.url = this.layer.wmsUrlToWfs(this.layer.url);
    this.layer.outputFormat = 'application/json';
    this.getMapService().addLayer(this.layer);
    this.updateTransformMsg();
    this.layerTypeChanged = true;
  }

  toWMTS():void{
    this.getMapService().removeLayerFromMap(this.layer);
    this.layer.url = this.layer.getWmtsCapabilitiesUrl();
    this.layer.type = 'WMTS';
    if (!this.layer.outputFormat) {
      this.layer.outputFormat = 'image/png';
    }
    this.getMapService().addLayer(this.layer);
    this.updateTransformMsg();
    this.layerTypeChanged = true;
  }

  toWMS():void{
    this.getMapService().removeLayerFromMap(this.layer);
    
    if(this.layer.isWFS()){
      this.layer.url = this.layer.wfsUrlToWms(this.layer.url);
    }
    else if(this.layer.isWMTS()){
      const serviceRegex = new RegExp('service(=|%3D)WMTS', 'ig');
      const versionRegex = new RegExp('version(=|%3D)2.0.0', 'ig');
      const wfsRegex = new RegExp('wmts', 'ig');
      const pathRegex = new RegExp('/gwc/service/wmts', 'ig');
      this.layer.url = this.layer.url.replace(pathRegex, '/wms').replace(serviceRegex, 'service=wms').replace(versionRegex, 'version=1.3.0').replace(wfsRegex, 'wms');
    }
    this.layer.type = 'WMS';
    this.layer.outputFormat = 'image/png';
    this.getMapService().addLayer(this.layer);
    this.updateTransformMsg();
    this.layerTypeChanged = true;
  }

  cancel() :void{
    console.log('cancel');
    this.layer.loadFromJson(this.layerBackup);
    if (this.layerTypeChanged) {
      this.getMapService().removeLayerFromMap(this.layer);
      this.getMapService().addLayer(this.layer);
    }
    // this.layer = Object.assign(this.layer, this.layerBackup);
    this.contextService.setCurrentAction('', []);
  }
}
