import AdministrativeArea from "./shared/AdministrativeArea";
import DownloadProjection from "./shared/DownloadProjection";

export default class Configuration {

  base_api_url='';


  static_base_url=".";

  maps_api_url="/fr/maps/maps";

  catalog_url="/fr/indexer/elastic/_search/?request_cache=true";

  geoserver_api_url='';

  documentation_url="";

  cors_proxy_url!:string;

  login_url!:string;

  publish_url!:string;

  home_url!:string;

  explorer_url!:string;

  administrativeAreas:AdministrativeArea[]=[];

  downloadProjections:DownloadProjection[]=[];

  addLayers!:any;

  standalone!:boolean;

  standalone_options!:any;

  new_map_defaults!:any;

  customHeaderAndFooter!:any;

  geoserver_extra_fonts=[];

  header!:any;

  footer!:any;

  dataviz:any={
    active:false
  };

  complexStyling:any={
    active:true
  };

  "3d"!:any;

  constructor(input?: any) {
    console.log(input);
    if (input) {
      Object.assign(this, input);
    }
    //downloadProjections
    if (input.downloadProjections && input.downloadProjections.length > 0) {
      this.downloadProjections = input.downloadProjections.map((x:any) => new DownloadProjection(x));
    } else {
      this.downloadProjections = [{
        label: 'EPSG:2154',
        codeEpsg: 'EPSG:2154',
      },
      {
        label: 'EPSG:4326',
        codeEpsg: 'EPSG:4326',
      },
      {
        label: 'EPSG:3857',
        codeEpsg: 'EPSG:3857',
      }
      ].map((x:any) => new DownloadProjection(x));
    }
     //administrativeAreas
    if (input.administrativeAreas && input.administrativeAreas.length > 0) {
      this.administrativeAreas = input.administrativeAreas.map((x:any) => new AdministrativeArea(x));
    }

  }


}
