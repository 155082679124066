






















/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
  },
})
export default class DatavizInfos extends BaseComponent {
  layer!: LayerConfig;
}
