



























/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import LayerConfig from '@/models/map-context/LayerConfig';
import PhotomaillageToolType from '@/models/map-context/PhotomaillageToolType';
import CesiumDecoupMeshService from '@/services/CesiumDecoupMeshService';
import CesiumEditionService from '@/services/CesiumEditionService';
import { CesiumMapService } from '@/services/CesiumMapService';
import CesiumSelectionService from '@/services/CesiumSelectionService';
import SelectionService from '@/services/SelectionService';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

declare let Cesium: any;

@Component({
  props: {
    layer: LayerConfig,
  },
})
export default class PhotomaillageToolBar extends BaseComponent {
  public PhotomaillageToolType = PhotomaillageToolType;

  selectionService!:SelectionService| CesiumSelectionService;

  editionService!:CesiumEditionService;

  layer!:LayerConfig;

  decoupmeshService!: CesiumDecoupMeshService;

  texthelp = 'text';

  textTop = '0';

  textLeft = '0';

  textVisible = true;


  getStyle() {
    return {
      visibility: this.textVisible === true ? 'visible' : 'hidden',
      'pointer-events': 'none',
      backgroundColor: '#FFFFFF50',
      width: '350px',
      height: '50px',
      color: '#000000',
      top: this.textTop,
      left: this.textLeft,
      position: 'absolute',
    };
  }

  created() {
    this.selectionService = this.getMapService().selectionService;

    const mapService = this.getMapService();
    if (mapService instanceof CesiumMapService) {
      this.editionService = mapService.editionService;
    }
  }

  mounted() {
    const mapService = this.getMapService();
    if (mapService instanceof CesiumMapService) {
      this.decoupmeshService = new CesiumDecoupMeshService(mapService, this.layer, this.changeTextHelp, this.changePositionHelp);
    }
  }

  changeTextHelp(text:string): void {
    this.texthelp = text;
    if (text === '') {
      this.textVisible = false;
    } else {
      this.textVisible = true;
    }
  }

  changePositionHelp(left:number, top: number) {
    this.textTop = `${top.toString()}px`;
    this.textLeft = `${left.toString()}px`;
  }

  public deleteAllHoles(): void {
    if (this.editionService.activeTool === '') {
      this.decoupmeshService.removeAllHoles();
      this.layer.layer3dConfig.listeTrou = [];
    }
  }

  public addHole(): void {

    if (this.editionService.activeTool === '') {
      this.editionService.activeTool = PhotomaillageToolType.ADD;
      this.decoupmeshService.stop();
      this.decoupmeshService.start((contour:any[]) => {
        console.log("addHole");
        this.editionService.activeTool = '';
        //this.layer.layer3dConfig.listeTrou.push(contour);
        let contourCarthographic:any[] = [];
        contour.forEach(cartesian => {
          const cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian);
          const lat = Cesium.Math.toDegrees(cartographic.latitude);
          const lon = Cesium.Math.toDegrees(cartographic.longitude);
          contourCarthographic.push({"latitude":lat,"longitude":lon});
        });
        this.layer.layer3dConfig.listeTrou = [contourCarthographic];
      });
    } else {
      this.decoupmeshService.stop();
      this.editionService.activeTool = '';
    }
  }

  public deleteLastHole(): void {
    return undefined;
  }
}

