

















































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Group from '@/models/user/Group';
import TreeElement from '@/models/files/TreeElement';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import { adminServiceInstance } from '@/services/AdminService';

@Component({
  props: {
    group: Group,
  },
})
export default class FileExplorer extends BaseComponent {
  adminService=adminServiceInstance;
  
  group!:Group;

  treeElements: TreeElement[]=[];

  currentPath='/';

  pathHistory:string[]=[];

  fileup:any=null;

  newDir=null;

  loading=false;

  mounted() {
    this.listPathFile('');
  }

  goTo(treeElement:TreeElement){
    if(treeElement.isDirectory()){
      this.listPathFile(treeElement.path);
    }
    else{
      this.$emit('fileSelected',treeElement)
    }
  }

  directGoTo(idx:number){
    console.log(idx)
    this.listPathFile('/'+this.pathSplitted.slice(0,idx+1).join('/'));
  }


  back(){
    if(this.pathHistory.length>0){
      let p:any=this.pathHistory.pop();
      this.listPathFile(p,false);
    }
  }

  refresh(){
    
    this.listPathFile(this.currentPath,false);
    
  }

  async uploadFile(event:any) {
    const selectedFile:any = event?.target?.files?.[0];
    if (selectedFile && selectedFile instanceof File) {
      console.log(selectedFile);
      this.loading=true;
      this.adminService.uploadFile(this.group,this.currentPath,selectedFile).then((result)=>{
        this.loading=false;
        this.refresh();
      });
    } 
  }

  get pathSplitted():string[]{
    return this.currentPath.split('/').slice(1);
  }

  deleteFileOrDir(treeElement:TreeElement){
    let message = 'Etes-vous sur de vouloir supprimer ce fichier ?';
    if(treeElement.isDirectory()){
      message = 'Etes-vous sur de vouloir supprimer ce dossier ?';
    }
    if (confirm(message)) {
        this.adminService.deleteFileOrDir(this.group,treeElement.path).then((res:any)=>{
          console.log(res);
          this.refresh();
      });
    }

  }

  createDir(){
    this.loading=true;
    this.adminService.createDir(this.group,this.currentPath,this.newDir).then((res:any)=>{
          console.log(res);
          this.loading=false;
          this.refresh();
    },(err)=>{
       this.loading=false;
       EventBus.$emit(EVENTS.ERROR, err.response.data.message);
    });
    

  }

  listPathFile(path:string,addToHistory=true){
    if(addToHistory)
      this.pathHistory.push(this.currentPath);
    this.currentPath = path;
    this.treeElements = [];
    this.loading=true;
    this.adminService.listFiles(this.group,path).then((res)=>{
      this.loading=false;
      if (res.data && res.data.length > 0) {
        this.treeElements = res.data.map((x:any)=>new TreeElement(x));
      } 
    });
  }
 
}
