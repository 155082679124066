




/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import BaseComponent from './BaseComponent';

@Component({
  props: {
  },
})
export default class Footer extends BaseComponent {
  created() {
    // this.mapService = mapservice;
  }
}
