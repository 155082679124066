






















































































import BaseComponent from '@/components/BaseComponent';
import FormNumberInput from '@/components/shared/FormNumberInput.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import { ParameterType } from '@/models/plugin/ParameterType';
import Plugin from '@/models/plugin/Plugin';
import PluginParameter from '@/models/plugin/parameter/PluginParameter';
import { SelectItem } from '@/models/plugin/parameter/SelectItem';
import Component from 'vue-class-component';
import { NOMINATIM } from './ApiAdressePlugin.vue';
import LayerConfig from '@/models/map-context/LayerConfig';

@Component({
  props: {
    selectedPlugin: Plugin,
  },
  components: {
    FormTextInput,
    FormNumberInput,
  },
})
export default class PluginEditPanel extends BaseComponent {
  static ID = 'PluginEditPanel';

  ParameterType = ParameterType;

  selectedPlugin!: Plugin;

  getRelatedParameter(parameter:any):any {
    let relatedParameter = null;
    if (parameter.relatedId) {
      relatedParameter = this.selectedPlugin.parameters.find((v) => parameter.relatedId === v.id);
    }
    return relatedParameter;
  }

  display(parameter:any): boolean {
    if (parameter.relatedId) {
      const relatedParameter = this.getRelatedParameter(parameter);
      if (parameter.id === 'viewbox') {
        return relatedParameter?.value === NOMINATIM;
      }
      return relatedParameter?.multiple === false ? relatedParameter?.value : relatedParameter?.value?.length > 0;
    }
    return true;
  }

  autocompleteSelect(parameter:any) {
    this.selectedPlugin.parameters.filter((v) => parameter.id === v.relatedId).forEach((p) => { p.value = ''; });
  }

  autocompleteOptions(parameter:PluginParameter<any>): SelectItem[] {
    if (parameter.type === ParameterType.LayerPicker) {
      return this.contextService.getCurrentContext().getOrderedLayerList().filter(
        (v) =>  (!parameter.hasAttributes || v.layerProperties?.length > 0) && (!parameter.hasFeatures || v.getLayerSource()?.getFeatures),
      ).map((v) => ({
        text: v.title,
        value: v.uid,
      }));
    } if (parameter.type === ParameterType.LayerGroupPicker) {
      return this.contextService.getCurrentContext().getOrderedLayerGroupList().filter(
        (v) => (v),
      ).map((v :any) => ({
        text: v.title,
        value: v.uid,
      }));
    } if (parameter.type === ParameterType.LayerAttributePicker) {
      const relatedParameter = this.getRelatedParameter(parameter);
      const unique = relatedParameter?.multiple === false;
      return this.contextService.getCurrentContext().getOrderedLayerList()
        .filter((v) => v.layerProperties?.length > 0
         && (relatedParameter === null || unique ? relatedParameter.value === v.uid : relatedParameter.value.indexOf(v.uid) !== -1))
        .flatMap((v) => v.layerProperties.map((l) => (unique ? {
          text: l.label || l.name,
          value: l.name,
        } : {
          text: `${l.label || l.name} (${v.title})`,
          value: `${v.uid}_${l.name}`,
        })));
    }
    return parameter.choices || [];
  }

  updatePlugin(): void {
    console.log('update');
    if (this.selectedPlugin && this.selectedPlugin.isActive) {
      this.selectedPlugin.isActive = false;
      // eslint-disable-next-line prefer-const
      let plugin = this.selectedPlugin;
      setTimeout(() => {
        plugin.isActive = true;
      },
      400);
    }

    this.$emit('updateDone');
  }
}
