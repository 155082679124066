






































































































































/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EventBus, { EVENTS } from '@/services/EventBus';
import Drag from 'cesium-entity-drag';
import Component from 'vue-class-component';
import PluginComponent from '../plugins/PluginComponent';
import FormTextInput from '../shared/FormTextInput.vue';

declare let Cesium: any;

// eslint-disable-next-line no-use-before-define
@Component<CesiumFeatureInfosPanel>({
  props: {
  },
  components: {
    FormTextInput,
  },
})
export default class CesiumFeatureInfosPanel extends PluginComponent {
  static ID='CesiumFeatureInfosPanel';

  private feature:any;

  isModel = false;

  drag:any = null;

  altitude:any = 0;

  offsetx:any = 0;

  offsety:any = 0;

  scale = 0;

  viewer:any;

  initPosition:any = null;

  initOrientation:any = null;

  initScale = 1;

  roll:any = 0;

  heading:any = 0;

  pitch:any = 0;

  getScale(scale:number) {
    return (10 ** scale).toFixed(2);
    // return scale;
  }

  mounted() {
    console.log('mounted');
    EventBus.$on(EVENTS.SELECT_FEATURE, this.onFeatureSelected);
    this.viewer = this.getCesiumMapService().viewer;
    const selectedFeatures = this.getMapService().selectionService.selectedFeature;
    if (selectedFeatures !== undefined) {
      this.onFeatureSelected(selectedFeatures);
    }
  }

  onFeatureSelected(features:any) {
    const featureIndex = 0;
    console.log('onFeatureSelected');
    this.feature = features[featureIndex];
    this.loadFeature();
  }

  beforeDestroy() {
    EventBus.$off(EVENTS.SELECT_FEATURE, this.onFeatureSelected);
    if (this.drag) {
      this.drag.disable();
    }
  }

  changeOffset() {
    if (this.initPosition != null) {
      this.drag.disable();
      let newPos = this.initPosition.clone();
      newPos = Cesium.Matrix4.multiplyByPoint(Cesium.Transforms.eastNorthUpToFixedFrame(newPos),
        new Cesium.Cartesian3(this.offsetx, this.offsety, this.altitude), new Cesium.Cartesian3());
      // newPos.z = this.initPosition.z + parseFloat(this.altitude);
      // console.log(this.offsetx + " " + this.offsety  + " " + this.altitude);
      this.feature.id.position = newPos;
      this.updatePositionConfig();
    }
  }

  changeScale() {
    this.feature.id.model.scale = 10 ** this.scale;
    this.feature.id.layerConfig.modelConfig.scale = this.scale;
    this.viewer.scene.requestRender();
  }

  updatePositionConfig() {
    this.feature.id.layerConfig.modelConfig.position = [this.getcoordlon(), this.getcoordlat(), this.getcoordalt()];
    this.viewer.scene.requestRender();
  }

  changeOrientation() {
    if (this.initOrientation != null) {
      this.drag.disable();
      const hpr = new Cesium.HeadingPitchRoll(
        Cesium.Math.toRadians(parseFloat(this.heading)),
        Cesium.Math.toRadians(parseFloat(this.pitch)),
        Cesium.Math.toRadians(parseFloat(this.roll)),
      );
      const orient = Cesium.Transforms.headingPitchRollQuaternion(this.feature.id.position.getValue(), hpr);
      this.feature.id.orientation = orient;
      this.feature.id.layerConfig.modelConfig.orientation = parseFloat(this.heading);
      this.viewer.scene.requestRender();
    }
  }

  getMaterialColor(materialName:string) {
    const prim = this.feature.primitive;
    const material = prim.getMaterial(materialName);
    return Cesium.Color.fromCartesian4(material.getValue('baseColorFactor')).toCssColorString();
  }

  changeMaterial(materialName:string, event:Event) {
    console.log(materialName, event);
    const prim = this.feature.primitive;
    const material = prim.getMaterial(materialName);
    console.log(material);
    let matCfg=this.feature.id.layerConfig.modelConfig.materials;
    matCfg[materialName]=event;
    this.feature.id.model.color = Cesium.Color.fromAlpha(Cesium.Color.WHITE, Cesium.Color.fromCssColorString(event).alpha);
    material.setValue('baseColorFactor', Cesium.Cartesian4.fromColor(Cesium.Color.fromCssColorString(event)));
    this.viewer.scene.requestRender();
  }

  getMaterials() {
    const prim = this.feature.primitive;
    return prim.gltf.materials.map((x:any) => x.name);
  }

  loadFeature() {
    this.isModel = false;
    if (this.feature.id && this.feature.id.position) {
      if (this.drag == null) {
        const wnd:any = window;
        wnd.viewer = this.viewer; // utilisé par le module de drag des entités
        this.drag = new Drag(this.viewer);

        // start drag
        this.drag.enable();
      }
      this.initPosition = this.feature.id.position.getValue().clone();
      this.initOrientation = this.feature.id.orientation.getValue().clone();
      this.heading = this.feature.id.layerConfig.modelConfig.orientation || 0;
      console.log(this.feature.id.model.scale);
      this.scale = Math.log10(this.feature.id.model.scale.getValue());
      console.log(this.feature.id.orientation);
      this.isModel = true;
    } else {
      // ce n'est pas un objet reconnu
      this.$emit('close');
    }
  }

  deleteObject() {
    console.log('delete object');
    console.log(this.feature.id.layerConfig);
    if (this.isModel) {
      this.viewer.entities.remove(this.feature.id);
    } else {
      this.feature.id.layerConfig.cesium_Layer.entities.remove(this.feature.id);
    }

    this.$emit('close');
  }

  getcoordlon():number {
    return Cesium.Math.toDegrees(Cesium.Cartographic.fromCartesian(this.feature.id.position.getValue().clone()).longitude);
  }

  getcoordlat():number {
    return Cesium.Math.toDegrees(Cesium.Cartographic.fromCartesian(this.feature.id.position.getValue().clone()).latitude);
  }

  getcoordalt():number {
    return Cesium.Cartographic.fromCartesian(this.feature.id.position.getValue().clone()).height;
  }

  changecoordlon(event:Event):void {
    let val = Number(event);
    if (isNaN(val) === false) {
      val = Math.max(Math.min(val, 180), -180);
      this.feature.id.position = Cesium.Cartesian3.fromDegrees(val, this.getcoordlat(), this.getcoordalt());
      this.updatePositionConfig();
    }
  }

  changecoordlat(event:Event):void {
    let val = Number(event);
    if (isNaN(val) === false) {
      val = Math.max(Math.min(val, 90), -90);
      this.feature.id.position = Cesium.Cartesian3.fromDegrees(this.getcoordlon(), val, this.getcoordalt());
      this.updatePositionConfig();
    }
  }

  changecoordalt(event:Event):void {
    const val = Number(event);
    if (isNaN(val) === false) {
      const coord = Cesium.Cartesian3.fromDegrees(this.getcoordlon(), this.getcoordlat(), val);
      this.feature.id.position.setValue(coord);
      this.updatePositionConfig();
    }
  }
}
