







/* eslint-disable @typescript-eslint/no-this-alias */
import Component from 'vue-class-component';
import PluginComponent from '@/components/plugins/PluginComponent';
import CesiumMesurePlugin from './cesium/CesiumMesurePlugin.vue';
import OlMesurePlugin from './openlayers/OlMesurePlugin.vue';

@Component({
  props: {
  },
  components: {
    CesiumMesurePlugin,
    OlMesurePlugin,
  },
})
export default class MesurePlugin extends PluginComponent {

}
