/* eslint-disable camelcase */
export default class User {
    id!:number;

    email!: string;

    login!: string;

    password!: string;

    name!: string;

    foreName!: string;

    is_superuser = false;

    constructor(input?: unknown) {
      if (input) {
        Object.assign(this, input);
      }
    }
}
