


















/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import { adminServiceInstance, AdminService } from '@/services/AdminService';

import FormTextInput from '@/components/shared/FormTextInput.vue';
import ActionButton from '@/components/shared/ActionButton.vue';
import Group from '@/models/user/Group';
import BaseComponent from '../../BaseComponent';
import UserListTable from './ListUserTable.vue';
import FileExplorer from '@/components/shared/FileExplorer.vue';

@Component({
  props: {
  },
  components: {
    FormTextInput,
    FileExplorer,
    ActionButton,
    UserListTable,
  },
})
export default class GroupStoragePanel extends BaseComponent {
  adminService=adminServiceInstance;

  group:any=null;



  idGroup = '';

  baseRoute = '/groups';



  mounted() {
    this.idGroup = this.$route.params.id;
    if (this.idGroup) {
      this.adminService.getGroup(this.idGroup).then((reponse) => {
        console.log(reponse);
        
        this.group = new Group(reponse.data);
        const t:any = this.$refs.userList;
        t.refresh();
        // EventBus.$emit(EVENTS.INFO, 'OK');
      });
    }
    this.$watch(
      () => this.$route.path,
      (newVal, oldVal) => {
        this.idGroup = this.$route.params.id;
        if (this.idGroup) {
          this.adminService.getGroup(this.idGroup).then((reponse) => {
            console.log(reponse);
            
            this.group = new Group(reponse.data);
            const t:any = this.$refs.userList;
            t.refresh();
            // EventBus.$emit(EVENTS.INFO, 'OK');
          });
        }
      },
    );
  }




  retourPagePrecedente() {
    this.$router.push({ path: '/groups' });
  }

}
