





























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SvgSymbolList from '@/components/layers/style/SvgSymbolList.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import SymbolStyle from '@/models/map-context/style/SymbolStyle';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    symbol: SymbolStyle,
    type: String,
    layer: LayerConfig,
    is3DView: Boolean,
  },
  components: {
    FormTextInput,
    SvgSymbolList,
  },
})
export default class SymbolStyleDefinition extends Vue {
  type!:string;

  symbol!:SymbolStyle;

  layer!:LayerConfig;

  get isSvgSymbolType():boolean {
    return this.type === 'svg';
  }

  inputChange() {
    this.$emit('symboChange', '');
  }

  addField(field:any) {
    this.symbol.offsetZ = `{${field.name}}`;
  }

  pictoChange() {
    this.symbol.fetchIcon();
    // WAIT FOR PICTO LOAD
    setTimeout(() => {
      this.$emit('symboChange', '');
    }, 500);
  }
}
