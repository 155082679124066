/* eslint-disable */
import WMSTileSource from 'ol/source/TileWMS';
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import axios from 'axios';
import {
  Observable,pipe, from, fromEventPattern,
} from 'rxjs';

import Feature from 'ol/Feature';
import WMTS from 'ol/source/WMTS';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSONFormat from 'ol/format/GeoJSON';
import {
  Fill, Stroke, Style, Circle,
}
  from 'ol/style';
import {
  map, filter, catchError,debounceTime,
} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { forkJoin, of, } from 'rxjs';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import Overlay from 'ol/Overlay';
import { transform, get } from 'ol/proj.js';
import MapService from './MapService';
import AbstractSelectionService from './AbstractSelectionService';
import EventBus, { EVENTS } from './EventBus';
import Filter from '@/models/map-context/filter/Filter'
import LayerConfig from '@/models/map-context/LayerConfig';
import { MapBrowserEvent } from 'ol';
import sanitizeHtml from 'sanitize-html';

export default class SelectionService extends AbstractSelectionService {
 
  private selectedFeatureSource!: VectorSource<any>;

  private selectionStyle: any;

  private vectorTileSource!: VectorTileSource;

  private vectorTileLayer!: VectorTileLayer;

  declare mapService: MapService;

  tileselection: any = {};

  timerId!:any;

  overlay!: Overlay;

  lastMoveTime=Date.now();

  selectionActive=true;

  pointerMove = new Subject<any>();

  content!:any;

  popupcloser!:any;

  constructor(mapService:MapService) {
    super();
    this.mapService=mapService;
    this.mapService.map.on('click', this.onMapClick.bind(this));
    this.mapService.map.on('pointermove', this.onPointerMove.bind(this));
    EventBus.$on(EVENTS.ENABLE_SELECTION,()=>this.selectionActive=true);
    EventBus.$on(EVENTS.DISABLE_SELECTION,()=>this.selectionActive=false);
   
    this.pointerMove.pipe(
      map((i: any) => i),
      debounceTime(200)
    )
    .subscribe((res)=>{
      return this.selectFeaturesAtPixel( res, true);});
  }

  destroyService() {
    EventBus.$off(EVENTS.ENABLE_SELECTION);
    EventBus.$off(EVENTS.DISABLE_SELECTION);
  }

  initOverLay(popupContainer:HTMLElement){
    this.content = popupContainer.getElementsByClassName('popup-content')[0];
    this.popupcloser = popupContainer.getElementsByClassName('ol-popup-closer')[0];
    this.popupcloser.onclick = this.cleanInfoBulle.bind(this);

    this.overlay = new Overlay({
      element: popupContainer,
      autoPan: {
        animation: {duration:250},
      },
    });
    this.mapService.map.addOverlay(this.overlay);
  }

  onMapClick(event:MapBrowserEvent<UIEvent>) {
    if(this.selectionActive){
      this.selectFeaturesAtPixel(event);
    }

  }

  onPointerMove(e:MapBrowserEvent<UIEvent>) {
    if(this.selectionActive){
      this.pointerMove.next(e);
    }
  }



  selectFeatures(allFeatures:any, infobulle: boolean, event:any) {
    if (!allFeatures || allFeatures.length == 0 || !allFeatures[0].layerConfig) {
      if (!infobulle) {
        EventBus.$emit(EVENTS.SELECT_NO_FEATURE, allFeatures);
      }
      console.error('could not find layer for this feature');
      return;
    }

    if (!infobulle) {
      this.selectedFeature = allFeatures; // ajoute le feature en surcouche à la carte
      EventBus.$emit(EVENTS.SELECT_FEATURE,allFeatures)
      if (this.selectedFeature.length) {
        this.addToSelection(this.selectedFeature[0]);
      }
    } else {
      if(this.selectedFeature && this.selectedFeature.length && allFeatures[0].getId() && allFeatures[0].getId()==this.selectedFeature[0].getId()){
        console.log('meme selection');
        return;
      }

      this.selectedFeature = allFeatures; // ajoute le feature en surcouche à la carte

      const feature = this.selectedFeature[0];
      const layer:LayerConfig = feature.layerConfig;
      if (layer && layer.popupInfos && layer.popupInfos.active) {
        const regEx = new RegExp(/{(.*?)}/, 'ig');
        let popupinfosContent:any=layer.popupInfos.content;
        if (typeof popupinfosContent === 'function') {
          popupinfosContent(feature,this.content);
        } else if(popupinfosContent!==undefined){
          //console.log(popupinfosContent);
          popupinfosContent =(<string>popupinfosContent).replace(regEx, (a:any, b:any) => {
            const value = feature ? Filter.evaluateExpression(b, feature.getProperties()) : null;
            return value != undefined ? value : '';
          });
          popupinfosContent = sanitizeHtml(popupinfosContent,{
            allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
            allowedAttributes: false,
            allowedSchemesByTag: {
              img: [ 'data','https' ]
            }
          });
          this.content.innerHTML = "<div class='ck-content'>"+popupinfosContent+'</div>';
        }
        if(layer.dataviz.activePopup){
          this.content.innerHTML += '<iframe src="'+layer.dataviz.getUrl(feature)+'" frameborder="0" width="380" height="400" allowtransparency>';
        }
        this.overlay.setPosition(event.coordinate);
        if (this.selectedFeature.length) {
          this.cleanSelection();
          this.addToSelection(this.selectedFeature[0]);
        }
      }
    }
  }

  cleanSelection() {
    if (this.selectedFeatureSource) {
      this.selectedFeatureSource.clear();
      // Clean vector tile selection
      this.tileselection = {};
      if (this.vectorTileLayer) this.vectorTileLayer.changed();
    }
  }

  cleanInfoBulle() {
    this.tileselection = {};
    this.overlay.setPosition(undefined);
  }

  getSelectionStyle(color:string){
    const stroke = new Stroke({
      color: color,
      width: 1,
    });
    const fill = new Fill({
      color: color,
    });
    let selectionStyle = [
      new Style({
        image: new Circle({
          stroke,
          radius: 15,
        }),
        stroke,
        fill,
      }),
    ];
    return selectionStyle;
  }

  addToSelection(feature:any) {
    console.log(feature);

    if (this.selectedFeatureSource && feature.layerConfig.type != 'VectorTile') {
      this.selectedFeatureSource.addFeature(feature);
      if(feature.layerConfig.selectionColor!=''){
        feature.setStyle(this.getSelectionStyle(feature.layerConfig.selectionColor))
      }
    } else {
      this.vectorTileLayer.setSource(feature.layerConfig.ol_Layer.getSource());

      let fid = feature.ol_uid;
      // Si un ID est défini pour cette couche, on l'utilise
      // cela permettra de selectionner l'objet en entier.
      // Sinon si l'objet est dans plusieurs tuiles, on aura qu'une portion de sélectionné
      if (feature.getId()) {
        fid = feature.getId();
      }
      // feature.getId();
      this.tileselection = {};
      this.tileselection[fid] = feature;

      this.vectorTileLayer.changed();
    }
  }

  getFeatureInfoUrl( event: any, wmsOptions: any, layer: LayerConfig) {
    const source=layer.getLayerSource();
    const viewResolution = /** @type {number} */ (this.mapService.view.getResolution());
    console.log(this);
    let url;
    if (source && source.getFeatureInfoUrl) {
      url = source.getFeatureInfoUrl(event.coordinate, viewResolution, this.mapService.mapProjectionCode, wmsOptions);
    } else if (source && source.getTileGrid && layer.type !== 'VectorTile') {
      const wmtssource: WMTS = <WMTS>source;
      const coordinate = transform(event.coordinate, 'EPSG:3857', source.getProjection().getCode());
      const tileGrid = source.getTileGrid();
      const requestedTile = tileGrid.getTileCoordForCoordAndResolution(coordinate, viewResolution);
      const tileExtent = tileGrid.getTileCoordExtent(requestedTile);
      const tileResolution = tileGrid.getResolution(requestedTile[0]);

      const x = Math.floor((coordinate[0] - tileExtent[0]) / tileResolution);
      const y = Math.floor((tileExtent[3] - coordinate[1]) / tileResolution);

      url = `${source.getTileUrlFunction().apply(requestedTile, [1, 1, 1], 'EPSG:3857')
        .replace('GetTile', 'GetFeatureInfo')}TileROW=${requestedTile[2]}&TileCOL=${requestedTile[1]}&TileMatrix=${wmtssource.getMatrixSet()}${encodeURIComponent(':')}${requestedTile[0]}&INFOFORMAT=${encodeURIComponent('application/json')}&I=${x}&J=${y}`;
    }
    return url;
  }


  addSelectionLayer() {
    if (!this.selectedFeatureSource) {
      this.selectedFeatureSource = new VectorSource({});
      const stroke = new Stroke({
        color: 'rgb(255, 244, 8)',
        width: 1,
      });
      const fill = new Fill({
        color: this.defaultSelectionColor,
      });
      this.selectionStyle = [
        new Style({
          image: new Circle({
            stroke,
            radius: 15,
          }),
          stroke,
          fill,
        }),
      ];

      this.mapService.map.addLayer(new VectorLayer({
        source: this.selectedFeatureSource,
        style: this.selectionStyle,
        zIndex: 320,
      }));

      if(this.mapService.mapProjectionCode=='EPSG:3857'){ //Il y a des erreurs sur les couches vectorTile si la carte est en proj 2154 par exemple
        const vectorTileStyle = new Style({
          stroke: new Stroke({
            color: 'rgb(0,255,0)',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(255,255,255,0.4)',
          }),
        });
        this.vectorTileSource = new VectorTileSource({});
        const self = this;
        const styleFunction:any = (feature:any, resolution:any) => {
          let fid = feature.ol_uid;
            if (feature.getId()) {
              fid = feature.getId();
            }
            if (fid in self.tileselection) {
              return vectorTileStyle;
            }
        };

          this.vectorTileLayer = new VectorTileLayer({
            style: styleFunction,
            map: this.mapService.map,
            zIndex: 322,
            renderMode: 'vector',
            source: this.vectorTileSource,

          });
      }

    }
  }
}
