







import PluginComponent from '@/components/plugins/PluginComponent';
import CesiumMapService from '@/services/CesiumMapService';
import MapService from '@/services/MapService';
import { MousePosition } from 'ol/control';
import { format } from 'ol/coordinate';
import sanitizeHtml from 'sanitize-html';
import Component from 'vue-class-component';

declare let Cesium: any;

@Component
export default class MousePositionPlugin extends PluginComponent {
  mousePosition!:MousePosition;

  posText='';

  isCesium = false;

  mounted() :void {
    if (this.getMapService() instanceof MapService) {
      this.addControl();
    } else if (this.getMapService() instanceof CesiumMapService) {
      this.isCesium = true;
      this.add3DControl();
    }
  }

  add3DControl(): void {
    const { viewer } = this.getCesiumMapService();
    const { scene } = viewer;
    const entity = viewer.entities.add({
      label: {
        show: false,
        showBackground: true,
        font: '14px monospace',
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        pixelOffset: new Cesium.Cartesian2(15, 0),
      },
    });

    const handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);
    handler.setInputAction((movement:any) => {
      const ray = viewer.camera.getPickRay(movement.endPosition);
      const cartesian = viewer.scene.globe.pick(ray, viewer.scene);
      /* const cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        scene.globe.ellipsoid,
      ); */
      if (cartesian) {
        const cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian);
        /* const cartographic = Cesium.Cartographic.fromCartesian(
          cartesian,
        ); */
        const longitudeString = Cesium.Math.toDegrees(
          cartographic.longitude,
        ).toFixed(2);
        const latitudeString = Cesium.Math.toDegrees(
          cartographic.latitude,
        ).toFixed(2);
        const heightString = cartographic.height.toFixed(2);

        entity.position = cartesian;
        entity.label.show = true;
        this.posText = `Lon: ${`   ${longitudeString}`.slice(-7)}\u00B0`
          + `\nLat: ${`   ${latitudeString}`.slice(-7)}\u00B0`
          + `\nAlt: ${`   ${heightString}`.slice(-7)}m`;
      } else {
        entity.label.show = false;
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  }

  beforeDestroy() :void {
    if (this.getOpenLayersMapService().map !== null) {
      this.getOpenLayersMapService().map.removeControl(this.mousePosition);
    }
  }

  addControl() :void {
    const formatParam = sanitizeHtml(this.plugin.getParameterValue('format'));
    const precisionParam = this.plugin.getParameterValue('precision');
    const el:any = this.$refs.mousePosition;
    this.mousePosition = new MousePosition({
      projection: this.plugin.getParameterValue('displayProj'),
      className: 'mouse-position',
      target: el,
      // undefinedHTML: '', deprecated in 7
      coordinateFormat(coords:any) {
        return format(coords, formatParam, precisionParam);
      },
    });

    this.getOpenLayersMapService().map.addControl(this.mousePosition);
  }
}
