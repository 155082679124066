import '@babel/polyfill';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import 'mutationobserver-shim';
import 'ol/ol.css';
import Vue from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import App from './App.vue';
import './plugins/bootstrap-vue';
import './registerServiceWorker';
import router from './router';
import './styles/app.less';
import './styles/vue-select-override.less';
import  { ContextService,contexteServiceInstance } from '@/services/ContextService';
import EventBus, { EVENTS } from './services/EventBus';

Vue.component('v-select', vSelect);


Vue.use(CKEditor);
Vue.config.productionTip = false;
Vue.config.devtools = true;
console.log('init error Handler');

contexteServiceInstance.init();
Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  // TODO: Perform any custom logic or log to server test
  console.log(err);
};
window.onerror = function (message, source, lineno, colno, error) {
  console.log(message);
};
EventBus.$on(EVENTS.CONFIG_LOADED, (config:any) => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');

});
var cfg=contexteServiceInstance;
