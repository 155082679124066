




















import GroupLayerSimplified from '@/components/layers/GroupLayerSimplified.vue';
import SingleLayerElementSimplified from '@/components/layers/SingleLayerElementSimplified.vue';
import AbstractLayerTreeElement from '@/models/map-context/AbstractLayerTreeElement';
import LayerConfig from '@/models/map-context/LayerConfig';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import Component from 'vue-class-component';
import PluginComponent from '../plugins/PluginComponent';

@Component({
  props: {},
  components: {
    GroupLayerSimplified,
    SingleLayerElementSimplified,
  },
})
export default class FiltrePlugin extends PluginComponent {
  static ID = 'FiltrePlugin';

  showAddLayerBtn = true;

  created() {
    console.log(this.contextService.currentContext);
  }

  mounted() {
    this.showAddLayerBtn = this.contextService.configuration.addLayers !== undefined;
    this.showAddLayerBtn =
      this.showAddLayerBtn && this.plugin && this.plugin.getParameterValue('showAddLayerBtn');
  }

  getLayersAndGroups() {
    return this.contextService.getCurrentContext().layers;
  }

  addLayer() {
    this.contextService.setCurrentAction('addLayer', {});
  }

  addGroupLayerElement() {
    this.contextService
      .getCurrentContext()
      .getLayers()
      .unshift(
        new LayerGroupConfig({
          title: 'Nouveau Groupe',
          childs: [],
        })
      );
  }

  addVectorLayer() {
    const newLayer: LayerConfig = new LayerConfig({
      title: 'Nouveau Dessin',
      type: 'Vector',
      visible: true,
      editable: true,
      layerProperties: [],
      boundingBoxEPSG4326: [-180, -90, 180, 90],
    });

    console.log(newLayer);

    try {
      this.contextService.getCurrentContext().getLayers().unshift(newLayer);
      this.getMapService().addLayer(newLayer);
    } catch (error: any) {
      newLayer.addError(error);
      console.log(error);
    }
  }
}
