























































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
import BaseComponent from '@/components/BaseComponent';
import LayerConfig from '@/models/map-context/LayerConfig';
import { createPopper } from '@popperjs/core';
import Component from 'vue-class-component';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
  },
})
export default class AddFilterPanel extends BaseComponent {
  layer!: LayerConfig;

  attribute:any = null;

  operator='';

  filterValue='';

  placement:any = 'top';

  get operators() {
    if (this.attribute && this.attribute.type.indexOf('string') > 0) {
      return [
        { item: 'like', name: 'contient' },
        { item: 'equal', name: 'égal à' },
        { item: 'notequal', name: 'différent de' },
        { item: 'is null', name: 'est nul' },
        { item: 'is not null', name: 'n\'est pas nul' },
      ];
    }
    return [{ item: '=', name: 'égal à' },
      { item: '>', name: 'supérieur à' },
      { item: '>=', name: 'supérieur ou égal à' },
      { item: '<', name: 'inférieur à' },
      { item: '<=', name: 'inférieur ou égal à' },
    ];
  }

  mounted():void{
    console.log(this.layer);
  }

  cancel():void{
    this.$emit('cancel');
  }

  /*
  * Pour avoir la combo qui se met au dessus ou en dessous en fonction la position dans la page
  * https://vue-select.org/guide/positioning.html#popper-js-integration
  */
  withPopper(dropdownList:any, component:any, { width }:any) {
    /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
    // eslint-disable-next-line no-param-reassign
    dropdownList.style.width = width;

    /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
    const popper = createPopper(component.$refs.toggle, dropdownList, {
      placement: this.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -1],
          },
        },
        {
          name: 'toggleClass',
          enabled: true,
          phase: 'write',
          fn({ state }) {
            component.$el.classList.toggle(
              'drop-up',
              state.placement === 'top',
            );
          },
        },
      ],
    });

    /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
    return () => popper.destroy();
  }

  add():void{
    console.log(this.layer);
    let filter = this.attribute.name + this.operator + this.filterValue;
    if (this.operator === 'like') {
      filter = `${this.attribute.name} ${this.operator} '%${this.filterValue}%'`;
    }
    if (this.operator === 'equal') {
      filter = `${this.attribute.name} = '${this.filterValue}'`;
    }
    if (this.operator === 'notequal') {
      filter = `${this.attribute.name} <> '${this.filterValue}'`;
    }
    this.$emit('add_filter', filter);
  }
}
