/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Plugin from '@/models/plugin/Plugin';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    plugin: {},
  },
  components: {
  },
})
export default class PluginComponent extends BaseComponent {
  plugin!:Plugin;

  // Méthode à surcharger dans les plugins de type FeatureInfos
  // en entrée prends le tableau des features sélectionnée
  // doit renvoyer true en retour pour afficher le panneau 
  // si le type de feature corresponds à ce que le plugin peut traiter
  // false sinon
  // Ex : renvoie true pour le plugin qui affiche les infos parcellaires si l'objet est une parcelle
  //
  openOnSelectedFeature(feature:any):boolean{
    return false;
  }
}
