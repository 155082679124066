










































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import PluginComponent from '@/components/plugins/PluginComponent';
import EventBus, { EVENTS } from '@/services/EventBus';

@Component({
  props: {
  },
  components: {
  },
})
export default class BookmarkerPlugin extends PluginComponent {
  static ID = 'BookmarkerPlugin';

  showShareModal = false;

  shareLink = '';

  mounted() {
  }

  addBookmarkInList() {
    const label = prompt('Entrez le label du bookmark :');
    if (!label) {
      alert('Veuillez entrer un label pour le bookmark');
      return;
    }
    const bookmark = {
      label: label,
      bbox: this.getMapService().getMapBoundsWGS84()
    };
    this.contextService.addBookmark(bookmark)
  }

  goToBookmark(bookmark: any): void{
    this.getMapService().zoomToBboxWGS84(bookmark.bbox)
  }

  removeBookmark(index: number) {
    this.contextService.removeBookmark(index)   
  }

  shareBookmark(bookmark: any) {
    const bbox = bookmark.bbox;
    if (bbox) {
      const bboxString = `?bbox=${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}`;
      this.shareLink = `${window.location.origin}${window.location.pathname}${bboxString}`;
      this.showShareModal = true;
    }
  }

  copyLink() {
    navigator.clipboard.writeText(this.shareLink).then(() => {
      EventBus.$emit(EVENTS.NOTIFICATION, 'Lien copié dans le presse-papiers');
    });
  }
}
