import { PluginLocation } from './PluginLocation';

export default class PluginConfig {
    active = false;

    activeInEdition = false;

    position = PluginLocation.TopLeft;

    icon = null;

    only3d = false;

    first = false;

    constructor(json: any = null) {
      this.fromJson(json);
    }

    fromJson(json: any) :void{
      if (json) {
        if (json.icon) {
          this.icon = json.icon;
        }

        this.active = json.isActive ?? false;

        this.only3d = json.only3d ?? false;

        this.activeInEdition = json.activeInEdition ?? false;

        this.first = json.first ?? false;
        console.log(json);
        if (json.position !== undefined && !Number.isNaN(Number(json.position))) {
          this.position = Number(json.position);
        } else if (json.position !== undefined) {
          this.position = PluginLocation[json.position as keyof typeof PluginLocation];
        }
      }
    }

    clone(): PluginConfig {
      return new PluginConfig({
        isActive: this.active,
        activeInEdition:this.activeInEdition,
        position: this.position,
        only3d: this.only3d,
        icon: this.icon,
        first: this.first,
      });
    }
}
