



























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SymbolStyleDefinition from '@/components/layers/style/SymbolStyleDefinition.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import LayerStyle from '@/models/map-context/LayerStyle';
import SymbolStyle from '@/models/map-context/style/SymbolStyle';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    layerStyle: LayerStyle,
    layer: LayerConfig,
    is3DView: Boolean,
  },
  components: {
    SymbolStyleDefinition,
  },
})
export default class PointStyleDefinition extends Vue {
  layerStyle!: LayerStyle;

  layer!:LayerConfig;

  pointSymbolTypeList = [{
    item: 'circle', name: 'cercle', is3d: true, isonly3d: false,
  },
  {
    item: 'square', name: 'carré', is3d: true, isonly3d: false,
  },
  {
    item: 'cross', name: 'croix', is3d: true, isonly3d: false,
  },
  {
    item: 'triangle', name: 'triangle', is3d: true, isonly3d: false,
  },
  {
    item: 'star', name: 'étoile', is3d: true, isonly3d: false,
  },
  {
    item: 'svg', name: 'svg', is3d: true, isonly3d: false,
  },
  ];

  onChangeType(event: any) {
    console.log(event);
    this.layerStyle.pointSymbolType = event;
    if (this.layerStyle.pointSymbolType === 'svg') {
      this.layerStyle.pointSymbol = new SymbolStyle();
    }
    this.inputChange();
  }

  get pointSymbolType() {
    return this.layerStyle.getPointSymbolType();
  }

  inputChange() {
    this.$emit('symboChange', '');
  }
}
