/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum PluginLocation
{
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight,
    LeftToolBar,
    FeatureInfosPanel,
    Modal,
}
