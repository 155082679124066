









































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { v4 as uuidv4 } from 'uuid';
import Picker from 'vanilla-picker';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    label: String,
    tooltip: String,
    placeholder: {},
    colorPicker: { default: false },
    textArea: { default: false },
    cols: { default: 8 },
    md: { default: 8 },
    labelcols: { default: 4 },
    value: {},
  },
})
export default class FormTextInput extends BaseComponent {
  label!:string;

  value:any;

  cols!:number;

  selectColor=false;

  md!:number;

  labelcols!:number;

  placeholder!:any;

  colorPicker!:boolean;

  id = uuidv4();

  picker!: Picker;

  mounted() {
    if (this.colorPicker) {
      const htmlinput:any = this.$refs.textInput;
      const buttoninput:any = this.$refs.buttonInput;
      const colorChooser:any = this.$refs.colorChooser;
      
      let color = 'black';
      if (this.value) {
        color = this.value;
      } else if (this.placeholder) {
        color = this.placeholder;
      }
      buttoninput.style.backgroundColor = color;
      this.picker = new Picker({
        parent: colorChooser,
        popup: false,
        editorFormat: 'rgb',
        color,
      });
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.picker.onDone = function (newColor) {
        buttoninput.style.backgroundColor = newColor.rgbaString;
        self.inputValue = newColor.rgbaString;
        self.selectColor=!self.selectColor;
      };
    }
  }

  clickSelectColor(){
    this.selectColor=!this.selectColor;
    

  }
  get placeholderContent() {
    if (this.placeholder !== undefined) return `${this.placeholder}`;
    return '';
  }
  onChange(e:Event) :void{
    if (this.colorPicker) {
      const htmlinput:any = this.$refs.textInput;
      const buttoninput:any = this.$refs.buttonInput;
      this.picker.setColor(htmlinput.value,true);
      buttoninput.style.backgroundColor = htmlinput.value;
    }
  }

  onFocus(e:Event) :void{
    this.$emit('focus', e);
  }

  onBlur(e:Event) :void{
    this.$emit('blur', e);
  }

  get computedCols() {
    if (this.colorPicker) {
      return this.cols - 1;
    }
    return this.cols;
  }

  get computedMdCols() {
    if (this.colorPicker) {
      return this.md - 1;
    }
    return this.md;
  }

  // model:any=null;
  get inputValue() {
    return this.value;
  }

  set inputValue(val) {
    this.$emit('input', val);
    this.$emit('inputChange', val);
  }
}
