




































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*  eslint-disable no-param-reassign */
import Component from 'vue-class-component';
import { adminServiceInstance, AdminService } from '@/services/AdminService';
import User from '@/models/user/User';
import EventBus, { EVENTS } from '@/services/EventBus';
import ActionButton from '@/components/shared/ActionButton.vue';
import QueryParams from '@/models/crud/QueryParams';
import { RegularShape } from 'ol/style';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    itemProvider: { type: Function },
  },
  components: {
    ActionButton,
  },
})
export default class UserListTable extends BaseComponent {
  adminService=adminServiceInstance;

  noResultsFound = false;

  itemProvider!:any;

  rows = 0;

  users = [];

  items = this.remoteUserProvider;

  fields= [
    {
      key: 'login', label: 'Login', sortable: true, class: 'text-center', sortDirection: 'desc',
    },
    {
      key: 'name', label: 'Nom', sortable: true, class: 'text-center', sortDirection: 'desc',
    },
    {
      key: 'foreName', label: 'Prénom', sortable: true, class: 'text-center',
    },
    {
      key: 'email', label: 'email', sortable: true, class: 'text-center',
    },
    { key: 'actions', label: 'Actions', class: 'text-center' },
  ];

  currentPage= 1;

  perPage= 5;

  pageOptions= [5, 10, 15, { value: 100, text: 'Show a lot' }];

  sortBy= '';

  sortDesc= false;

  sortDirection='asc';

  filter= null;

  filterOn= [];

  mounted() {
    if (this.itemProvider) {
      this.items = this.itemProvider;
    }
    console.log('mounted');
  }

  onFiltered(filteredItems:any) {
    this.rows = filteredItems.length;
    this.currentPage = 1;
  }

  refresh() {
    const t:any = this.$refs.table;
    t.refresh();
  }

  remoteUserProvider(ctx:any) {
    console.log(ctx);
    const cp = new QueryParams(ctx);
    cp.filterOn = ['name', 'email'];
    return this.adminService.getUsers(cp).then((res) => {
      console.log(res);
      if (res.data && res.data.count > 0) {
        this.rows = res.data.count;
        this.users = res.data.results;
      } else {
        this.rows = 0;
        this.users = [];
        this.noResultsFound = true;
      }
      return this.users;
    });
  }
}
