/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-cycle
import { v4 as uuidv4 } from 'uuid';
import LayerGroupConfig from './LayerGroupConfig';

export default class AbstractLayerTreeElement {
  visible = false;

  uid!: string;

  id!: string;

  public static counter = 0;

  parent?: LayerGroupConfig;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
    if (!this.id) {
      AbstractLayerTreeElement.counter += 1;
      this.id = `generated_${AbstractLayerTreeElement.counter}`;
    }
    if (!this.uid) {
      this.uid = uuidv4();
    }
  }

  isLayerVisible(): boolean {
    if (this.parent) {
      return this.parent.isLayerVisible() && this.visible;
    }
    return this.visible;
  }

  isVisible(): boolean {
    return this.visible;
  }

  toJson(): any {
    return {};
  }
  toJsonDuplication(): any {
    return {};
  }
}
