























































/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EditionToolType from '@/models/map-context/EditionToolType';
import LayerConfig from '@/models/map-context/LayerConfig';
import CesiumSelectionService from '@/services/CesiumSelectionService';
import EditionService from '@/services/EditionService';
import { MapService } from '@/services/MapService';
import SelectionService from '@/services/SelectionService';
import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    layer: LayerConfig,
  },
})
export default class EditionToolBar extends BaseComponent {
  public EditionToolType = EditionToolType;

  copiedFeature:any = null;

  selectionService!:SelectionService| CesiumSelectionService;

  editionService!:EditionService;

  layer!:LayerConfig;

  data() {
    return {
      selectionService: undefined,
      editionService: undefined,
    };
  }

  created() {
    this.selectionService = this.getMapService().selectionService;
    const mapService = this.getMapService();
    if (mapService instanceof MapService) {
      this.editionService = mapService.editionService;
    }
  }

  beforeDestroy() {
    this.editionService.reset();
  }

  copyFeature() {
    const toCopy = this.selectionService.selectedFeature[0];
    if (toCopy instanceof Feature) {
      this.copiedFeature = this.selectionService.selectedFeature[0].clone();
    } else {
      const geom = new Polygon(toCopy.flatCoordinates_, 'XY',
        this.selectionService.selectedFeature[0].ends_);
      const feature = new Feature({
        geometry: geom,
        name: 'Copied Polygon',
      });
      this.copiedFeature = feature;
    }
  }

  isActive(tool:string) :boolean {
    return this.editionService.activeTool === tool;
  }

  pasteFeature() {
    console.log(this.copiedFeature);
    this.layer.getLayerSource().addFeature(this.copiedFeature);
    this.editionService.save(this.layer);
  }

  public addAPolygon(): void {
    this.clickTool(EditionToolType.ADD_POLYGON);
  }

  public addALine(): void {
    this.clickTool(EditionToolType.ADD_LINE);
  }

  public addAPoint(): void {
    this.clickTool(EditionToolType.ADD_POINT);
  }

  public addACircle(): void {
    this.clickTool(EditionToolType.ADD_CIRCLE);
  }

  public clickTool(tool:string) {
    console.log('clickTool', tool);
    // if current active tool is not this one
    if (this.editionService.activeTool !== tool) {
      this.editionService.reset();
      switch (tool) {
        case EditionToolType.UPDATE_SHAPE:
          this.editionService.updateShape(this.layer);
          break;
        case EditionToolType.MOVE_SHAPE:
          this.editionService.moveShape(this.layer);
          break;
        case EditionToolType.DELETE:
          this.editionService.delete(this.layer);
          break;
        case EditionToolType.ADD_POLYGON:
        case EditionToolType.ADD_POINT:
        case EditionToolType.ADD_LINE:
        case EditionToolType.ADD_CIRCLE:
          this.editionService.activeEdition(tool, this.layer);
          break;
        default:
          break;
      }
    } else {
      this.editionService.reset();
    }
  }

  public updateShape(): void {
    this.clickTool(EditionToolType.UPDATE_SHAPE);
  }

  public moveShape(): void {
    this.clickTool(EditionToolType.MOVE_SHAPE);
  }

  public deleteFeature(): void {
    this.clickTool(EditionToolType.DELETE);
  }
}
