/* eslint-disable no-continue */

/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-named-as-default */
/* eslint-disable class-methods-use-this */
import LayerConfig from '@/models/map-context/LayerConfig';
import CesiumMapService from './CesiumMapService';
import EventBus, { EVENTS } from './EventBus';

declare let Cesium: any;

export default class CesiumPerformanceService {
  private mapService!: CesiumMapService;

  private viewer: any = null;

  private frameRate: any = null;

  constructor(mapService: CesiumMapService, isDebug:boolean) {
    console.log('CesiumPerformanceService');
    this.mapService = mapService;

    this.viewer = this.mapService.viewer;

    const options = {
      scene: this.viewer.scene,
      samplingWindow: 1.0, // fps calculé durant cette periode
      quietPeriod: 2.0, // wait after startup page
      warmupPeriod: 1.0, // duree du warmup
      minimumFrameRateDuringWarmup: 400, // < fps -> entree dans le warning
      minimumFrameRateAfterWarmup: 400, // > fps -> sortie du warning
      fps: 0,
    };
    this.frameRate = new Cesium.FrameRateMonitor(options);
    const eventHelper = new Cesium.EventHelper();
    eventHelper.add(this.frameRate.lowFrameRate, (scene:any, fps:number) => {
      options.fps = fps;
    }, this);

    setInterval(() => {
      this.fpsManage(options.fps);
    }, 1000);

    if (isDebug === true) {
      this.viewer.scene.debugShowFramesPerSecond = true;
    }

    this.testNavigator();
  }

  fpsManage(fps:number) :void {
    if (this.viewer.scene.requestRenderMode === true || this.frameRate.lastFramesPerSecond === undefined) return;
    if (fps < 10) {
      if (this.viewer.resolutionScale === 0.9) return; // min

      if (this.viewer.scene.globe.maximumScreenSpaceError < 3) {
        this.viewer.scene.globe.maximumScreenSpaceError += 0.1;
      }

      this.viewer.scene.postProcessStages.fxaa.enabled = false;
      this.viewer.scene.fog.enabled = false;
      this.viewer.shadowMap.size = 1024;

      if (this.viewer.scene.globe.maximumScreenSpaceError >= 3) {
        this.viewer.scene.globe.enableLighting = false;
        this.viewer.resolutionScale = 0.9;
        this.viewer.shadowMap.enabled = false;
      }
    } else {
      if (this.viewer.scene.postProcessStages.fxaa.enabled === this.mapService.config.cesiumParameters.fxaa) return; // max

      if (this.viewer.scene.globe.maximumScreenSpaceError > 2) {
        this.viewer.scene.globe.maximumScreenSpaceError -= 0.1;
      }
      this.viewer.resolutionScale = 1.0;

      if (this.viewer.scene.globe.maximumScreenSpaceError <= 2) {
        if (fps > 15) {
          this.viewer.shadowMap.enabled = this.mapService.config.cesiumParameters.shadowMap;
          this.viewer.scene.globe.enableLighting = true;
          this.viewer.scene.fog.enabled = this.mapService.config.cesiumParameters.fog;
          this.viewer.shadowMap.size = 2048;
        }
        if (fps >= 20) {
          this.viewer.scene.postProcessStages.fxaa.enabled = true;
        }
      }
    }
  }

  testNavigator() :void {
    const nav = this.getNavigator();
    console.log(nav);
    if (nav.toLocaleLowerCase().indexOf('chrome') === -1) {
      EventBus.$emit(EVENTS.NOTIFICATION, 'Pour une expérience optimale, préférez l\'utilisation du navigateur Chrome ou Edge');
    }
  }

  getNavigator() : string {
    const ua = navigator.userAgent; let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem !== null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem !== null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }
}
