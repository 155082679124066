export default class Application {
  id!: number;

  // eslint-disable-next-line camelcase
  display_name!: string;

  description!: string;

  // idUserOwner!: number; deprecated

  ownerId!: string;

  config!: string;

  thumbnail = null;

  tags=[];

  // eslint-disable-next-line camelcase
  public_access = false;

  is_default = false;

  highlight = false;

  usergroup!: number;

  access: any[] = [];

  'map_access': any[] = [];

  constructor(input?: unknown) {
    console.log(input);
    if (input) {
      Object.assign(this, input);
    }
  }

  setTumbnail(blob:any) :void{
    this.thumbnail = blob;
  }
}
