import { ICapabilitiesResponse, OGCLayer } from "./RemoteServer";

export default class CapabilitiesResponse implements ICapabilitiesResponse {
  
    type!: string;
    
    useProxy!: boolean;

    layers!: OGCLayer[];
  
    constructor(input?: unknown) {
        console.log("CapabilitiesResponse");
        console.log(input);
      if (input) {
        Object.assign(this, input);
      }
    }
    flatten(arr: OGCLayer[]): OGCLayer[] {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        // eslint-disable-next-line prefer-arrow-callback
        return arr.reduce(function (flat: OGCLayer[], toFlatten) {
          const toConcat: OGCLayer[] = toFlatten.Layer ? self.flatten(toFlatten.Layer) : [<OGCLayer>toFlatten];
          return flat.concat(toConcat);
        }, []);
      }
    
    /**
    * Retourne la liste à plat des couches (noeuds enfants) et non la liste arborescente avec les groupes de couches
     */
    getLayerList(): OGCLayer[] {
        return this.flatten(this.layers);
    }

    findLayer(layerName:string){
        let lname=layerName;
        let fullName=lname;// avec le worskpace:
        if(lname.indexOf(':')>0){
            lname=lname.split(':')[1];
        }
        let layers=this.getLayerList();
        if(layers && layers.length>0){
            const layerFound=layers.find((x:any) => x.Name === lname ||x.Name === fullName );
            console.log(layerFound)
            return layerFound;
        }
        return undefined;
    }
   
  }