/* eslint-disable max-len */
/* eslint-disable dot-notation */
/* eslint-disable prefer-template */
/* eslint-disable no-continue */

/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-named-as-default */
/* eslint-disable class-methods-use-this */
import LayerConfig from '@/models/map-context/LayerConfig';
import CesiumMapService from './CesiumMapService';
import EventBus, { EVENTS } from './EventBus';

export default class CesiumEditionService {
  // public currentStyle: FeatureStyle;

  public activeTool = '';

  listenerKey: any;

  mapService: CesiumMapService;

  constructor(mapService: CesiumMapService) {
    this.mapService = mapService;
  }

  activeEdition(type: string, layer: LayerConfig): void {
    this.activeTool = type;
    EventBus.$emit(EVENTS.DISABLE_SELECTION);
  }

  public reset(): void {
    this.activeTool = '';
  }
}
