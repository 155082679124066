import { ParameterType } from '../ParameterType';
import { SelectItem } from './SelectItem';

export default class PluginParameter<T> {
    id: string;

    label: string;

    description?: string;

    min?: T;

    max?: T;

    type: ParameterType;

    value: T;

    multiple: boolean;

    hasAttributes: boolean;

    hasFeatures: boolean;

    choices?: SelectItem[];

    relatedId?: string;

    constructor(id: string, label: string, type: ParameterType, value: T, options?: {
        description?:string,
        min?:T,
        max?:T,
        multiple?:boolean,
        choices?:SelectItem[],
        relatedId?:string,
        hasAttributes?:boolean,
        hasFeatures?:boolean,
      }) {
      this.id = id;
      this.label = label;
      this.type = type;
      this.value = value;
      this.description = options?.description;
      this.min = options?.min;
      this.max = options?.max;
      this.multiple = options?.multiple === true;
      this.choices = options?.choices;
      this.relatedId = options?.relatedId;
      this.hasAttributes = options?.hasAttributes === true;
      this.hasFeatures = options?.hasFeatures === true;
    }

    setValue(val:T) :void{
      this.value = val;
    }
}
