export default class TreeElement {
    path = '';

    directory!: boolean;
   
    name!:string;

    size!:string;

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
    }

    isDirectory(){
        return this.directory;
    }

}