









import Component from 'vue-class-component';
import BaseComponent from './BaseComponent';
import sanitizeHtml from 'sanitize-html';

@Component
export default class PresentationPanel extends BaseComponent {
      static ID = 'PresentationPanel';

    title:any='';

    infosContent:any='';

    mounted():void {
      this.title=this.contextService.currentApplication?.display_name;
      
      let content:any = this.contextService.currentContext?.presentation;
      this.infosContent = sanitizeHtml(content,{
            allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
            allowedAttributes: false,
            allowedSchemesByTag: {
              img: [ 'data','https' ]
            }
          });

    }


}
