export default class EditionToolType {
    static ADD_POLYGON = 'ADD_POLYGON';

    static ADD_POINT = 'ADD_POINT';

    static ADD_LINE = 'ADD_LINE';

    static ADD_CIRCLE = 'ADD_CIRCLE';

    static UPDATE_SHAPE = 'UPDATE_SHAPE';

    static MOVE_SHAPE = 'MOVE_SHAPE';

    static DELETE = 'delete';
}
