import {
  Icon, Fill, Stroke, Style, RegularShape, Circle as CircleStyle, Text,
} from 'ol/style';
import Feature from 'ol/Feature';
import { TextPlacement } from 'ol/style/Text';
import MapUtils from '../MapUtils';

export default class TextStyle {
    baseStyle!: TextStyle;

    text = '';

    padding!: number;

    offsetX: number|undefined = undefined;

    offsetY: number|undefined = undefined;

    offsetZ='0';

    textFontSize!: number;

    textFontFamily!: string;

    textFillColor!: string;

    followLine = false;

    halo !: boolean;
    
    haloSize !: number;

    lineGeom = false;
    
    useCentroid = false;

    geomAttributeName = '';

    textAlignement!: CanvasTextAlign;

    textStrokeColor!: string;

    textBackGroundFillColor!: string;

    textBackGroundStrokeColor!: string;

    textBackGroundHaloColor = '#FFFFFF';

    textRotation!: number;

    textVerticalPadding!: number;


    availableFunctions=['round'];

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
    }

    public setBaseStyle(baseStyle: TextStyle):void {
      this.baseStyle = baseStyle;
    }

    toJson():any {
      return {
        text: this.text,
        padding: this.padding,
        halo: this.halo,
        haloSize: this.haloSize,
        textBackGroundHaloColor: this.textBackGroundHaloColor,
        offsetX: this.offsetX,
        offsetY: this.offsetY,
        offsetZ: this.offsetZ,
        followLine: this.followLine,
        lineGeom: this.lineGeom,
        useCentroid: this.useCentroid,
        geomAttributeName: this.geomAttributeName,
        textFontSize: this.textFontSize,
        textFontFamily: this.textFontFamily,
        textFillColor: this.textFillColor,
        textAlignement: this.textAlignement,
        textStrokeColor: this.textStrokeColor,
        textBackGroundFillColor: this.textBackGroundFillColor,
        textBackGroundStrokeColor: this.textBackGroundStrokeColor,
        textRotation: this.textRotation,
        textVerticalPadding: this.textVerticalPadding,
      };
    }

    setDefaultValues():void {
      this.text = '';
      this.padding = 3;
      this.textFillColor = '#000000';
      this.textStrokeColor = 'rgba(200, 200, 200, 1)';
      this.textBackGroundFillColor = '';
      this.textBackGroundStrokeColor = '';
      this.textFontSize = 11;
      this.textFontFamily = 'Arial';
      this.followLine = false;
      this.halo = false;
      this.haloSize = 1;
      this.textBackGroundHaloColor = '#FFFFFF';
      this.offsetX = 0;
      this.offsetY = 0;
      this.offsetZ = '0';
      this.textAlignement = 'center';
      this.textRotation = 0;
      this.textVerticalPadding = 10;
    }

    public createTextStyle(feature?: Feature<any>):Text {
      const align:CanvasTextAlign = this.getTextAlignement();// ou end,left,right,start
      const baseline:CanvasTextBaseline = 'middle';
      const size = this.getTextFontSize();
      const offsetX = this.getOffsetX();
      const offsetY = this.getOffsetY() !== 0 ? this.getOffsetY() : this.getTextVerticalPadding() * -1;
      const weight = 'normal';// ou bold

      let placement:TextPlacement = 'point';// ou line
      if(this.followLine){
        placement='line';
      }
      const maxAngle = 45;
      const overflow = false;
      const rotation = (this.getTextRotation() * Math.PI) / 180;
      const fontFamily= this.getFontFamily();
      const font = `${weight} ${size}px "${fontFamily}"`;
      const fillColor = this.getTextFillColor();
      const backGroundfillColor = this.getTextBackGroundFillColor();
      const backGroundstrokeColor = this.getTextBackGroundStrokeColor();
      const outlineColor = this.getTextBackGroundHaloColor();

      let outlineWidth = 0;
      if(this.getHalo()){
        outlineWidth = Number(this.getHaloSize());
      }
      const options = {
        textAlign: align,
        textBaseline: baseline,
        font,
        padding: this.getTextPadding(),
        text: this.getTextContent(feature),
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({ color: outlineColor, width: outlineWidth }),
        offsetX,
        offsetY,
        placement,
        maxAngle,
        overflow,
        rotation,
      };
      if (backGroundfillColor && backGroundfillColor.length > 0) {
        (<any>options).backgroundFill = new Fill({ color: backGroundfillColor });
      }
      if (backGroundstrokeColor && backGroundstrokeColor.length > 0) {
        (<any>options).backgroundStroke = new Stroke({ color: backGroundstrokeColor });
      }
      return new Text(options);
    }

    private getTextContent(feature?: Feature<any>) {
      let retour= this.getText().replace(/{(.*?)}/g, (a, b) => {
        const value = feature ? feature.get(b) : null;

        return value || '';
      });


      this.availableFunctions.forEach(functionName => {
        let toReplace=new RegExp(functionName+'\\((.*?)\\)', 'g')
        retour=retour.replace(toReplace, (a, b) => b);
      });

      return retour;
    }

    getOffsetY():any {
      return this.offsetY !== undefined ? this.offsetY : this.baseStyle.getOffsetY();
    }

    getHaloSize():any {
      return this.haloSize !== undefined ? this.haloSize : this.baseStyle.getHaloSize();
    }

    getHalo():any {
      return this.halo !== undefined ? this.halo : this.baseStyle.getHalo();
    }
    getFontFamily():any {
      return this.textFontFamily !== undefined ? this.textFontFamily : this.baseStyle.getFontFamily();
    }

    getOffsetZ():any {
      return this.offsetZ !== undefined ? this.offsetZ : this.baseStyle.getOffsetZ();
    }

    getOffsetX():any {
      return this.offsetX !== undefined ? this.offsetX : this.baseStyle.getOffsetX();
    }

    getTextPadding():any {
      return this.padding !== undefined
        ? [this.padding, this.padding, this.padding, this.padding]
        : this.baseStyle.getTextPadding();
    }

    getPadding():any {
      return this.padding !== undefined
        ? this.padding
        : this.baseStyle.getPadding();
    }

    getTextBackGroundFillColor(): string {
      return this.textBackGroundFillColor !== undefined
        ? this.textBackGroundFillColor : this.baseStyle.getTextBackGroundFillColor();
    }

    getTextBackGroundHaloColor(): string {
      return this.textBackGroundHaloColor !== undefined
        ? this.textBackGroundHaloColor : this.baseStyle.getTextBackGroundHaloColor();
    }

    getTextBackGroundStrokeColor(): string {
      return this.textBackGroundStrokeColor !== undefined
        ? this.textBackGroundStrokeColor : this.baseStyle.getTextBackGroundStrokeColor();
    }

    getTextFontSize(): number {
      return this.textFontSize ? this.textFontSize : this.baseStyle.getTextFontSize();
    }

    getTextVerticalPadding(): number {
      return this.textVerticalPadding ? this.textVerticalPadding : this.baseStyle.getTextVerticalPadding();
    }

    getFontSld(): string {
      return `<Font>
        <CssParameter name="font-family">${this.getFontFamily()}</CssParameter>
        <CssParameter name="font-size">${this.getTextFontSize()}</CssParameter>
        <CssParameter name="font-style">normal</CssParameter>
        <CssParameter name="font-weight">bold</CssParameter>
      </Font>`;
    }

    getAnchorPointSld(): string {
      let anchor = `<AnchorPoint>
                        <AnchorPointX>0.5</AnchorPointX>
                        <AnchorPointY>0.5</AnchorPointY>
                    </AnchorPoint>`;

      if (this.getTextAlignement() === 'start' || this.getTextAlignement() === 'left') {
        anchor = `<AnchorPoint>
                    <AnchorPointX>0.0</AnchorPointX>
                    <AnchorPointY>0.5</AnchorPointY>
                 </AnchorPoint>`;
      }
      if (this.getTextAlignement() === 'end' || this.getTextAlignement() === 'right') {
        anchor = `<AnchorPoint>
                    <AnchorPointX>1.0</AnchorPointX>
                    <AnchorPointY>0.5</AnchorPointY>
                  </AnchorPoint>`;
      }
      return anchor;
    }

    textSymbolizerToSld():string {
      let txtSld = '';
      if (this.getText()) {
        let label = '';
        if (this.getText().indexOf('{') >= 0) {
          let labelComplex = this.getText().replace(/{(.*?)}/g, (a, b) => ` <ogc:PropertyName>${b.replace(/\{|\}/g, '')}</ogc:PropertyName>` || '');

          this.availableFunctions.forEach(functionName => {
            let toReplace=new RegExp(functionName+'\\((.*?)\\)', 'g');
            labelComplex=labelComplex.replace(toReplace, (a, b) => {
              return `<ogc:Function name="${functionName}">${b}</ogc:Function>`
            });
          });

          label = `<Label>
                ${labelComplex}
              </Label>`;
        } else {
          label += `<Label>
                <ogc:Literal>${this.getText()}</ogc:Literal>
              </Label>`;
        }
        if(this.getHalo()){
        label += `
        <Halo>
           <Radius>${this.getHaloSize()}</Radius>
           <Fill>
            <CssParameter name="fill">${MapUtils.rgba2hex(this.getTextBackGroundHaloColor())}</CssParameter> 
            <CssParameter name="fill-opacity">${MapUtils.rgba2opacity(this.getTextBackGroundHaloColor())}</CssParameter> 
           </Fill>
         </Halo>`;
        }
        let padding=0;
        if(this.getPadding()){
          padding=this.getPadding();
        }
        let placement= `
          <PointPlacement>
           ${this.getAnchorPointSld()}
            <Displacement>
              <DisplacementX>${this.getOffsetX()}</DisplacementX>
              <DisplacementY>${this.getOffsetY()}</DisplacementY>
            </Displacement>
            <Rotation>${this.getTextRotation()}</Rotation>
          </PointPlacement>
        `;
        let vendor=`<VendorOption name="graphic-margin">${padding}</VendorOption>
          <VendorOption name="graphic-resize">stretch</VendorOption>
          <VendorOption name="spaceAround">60</VendorOption>
          <VendorOption name="autoWrap">100</VendorOption>
          <VendorOption name="maxDisplacement">5</VendorOption>`;

        if(this.followLine ||this.lineGeom){
          placement= `
            <LinePlacement>
              <PerpendicularOffset>${this.getOffsetY()}</PerpendicularOffset>
            </LinePlacement>
          `;
          
        }
        
        if(this.followLine){
          vendor += `<VendorOption name="followLine">true</VendorOption>`;
        }
        
        let graphic='';
        if(this.getTextBackGroundFillColor()!=''){
          let stroke='';
          if(this.getTextBackGroundStrokeColor()!=''){
            stroke=`
            <Stroke>
                <CssParameter name="stroke">${MapUtils.rgba2hex(this.getTextBackGroundStrokeColor())}</CssParameter>
                <CssParameter name="stroke-opacity">${MapUtils.rgba2opacity(this.getTextBackGroundStrokeColor())}</CssParameter>                      
            </Stroke>
            `;
          }
          graphic=`<Graphic>
          <Mark>
            <WellKnownName>square</WellKnownName>
            <Fill>
                <CssParameter name="fill">${MapUtils.rgba2hex(this.getTextBackGroundFillColor())}</CssParameter> 
                <CssParameter name="fill-opacity">${MapUtils.rgba2opacity(this.getTextBackGroundFillColor())}</CssParameter>                 
            </Fill>
            ${stroke}
          </Mark>
        </Graphic>`;
        }
        

        let geometry= '';
        if(this.useCentroid){
          let geomAttributeName='the_geom';
          if(this.geomAttributeName.length>0){
            geomAttributeName=this.geomAttributeName;
          }
          geometry=`<Geometry>
          <ogc:Function name="centroid">
            <ogc:PropertyName>${geomAttributeName}</ogc:PropertyName>
          </ogc:Function>
        </Geometry>`;   
        }

        txtSld = `<TextSymbolizer>
            ${geometry}
            ${label}
            ${this.getFontSld()}
            <LabelPlacement>
              ${placement}
            </LabelPlacement>

            <Fill>
              <CssParameter name="fill">${MapUtils.rgba2hex(this.getTextFillColor())}</CssParameter>
              <CssParameter name="fill-opacity">${MapUtils.rgba2opacity(this.getTextFillColor())}</CssParameter>
            </Fill>
            ${graphic}
            ${vendor}
          </TextSymbolizer>`;  
      }

      return txtSld;
    }

    getText(): string {
      return (this.text !== undefined && this.text != '') ? this.text : this.baseStyle? this.baseStyle.getText():'';
    }

    getTextAlignement(): CanvasTextAlign {
      return this.textAlignement !== undefined
        ? this.textAlignement : this.baseStyle.getTextAlignement();
    }

    getTextFillColor(): string {
      return this.textFillColor ? this.textFillColor : this.baseStyle.getTextFillColor();
    }

    getTextRotation(): number {
      return this.textRotation !== undefined
        ? this.textRotation : this.baseStyle.getTextRotation();
    }

    getTextStrokeColor(): string {
      return this.textStrokeColor ? this.textStrokeColor : this.baseStyle.getTextStrokeColor();
    }
}
