

















/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import GeometryType from '@/models/map-context/GeometryType';
import LayerConfig from '@/models/map-context/LayerConfig';
import StyleRule from '@/models/map-context/style/StyleRule';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import { toContext } from 'ol/render';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

// eslint-disable-next-line no-use-before-define
@Component<VectorLegend>({
  name:'VectorLegend',
  props: {
    layer: LayerConfig,
    rule:StyleRule,
    refreshToken:Number
  },
  components: {
    VectorLegend,
  },
  watch: {
    layer(layer: LayerConfig) {
      this.drawVectorLegend();
    },
    refreshToken(layer: Number) {
      this.drawVectorLegend();
    },
    rule: {
      // deep: true, // deep watch pour savoir quand on a changé une couleur ou autre paramètre dans l'objet rule
      handler: function(newValue, oldValue) {
          this.drawVectorLegend();
      }
    },
  },
})
export default class VectorLegend extends BaseComponent {
  
  layer!:LayerConfig;

  rule!:StyleRule;

  legendUrl='';

  mounted() {
    console.log(this.rule);
    this.drawVectorLegend();
  }

  getLabel(rule:StyleRule){
    if(rule.label)
      return rule.label;
    else
     return rule.value;
  }

  drawVectorLegend() {
    console.log('drawVectorLegend');
    if (this.$refs.canvas) {
      const canvasref:any=this.$refs.canvas;
      const ctx: CanvasRenderingContext2D = <any>canvasref.getContext('2d');
      let height = 30;
      if (this.layer && this.layer.geometryType === GeometryType.POINT) {
        if (this.rule) {
          height = 15 + Number(this.rule.style.pointSymbol.getSize());
        }
        else {
          height = 15 + Number(this.layer.style.pointSymbol.getSize());
        }
      }
      const vectorContext = toContext(ctx, { size: [100, height] });

      if (this.rule) {
        const st = this.rule.style.getStyle(undefined,undefined,true);
        console.log(this.rule.style.fillStyle.fillColor);
        vectorContext.setStyle(st);
      } else if (this.layer) {
        vectorContext.setStyle(this.layer.style.getStyle(undefined,undefined,true));
      }

      // wait for image load
      setTimeout(()=>{
        if (this.layer && this.layer.geometryType === GeometryType.LINE) {
          vectorContext.drawGeometry(new LineString([[2, 2],[20, 30], [30, 2],  [58, 30]]));
        } else if (this.layer && this.layer.geometryType === GeometryType.POINT) {
          vectorContext.drawGeometry(new Point([50, height/2]));
        } else {
          vectorContext.drawGeometry(new Polygon([[[2, 2], [58, 2], [58, 28], [2, 28], [2, 2]]]));
        }
      },500)
      
    }
  }
}
