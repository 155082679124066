export interface IParameterTile {
  name:string;
  links: { [layerId: string]: string };
}

export interface IDashBoardTile {
  url:string;
  columns:number;
  rows:number;
  img:boolean;
  parameters:IParameterTile[];
}

export default class DashBoardTile implements IDashBoardTile {
    url = '';

    columns = 1;

    rows = 1;

    img = false;

    parameters:IParameterTile[] = [];

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
    }

    toJson():IDashBoardTile {
      return {
        url: this.url,
        columns: this.columns,
        rows: this.rows,
        img: this.img,
        parameters: this.parameters || [],
      };
    }
}
