























import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';

import BaseComponent from '../../BaseComponent';
import SingleStyleDefinition from '../style/SingleStyleDefinition.vue';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
    SingleStyleDefinition,
  },
})
export default class SingleLayerCoupe extends BaseComponent {
  layer!: LayerConfig;

  name = '';

  altitude = '';

  direction = '';

  active = true;

  coupeList: { name: string, altitude: number, active: boolean, direction:string }[] = [];

  addDataCoupe(): void {
    this.coupeList.push({
      name: this.name, altitude: parseFloat(this.altitude), active: false, direction: 'Z',
    });
    this.name = '';
    this.altitude = '';
    this.direction = 'Z';
    this.active = false;
  }

  deleteDataCoupe(index: number): void {
    this.coupeList.splice(index, 1);
  }

  created(): void {
    this.coupeList = this.layer.layer3dConfig.listeCoupe;
  }
}
