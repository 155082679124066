











/* eslint-disable class-methods-use-this */
import PluginComponent from '@/components/plugins/PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';

@Component
export default class DashBoardPlugin extends PluginComponent {
    static ID='DashBoardPlugin';

    display = false;

    editable = false;

    created():void {
      this.editable = this.plugin.getParameterValue('hideable') === true;
      console.log('plugin created');
    }

    mounted(): void {
      this.display = this.plugin.getParameterValue('display') !== false;
      EventBus.$emit(EVENTS.DASHBOARD_DISPLAY, this.display);
      console.log('plugin mounted');
    }

    updated(): void {
      console.log('plugin updated');
      EventBus.$emit(EVENTS.DASHBOARD_UPDATED, this.plugin);
    }

    onClick():void {
      if (this.editable) {
        this.display = !this.display;
        EventBus.$emit(EVENTS.DASHBOARD_DISPLAY, this.display);
      }
    }
}
