
















































/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SingleLayerElementSimplified from '@/components/layers/SingleLayerElementSimplified.vue';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import Plugin from '@/models/plugin/Plugin';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import { Watch } from 'vue-property-decorator';

@Component({
  name: 'GroupLayerSimplified',
  props: {
    layer: LayerGroupConfig,
    plugin: {},
  },
  components: {
    SingleLayerElementSimplified,
    GroupLayerSimplified,
  },
})
export default class GroupLayerSimplified extends BaseComponent {
  layer!: LayerGroupConfig;

  plugin!:Plugin;

  showConfigBtn = true;

  collapsed = true;

  visible = false;


  mounted() {
    this.updateVisible();
  }

  get childs() {
    return this.layer.childs;
  }
    
  @Watch('layer.visible')
  updateVisible(){
    this.visible=this.layer.visible;
  }

  onChange(): void {
    this.layer.setVisible(!this.layer.visible);
  }

  isFirstElementInTree() {
    return (
      this.contextService.getCurrentContext().getLayers()[0] === this.layer
      || (this.layer.parent !== undefined
        && this.layer.parent.childs[0] === this.layer)
    );
  }

  isEmptyGroup() {
    return this.layer.childs.length === 0;
  }

  infoGroup() {
    this.contextService.setCurrentAction('infoGroup', this.layer);
  }

  deleteGroup(): void {
    this.getMapService().removeGroupFromList(this.layer);
  }



  toggle(): void {
    this.collapsed = !this.collapsed;
  }
}
