export default class Layer3dConfig {
    scaleZ = 100;

    scaleZMin = 0;

    scaleZMax = 100;

    stepScale:number=(this.scaleZMax - this.scaleZMin) / 10 ;

    offsetZ = 0;

    offsetZMin = -100;

    offsetZMax = 100;

    stepOffset:number=(this.offsetZMax - this.offsetZMin) / 10 ;

    altitudeType ?:string = 'onterrain';

    altitudeOffset?: number = 0;

    maximumScreenSpaceError = '';

    is3d = false;

    listeCoupe:any = [];

    listeTrou:any = [];

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
    }

    toJson():any {
      return {
        altitudeOffset: this.altitudeOffset,
        altitudeType: this.altitudeType,
        offsetZ: this.offsetZ,
        offsetZMin: this.offsetZMin,
        offsetZMax: this.offsetZMax,
        scaleZ: this.scaleZ,
        scaleZMin: this.scaleZMin,
        scaleZMax: this.scaleZMax,
        maximumScreenSpaceError: this.maximumScreenSpaceError,
        listeCoupe: this.listeCoupe,
        listeTrou:this.listeTrou,
      };
    }

    getScaleZ(): number {
      return this.scaleZ;
    }

    setScaleZ(scale: number): void {
      this.scaleZ = scale;
    }

    getOffsetZ() :number {
      return this.offsetZ;
    }

    setOffsetZ(offset: number): void {
      this.offsetZ = offset;
    }

    getOffsetZMin():number {
      return Number(this.offsetZMin);
    }

    setOffsetZMin(offset: number): void {
      this.offsetZMin = offset;
      this.setStepOffset();
    }

    getOffsetZMax():number {
      return Number(this.offsetZMax);
    }

    setOffsetZMax(offset: number): void {
      if (!Number.isNaN(offset)) {
        // eslint-disable-next-line no-empty

        this.offsetZMax = offset;
        this.setStepOffset();
      }
    }

    getScaleZMin(): number {
      return Number(this.scaleZMin);
    }

    setScaleZMin(scale: number): void {
      if (!Number.isNaN(scale)) {
        this.scaleZMin = scale;
        this.setStepScale();
      }
    }

    getScaleZMax():number {
      return Number(this.scaleZMax);
    }

    setScaleZMax(scale: number): void {
      if (!Number.isNaN(scale)) {
        this.scaleZMax = scale;
        this.setStepScale();
      }
    }

    setStepOffset() :void {
      this.stepOffset = (this.offsetZMax - this.offsetZMin) / 10;
    }

    getStepOffset():number {
      this.setStepOffset();
      return Number(this.stepOffset);
    }

    setStepScale() :void {
      this.stepScale = (this.scaleZMax - this.scaleZMin) / 10;
    }

    getStepScale(): number {
      this.setStepScale();
      return Number(this.stepScale);
    }
}
