




































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import BaseComponent from '@/components/BaseComponent';
import SymbolStyleDefinition from '@/components/layers/style/SymbolStyleDefinition.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import FillStyle from '@/models/map-context/style/FillStyle';
import Component from 'vue-class-component';

@Component({
  props: {
    fillStyle: FillStyle,
  },
  components: {
    SymbolStyleDefinition,
    FormTextInput,
  },
})
export default class FillStyleDefinition extends BaseComponent {
  fillStyle!:FillStyle;

  motifList=[{ item: 'simple', name: 'Remplissage simple', is3d: true },
    { item: 'none', name: 'Pas de remplissage', is3d: true },
    { item: 'svg', name: 'symboles SVG', is3d: false },
    { item: 'circle', name: 'cercles', is3d: false },
    { item: 'square', name: 'carrés', is3d: false },
    { item: 'slash', name: 'hachure', is3d: true },
    { item: 'backslash', name: 'hachure (2)', is3d: true },
    { item: 'cross', name: 'croix', is3d: false },
    { item: 'plus', name: 'plus', is3d: false },
    { item: 'horline', name: 'Lignes horizontales', is3d: false },
    { item: 'vertline', name: 'Lignes verticales', is3d: false },
    { item: 'dot', name: 'points', is3d: false }];

  get hasMotif() {
    return this.fillStyle.fillPattern !== undefined
    && this.fillStyle.fillPattern !== ''
    && this.fillStyle.fillPattern !== 'none'
    && this.fillStyle.fillPattern !== 'simple';
  }

  get fillPattern() {
    console.log(this.fillStyle.getFillPattern());
    return this.fillStyle.getFillPattern();
  }

  onChangeType(event:any) {
    this.fillStyle.fillPattern = event;
    this.inputChange();
  }

  inputChange() {
    this.$emit('symboChange', '');
  }
}
