/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import Filter from '@/models/map-context/filter/Filter';
import LayerConfig from '@/models/map-context/LayerConfig';
import TileState from 'ol/TileState';
import ContextService from './ContextService';

export default class LayerManager {
  nbtileLoading = 0;

  contextService!:ContextService;

  constructor(cs:ContextService) {
    this.contextService = cs;
  }

  addLoadingFunction(source: any, layerconfig: LayerConfig, crsfToken:string) :void{
    layerconfig.checkSourceAuthentification();

    const layercfg = layerconfig;
    const self = this;
    const loader = function customLoader(tile: any, src: string) {
      const maxUrlLength = 2000;
      /* if (!layercfg.authenticationInfos.isRequired() && src.length < maxUrlLength) {
        tile.getImage().src = src;
        return;
      } */
      var client = new XMLHttpRequest();
      // client.withCredentials=true;
      /*
      client.onreadystatechange = () => {
        if (client.readyState === client.HEADERS_RECEIVED) {
          const contentType = client.getResponseHeader("Access-Control-Allow-Origin");
          console.log(contentType)
        }
      };
      */
      // client.timeout = 1000;
      let params;
      let url:any;
      if (src.length < maxUrlLength) {
        url=src;
        client.open('GET', src);
      } else {
        const urlArray = src.split('?');
        url = urlArray[0];
        if (layercfg.useProxy) {
          url = self.contextService.getProxyFiedUrl(url);
        }
        params = urlArray[1];
        client.open('POST', url, true);
        client.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        client.setRequestHeader('X-CSRFToken', crsfToken);
      }
      client.responseType = 'arraybuffer';
      if (layercfg.authenticationInfos.isFilled()) {
        client.setRequestHeader('Authorization', layerconfig.authenticationInfos.getBasicAutorisationValue());
      }
    
      // const startime = (new Date()).getTime();
      client.onload = function onload() {
        /*
        const stoptime = (new Date()).getTime();
        const delay = (stoptime - startime) / 1000;
        if (delay > 3) {
          console.log('Problème dans de chargement de la couche: Le serveur est trop long à répondre');
          // layercfg.error =
          // 'Problème dans de chargement de la couche: Le serveur est trop long à répondre';
        }
        */
        self.handleError(layercfg,tile,url,this);
        
        const decoder = new TextDecoder();
        const str = decoder.decode(this.response);
        if(str.indexOf('ServiceException')>0){
          layercfg.addError( `Erreur dans le chargement de la couche:<br>${str}<br> ${url}`,200);
          return;
        }
        const arrayBufferView = new Uint8Array(this.response);
        const blob = new Blob([arrayBufferView], { type: 'image/png' });
        const urlCreator = window.URL || (window as any).webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        tile.getImage().src = imageUrl;
      };
      client.addEventListener('error', (e) => {
        layercfg.useProxy = true;
        if(client.status==0){
          layercfg.addError( `Erreur CORS :<br> ${url}`);
        }else{
          layercfg.addError( `Erreur dans le chargement de la couche:<br> ${url}`,client.status);
        }
        tile.error = layercfg.error;
        tile.setState(TileState.ERROR);
      });
      /*
      client.addEventListener('timeout', (e) => {
        console.log('timeout');
        tile.setState(TileState.ERROR);
      });
      */
      client.send(params);
    };

    if (source.setImageLoadFunction) {
      source.setImageLoadFunction(loader);
    } else if (layerconfig.type !== 'VectorTile' && !layerconfig.isWFS() && layerconfig.type !== 'Vector') {
      source.setTileLoadFunction(loader);
    }
  }

  handleError(layercfg: LayerConfig,tile:any,url:string, reponse: any){
    if (reponse.status === 401) {
      layercfg.addError('Erreur dans le chargement de la couche: Autorisation requise (401)',401);
    } else if (reponse.status === 500) {
      layercfg.addError( 'Erreur dans le chargement de la couche',500);
    } else if (reponse.status === 404) {
      layercfg.addError( `Erreur dans le chargement de la couche: Non Trouvé (404)<br> ${url}`,404);
    } else if (reponse.status === 400) {
      layercfg.addError( `Erreur dans le chargement de la couche:<br>${reponse.responseText}<br> ${url}`,400);
    }

    if(reponse.status === 401 ||reponse.status === 500||reponse.status === 404||reponse.status === 400){
      if(tile){
        tile.error = layercfg.error;
        tile.setState(TileState.ERROR);
      }
    }
  }

  addLoading(layerSource: LayerConfig, e: any) :void{
    this.nbtileLoading += 1;
    if (!e.target.nbtileLoading) {
      e.target.nbtileLoading = 0;
    }
    e.target.nbtileLoading += 1;
  }

  addLoaded(layerSource: LayerConfig, e: any) :void{
    if (layerSource.type === 'VectorTile' && layerSource.filter) {
      const filter = new Filter();
      filter.fromCQL(layerSource.filter);
      e.tile.setFeatures(e.tile.getFeatures().filter((x:any) => filter.evaluate(x)));
    }
    this.nbtileLoading -= 1;
    e.target.nbtileLoading -= 1;
  }

  addLoadError(layerSource: LayerConfig, e: any) :void{
    this.nbtileLoading -= 1;
    if (e.tile && e.tile.error) {
      layerSource.addError(e.tile.error.message,e.tile.error.type);
    }
    else{
      layerSource.addError('Erreur dans le chargement de la couche');
    }
    e.target.nbtileLoading -= 1;
  }

 
  addLoadingListener(layerSource: any, layerconfig: LayerConfig) :void{
    if (!layerconfig.tiled) {
      layerSource.on('imageloadstart',
        this.addLoading.bind(this, layerconfig));
      layerSource.on('imageloadend',
        this.addLoaded.bind(this, layerconfig));
      layerSource.on('imageloaderror',
        this.addLoadError.bind(this, layerconfig));
    } else {
      layerSource.on('tileloadstart',
        this.addLoading.bind(this, layerconfig));
      layerSource.on('tileloadend',
        this.addLoaded.bind(this, layerconfig));
      layerSource.on('tileloaderror',
        this.addLoadError.bind(this, layerconfig));
    }
  }

  getVectorLoaderFunction(layerconfig:LayerConfig):any {
    const self = this;
    const vectorLoaderFunction = function vectorLoaderFunction(extent:any,
      resolution:any, projection:any, callback:any) {
      const url = layerconfig.getWFSUrl(extent, projection);
      const xhr = new XMLHttpRequest();
      self.nbtileLoading += 1;
      xhr.open('GET', url);
      if (layerconfig.hasAuthenticationInfos()) {
        xhr.setRequestHeader('Authorization', layerconfig.authenticationInfos.getBasicAutorisationValue());
      }
      const onError = function onError(e:any) {
        self.nbtileLoading -= 1;
        self.handleError(layerconfig,undefined,url,e)
        layerconfig.getLayerSource().removeLoadedExtent(extent);
      };
      xhr.onerror = onError;
      xhr.onload = function onload() {
        self.nbtileLoading -= 1;
        if (xhr.status === 200) {
          const features = layerconfig.getLayerSource().getFormat().readFeatures(xhr.responseText);
          layerconfig.getLayerSource().addFeatures(features);
          callback(features);
        } else {
          onError(xhr);
        }
      };
      xhr.send();
    };
    return vectorLoaderFunction;
  }
}
