export default class ModelConfig {
    position!:number[];

    orientation!:number;

    scale!:number;

    projection!: string;

    materials={};

    constructor(input?: any) {
      if (input) {
        Object.assign(this, input);
      }
    }

    toJson():any {
      return {
        position: this.position,
        orientation: this.orientation,
        scale: this.scale,
        projection: this.projection,
        materials: this.materials
      };
    }
}
