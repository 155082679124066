











/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import { adminServiceInstance, AdminService } from '@/services/AdminService';
import User from '@/models/user/User';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import EventBus, { EVENTS } from '@/services/EventBus';
import ActionButton from '@/components/shared/ActionButton.vue';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
  },
  components: {
    FormTextInput,
    ActionButton,
  },
})
export default class ConfirmUserPanel extends BaseComponent {
  adminService=adminServiceInstance;

  accountValid = false;

  accountError = false;

  message ='';

  mounted() {
    const tok:any = this.$route.query.token;
    console.log(tok);
    if (tok) {
      this.adminService.confirmUser(tok).then((reponse) => {
        console.log(reponse);
        this.accountValid = true;
        // EventBus.$emit(EVENTS.INFO, 'OK');
      },
      (error) => {
        console.log(error);

        this.accountError = true;
        this.message = error.response.data.error;
        // EventBus.$emit(EVENTS.INFO, 'OK');
      });
    }
  }
}
