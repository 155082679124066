


































































































































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import { adminServiceInstance, AdminService } from '@/services/AdminService';
import User from '@/models/user/User';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import EventBus, { EVENTS } from '@/services/EventBus';
import ActionButton from '@/components/shared/ActionButton.vue';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
  },
  components: {
    FormTextInput,
    ActionButton,
  },
})
export default class UserPanel extends BaseComponent {
  isProfil= false;

  adminService=adminServiceInstance;

  user:User=new User();

  newUser = true;

  showPasswordPlaceholder= true;

  showPassword= false;

  currentPath= '';

  passwordConfirm = '';

  checkPass = false;

  checkall = false;

  mounted() {
    const userId = this.$route.params.id;
    this.currentPath = this.$route.path;
    if (this.$route.query.parentProps) {
      this.isProfil = true;
    }
    if (userId && !this.isSignUp()) {
      this.adminService.getUser(userId).then((reponse) => {
        console.log(reponse);
        this.newUser = false;
        this.user = new User(reponse.data);
        // EventBus.$emit(EVENTS.INFO, 'OK');
      });
    }
    this.$watch(
      () => this.$route.path,
      (newPath, oldPath) => {
        // Le chemin d'accès a changé, mettez à jour la propriété currentPath
        this.currentPath = newPath;
        // Vous pouvez également appeler une fonction pour effectuer une action personnalisée ici
        const watchuserId = this.$route.params.id;
        if (this.$route.query.parentProps) {
          this.isProfil = true;
        }
        if (watchuserId && !this.isSignUp()) {
          this.adminService.getUser(watchuserId).then((reponse) => {
            console.log(reponse);
            this.newUser = false;
            this.user = new User(reponse.data);
            // EventBus.$emit(EVENTS.INFO, 'OK');
          });
        }
      },
    );
  }

  isSignUp() {
    return this.currentPath === '/users/signup';
  }

  retourPagePrecedente() {
    if (this.isSignUp()) {
      this.$router.push({ path: '/maps' });
    } else {
      this.$router.push({ path: '/users' });
    }
  }

  isValidEmail(email: string): boolean {
    // Vous pouvez utiliser une expression régulière ou une logique de validation plus complexe ici
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  create(event:any) {
    event.preventDefault();
    if (this.newUser
      && (!this.user.password || !this.user.email || !this.user.foreName
      || !this.user.login || !this.user.name
      || !this.isValidEmail(this.user.email))) {
      this.checkall = true;
      return;
    }
    if (!this.newUser
      && (!this.user.email || !this.user.foreName
      || !this.user.login || !this.user.name
      || !this.isValidEmail(this.user.email))) {
      this.checkall = true;
      return;
    }
    if (this.user.password !== this.passwordConfirm && this.newUser) {
      this.checkPass = true;
      return; // Ne continuez pas le processus de création.
    }
    console.log('create User');
    // ACTION UTILISATEUR
    if (this.isSignUp()) {
      this.contextService.signup(this.user).then((reponse:any) => {
        if ((reponse.statusText === 'Created' || reponse.statusText === 'OK') && !this.isProfil) {
          this.$router.push({ path: '/maps' });
          EventBus.$emit(EVENTS.INFO, 'Un mail vous a été envoyé pour finaliser la création de votre compte');
        } else {
          EventBus.$emit(EVENTS.INFO, 'Sauvegarde effectuée');
        }
      });
    } else {
      // ACTION ADMINISTRATEUR
      this.adminService.createOrUpdateUser(this.user).then((reponse) => {
        if ((reponse.statusText === 'Created' || reponse.statusText === 'OK') && !this.isProfil) {
          this.$router.push({ path: '/users' });
          EventBus.$emit(EVENTS.INFO, 'Sauvegarde effectuée');
        } else {
          EventBus.$emit(EVENTS.INFO, 'Sauvegarde effectuée');
        }
      });
    }
  }
}
