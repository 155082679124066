























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    title: String,
  },
})
export default class ModalPanel extends BaseComponent {
  public closed=false;

  close() :void{
    this.closed = true;
    this.contextService.currentAction = '';
  }
}
