




























































































































































































































































































































/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
import CatalogSearch from '@/components/layers/add-layer-panel/CatalogSearch.vue';
import OgcLayerResult from '@/components/layers/add-layer-panel/OgcLayerResult.vue';
import AuthenticationInfoPanel from '@/components/shared/AuthenticationInfoPanel.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import ModalPanel from '@/components/shared/ModalPanel.vue';
import { ICapabilitiesResponse, OGCLayer, RemoteServer } from '@/models/RemoteServer';
import AuthenticationInfos from '@/models/map-context/AuthenticationInfos';
import LayerConfig from '@/models/map-context/LayerConfig';
import { EVENTS, EventBus } from '@/services/EventBus';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
  },
  components: {
    ModalPanel,
    OgcLayerResult,
    FormTextInput,
    CatalogSearch,
    AuthenticationInfoPanel,
  },
})
export default class AddLayerPanel extends BaseComponent {
  url='';// 'https://pigma.org/geoserver/wms?request=GetCapabilities&version=1.3.0';

  loading=false;

  tabIndex=0;

  tilesetDataTypeList = [
    { item: '3DTiles', name: 'Maquette 3D (format 3D Tiles) ' },
    { item: '3DTiles_Photomaillage', name: 'Photomaillage (format 3D Tiles)' },
    { item: '3DTilesPointCloud', name: 'Nuage de point (format 3D Tiles)' },
  ];

  tilesetDataType='3DTiles';

  resultList:ICapabilitiesResponse|null=null;

  selectedLayerList:OGCLayer[]=[];

  selectedServer:RemoteServer|null=null;

  currentUrl = '';

  serverList=[];

  errorTxt:string|null=null;

  filter='';

  layerList:OGCLayer[]=[];

  showAuthInfos = false;

  authenticationInfos = new AuthenticationInfos();

  generalConfig:any =null;

  geojsonUrl = '';

  tilesetUrl = '';

  isCsw = false;

  filterTextChange = new Subject<string>();

  ogcTabIndex=2;

  /**
   * paging parameters.
   */
  pagingInfos = {
    pageSize: 50,
    currentPage: 1,
    nbPages: 1,
  };

  onChangeDataType(event:any):void {
    this.tilesetDataType = event;
  }

  filterChange():void {
    console.log('filter change');
    const self = this;
    if (this.isCsw) {
      this.filterTextChange.next(this.filter);
    } else if (this.resultList !== null) {
      this.layerList = this.filterList(
        JSON.parse(JSON.stringify(this.resultList.layers)), self.filter,
      );
    }
  }

  filterList(layerList:OGCLayer[], filter:string):OGCLayer[] {
    const retour:OGCLayer[] = [];
    layerList.forEach((element:OGCLayer) => {
      if (element.Layer) {
        element.Layer = this.filterList(element.Layer, filter);
        if (element.Layer.length > 0) {
          retour.push(element);
        }
      } else if (element.Title.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
        retour.push(element);
      }
      console.log(element);
    });
    return retour;
  }

  mounted() :void {
    this.generalConfig = this.contextService.configuration.addLayers;
    this.filterTextChange.pipe(debounceTime(1000))
      .subscribe((res) => this.pageChange(this.pagingInfos.currentPage));
    this.contextService.loadOGCServerList().then((reponse:any) => {
      console.log(reponse.data);
      this.serverList = reponse.data.serverList.map((x:any) => ({ value: x, text: x.nom }));
    });

    if(this.generalConfig.catalog!=undefined && this.generalConfig.catalog==false){
      this.ogcTabIndex--;
    }
    if(this.generalConfig.osm!=undefined && this.generalConfig.osm==false){
      this.ogcTabIndex--;
    }
    if(this.generalConfig.ogc!=undefined && this.generalConfig.ogc==false){
      this.ogcTabIndex=-1;
    }


  }

  pageChange(page:number):void {
    this.pagingInfos.currentPage = page;
    this.loading = true;
    this.connectCSW(this.url);
  }

  isSelected(result:OGCLayer): boolean {
    if (this.selectedLayerList.length === 0) {
      return false;
    }
    return this.selectedLayerList.find((x:OGCLayer) => x.Title === result.Title) !== undefined;
  }

  selectOGCServer() :void{
    if (this.selectedServer !== null) {
      this.url = this.selectedServer.url;
      const authInfos = this.contextService.getAuthInfos()[this.selectedServer.url];
      console.log(authInfos);
      if (authInfos !== undefined) {
        this.authenticationInfos = new AuthenticationInfos(authInfos);
      }
      if (this.selectedServer.type === 'CSW') {
        console.log('csw service detected');
        this.isCsw = true;
        this.connectCSW(this.selectedServer.url);
      } else {
        this.isCsw = false;
        this.getCapabilities(this.selectedServer.url);
      }
    }
  }

  connectServer() :void{
    console.log('connect', this.url);
    let capabilitiesUrl = this.url.trim();
    if (capabilitiesUrl.length === 0 && this.selectedServer !== null) {
      capabilitiesUrl = this.selectedServer.url;
    }
    if (capabilitiesUrl.length === 0) {
      this.error('Veuillez renseigner une url ou choisir un serveur dans la liste');
    } else {
      const searchMask = 'getCapabilities';
      const regEx = new RegExp(searchMask, 'ig');
      if (!regEx.test(capabilitiesUrl)) {
        if (capabilitiesUrl.indexOf('?') > 0) {
          capabilitiesUrl = `${capabilitiesUrl}&request=getCapabilities`;
        } else {
          capabilitiesUrl = `${capabilitiesUrl}?request=getCapabilities`;
        }
      }
      if (capabilitiesUrl.indexOf('/csw') > 0) {
        console.log('csw service detected');
        this.isCsw = true;
        this.connectCSW(capabilitiesUrl);
      } else {
        this.isCsw = false;
       
        this.getCapabilities(capabilitiesUrl);
      }
    }
  }

  connectCSW(url:string) {
    let filterTxt = 'protocol=\'OGC:WMS-1.1.1-http-get-map\' OR protocol=\'OGC:WMS\'';

    if (this.filter.length > 0) {
      filterTxt = `(${filterTxt}) AND title Like '%${this.filter}%'`;
    }

    const startPos = (this.pagingInfos.currentPage - 1) * this.pagingInfos.pageSize + 1;
    url = url.replace('getCapabilities', 'GetRecords');
    const params = `&service=CSW&version=2.0.2&typeNames=csw:Record&resultType=results&maxRecords=${this.pagingInfos.pageSize}&startPosition=${startPos}&ELEMENTSETNAME=full`;
    const filter = encodeURIComponent(filterTxt);
    const cswurl = url
          + params
          + '&constraintLanguage=CQL_TEXT&CONSTRAINT_LANGUAGE_VERSION=1.1.0&CONSTRAINT='
          + filter;
    const self = this;
    const headers:any = {};
    if (this.authenticationInfos
      && this.authenticationInfos.isFilled()) {
      headers.Authorization = this.authenticationInfos.getBasicAutorisationValue();
    }
    this.loading = true;
    this.capabilitiesService.getRecords(cswurl, headers)
      .subscribe(
        (data:any) => {
          console.log(data);
          self.resultList = data;
          if (self.resultList !== null) {
            self.layerList = self.resultList.layers;
          }

          self.loading = false;
        },
        (error:any) => {
          console.log(error);
          this.error(error);
          this.loading = false;
        },
      );
  }

  selectLayer(layer:any) :void{
    // this.empty
    console.log(`Select Layer ${layer}`);
    if (this.selectedLayerList.indexOf(layer) >= 0) {
      this.selectedLayerList.splice(this.selectedLayerList.indexOf(layer), 1);
    } else {
      this.selectedLayerList.push(layer);
    }
  }

  selectCatalogLayer(layer:OGCLayer) :void{
    if (this.selectedLayerList.indexOf(layer) >= 0) {
      this.selectedLayerList.splice(this.selectedLayerList.indexOf(layer), 1);
    } else {
      this.selectedLayerList.push(layer);
    }

    const url:any = layer.Url;
    this.currentUrl = url;
    this.resultList = {
      type: 'WMS',
      useProxy: false,
      layers: [],
    };
    console.log(`Select Catalog Layer ${layer}`);
  }

  get resultsLoaded() :boolean {
    return this.layerList.length > 0 || this.filter.length > 0;
  }

  back() :void{
    this.resultList = null;
    this.filter = '';
    this.layerList = [];
  }

  addVectorTile():void{
    this.appendLayerToMap(true);
  }

  addOSM():void{
    const newLayer = new LayerConfig({
      layername: 'OSM',
      title: 'OSM',
      type: 'OSM',
      visible: true,
      boundingBoxEPSG4326: [-175, -85, 175, 85],
      Attribution: { Title: '© OpenStreetMap',OnlineResource:'https://www.openstreetmap.org/copyright'  },
    });
    this.addLayerToMap(newLayer);
  }

  addTileset():void {
    const newLayer = new LayerConfig({
      layername: '',
      title: 'Nouvelle couche 3D',
      type: this.tilesetDataType,
      visible: true,
      boundingBoxEPSG4326: [-175, -85, 175, 85],
      url: this.tilesetUrl,
    });
    this.addLayerToMap(newLayer);
  }

  addGeoJsonLayer():void {
    const newLayer = new LayerConfig({
      layername: '',
      title: 'Nouvelle couche JSON',
      type: 'Vector',
      visible: true,
      boundingBoxEPSG4326: [-175, -85, 175, 85],
      url: this.geojsonUrl,
    });
    this.addLayerToMap(newLayer);
  }

  addVectorTileOpenMaptiles(style: string, title:string) {
    const newLayer = new LayerConfig({
      layername: 'openmaptiles',
      url: 'https://openmaptiles.geo.data.gouv.fr/data/france-vector/{z}/{x}/{y}.pbf',
      mbStyleUrl: 'https://openmaptiles.geo.data.gouv.fr/styles/osm-bright/style.json',
      description: '',
      queryable: false,
      title: title,
      popupInfos: { content: '{layer}', active: true, edited: false },
      protocol: 'TMS',
      type: 'VectorTile',
      visible: true,
      boundingBoxEPSG4326: [-175, -85, 175, 85],
      Attribution: { Title: '© OpenMapTiles', OnlineResource: '' },
    });
    this.addLayerToMap(newLayer);
  }

  addVectorTilePlanIGN(style: string, title:string):void{
    // couche PLAN.IGN pas encore dispo sur la geoplateforme
    const newLayer = new LayerConfig({
      layername: 'plan_ign',
      url: 'https://wxs.ign.fr/essentiels/geoportail/tms/1.0.0/PLAN.IGN/{z}/{x}/{y}.pbf',
      mbStyleUrl: 'https://data.geopf.fr/annexes/ressources/vectorTiles/styles/PLAN.IGN/essentiels/' + style + '.json',
      metadataUrl: 'https://geoservices.ign.fr/planign',
      description: '',
      queryable: false,
      title: title,
      popupInfos: { content: '{layer}', active: true, edited: false },
      protocol: 'TMS',
      type: 'VectorTile',
      visible: true,
      boundingBoxEPSG4326: [-175, -85, 175, 85],
      Attribution: { Title: '© IGN', OnlineResource: 'https://www.ign.fr' },
    });
    this.addLayerToMap(newLayer);
  }

  addIGN():void{
    const newLayer = new LayerConfig({
      layername: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
      url: 'https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities',
      metadataUrl: 'https://geoservices.ign.fr/planign',
      description: 'Cartographie multi-échelles sur le territoire national, issue des bases de données vecteur de l’IGN, mis à jour régulièrement et réalisée selon un processus entièrement automatisé.',
      title: 'Plan IGN',
      type: 'WMTS',
      visible: true,
      boundingBoxEPSG4326: [-175, -85, 175, 85],
      Attribution: { Title: '© IGN', OnlineResource: 'https://www.ign.fr' },
    });
    this.addLayerToMap(newLayer);
  }

  addIGNOrtho():void{
    const newLayer = new LayerConfig({
      layername: 'ORTHOIMAGERY.ORTHOPHOTOS',
      url: 'https://data.geopf.fr/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities',
      metadataUrl: 'https://wxs.ign.fr/essentiels/geoportail/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities',
      description: 'Photographies aériennes',
      title: 'Photographies aériennes',
      type: 'WMTS',
      boundingBoxEPSG4326: [-175, -85, 175, 85],
      visible: true,
      Attribution: { Title: '© IGN', OnlineResource: 'https://www.ign.fr' },
    });
    this.addLayerToMap(newLayer);
  }

  addWMTS(){
    let layer=this.selectedLayerList[0];
    const newLayer=this.initLayer(layer,'WMS',false);
    newLayer.url = newLayer.getWmtsCapabilitiesUrl();
    newLayer.type='WMTS';
    if (!newLayer.outputFormat) {
      newLayer.outputFormat = 'image/png';
    }
    this.addLayerToMap(newLayer);
  }
  addWFS(){
    let layer=this.selectedLayerList[0];
    const newLayer=this.initLayer(layer,'WMS',false);
    newLayer.url = newLayer.wmsUrlToWfs(newLayer.url);
    newLayer.outputFormat = 'application/json';
    newLayer.type='WFS';
    this.addLayerToMap(newLayer);
  }

  initLayer(selectedLayer:OGCLayer,type:string,useProxy:Boolean):LayerConfig{
    let outputFormat;
        if (selectedLayer.outputFormat) {
          outputFormat = selectedLayer.outputFormat;
        }
        if (selectedLayer.Format && selectedLayer.Format.indexOf('image/png') >= 0) {
          outputFormat = 'image/png';
        }
        console.log(selectedLayer.outputFormat);
        let projection = this.getMapService().mapProjectionCode;
        let dataProjection;
        if (selectedLayer.CRS
        && (selectedLayer.CRS.indexOf(this.getMapService().mapProjectionCode) >= 0)) {
          projection = this.getMapService().mapProjectionCode;
          // eslint-disable-next-line prefer-destructuring
          dataProjection = selectedLayer.CRS[0];
        } else if (selectedLayer.CRS && Array.isArray(selectedLayer.CRS)) {
        // eslint-disable-next-line prefer-destructuring
          projection = selectedLayer.CRS[0];
          // eslint-disable-next-line prefer-destructuring
          dataProjection = selectedLayer.CRS[0];
        } else if (selectedLayer.CRS) {
          projection = selectedLayer.CRS;
          dataProjection = selectedLayer.CRS;
        }

        console.log(selectedLayer);
        let url = this.currentUrl;
        if (selectedLayer.Url) {
          url = selectedLayer.Url;
        }
        //let { type } = resultList;
        if (selectedLayer.Type) {
          type = selectedLayer.Type;
        }
        const newLayer = new LayerConfig({
          layername: selectedLayer.Name,
          url,
          title: selectedLayer.Title,
          description: selectedLayer.Abstract,
          type,
          outputFormat: outputFormat,
          visible: true,
          projection: projection,
          dataProjection: dataProjection,
          Attribution: selectedLayer.Attribution,
          maxScaleDenominator: selectedLayer.MaxScaleDenominator,
          minScaleDenominator: selectedLayer.MinScaleDenominator,
          boundingBoxEPSG4326: undefined,
        });
        if (selectedLayer.MetadataURL && selectedLayer.MetadataURL.length > 0) {
          newLayer.metadataUrl = selectedLayer.MetadataURL[0].OnlineResource;
        }
        if (useProxy) {
          newLayer.url = this.contextService.getProxyFiedUrl(this.currentUrl);
        }

        if (this.authenticationInfos.isFilled()) {
          newLayer.authenticationInfos = new AuthenticationInfos({ ...this.authenticationInfos });
        }
        this.computeBbox(newLayer, selectedLayer);
    return newLayer;
  }
  appendLayerToMap(vectorTile = false) :void{
    if (this.selectedLayerList.length > 0 && this.resultList != null) {
      // eslint-disable-next-line prefer-destructuring
      const resultList = this.resultList;
      const { type } = resultList;
      this.selectedLayerList.forEach((selectedLayer) => {
        const newLayer=this.initLayer(selectedLayer,type,resultList.useProxy);
        if (vectorTile) {
          newLayer.type = 'VectorTile';
          if (selectedLayer.Format && selectedLayer.Format.indexOf('application/vnd.mapbox-vector-tile') >= 0) {
            newLayer.outputFormat = 'application/vnd.mapbox-vector-tile';
          } else if (selectedLayer.Format && selectedLayer.Format.indexOf('application/x-protobuf;type=mapbox-vector') >= 0) {
            newLayer.outputFormat = 'application/x-protobuf;type=mapbox-vector';
          } else {
            newLayer.outputFormat = 'application/x-protobuf';
          }
        }
        
        
        if (this.resultList != null && this.resultList.type === 'WMTS') {
          newLayer.layername = selectedLayer.Identifier;
        }
        console.log(newLayer);

        this.addLayerToMap(newLayer);
      });
      this.selectedLayerList = [];
    }
  }

  private addLayerToMap(newLayer: LayerConfig) {
    try {
      this.contextService.getCurrentContext().getLayers().unshift(newLayer);
      this.getMapService().addLayer(newLayer);
    } catch (error) {
      this.error(error);
    }
  }

  hasVectorTileFormat() :boolean {
    return this.selectedLayerList.length > 0
      && this.selectedLayerList.find((x:OGCLayer) => x.Format && (x.Format.indexOf('application/x-protobuf;type=mapbox-vector') >= 0
        || x.Format.indexOf('application/vnd.mapbox-vector-tile') >= 0
        || x.Format.indexOf('application/x-protobuf') >= 0)) !== undefined;
  }

  hasWMTSFormat() :boolean {
      return this.selectedLayerList.length == 1
        && this.selectedLayerList.find((x:OGCLayer) => x.Wmts) !== undefined;
  }
  hasWFSFormat() :boolean {
      return this.selectedLayerList.length == 1
        && this.selectedLayerList.find((x:OGCLayer) => x.Wfs) !== undefined;
  }
  
  private computeBbox(newLayer: LayerConfig, selectedLayer:OGCLayer) {
    if (this.resultList != null && this.resultList.type === 'WMTS') {
      let bbox = selectedLayer.WGS84BoundingBox;
      if (bbox) {
        for (let i = 0; i < bbox.length; i += 1) {
          bbox[i] = +bbox[i].toFixed(3);
        }
      } else {
        bbox = [-180.0, -90.0, 180.0, 90.0];
      }
      newLayer.boundingBoxEPSG4326 = bbox;
    } else {
      const bbox = selectedLayer.EX_GeographicBoundingBox;
      for (let i = 0; i < bbox.length; i += 1) {
        bbox[i] = +bbox[i].toFixed(3);
      }
      newLayer.boundingBoxEPSG4326 = bbox;
    }
  }

  error(error:any) :void{
    console.log(error);
    this.errorTxt = error;
  }

  checkLayers(capabilitiesResponse:ICapabilitiesResponse) :void{
    // Vérifie que les couches répondent au GetMap
    EventBus.$emit(EVENTS.INFO, 'Tests des 15 premières couches du service en cours...');
    capabilitiesResponse.layers.slice(0, 15).forEach((element:OGCLayer) => {
      if (element.Layer === undefined) { // s'il ne s'agit pas d'un groupe de couche
        console.log(element);
        let url = this.currentUrl + '&request=GetMap&layers=' + element.Name + '&width=256&height=256&format=image/jpeg&srs=EPSG%3A4326&bbox=-145.15104058007,21.731919794922,-57.154894212888,58.961058642578';
        const searchMask = 'request(=|%3D)getCapabilities';
        const regEx = new RegExp(searchMask, 'ig');
        const replaceMask = '';

        url = url.replace(regEx, replaceMask);
        this.contextService.loadUrl(url)
          .then((res) => {
            if (res.data.indexOf && res.data.indexOf('Exception') > 0) {
              element.status = 'Réponse à une requete GetMap KO';
            } else {
              element.status = 'OK (Réponse à une requete GetMap)';
            }
          },
          (res) => {
            console.log('ko');
            element.status = 'Réponse à une requete GetMap KO';
          });
      }
    });
  }

  close() :void{
    this.contextService.setCurrentAction('', []);
  }

  getCapabilities(url: string) :void{

    const serviceSearchMask = 'service=';
    const serviceRegEx = new RegExp(serviceSearchMask, 'ig');
    if (!serviceRegEx.test(url)) {
      url = `${url}&service=wms`;
      if (url.indexOf('?') > 0) {
          url = `${url}&service=wms`;
      } else {
          url = `${url}?service=wms`;
      }
    }
    const self = this;
    const headers:any = {};
    if (this.authenticationInfos
      && this.authenticationInfos.isFilled()) {
      headers.Authorization = this.authenticationInfos.getBasicAutorisationValue();
    }
    this.loading = true;
    this.currentUrl = url;
    this.capabilitiesService.getCapabilities(url, headers, true)
      .subscribe(
        (data:any) => {
          console.log(data);
          self.resultList = data;
          if (self.resultList !== null) {
            self.layerList = self.resultList.layers;
          }

          self.loading = false;
        },
        (error:any) => {
          console.log(error);
          this.error(error);
          this.loading = false;
        },
      );
  }
}
