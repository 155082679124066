















import Component from 'vue-class-component';
import PluginComponent from '@/components/plugins/PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';
import LayerConfig from '@/models/map-context/LayerConfig';

@Component({
  props: {
  },
  components: {
  },
})
export default class StatsPlugin extends PluginComponent {
    static ID='StatsPlugin';

    config:any=null;

    stats:any =[];

    mounted():void {
      EventBus.$on(EVENTS.MAP_CURRENT_BBOX_CHANGE, (bbox:any) => this.onMoveEnd(bbox));
      this.config = this.plugin.getParameterValue('config');
      this.refreshStats();
    }

    onMoveEnd(bbox:any):void {
      console.log(this.config);
      this.refreshStats();
    }

    getStatContent(features:any, statsParams:any):string {
      console.log(this);
      let value:number|null = null;
      if (statsParams.operator === 'COUNT') {
        value = features.length;
      } else if (statsParams.operator === 'MEAN') {
        const sum = features
          .reduce((accumulator:any, object:any) => accumulator + Number(object.get(statsParams.field)), 0);
        value = Math.round((sum / features.length) * 100) / 100;
      } else if (statsParams.operator === 'SUM') {
        value = features
          .reduce((accumulator:any, object:any) => accumulator + Number(object.get(statsParams.field)), 0);
      } else if (statsParams.operator === 'MAX') {
        const arrMax = (arr:any) => Math.max(...arr);
        value = arrMax(features.map((x:any) => x.get(statsParams.field)));
      } else if (statsParams.operator === 'MIN') {
        const arrMin = (arr:any) => Math.min(...arr);
        value = arrMin(features.map((x:any) => x.get(statsParams.field)));
      }
      if (value !== null) {
        return statsParams.template.replace('{x}', value.toLocaleString());
      }

      return 'no data';
    }

    refreshStats():void {
      this.stats = [];
      this.config.stats.forEach((params:any, i:number) => {
        const { layerId } = params;
        if (this.getMapService().getLayersById(layerId)) {
          const layer:LayerConfig|undefined = this.getMapService().config.getLayerById(layerId);
          if (layer !== undefined) {
            const source = layer.getLayerSource();
            const extent = this.getMapService().getMapExtent();
            const features = source.getFeaturesInExtent(extent);
            const content = this.getStatContent(features, params);
            this.stats.push({ content, id: i });
          }
        }
      });
    }
}
