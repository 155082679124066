















































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-destructuring */
import BaseComponent from '@/components/BaseComponent';
import LayerConfig from '@/models/map-context/LayerConfig';
import * as jsts from 'jsts';
import { Feature } from 'ol';
import Component from 'vue-class-component';

import {
LineString,
LinearRing,
MultiLineString,
MultiPoint,
MultiPolygon,
Point,
Polygon
} from 'ol/geom';
import { Projection, addCoordinateTransforms, addProjection } from 'ol/proj';
import {
Circle, Fill, Stroke, Style,
} from 'ol/style';

@Component({
  props: {
    layer: LayerConfig,
    features: {
      type: Array,
      default: [],
    },
    attributes: {
      type: Array,
      default: [],
    },
    selectedFeatures: {
      type: Array,
      default: [],
    },
  },
  components: {
  },
})
export default class FeatureListTable extends BaseComponent {
  layer!: LayerConfig;

  attributes!:any;

  currentPage = 1;

  perPage = 20;

  rows = 0;

  features!:any;

  allFeatures!:any;

  selectedFeatures:any;

  currentSortAttribute:any;

  currentSortOrderAsc = true;

  parser:any;

  resizing = false;

  highlightStyle = new Style({
    fill: new Fill({
      color: 'rgba(253,255,154,0.7)',
    }),
    stroke: new Stroke({
      color: '#CCCCCC',
      width: 1,
    }),
    image: new Circle({
      radius: 7,
      stroke: new Stroke({
        color: '#CCCCCC',
        lineDash: [],
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(253,255,154,0.7)',
      }),
    }),
  });

  mounted():void{
    // eslint-disable-next-line no-undef
    this.parser = new jsts.io.OL3Parser();
    this.parser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon,
    );
    addProjection(new Projection({
      code: 'WGS84',
      units: 'degrees',
    }));

    addCoordinateTransforms(
      'WGS84',
      'EPSG:4326',
      (coordinate) => [coordinate[1], coordinate[0]],
      (coordinate) => [coordinate[1], coordinate[0]],
    );
    // test de colonne resizable => voir les css a activer avec
    // setTimeout(() => { this.addResizableHandles(); }, 1000);
  }

  sortIcon(attribute:any):string {
    if (this.currentSortAttribute === attribute) {
      return this.currentSortOrderAsc ? 'sort-alpha-down' : 'sort-alpha-down-alt';
    }
    return 'arrow-down-up';
  }

  isLink(value:any):boolean {
    if (typeof value === 'string' && value.indexOf('http') === 0) {
      return true;
    }
    return false;
  }

  sortBy(attribute:any) {
    if (this.currentSortAttribute === attribute) {
      this.currentSortOrderAsc = !this.currentSortOrderAsc;
    } else {
      this.currentSortAttribute = attribute;
      this.currentSortOrderAsc = true;
    }
    this.$emit('changeSort', { attribute, ascending: this.currentSortOrderAsc });
  }

  addResizableHandles() {
    console.log('addResizableHandles');
    let thElm:any;
    let startOffset:any;
    const self = this;
    const createResizableColumn = function (col:any, resizer:any) {
    // Track the current position of mouse
      let x = 0;
      let w = 0;
      const mouseMoveHandler = function (e:any) {
        // Determine how far the mouse has been moved
        const dx = e.clientX - x;
        console.log(dx);
        // Update the width of column
        col.style.width = `${w + dx}px`;
        col.style['max-width'] = `${w + dx}px`;
      };

      // When user releases the mouse, remove the existing event listeners
      const mouseUpHandler = function () {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };
      const mouseDownHandler = function (e:any) {
        // Get the current mouse position
        x = e.clientX;
        self.resizing = true;
        // Calculate the current width of column
        const styles = window.getComputedStyle(col);
        w = parseInt(styles.width, 10);

        // Attach listeners for document's events
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      resizer.addEventListener('mousedown', mouseDownHandler);
    };

    Array.prototype.forEach.call(
      document.querySelectorAll('table th'),
      (th:any) => {
        console.log(th);
        th.style.position = 'relative';

        const grip:any = document.createElement('div');
        grip.innerHTML = '&nbsp;';
        grip.style.top = 0;
        grip.style.right = 0;
        grip.style.bottom = 0;
        grip.style.width = '5px';
        grip.style.position = 'absolute';
        grip.style.cursor = 'col-resize';
        th.appendChild(grip);
        createResizableColumn(th, grip);
      },
    );
  }

  zoomToFeature(feature:any) {
    if (feature.getGeometry().getType() === 'Point') {
      const jstsGeom:any = this.parser.read(feature.getGeometry());
      // create a buffer of X meters
      const buffered = jstsGeom.buffer(500);
      this.getOpenLayersMapService().map.getView().fit(this.parser.write(buffered));
    } else {
      this.getOpenLayersMapService().map.getView().fit(feature.getGeometry());
    }
  }

  beforeDestroy() {
    console.log('beforeDestroy');
  }

  getLabel(k:any):string {
    if (k.label) return k.label;
    return k.name;
  }

  onClick(feature: Feature<any>) {
    this.$emit('onClick', feature);
  }

  highlightFeature(feature: Feature<any>) {
    if (!this.isSelected(feature)) {
      if (feature.setStyle) {
        feature.setStyle(this.highlightStyle);
      } else {
        const f:any = feature;
        f.layerConfig = this.layer;
        this.getMapService().selectionService.addToSelection(f);
      }
    }
  }

  isSelected(feature: any):boolean {
    return this.selectedFeatures.indexOf(feature) >= 0;
  }

  unHighlightFeature(feature: Feature<any>) {
    if (!this.isSelected(feature)) {
      feature.setStyle(undefined);
    }
  }
}
