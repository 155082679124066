/* eslint-disable */ 
import {
  Icon, Fill, Stroke, Style, RegularShape, Circle as CircleStyle, Text,
} from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import TextStyle from './style/TextStyle';
import SymbolStyle from './style/SymbolStyle';
import FillStyle from './style/FillStyle';
import StrokeStyle from './style/StrokeStyle';
import LayerConfig from './LayerConfig';
import MapUtils from './MapUtils';

export default class LayerStyle {
    static defaultStyle: LayerStyle;

    private baseStyle!: LayerStyle;

    private cache!: Style|null;

    fillStyle = new FillStyle();

    strokeStyle = new StrokeStyle();

    pointSymbol = new SymbolStyle();

    textStyle= new TextStyle();

    pointSymbolType!: string;

    pointGeometry!: boolean;

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
        this.textStyle = new TextStyle(json.textStyle);
        this.fillStyle = new FillStyle(json.fillStyle);
        this.strokeStyle = new StrokeStyle(json.strokeStyle);
        this.pointSymbol = new SymbolStyle(json.pointSymbol);
      }
      if(LayerStyle.defaultStyle !== undefined){
        this.setBaseStyle(LayerStyle.defaultStyle);
      }
        
      this.clearCache();
    }

    toJson() {
      return {
        fillStyle: this.fillStyle.toJson(),
        strokeStyle: this.strokeStyle.toJson(),
        pointSymbol: this.pointSymbol.toJson(),
        textStyle: this.textStyle.toJson(),
        pointSymbolType: this.pointSymbolType,
        pointGeometry: this.pointGeometry,
      };
    }

    clone(): LayerStyle {
      const l = new LayerStyle(this.toJson());
      return l;
    }

    setDefaultValues() {
      this.fillStyle = new FillStyle();
      this.fillStyle.setDefaultValues();

      this.strokeStyle = new StrokeStyle();
      this.strokeStyle.setDefaultValues();

      this.pointSymbol = new SymbolStyle();
      this.pointSymbol.setDefaultValues();

      this.textStyle = new TextStyle();
      this.textStyle.setDefaultValues();

      this.pointSymbolType = 'circle';
      this.pointGeometry = false;
    }

    public setBaseStyle(baseStyle: LayerStyle) {
      this.baseStyle = baseStyle;
      this.textStyle.setBaseStyle(baseStyle.textStyle);
      this.fillStyle.setBaseStyle(baseStyle.fillStyle);
      this.strokeStyle.setBaseStyle(baseStyle.strokeStyle);
      this.pointSymbol.setBaseStyle(baseStyle.pointSymbol);
    }

    public getBaseStyle(): LayerStyle{
      return this.baseStyle.clone();
    }

    private removeUndefined(obj:any):any {
      return Object.fromEntries(
        Object.entries(obj)
          .filter(([_, value]) => value != undefined)
          .map(([key, value]) => [
            key,
            value === Object(value) ? this.removeUndefined(value) : value,
          ]),
      );
    }
    deepMergeObjects(obj1:any, obj2:any):any {
      const result:any = {};
  
      for (const key in obj2) {
          if (obj2.hasOwnProperty(key)) {
              if (typeof obj2[key] === "object" && obj1.hasOwnProperty(key) && typeof obj1[key] === "object") {
                  result[key] = this.deepMergeObjects(obj1[key], obj2[key]);
              } else {
                  result[key] = obj2[key];
              }
          }
      }
  
      for (const key in obj1) {
          if (obj1.hasOwnProperty(key) && !result.hasOwnProperty(key)) {
              if (typeof obj1[key] === "object") {
                  result[key] = this.deepMergeObjects(obj1[key], {});
              } else {
                  result[key] = obj1[key];
              }
          }
      }
  
      return result;
  }
    // retourne un merge entre le style par defaut et les valeurs saisie
    public getMergedBaseStyle(): LayerStyle{
      
      let base = this.baseStyle.toJson();
      console.log(base);
      let style = this.removeUndefined(this.toJson());
      console.log(style)
      const mergedJSON = this.deepMergeObjects(base,style);// Object.assign(base, style);
      console.log(mergedJSON)
      const l = new LayerStyle(mergedJSON);
      return l;
    }

    applyRuleColor(color: string) {
      this.fillStyle.fillColor = color;
      this.strokeStyle.color = color;
      // this.pointFillColor = color;
      // this.pointStrokeColor = color;
      this.pointSymbol.symbolFillColor = color;
      this.pointSymbol.symbolStrokeColor = color;
      this.fillStyle.fillSymbol.symbolFillColor = color;
      this.fillStyle.fillSymbol.symbolStrokeColor = color;
      this.strokeStyle.symbol.symbolStrokeColor = color;
      this.strokeStyle.symbol.symbolFillColor = color;
    }

    toSld(layer: LayerConfig): string {
      let retour = '';
      if (layer.geometryType == 'polygone') {
        const fillContent = this.fillStyle.toSld();
        const strokeContent = this.strokeStyle.toSld();
        retour = `
            <PolygonSymbolizer>
              ${fillContent}
              ${strokeContent} 
            </PolygonSymbolizer>`;

        if(this.fillStyle.hasFillPattern()){
          const fillPatternContent = this.fillStyle.getFillPatternSld();
          retour += `
            <PolygonSymbolizer>
              ${fillPatternContent}    
            </PolygonSymbolizer>`;
        }

      } else if (layer.geometryType == 'point') {
        const wkn = this.getPointSymbolType();
        let graphic = `<Mark>
                            <WellKnownName>${wkn}</WellKnownName>
                            <Fill>
                                <CssParameter name="fill">${MapUtils.rgba2hex(this.pointSymbol.getSymbolFillColor())}</CssParameter>
                                <CssParameter name="fill-opacity">${MapUtils.rgba2opacity(this.pointSymbol.getSymbolFillColor())}</CssParameter>
                            </Fill>
                            <Stroke>
                                <CssParameter name="stroke">${MapUtils.rgba2hex(this.pointSymbol.getSymbolStrokeColor())}</CssParameter>
                                <CssParameter name="stroke-width">${this.pointSymbol.getSymbolStrokeWidth()}</CssParameter>
                            </Stroke>
                        </Mark>`;
        if (wkn == 'svg') {
          let url = window.location.origin + window.location.pathname;
          graphic = `<ExternalGraphic>
                <OnlineResource
                  xlink:type="simple"
                  xlink:href="${url}${this.pointSymbol.getSvgIconUrl()}?fill=${MapUtils.rgba2hex(this.pointSymbol.getSymbolFillColor())}" />
                <Format>image/svg</Format>
              </ExternalGraphic>`;
        }

        retour = `
            <PointSymbolizer>
                <Graphic>
                ${graphic}
                <Size>${this.getPointSymbolSize()}</Size>
                </Graphic>
            </PointSymbolizer>`;
      } else if (layer.geometryType == 'ligne') {
        const strokeContent = this.strokeStyle.toSld();
        retour = `
            <LineSymbolizer>
                ${strokeContent} 
                <PerpendicularOffset>0</PerpendicularOffset>
          </LineSymbolizer>`;// <CssParameter name="stroke-dasharray">5 2</CssParameter>
      } else {
        return '!! type de géometrie non définie';
      }
      retour += this.textStyle.textSymbolizerToSld();
      return retour;
    }

    getPointSymbolType(): string {
      return this.pointSymbolType ? this.pointSymbolType : this.baseStyle.getPointSymbolType();
    }

    getPointGeometry(): boolean {
      return this.pointGeometry != undefined ? this.pointGeometry : this.baseStyle.getPointGeometry();
    }

    getPointSymbolSize(feature?: Feature<any>): any {
      const radius = this.pointSymbol.getSize();
      if (feature && radius.indexOf && radius.indexOf('{') >= 0) {
        const circle = radius.replace(/{(.*?)}/, (a:any, b:any) => {
          const value = feature ? feature.get(b) : null;

          return value || 0;
        });
        return Number(eval(circle));
      }
      return radius;
    }

    clearCache() {
      this.cache = null;
      if (this.pointSymbol && this.pointSymbol.baseStyle && this.pointSymbol.getSvgIconUrl()) this.pointSymbol.fetchIcon();
    }


    getStyle(feature?: Feature<any>, resolution?: number,disableCache=false): Style {
      // If text contains feature attribute do not use shared cached style
      let useCache = !new RegExp(/{(.*?)}/).test(this.textStyle.getText());

      if (useCache && this.cache && !disableCache) {
        return this.cache;
      }

      let fillColor: any = this.fillStyle.getFillColor();
      let strokeColor: any = this.strokeStyle.getColor();
      if (this.fillStyle.hasFillPattern()) {
        useCache = false;
        fillColor = this.fillStyle.getRenderFunction();
      }

      if (this.strokeStyle.hasPattern()) {
        useCache = false;
        strokeColor = this.strokeStyle.getRenderFunction();
      }

      const style = new Style(
        {
          stroke: new Stroke(
            {
              color: strokeColor,
              width: this.strokeStyle.getStrokeWidth(),
              lineCap:<any>this.strokeStyle.getLineCap(),
              lineJoin:<any>this.strokeStyle.getLineJoin(),
              lineDash:<any>this.strokeStyle.getLineDash()!.split(' '),
              lineDashOffset:this.strokeStyle.getLineDashOffset(),
            },
          )
        },
      );
      if(this.fillStyle.getFillPattern()!='none'){
        style.setFill(new Fill(
          {
            color: fillColor,
          },
        ));
      }

      let _stroke;
      if (this.pointSymbol.getSymbolStrokeColor()) {
        _stroke = new Stroke(
          {
            color: this.pointSymbol.getSymbolStrokeColor(),
            width: this.pointSymbol.getSymbolStrokeWidth(),
          },
        );
      }

      const _fill = new Fill(
        {
          color: this.pointSymbol.getSymbolFillColor(),
        },
      );
      if (this.getPointSymbolType() == 'circle') {
        const symbolStyle = new CircleStyle(
          {
            radius: this.getPointSymbolSize(feature) / 2,
            stroke: _stroke,
            fill: _fill,
          },
        );
        style.setImage(symbolStyle);
      }
      if (this.getPointSymbolType() == 'svg') {
        if (this.pointSymbol.getSvgContent() != undefined) {
          const svgContent = this.pointSymbol.getSvgContent();
          if (svgContent && svgContent.length > 0) {
            // console.log(svgContent);
            const imgContent = `data:image/svg+xml; charset=utf8, ${encodeURIComponent(svgContent)}`;
            let img=new Image();
            img.src = imgContent;
            const symbolStyle = new Icon({
              crossOrigin: 'anonymous',
              //imgSize: [14,14],
              width:14, //for ol 9.1
              height:14,
              img:img,
              scale: Number(this.getPointSymbolSize()) / 14,
              // src: imgContent,
            });
            style.setImage(symbolStyle);
            useCache = true;
          }
        } else {
          useCache = false;
        }
      }
      if (this.getPointSymbolType() == 'square'
            || this.getPointSymbolType() == 'cross'
            || this.getPointSymbolType() == 'triangle'
            || this.getPointSymbolType() == 'star') {
        // default square values
        let points = 4;
        const radius = this.getPointSymbolSize(feature) / 2;
        let radius2;
        let angle = Math.PI / 4;
        let rotation = 0;
        if (this.getPointSymbolType() == 'cross') {
          points = 4;
          radius2 = 0;
          rotation = 0;
          angle = 0;
        } else if (this.getPointSymbolType() == 'triangle') {
          points = 3;
          angle = 0;
        } else if (this.getPointSymbolType() == 'star') {
          points = 5;
          radius2 = this.getPointSymbolSize(feature) / 4;
          rotation = 0;
          angle = 0;
        }

        const symbolStyle = new RegularShape({
          stroke: _stroke,
          fill: _fill,
          points,
          radius,
          radius2,
          rotation,
          angle,
        });

        style.setImage(symbolStyle);
      }

      if (this.textStyle.getText() && this.textStyle.getText().length > 0) {
        style.setText(this.textStyle.createTextStyle(feature));
      }
      if (this.getPointGeometry() && feature && (<any>feature.getGeometry()).getType() != 'Point') {
        style.setGeometry((feature) => {
          const geometry:any = feature.getGeometry();
          let point;
          switch (geometry.getType()) {
            case 'MultiPolygon':
              var poly = geometry.getPolygons().reduce((left:any, right:any) => (left.getArea() > right.getArea() ? left : right));
              point = poly.getFlatInteriorPoint();
              break;
            case 'Polygon':
              point = geometry.getFlatInteriorPoint();
              break;
            default:
              point = geometry;
          }
          return new Point(point);
        });
      }
      if (useCache) {
        this.cache = style;
      }

      return style;
    }
}
LayerStyle.defaultStyle = new LayerStyle();
LayerStyle.defaultStyle.setDefaultValues();
