/* eslint-disable no-continue */

/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-named-as-default */
/* eslint-disable class-methods-use-this */
import LayerConfig from '@/models/map-context/LayerConfig';
import CesiumMapService from './CesiumMapService';

declare let Cesium: any;

export default class CesiumDecoupMeshService {
  private mapService!: CesiumMapService;

  private layer!: LayerConfig;

  private viewer: any = null;

  private pointprimitive: any = null;

  private digPoint: any = [];

  private handler: any = null;

  private clkb : any = null;

  private texthelp: any;

  private textpos: any;

  private isMove = false;

  constructor(mapService: CesiumMapService, layer: LayerConfig, texthelp:any, textpos:any) {
    this.mapService = mapService;
    this.layer = layer;
    this.viewer = this.mapService.viewer;
    this.digPoint = [];
    this.pointprimitive = [];
    this.handler = new Cesium.ScreenSpaceEventHandler(document.getElementById('cesiumContainer'));
    this.texthelp = texthelp;
    this.textpos = textpos;
    this.pointprimitive = this.viewer.scene.primitives.add(new Cesium.PointPrimitiveCollection());
  }

  start(CallbAck : any) :void {
    console.log('start Decoup Mesh');
    if (this.texthelp !== undefined) {
      this.texthelp('simple clic pour ajouter un points de découpe. double clic pour ajouter la découpe.');
    }
    this.digPoint = [];
    this.clkb = CallbAck;
    this.pointprimitive.removeAll();
    this.addEvent();
  }

  stop():void {
    this.pointprimitive.removeAll();
    this.handler.destroy();
    this.handler = new Cesium.ScreenSpaceEventHandler(document.getElementById('cesiumContainer'));
    this.clkb = null;
    if (this.texthelp !== undefined) {
      this.texthelp('');
    }
  }

  addEvent():void {
    this.handler.setInputAction(

      (event: any) => {
        if (this.isMove === true) {
          const earthPosition = this.viewer.scene.pickPosition(event.position);
          const cartographic = Cesium.Cartographic.fromCartesian(earthPosition);
          const longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
          const latitudeString = Cesium.Math.toDegrees(cartographic.latitude);

          this.pointprimitive.add({
            position: earthPosition,
            color: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 1,
            pixelSize: 8,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          });
          this.digPoint.push(new Cesium.Cartesian3.fromDegrees(longitudeString, latitudeString));
          this.viewer.scene.requestRender();
        }
        this.isMove = false;
      },
      Cesium.ScreenSpaceEventType.LEFT_CLICK,
    );

    this.handler.setInputAction(

      () => {
        const holePts = Array.from(this.digPoint);
        this.holePlanes(holePts);
        this.viewer.scene.requestRender();
        if (this.clkb != null) {
          this.clkb(holePts);
        }
        this.stop();
      },
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK,
    );

    this.handler.setInputAction(

      (event: any) => {
        this.isMove = true;
        if (this.textpos !== undefined) {
          this.textpos(event.endPosition.x + 10, event.endPosition.y - 5);
        }
      },
      Cesium.ScreenSpaceEventType.MOUSE_MOVE,
    );
  }

  holePlanes(holePts: any):void {
    const pointsLength = holePts.length;
    const clippingPlanes = [];
    const area = Cesium.PolygonPipeline.computeArea2D(holePts);
    let i = 0;

    for (i = 0; i < pointsLength; i += 1) {
      const nextIndex = (i + 1) % pointsLength;
      let midpoint = Cesium.Cartesian3.add(holePts[i], holePts[nextIndex], new Cesium.Cartesian3());
      midpoint = Cesium.Cartesian3.multiplyByScalar(midpoint, 0.5, midpoint);
      const up = Cesium.Cartesian3.normalize(midpoint, new Cesium.Cartesian3());
      let right = Cesium.Cartesian3.subtract(holePts[nextIndex], midpoint, new Cesium.Cartesian3());
      right = Cesium.Cartesian3.normalize(right, right);
      let normal = Cesium.Cartesian3.cross(right, up, new Cesium.Cartesian3());
      normal = Cesium.Cartesian3.normalize(normal, normal);
      if (area < 0) {
        normal = Cesium.Cartesian3.multiplyByScalar(normal, -1, normal);
      }
      const plane = new Cesium.Plane.fromPointNormal(midpoint, normal);
      const clippingPlane = new Cesium.ClippingPlane.fromPlane(plane);
      clippingPlanes.push(clippingPlane);
    }
    // pour couper le photomaillage
    // pour couper le photomaillage
    this.layer.cesium_Layer.clippingPlanes = new Cesium.ClippingPlaneCollection({
      planes: clippingPlanes,
      unionClippingRegions: false, // si true: coupe tout ce qui est à l'extérieur de la zone cliquée
      enabled: true,
      edgeColor: Cesium.Color.WHITE,
      modelMatrix: Cesium.Matrix4.inverse(this.layer.cesium_Layer._initialClippingPlanesOriginMatrix, new Cesium.Matrix4()), // ligne importante: on est obligés de passer par cette transfo de matrice car notre tileset n'a pas de matrice de transfo à la base
    });
  }

  removeAllHoles():void {
    this.layer.cesium_Layer.clippingPlanes.removeAll();
  }

  removeLastHole():void {
    // supprime le dernier item ajouté à la pile => index 0
    if (this.layer.cesium_Layer.clippingPlanes.length > 0) {
      this.layer.cesium_Layer.clippingPlanes.remove(this.layer.cesium_Layer.clippingPlanes.get(0));
    }
  }
}
