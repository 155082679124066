
























































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ActionButton from '@/components/shared/ActionButton.vue';
import QueryParams from '@/models/crud/QueryParams';
import Group from '@/models/user/Group';
import { adminServiceInstance } from '@/services/AdminService';
import EventBus, { EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
  },
  components: {
    ActionButton,
  },
})
export default class GroupListPanel extends BaseComponent {
  adminService=adminServiceInstance;

  noResultsFound = false;

  rows = 0;

  groups = [];

  items = this.remoteListProvider;

  fields= [
    {
      key: 'name', label: 'Nom', sortable: true, class: 'text-center', sortDirection: 'desc',
    },
    { key: 'actions', label: 'Actions', class: 'text-center' },
  ];

  currentPage= 1;

  perPage= 5;

  pageOptions= [5, 10, 15, { value: 100, text: 'Show a lot' }];

  sortBy= '';

  sortDesc= false;

  sortDirection='asc';

  filter= null;

  filterOn= [];

  baseRoute = '/groups';

  ModalConfirmation = false;

  groupToDelete= null as Group | null;

  prepareDeleteGroup(group: Group) {
    // Prépare la suppression en stockant l'élément à supprimer
    this.groupToDelete = group;
    this.ModalConfirmation = true;
  }

  onFiltered(filteredItems:any) {
    this.rows = filteredItems.length;
    this.currentPage = 1;
  }

  deleteItem(user:Group) {
    this.adminService.deleteGroup(user).then((reponse:any) => {
      console.log(reponse);
      EventBus.$emit(EVENTS.INFO, 'Suppression effectuée');
      const t:any = this.$refs.table;
      this.groupToDelete = null;
      t.refresh();
    });
  }

  open(user:Group) {
    this.$router.push({ path: `${this.baseRoute}/${user.id}` });
  }

  storage(user:Group) {
    this.$router.push({ path: `${this.baseRoute}/${user.id}/storage` });
  }

  remoteListProvider(ctx:any) {
    console.log(ctx);
    const cp = new QueryParams(ctx);
    cp.filterOn = ['name', 'id'];
    return this.adminService.getGroups(cp).then((res) => {
      console.log('filtre', res);
      if (res.data && res.data.count > 0) {
        this.rows = res.data.count;
        this.groups = res.data.results;
      } else {
        this.rows = 0;
        this.groups = [];
        this.noResultsFound = true;
      }
      return this.groups;
    });
  }

  loadItems():void {
    this.noResultsFound = false;
    this.adminService.getGroups().then((res) => {
      console.log(res);
      if (res.data && res.data.count > 0) {
        this.rows = res.data.count;
        this.groups = res.data.results;
      } else {
        this.rows = 0;
        this.groups = [];
        this.noResultsFound = true;
      }
    });
  }
}
