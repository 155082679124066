/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line import/no-cycle
import AbstractLayerTreeElement from './AbstractLayerTreeElement';
// eslint-disable-next-line import/no-cycle
import LayerConfig from './LayerConfig';

export default class LayerGroupConfig extends AbstractLayerTreeElement {
  idGroup: any;

  title!: string;

  showNbChilds=true;

  description!:string;

  collapsed = false;

  /* si on veut par la suite pouvoir changer la couleur des icones qui sont
   des svg chargés dans une balise img
   on peut le faire avec l'attribut css filter mais il faudra convertir
   la couleur rgb choisie en filter
   cf https://blog.union.io/code/2017/08/10/img-svg-fill/
   et https://codepen.io/sosuke/pen/Pjoqqp
  */
  svgIconUrl!:string;

  childs!: AbstractLayerTreeElement[];

  constructor(input?: any) {
    super(input);
    if (input) {
      Object.assign(this, input);

      if (!this.id) {
        AbstractLayerTreeElement.counter += 1;
        this.id = `generated_${AbstractLayerTreeElement.counter}`;
      }

      // create LayerConfig recursively for childs
      if (this.childs) {
        for (let j = 0; j < this.childs.length; j += 1) {
          // eslint-disable-next-line keyword-spacing
          if ((<any>this.childs[j]).childs !== undefined) {
            this.childs[j] = new LayerGroupConfig(this.childs[j]);
          } else {
            this.childs[j] = new LayerConfig(this.childs[j]);
          }

          // this.childs[j] = new LayerConfig(this.childs[j]);
          this.childs[j].parent = this;
        }
      }
    }
  }

  isGroup(): boolean {
    return true;
  }

  setVisible(visible: boolean): void {
    this.visible = visible;
    console.log('setVisible');
    for (const child of this.getAllChildLayers()) {
      if (child.getOlLayer()) {
        child.getOlLayer().setVisible(child.isLayerVisible());
      } else if (child.getCesiumLayer()) {
        child.getCesiumLayer().show = child.isLayerVisible();
      } else {
        console.error('element is supposed to have an olLayer attribute');
        console.error(child);
      }
    }
  }

  /**
   *  Retourne tous les enfants et sous enfants de type Layer (pas les groupes)
   */
  getAllChildLayers(): LayerConfig[] {
    let retour: LayerConfig[] = [];
    if (this.childs.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of this.childs) {
        if (child instanceof LayerGroupConfig) {
          retour = retour.concat(child.getAllChildLayers());
        } else if (child instanceof LayerConfig) {
          retour.push(child);
        }
      }
    }

    return retour;
  }

  getAllChilds(): AbstractLayerTreeElement[] {
    let retour: AbstractLayerTreeElement[] = [];
    if (this.childs.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of this.childs) {
        retour.push(child);
        if (child instanceof LayerGroupConfig) {
          retour = retour.concat(child.getAllChilds());
        }
      }
    }
    return retour;
  }

  toJson(): any {
    const retour = {
      idGroup: this.idGroup,
      uid : this.uid,
      visible: this.isVisible(),
      collapsed: this.collapsed,
      title: this.title,
      showNbChilds: this.showNbChilds,
      svgIconUrl: this.svgIconUrl,
      description: this.description,
      childs: <any>[],
    };
    this.childs.forEach((layer) => retour.childs.push(layer.toJson()));
    return retour;
  }
  toJsonDuplication(): any {
    const retour = {
      idGroup: this.idGroup,
      visible: this.isVisible(),
      title: this.title+' (Copie)',
      svgIconUrl: this.svgIconUrl,
      description: this.description,
      childs: <any>[],
    };
    this.childs.forEach((layer) => retour.childs.push(layer.toJsonDuplication()));
    return retour;
  }
}
