

































































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DownloadPanel from '@/components/layers/DownloadPanel.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import Plugin from '@/models/plugin/Plugin';
import { EventBus, EVENTS } from '@/services/EventBus';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import LayerRuleFilterList from '../plugins/legend/LayerRuleFilterList.vue';

@Component({
  props: {
    layer: LayerConfig,
    plugin: {},
  },
  components: {
    DownloadPanel,
    LayerRuleFilterList,
  },
})
export default class SingleLayerElement extends BaseComponent {
  layer!: LayerConfig;

  plugin!:Plugin;

  showActions = false;

  showDownLoad = false;

  target:any = null;

  showDownloadBtn = true;

  showConfigBtn = true;

  showDeleteBtn = true;

  is3Dtiles = false;

  displayedOpacity = '100';

  configScale ={};

  configOffset={};

  opacity = '1' ;

  opacityChange = new Subject<string>();

  mounted() {
    console.log(this.visible);

    const valOpacity = this.layer.getOpacity();
    if (valOpacity != null && valOpacity !== undefined) {
      this.opacity = valOpacity;
    }

    this.displayedOpacity = `${parseFloat(this.opacity) * 100}`;

    this.showConfigBtn = this.plugin && this.plugin.getParameterValue('showConfigBtn');

    this.showDeleteBtn = this.plugin && this.plugin.getParameterValue('showDeleteBtn');

    if (this.is3DView()) {
      this.showDownloadBtn = false;

      this.showActions = true;

      if (this.layer.type.indexOf('3DTiles') >= 0) {
        this.is3Dtiles = true;
      }
      this.opacityChange.pipe(debounceTime(200)).subscribe((res) => this.updateOpacity());
    } else {
      this.updateOpacity();
    }
    this.updateCoupeActive();
  }

  isCoupeDirectionZ(coupe:any):boolean {
    if (coupe.direction) {
      return coupe.direction.toLowerCase() === 'z';
    }
    return true;
  }

  isCoupeDirectionY(coupe:any):boolean {
    if (coupe.direction) {
      return coupe.direction.toLowerCase() === 'y';
    }
    return false;
  }

  isCoupeDirectionX(coupe:any):boolean {
    if (coupe.direction) {
      return coupe.direction.toLowerCase() === 'x';
    }
    return false;
  }

  copyLayer(){
    let jsonDef=this.layer.toJsonDuplication();
    this.contextService.addToClipBoad(jsonDef);
  }

  duplicate(){
    let jsonDef=this.layer.toJsonDuplication();
    
    console.log(jsonDef);
    let layerCfg = new LayerConfig(jsonDef);
    this.addLayerToMap(layerCfg);
    //INSERT INTO GROUP IF ANY
    if(this.layer.parent){
      this.getMapService().changeLayerOrder(layerCfg, this.layer.parent, 'into');
    }
    
    
  }
  
  private addLayerToMap(newLayer: LayerConfig) {
    try {
      this.contextService.getCurrentContext().getLayers().unshift(newLayer);
      this.getMapService().addLayer(newLayer);
    } catch (error) {
      EventBus.$emit(EVENTS.ERROR, error);
    }
  }


  updateCoupeActive() {
    if (!this.layer.is3DModel()) return;

    let isCoupeActive = false;
    this.layer.layer3dConfig.listeCoupe.forEach((coupe:any) => {
      if (coupe.active && !isCoupeActive) {
        isCoupeActive = true;
      } else {
        coupe.active = false;
      }
    });

    if (isCoupeActive === false && this.layer.layer3dConfig.listeCoupe.length > 0) {
      this.layer.layer3dConfig.listeCoupe[0].active = true;
    }
    this.refreshCoupe();
  }

  handleOrientationCoupeChange(event:any, coupe:any) {
    coupe.direction = event.target.value;
    this.refreshCoupe();
  }

  switchCoupe(event:any, coupe:any) {
    console.log('switchCoupe');
    this.layer.layer3dConfig.listeCoupe.forEach((coupe:any) => {
      coupe.active = false;
    });
    coupe.active = event;
    this.refreshCoupe();
  }

  refreshCoupe():void {
    const cesiumMapServiceInstance = this.getCesiumMapService();
    cesiumMapServiceInstance.addcoupeZ(this.layer);
  }

  IsPhotomaillage() : boolean {
    return this.layer.type === '3DTiles_Photomaillage';
  }

  updateOpacity():void {
    this.displayedOpacity = `${parseFloat(this.opacity) * 100}`;
    this.layer.setOpacity(parseFloat(this.opacity));
    if (this.is3DView()) {
      this.layer.forceUpdateOpacity(this.getCesiumMapService());
    }
  }

  changeValueOpacity() {
    this.updateOpacity();
    if(this.is3DView()){
      this.getCesiumMapService().updateView();
    }
  }

  toggleActions() {
    this.showActions = !this.showActions;
  }

  get visible() :boolean {
    return this.layer.visible;
  }

  set visible(visibility:boolean) {
    console.log('setVisible')
    this.layer.setVisible(!this.layer.visible);
  }

  onChange() {
    this.layer.setVisible(!this.layer.visible);
    if(this.is3DView()){
      this.getCesiumMapService().updateView();
    }
  }

  get inRange() {
    // eslint-disable-next-line prefer-destructuring
    const currentScale = this.getMapService().currentScale;
    return this.layer.inRange(currentScale);
  }

  get offsetZ():string {
    if (this.layer.getOffsetZ()) {
      return `${this.layer.getOffsetZ()}`;
    }
    // eslint-disable-next-line quotes
    return "0";
  }

  set offsetZ(offset:string) {
    // console.log("newOffsetValue:  " +offset);
    this.layer.setOffsetZ(parseFloat(offset));
    this.getMapService().setOffsetZ(this.layer);
  }

  get scaleZ():string {
    if (this.layer.getScaleZ()) {
      return `${this.layer.getScaleZ()}`;
    }
    // eslint-disable-next-line quotes
    return "1";
  }

  set scaleZ(scale:string) {
    // console.log("newscaleValue:  " +scale);
    this.layer.setScaleZ(parseFloat(scale));
    this.getMapService().setScaleZ(this.layer);
  }

  get stepScale():string {
    return `${this.layer.getStepScale()}`;
  }

  get scaleZMin():string {
    return `${this.layer.getScaleZMin()}`;
  }

  get scaleZMax():string {
    return `${this.layer.getScaleZMax()}`;
  }

  get stepOffset():string {
    return `${this.layer.getStepOffset()}`;
  }

  get offsetZMax():string {
    return `${this.layer.getOffsetZMax()}`;
  }

  get offsetZMin():string {
    return `${this.layer.getOffsetZMin()}`;
  }



  mouseDown(event:any): void {
    this.target = event.target;
  }

  dragstartHandler(event:any): void{
    console.log('drag start', this.target);
    if (this.target !== null) { // && (this.target.classList.contains('draggable')
    // || this.target.classList.contains('bi-justify'))
    // setTimeout for chrome bug => see https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
      setTimeout(()=>{
              this.contextService.setDraggingLayer(this.layer);
      event.dataTransfer.setData('text/plain', this.layer.id);
      // event.dataTransfer.setData('text/html', this.target.innerHTML);
      event.dataTransfer.effectAllowed = 'move'; // only allow moves
      },10)

    } else {
      event.preventDefault();
    }
    console.log('drag start ok');
  }

  /*showDropTarget(): boolean {
    return false;
  }*/

  showDropTarget(): boolean {
    return (
      this.contextService.getDraggingLayer() !== null
      && this.contextService.getDraggingLayer() !== this.layer
    );
  }

  showTableAttribute(): void {
    this.contextService.tablelayer = this.layer;
    EventBus.$emit(EVENTS.OPEN_ATTRIBUTES, this.layer);
  }

  onClickInfo() {
    if (this.layer.metadataUrl && this.layer.metadataUrl.length > 0) {
      const url = this.layer.metadataUrl;
      window.open(encodeURI(url), 'target=_blank');
    }
  }

  dragendHandler(event:any): void {
    event.preventDefault();
    console.log('drag end');
    this.contextService.setDraggingLayer(null);
  }

  dropHandler(event:any, target:string) {
    event.preventDefault();
    console.log(`drop ${target} ${this.layer.title}`);
    const dragLayer = this.contextService.getDraggingLayer();
    if (dragLayer !== null && dragLayer !== this.layer) {
      this.getMapService().changeLayerOrder(dragLayer, this.layer, target);
    }
    if (event.target !== undefined && event.target.style !== undefined) {
      event.target.classList.remove('overed');
    }
  }

  dragEnterHandler(event:any) {
    event.preventDefault();
    // console.log('drag enter');
    // console.log(event);
    if (event.target !== undefined && event.target.style !== undefined
      && this.contextService.getDraggingLayer() !== this.layer) {
      event.target.classList.add('overed');
    }
  }

  dragLeaveHandler(event:any) {
    event.preventDefault();
    // console.log('drag leave');
    // console.log(event);
    if (event.target !== undefined && event.target.style !== undefined) {
      event.target.classList.remove('overed');
    }
  }

  dragOverHandler(event:any) {
    event.preventDefault();
    // console.log('drag over');
    if (event.dataTransfer !== null) {
      event.dataTransfer.dropEffect = 'move';
    }
  }

  editLayer() {
    console.log('edit layer');
    if (!this.layer.editing) {
      const editedLayer = this.getMapService().getLayerBeingEdited();
      if (editedLayer !== undefined) {
        editedLayer.editing = false;
      }
      EventBus.$emit(EVENTS.START_EDITION, this.layer);
    } else {
      EventBus.$emit(EVENTS.STOP_EDITION, this.layer);
    }
    this.layer.editing = !this.layer.editing;
  }

  infoLayer() {
    this.contextService.setCurrentAction('infoLayer', this.layer);
  }

  zoomToLayer() {
    this.getMapService().zoomToLayer(this.layer);
  }

  downloadLayer() {
    this.showDownLoad = !this.showDownLoad;
  }

  removeLayer() {
    if(this.contextService.tablelayer==this.layer){
      this.contextService.tablelayer=null;
    }
    this.getMapService().removeLayerFromList(this.layer);
    this.getMapService().removeLayerFromMap(this.layer);
    
    EventBus.$emit(EVENTS.LAYER_REMOVED, this.layer);
  }

  Hole3dtiles() {
    if (true) {
      EventBus.$emit(EVENTS.START_PHOTOMAILLAGE_EDITION, this.layer);
    } else {
    }
  }
}
