/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue';
import Component from 'vue-class-component';
import { MapService, mapServiceInstance } from '@/services/MapService';
import { CesiumMapService, cesiumMapServiceInstance } from '@/services/CesiumMapService';
import { contexteServiceInstance, ContextService } from '@/services/ContextService';
import { pluginServiceInstance, PluginService } from '@/services/PluginService';
import { CapabilitiesService, capabilitiesServiceInstance } from '@/services/CapabilitiesService';

@Component
export default class BaseComponent extends Vue {
  private mapService: MapService = mapServiceInstance;

  public cesiumMapService: CesiumMapService = cesiumMapServiceInstance;

  public contextService: ContextService = contexteServiceInstance;

  public pluginService: PluginService = pluginServiceInstance;

  public capabilitiesService: CapabilitiesService = capabilitiesServiceInstance;

  pluginComponents:any=[];

  getCesiumMapService() {
    if (this.is3DView()) {
      return this.cesiumMapService;
    }
    throw new Error('3D view is not loaded');
  }

  getOpenLayersMapService() {
    if (this.is3DView()) {
      throw new Error('3D view is not loaded');
    }
    return this.mapService;
  }

  getMapService() {
    if (this.is3DView()) {
      return this.cesiumMapService;
    }
    return this.mapService;
  }

  is3DView() :boolean {
    if(this.contextService.is3d){
      return true;
    }
    if (!this.$route) {
      return false;
    }
    const cesium = this.$route.query['3d'];
    if (cesium !== undefined && cesium === 'true') {
      return true;
    }
    return false;
  }

  public get debug():boolean {
    if (!this.$route) {
      return false;
    }
    const debug = this.$route.query['debug'];
    if (debug !== undefined && debug === 'true') {
      return true;
    }
    return false;
  }

  public get beta():boolean {
    if (!this.$route) {
      return false;
    }
    const beta = this.$route.query['beta'];
    if (beta !== undefined && beta === 'true') {
      return true;
    }
    return false;
  }

  loadPlugins() {
    const requireComponent = require.context(
    // The relative path of the components folder
      './plugins/',
      // Whether or not to look in subfolders
      true,
      // The regular expression used to match base component filenames
      /[A-Z]\w+\.(vue|js)$/,
    );

    requireComponent.keys().forEach((fileName:string) => {
      const componentConfig = requireComponent(fileName);
      console.log(componentConfig.default);
      // Gets the file name regardless of folder depth
      if (fileName && fileName.split('/') !== undefined) {
        const fileNameA = fileName.split('/');
        if (fileNameA && fileNameA.length > 0) {
          const fname = fileNameA.pop();
          if (fname && this.$options.components) {
            const componentName = fname.split('.')[0];
            // register the component locally
            this.pluginComponents.push(componentConfig);
            this.$options.components[componentName] = componentConfig.default;
          }
        }
      }
    });
  }
}
