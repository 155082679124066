/* eslint-disable @typescript-eslint/no-this-alias */
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import MapUtils from '../MapUtils';
import SymbolStyle from './SymbolStyle';

export default class StrokeStyle {
    baseStyle!: StrokeStyle;

    static PATTERN_TYPES=[
      { item: 'simple', name: 'Ligne simple', is3d: true },
      { item: 'svg', name: 'symboles SVG', is3d: false },
      { item: 'circle', name: 'cercles', is3d: false },
      { item: 'square', name: 'carrés', is3d: false },
      { item: 'cross', name: 'croix', is3d: false },
      { item: 'plus', name: 'plus', is3d: false },
      { item: 'vertline', name: 'Lignes verticales', is3d: false },
      { item: 'slash', name: 'hachure', is3d: false },
      { item: 'backslash', name: 'hachure (2)', is3d: false }];

      static LINECAP_TYPES=[
        { item: 'butt', name: 'coupées à angle droit (butt)' },
        { item: 'round', name: 'arrondies (round)' },
        { item: 'square', name: 'carrées (square)' }];

      static LINEJOIN_TYPES=[
        { id: 'miter', name: 'onglet (miter)' },
        { id: 'round', name: 'rond (round)' },
        { id: 'bevel', name: 'biseau (bevel)' }];

    symbol: SymbolStyle;

    color!: string;

    pattern='simple';

    strokeWidth!: number;

    lineCap!: string;

    lineJoin!: string;

    lineDash!: string;

    lineDashOffset!: number;

    patternMargin!: number;

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
        this.symbol = new SymbolStyle(json.symbol);
      } else {
        this.symbol = new SymbolStyle();
      }
    }

    public setBaseStyle(baseStyle: StrokeStyle):void {
      this.baseStyle = baseStyle;
      if (baseStyle !== undefined) this.symbol.setBaseStyle(baseStyle.symbol);
    }

    hasPattern():boolean {
      const fillPattern = this.getPattern();
      return fillPattern !== undefined && fillPattern.length > 0 && fillPattern !== 'simple';
    }

    setDefaultValues() :void{
      this.symbol = new SymbolStyle();
      this.symbol.setDefaultValues();

      this.color = '#3399CC';
      this.strokeWidth = 1;
      this.pattern = 'simple';
      this.patternMargin = 0;
      this.lineCap = 'butt';
      this.lineJoin = 'miter';
      this.lineDash = '';
      this.lineDashOffset = 0;
    }

    toJson():any {
      return {
        symbol: this.symbol.toJson(),
        color: this.color,
        strokeWidth: this.strokeWidth,
        pattern: this.pattern,
        patternMargin: this.patternMargin,
        lineCap: this.lineCap,
        lineJoin: this.lineJoin,
        lineDash: this.lineDash,
        lineDashOffset: this.lineDashOffset,

      };
    }

    getPatternMargin(): number {
      return this.patternMargin !== undefined
        ? this.patternMargin : this.baseStyle.getPatternMargin();
    }

    getColor(): string {
      return this.color ? this.color : this.baseStyle.getColor();
    }

    getStrokeWidth(): number {
      return this.strokeWidth ? this.strokeWidth : this.baseStyle.getStrokeWidth();
    }

    getLineDashOffset(): number | undefined {
      return this.lineDashOffset !== undefined
        ? this.lineDashOffset : this.baseStyle.getLineDashOffset();
    }

    getLineDash(): string | undefined {
      return this.lineDash !== undefined
        ? this.lineDash : this.baseStyle.getLineDash();
    }

    getLineJoin(): string | undefined {
      return this.lineJoin !== undefined
        ? this.lineJoin : this.baseStyle.getLineJoin();
    }

    getLineCap(): string | undefined {
      return this.lineCap !== undefined
        ? this.lineCap : this.baseStyle.getLineCap();
    }

    toSld() :string {
      let strokeContent = `
        <CssParameter name="stroke">${MapUtils.rgba2hex(this.getColor())}</CssParameter>
        <CssParameter name="stroke-width">${this.getStrokeWidth()}</CssParameter>
        `;
      if (this.hasPattern()) {
        strokeContent = this.getSldGraphic('stroke');
      }

      const retour = `
                <Stroke>
                ${strokeContent}
                <CssParameter name="stroke-dasharray">${this.symbol.getSize()} ${this.getPatternMargin()}</CssParameter>
                <CssParameter name="stroke-dashoffset">${this.getLineDashOffset()}</CssParameter>
                <CssParameter name="stroke-linejoin">${this.getLineJoin()}</CssParameter>
                <CssParameter name="stroke-linecap">${this.getLineCap()}</CssParameter>
                </Stroke>
           
         `;
      return retour;
    }

    getPattern(): string {
      return this.pattern !== undefined ? this.pattern : this.baseStyle.getPattern();
    }

    getRenderFunction(): any {
      const pattern = this.getPattern();
      const img = this.symbol.getImage();

      const canvas = document.createElement('canvas');
      const context:any = canvas.getContext('2d');
      const pixelRatio = DEVICE_PIXEL_RATIO;
      const self = this;
      const colorfunction = (function () {
        if (['slash', 'backslash'].indexOf(pattern) >= 0) {
          canvas.width = 32;
          canvas.height = 16;
        } else if (['horline', 'vertline'].indexOf(pattern) >= 0) {
          canvas.width = 10;
          canvas.height = 10;
        } else if (['svg'].indexOf(pattern) >= 0) {
          canvas.width = Number(self.symbol.getSize()) + Number(self.getPatternMargin()) * 2;
          canvas.height = Number(self.symbol.getSize()) + Number(self.getPatternMargin()) * 2;
        } else {
          canvas.width = Number(self.symbol.getSize()) + Number(self.getPatternMargin()) * 2;
          canvas.height = Number(self.symbol.getSize()) + Number(self.getPatternMargin()) * 2;
        }

        const strokeColor = self.symbol.getSymbolStrokeColor();
        context.strokeStyle = strokeColor;

        const fillColor = self.symbol.getSymbolFillColor();
        context.fillStyle = fillColor;
        context.lineWidth = self.symbol.getSymbolStrokeWidth();
        if (pattern === 'slash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
        } else if (pattern === 'backslash') {
          const x0 = 36;
          const x1 = -4;
          const y0 = -2;
          const y1 = 18;
          const offset = 32;
          context.scale(-1, 1);
          context.beginPath();
          context.moveTo(x0, y0);
          context.lineTo(x1, y1);
          context.moveTo(x0 - offset, y0);
          context.lineTo(x1 - offset, y1);
          context.moveTo(x0 + offset, y0);
          context.lineTo(x1 + offset, y1);
          context.stroke();
        } else if (pattern === 'cross') {
          context.scale(self.symbol.getSize() / 10, self.symbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (pattern === 'plus') {
          context.translate(Number(self.getPatternMargin()), Number(self.getPatternMargin()));
          context.scale(self.symbol.getSize() / 10, self.symbol.getSize() / 10);
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (pattern === 'square') {
          context.scale(self.symbol.getSize() / 10, self.symbol.getSize() / 10);
          context.beginPath();
          context.moveTo(1, 1);
          context.lineTo(9, 1);
          context.lineTo(9, 9);
          context.lineTo(1, 9);
          context.lineTo(1, 1);
          if (strokeColor.length > 0) context.stroke();
          if (fillColor.length > 0) context.fill();
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (pattern === 'dot') {
          context.scale(self.symbol.getSize() / 10, self.symbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 1, 0, 2 * Math.PI);
          if (strokeColor.length > 0) context.stroke();
          if (fillColor.length > 0) context.fill();
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (pattern === 'circle') {
          context.scale(self.symbol.getSize() / 10, self.symbol.getSize() / 10);
          context.beginPath();
          context.arc(5, 5, 4, 0, 2 * Math.PI);
          if (strokeColor.length > 0) context.stroke();
          if (fillColor.length > 0) context.fill();
          context.setTransform(1, 0, 0, 1, 0, 0);
        } else if (pattern === 'horline') {
          context.beginPath();
          context.moveTo(0, 5);
          context.lineTo(10, 5);
          context.stroke();
        } else if (pattern === 'vertline') {
          context.beginPath();
          context.moveTo(5, 0);
          context.lineTo(5, 10);
          context.stroke();
        } else if (pattern === 'svg') {
          if (img instanceof HTMLImageElement) {
            context.drawImage(img, self.getPatternMargin(),
              self.getPatternMargin(), self.symbol.getSize(), self.symbol.getSize());
          }
        }

        return context.createPattern(canvas, 'repeat');
      }());
      return colorfunction;
    }

    getSldGraphic(type = 'fill'):string {
      let graphicType = 'GraphicFill';
      const fillcolor = MapUtils.rgba2hex(this.symbol.getSymbolFillColor());
      const strokecolor = MapUtils.rgba2hex(this.symbol.getSymbolStrokeColor());
      if (type === 'stroke') {
        graphicType = 'GraphicStroke';
      }
      const fillPattern = this.getPattern();
      let wkn = '';
      if (fillPattern === 'cross'
            || fillPattern === 'circle'
            || fillPattern === 'square'
            || fillPattern === 'triangle'
            || fillPattern === 'star'
            || fillPattern === 'x') {
        wkn = fillPattern;
      } else if (fillPattern === 'slash' || fillPattern === 'backslash' || fillPattern === 'plus'
            || fillPattern === 'horline' || fillPattern === 'vertline'
            || fillPattern === 'dot' || fillPattern === 'times'
            || fillPattern === 'oarrow' || fillPattern === 'carrow'
      ) {
        wkn = `shape://${fillPattern}`;
      }

      let retour = '';
      if (fillPattern === 'svg') {
        const url = this.symbol.getSvgSymbolUrl();
        retour = `<${graphicType}>
        <Graphic>
          <ExternalGraphic>
            <OnlineResource
              xlink:type="simple"
              xlink:href="${url}" />
            <Format>image/svg</Format>
          </ExternalGraphic>
          <Size>${this.symbol.getSize()}</Size>
        </Graphic>
        </${graphicType}>
        `;
      } else {
        retour = `
        <${graphicType}>
        <Graphic>
            <Mark>
                <WellKnownName>${wkn}</WellKnownName>
                <Stroke>
                    <CssParameter name="stroke">${strokecolor}</CssParameter>
                    <CssParameter name="stroke-width">${this.symbol.getSymbolStrokeWidth()}</CssParameter>
                </Stroke>
                <Fill>
                    <CssParameter name="fill">${fillcolor}</CssParameter>
                    <CssParameter name="fill-opacity">${MapUtils.rgba2opacity(this.symbol.getSymbolFillColor())}</CssParameter>
                </Fill>
            </Mark>
            <Size>${this.symbol.getSize()}</Size>
          </Graphic>
        </${graphicType}>`;
      }

      return retour;
    }
}
