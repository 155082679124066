




























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable class-methods-use-this */
import BaseComponent from '@/components/BaseComponent';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import AddLayerPanel from '@/components/layers/add-layer-panel/AddLayerPanel.vue';
import GroupLayerInfos from '@/components/layers/GroupLayerInfos.vue';
import LayerListPanel from '@/components/layers/LayerListPanel.vue';
import SingleLayerInfos from '@/components/layers/SingleLayerInfos.vue';
import LeftSideBar from '@/components/LeftSideBar.vue';
import DashBoardPanel from '@/components/map/DashBoardPanel.vue';
import FeatureInfosPanel from '@/components/map/FeatureInfosPanel.vue';
import Map from '@/components/map/Map.vue'; // @ is an alias to /src
import AlertPanel from '@/components/shared/AlertPanel.vue';

import MapContext from '@/models/map-context/MapContext';
import Component from 'vue-class-component';

import FeatureListPanel from '@/components/layers/FeatureListPanel.vue';
import LoadingPanel from '@/components/shared/LoadingPanel.vue';
import Application from '@/models/map-context/Application';
import { EventBus, EVENTS } from '@/services/EventBus';

// eslint-disable-next-line no-use-before-define
@Component<Home>({
  props: {
  },
  watch: {
    $route(to, from) {
      this.routeChange(to, from);
    },
  },
  components: {
    Map,
    Header,
    LeftSideBar,
    Footer,
    LayerListPanel,
    AddLayerPanel,
    GroupLayerInfos,
    SingleLayerInfos,
    FeatureInfosPanel,
    FeatureListPanel,
    AlertPanel,
    LoadingPanel,
    DashBoardPanel,
  },
})
export default class Home extends BaseComponent {
  cesiumMap = false;
  swipeActive = false;
  
  mounted() {
    if (this.contextService.configLoaded) {
      this.onConfigLoaded(this.contextService.configuration);
    } else {
      EventBus.$on(EVENTS.CONFIG_LOADED, (config:any) => this.onConfigLoaded(config));
    }
    EventBus.$on(EVENTS.SWIPE_ENABLED, this.swipeEnabled);
    EventBus.$on(EVENTS.PLUGIN_ACTIVATION_CHANGE, this.onUpdateActive);
  }

  onUpdateActive() {
    let swipeActive = false
    if (this.pluginService.isActive("SwipePlugin")) {
      swipeActive = this.pluginService.getParameter("SwipePlugin", "layer") !== null;
    }
    this.swipeActive = swipeActive;
  }

  beforeDestroy() {
    EventBus.$off(EVENTS.SWIPE_ENABLED, this.swipeEnabled)
    EventBus.$off(EVENTS.PLUGIN_ACTIVATION_CHANGE, this.onUpdateActive);
    EventBus.$off(EVENTS.CONFIG_LOADED, (config:any) => this.onConfigLoaded(config));
  }

  swipeEnabled(swipeActive:boolean) {
    this.swipeActive = swipeActive;
  }

  routeChange(to:any, from:any) {
    console.log(to, from);
    this.onConfigLoaded(this.contextService.configuration);
  }

  onConfigLoaded(config:any) {
    console.log(this.$route);

    const mapId = this.$route.params.id;
    const wmcParam = this.$route.params.wmc;
    const showHeader = this.$route.query.header;
    const localMap = this.$route.query.local;


    if (showHeader !== undefined && showHeader === 'false') {
      this.contextService.showHeader = false;
    }
    const showFooter = this.$route.query.footer;
    if (showFooter !== undefined && showFooter === 'false') {
      this.contextService.showFooter = false;
    }
    let isLocal = false;
    if (localMap !== undefined && localMap === 'true') {
      isLocal = true;
    }
    this.contextService.currentContext = null;
    // on attends que le composant map soit détruit pour charger la nouvelle carte ensuite en fixant la variable this.contextService.currentContext
    this.$nextTick().then(() => {
      console.log(mapId);

      if (wmcParam !== undefined) {
        this.contextService.loadUrl(`./sample_data/contexts/${wmcParam}`).then((reponse:any) => {
          console.log(reponse.data);
          const newApp = new Application({
            display_name: wmcParam,
          });
          this.capabilitiesService.parseWMC(reponse.data).subscribe((appConfig) => {
            const currentAppConfig = new MapContext(appConfig);
            this.contextService.loadContext(newApp, currentAppConfig);
          });
        }).catch((error) => { EventBus.$emit(EVENTS.ERROR, error); });
      } else if (mapId === undefined && isLocal && this.contextService.localApplication && this.contextService.localContext) {
        console.log('set context');
        this.contextService.loadContext(this.contextService.localApplication, this.contextService.localContext);
      } else if (mapId === undefined) {
        this.contextService.createMap();
        this.contextService.startEdition();
      } else {
        this.contextService
          .getMapById(parseInt(mapId, 10)).then((reponse:any) => {
            console.log(reponse.data);
            const retour = reponse.data;
            if (retour.config !== undefined) {
              // const appConfig = JSON.parse(retour.config);
              const appConfig = retour.config;
              console.log('loading ', retour.config);
              const application = new Application(retour);
              const currentAppConfig = new MapContext(appConfig);
              this.contextService.loadContext(application, currentAppConfig);
            }
          }).catch((error) => { EventBus.$emit(EVENTS.ERROR, error); });
      }
    });
  }

  getHeaderHeight() {
    if (this.contextService.configuration.customHeaderAndFooter && this.contextService.configuration.customHeaderAndFooter.header_height) {
      return `min-height:${this.contextService.configuration.customHeaderAndFooter.header_height}px`;
    }
    return '';
  }

  get isMapContextLoaded() {
    console.log('isMapContextLoaded', this.contextService.currentContext !== null);
    return this.contextService.currentContext !== null;
  }
}
