/* eslint-disable class-methods-use-this */
export default class DownloadProjection {
    label='';

    codeEpsg='';

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
    }
}
