



















































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';
import PluginComponent from '../PluginComponent';

declare let Cesium: any;

@Component({
  props: {
  },
  components: {
    FormTextInput,
  },
})
export default class AddModelPlugin extends PluginComponent {
    static ID='AddModelPlugin';

    viewer:any;

    modelUrl = '';

    mounted() {
      this.viewer = this.getCesiumMapService().viewer;
    }

    addModelFromUrl(scale = 1) {
      this.addModelToMap(this.modelUrl, scale);
    }

    addModel(modelFile:string, scale = 1) {
      this.addModelToMap(`./3d_models/${modelFile}`, scale);
    }

    addModelToMap(urlModel:string, scale = 1) {
      const { viewer } = this;
      const { canvas } = viewer.scene;

      const ray = viewer.camera.getPickRay(new Cesium.Cartesian2(
        Math.round(canvas.clientWidth / 2),
        Math.round(canvas.clientHeight / 2),
      ));
      const position = viewer.scene.globe.pick(ray, viewer.scene);
      console.log(position);
      const cartographic = Cesium.Cartographic.fromCartesian(position);
      console.log(
        `lon ${Cesium.Math.toDegrees(cartographic.longitude)}, `
          + `lat ${Cesium.Math.toDegrees(cartographic.latitude)}, `
          + `alt ${cartographic.height}`,
      );
      const modelConfig = new LayerConfig({
        type: '3dmodel',
        url: urlModel,
        visible: true,
        title: '3dmodel',
        modelConfig: {
          scale,
          projection: 'EPSG:4326',
          position: [Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude), cartographic.height],
          orientation: 90,
        },
      });
      this.getCesiumMapService().addModel(modelConfig, position);
      this.contextService.getCurrentContext().getLayers().unshift(modelConfig);
    }
}
