



















































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
import { OGCLayer } from '@/models/RemoteServer';
import axios from 'axios';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';
import CatalogSearchResult from './CatalogSearchResult.vue';

@Component({
  props: {
  },
  components: {
    CatalogSearchResult,

  },
})
export default class CatalogSearch extends BaseComponent {
  results:OGCLayer[]=[];

  loading=true;

  selectedLayerList:OGCLayer[]=[];

  filter='';

  filterTextChange = new Subject<string>();

  currentPage=1;

  perPage=6;

  totalResults=0;

  get paginatedResults(): OGCLayer[] {
    const startIndex = (this.currentPage - 1) * this.perPage;
    const endIndex = this.currentPage * this.perPage;
    return this.results.slice(startIndex, endIndex);
  }

  selectLayer(layer:any) :void{
    console.log(`Select Layer ${layer}`);
    if (this.selectedLayerList.indexOf(layer) >= 0) {
      this.selectedLayerList.splice(this.selectedLayerList.indexOf(layer), 1);
    } else {
      this.selectedLayerList.push(layer);
    }
    this.$emit('selectLayer', layer);
  }

  isSelected(result:OGCLayer): boolean {
    if (this.selectedLayerList.length === 0) {
      return false;
    }
    return this.selectedLayerList.find((x:OGCLayer) => x.Title === result.Title) !== undefined;
  }

  filterChange() {
    // this.search();
    this.filterTextChange.next(this.filter);
  }

  mounted() {
    this.search();
    this.filterTextChange.pipe(debounceTime(1000)).subscribe((res) => this.search());
  }

  search() {
    console.log('Mounted');
    let queryFilter = '*';
    let sort:any = [{
      'metadata-fr.publicationDate':
    { order: 'desc', unmapped_type: 'date' },
    }, { 'content-fr.published_at': { order: 'desc', unmapped_type: 'date' } }];
    if (this.filter.length > 0) {
      queryFilter = this.filter;
      sort = [{
        _score: 'desc',
      }];
    }
    const data = {
      from: 0,
      size: 50,
      query: {
        bool: {
          must: [{
            bool: {
              should: [
                {
                  query_string: {
                    analyzer: 'my_search_analyzer',
                    boost: 5,
                    default_operator: 'AND',
                    fields: [
                      'data_and_metadata',
                      'metadata-fr.title^5',
                      'metadata-fr.abstract^3',
                      'content-fr.title^5',
                      'content-fr.excerpt^3',
                      'content-fr.plaintext',
                    ],
                    fuzziness: 'AUTO',
                    minimum_should_match: '90%',
                    query: queryFilter,
                  },
                },
                {
                  query_string: {
                    analyzer: 'my_search_analyzer',
                    boost: 1,
                    default_operator: 'OR',
                    fields: [
                      'data_and_metadata',
                      'metadata-fr.title^5',
                      'metadata-fr.abstract^3',
                      'content-fr.title^5',
                      'content-fr.excerpt^3',
                      'content-fr.plaintext',
                    ],
                    fuzziness: 'AUTO',
                    minimum_should_match: '90%',
                    query: queryFilter,
                  },
                },
              ],
            },
          }, { bool: { should: [{ term: { 'metadata-fr.link.service.keyword': 'WMS' } }] } },
          ],
        },

      },
      collapse: {
        field: 'uuid.keyword',
        inner_hits: {
          name: 'data',
          size: 3,
          highlight: {
            pre_tags: ['<span class="highlighted">'],
            post_tags: ['</span>'],
            require_field_match: false,
            type: 'unified',
            fragmenter: 'span',
            order: 'score',
            highlight_query: {
              query_string: {
                query: '*', fields: ['data_and_metadata', 'metadata-fr.title', 'metadata-fr.abstract', 'content-fr.title', 'content-fr.plaintext'], analyzer: 'my_search_analyzer', fuzziness: 'AUTO', minimum_should_match: '90%',
              },
            },
            fields: {
              '*data-fr.*': { fragment_size: 100, number_of_fragments: 1 },
              'metadata-fr.title': { number_of_fragments: 0 },
              'content-fr.title': { number_of_fragments: 0 },
              'content-fr.plaintext': { fragment_size: 50, number_of_fragments: 1 },
            },
          },
        },
      },
      aggregations: {
        'metadata-fr.category': { terms: { field: 'metadata-fr.category.keyword', order: { _key: 'asc' }, size: 500 }, 
        aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, 
        'metadata-fr.type': { terms: { field: 'metadata-fr.type.keyword', order: { _key: 'asc' }, size: 500 }, aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, 'metadata-fr.license': { terms: { field: 'metadata-fr.license.keyword', order: { _key: 'asc' }, size: 500 }, aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, 'metadata-fr.link.service': { terms: { field: 'metadata-fr.link.service.keyword', order: { _key: 'asc' }, size: 500 }, aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, 'metadata-fr.link.formats': { terms: { field: 'metadata-fr.link.formats.keyword', order: { _key: 'asc' }, size: 500 }, aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, 'metadata-fr.publicationDate': { terms: { field: 'metadata-fr.publicationDate', order: { _key: 'asc' }, size: 500 }, aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, 'metadata-fr.updateFrequency': { terms: { field: 'metadata-fr.updateFrequency.keyword', order: { _key: 'asc' }, size: 500 }, aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, 'metadata-fr.territory': { terms: { field: 'metadata-fr.territory.keyword', order: { _key: 'asc' }, size: 500 }, 
        aggs: { count_per_metadata: { cardinality: { field: 'uuid.keyword' } } } }, count_by_scope: { terms: { field: 'type.keyword', order: { _key: 'asc' }, size: 500 }, aggs: { count: { cardinality: { field: 'uuid.keyword' } } } },
      },
      sort,
      post_filter: { terms: { 'type.keyword': ['dataset', 'nonGeographicDataset', 'series', 'nonGeographicSeries', 'service', 'post'] } },
    };
    // const urlCatalogue = 'https://preprod.pigma.org/elastic/_search?request_cache=true';
    const urlCatalogue = this.contextService.configuration.catalog_url;
    this.results = [];
    this.loading = true;
    axios.post(urlCatalogue, data, { headers: { 'X-CSRFToken': this.contextService.getCsrfToken() } }).then((result:any) => {
      console.log(result.data);
      this.totalResults = result.data.aggregations.count_by_scope.buckets[0].count.value;
      result.data.hits.hits.forEach((element: any) => {
        const toAdd:any = element;
        // eslint-disable-next-line dot-notation
        console.log(toAdd['_source']);
        // eslint-disable-next-line dot-notation
        const links = toAdd['_source']['metadata-fr'].link.filter((x:any) => (x.service && x.service === 'WMS'));
        const wfslinks = toAdd['_source']['metadata-fr'].link.filter((x:any) => (x.service && x.service === 'WFS'));
        const srcBbox = toAdd['_source']['metadata-fr'].bbox.coordinates[0];
        const idSlug = toAdd['_source']['slug'];
        const nativeCRS = toAdd['_source']['metadata-fr'].native_crs;
        const crsList = [];
        if (nativeCRS) {
          crsList.push(nativeCRS);// native first
        }
        crsList.push('EPSG:3857');
        let thumbnailUrl = './img/vignette-data-geo.png';
        const urlImage = toAdd['_source']['metadata-fr'].image.find((x: { type: string; }) => x.type === 'thumbnail');
        if (urlImage && urlImage.url && urlImage.url.length > 0) {
          thumbnailUrl = urlImage.url;
        }

        let copyright:any='';
        if(toAdd['_source']['metadata-fr'].copyright && toAdd['_source']['metadata-fr'].copyright.length>0){
          copyright={ Title: toAdd['_source']['metadata-fr'].copyright} ;
        }
        if (links.length > 0) {
          const ogcResult:OGCLayer = {
            Identifier: links[0].name,
            Url: `${links[0].url}?service=WMS&request=GetCapabilities&version=1.3.0`,
            ThumbnailUrl: thumbnailUrl,
            CRS: crsList,
            Style: [],
            MetadataURL: [{ OnlineResource: `${this.contextService.getExplorerUrl()}/fr/jeux-de-donnees/${idSlug}/info` }],
            Name: links[0].name,
            Title: toAdd['_source']['metadata-fr'].title,
            Abstract: toAdd['_source']['metadata-fr'].abstract,
            Attribution: copyright,
            WGS84BoundingBox: [srcBbox[0][0], srcBbox[0][1], srcBbox[2][0], srcBbox[2][1]],
            EX_GeographicBoundingBox: [srcBbox[0][0], srcBbox[0][1], srcBbox[2][0], srcBbox[2][1]],
          };
          if (toAdd['_source']['metadata-fr'].responsibleParty && toAdd['_source']['metadata-fr'].responsibleParty.length > 0) {
            ogcResult.OrganisationName = toAdd['_source']['metadata-fr'].responsibleParty[0].organisationName;
          }
          ogcResult.Wmts=true;
          if(wfslinks.length>0){
            ogcResult.Wfs=true;
          }
          this.results.push(ogcResult);
        }
      });
      this.loading = false;
    });
  }
}

