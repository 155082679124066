




























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormTextInput from '@/components/shared/FormTextInput.vue';
import AuthenticationInfos from '@/models/map-context/AuthenticationInfos';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    authenticationInfos: AuthenticationInfos,
    url: String,
  },
  components: {
    FormTextInput,
  },
})
export default class AuthenticationInfoPanel extends BaseComponent {
  authenticationInfos!:AuthenticationInfos;

  url!:string;

  authTypeList=[
    { item: 'none', name: 'Aucune' },
    { item: 'basic', name: 'Simple (Basic Auth)' }];

  mounted() {
    console.log(this.authenticationInfos);
  }

  onInfosChange() {
    if (this.authenticationInfos.store) {
      this.contextService.addAuthInfo(this.url, this.authenticationInfos);
    }
  }

  onStoreChange() {
    console.log(this.authenticationInfos.store);
    if (this.authenticationInfos.store) {
      this.contextService.addAuthInfo(this.url, this.authenticationInfos);
    } else {
      this.contextService.removeAuthInfo(this.url);
    }
  }
}
