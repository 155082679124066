







import PluginComponent from '@/components/plugins/PluginComponent';
import MapUtils from '@/models/map-context/MapUtils';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';

@Component
export default class ScaleListPlugin extends PluginComponent {
  currentScale:any=null;

  scaleList=[{ value: 500, text: '1 : 500' },
    { value: 1000, text: '1 : 1 000' },
    { value: 2000, text: '1 : 2 000' },
    { value: 5000, text: '1 : 5 000' },
    { value: 10000, text: '1 : 10 000' },
    { value: 25000, text: '1 : 25 000' },
    { value: 50000, text: '1 : 50 000' },
    { value: 100000, text: '1 : 100 000' },
    { value: 200000, text: '1 : 200 000' },
    { value: 500000, text: '1 : 500 000' },
    { value: 1000000, text: '1 : 1 000 000' },
    { value: 2000000, text: '1 : 2 000 000' },
    { value: 4000000, text: '1 : 4 000 000' },
  ];

  onZoomLevelChange(scale:any): void {
    this.currentScale = null;
    console.log(scale);
    EventBus.$off(EVENTS.MAP_CURRENT_ZOOM_LEVEL_CHANGE);
  }

  setScale():void{
    console.log(this.getOpenLayersMapService().view.getResolutions());
    const targetResol = MapUtils.getResolutionFromScale(this.currentScale,
      this.getOpenLayersMapService().map.getView().getProjection().getUnits());
    console.log(targetResol);
    // desactive event handling
    EventBus.$off(EVENTS.MAP_CURRENT_ZOOM_LEVEL_CHANGE);
    this.getOpenLayersMapService().view.setResolution(targetResol);
    // reactive event handling
    setTimeout(() => {
      EventBus.$on(EVENTS.MAP_CURRENT_ZOOM_LEVEL_CHANGE,
        (scale:any) => this.onZoomLevelChange(scale));
    }, 1000);
  }
}
