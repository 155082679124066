





























































































































/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import { EventBus, EVENTS } from '@/services/EventBus';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Component from 'vue-class-component';
import LegendPlugin from '../legend/LegendPlugin.vue';
import PluginComponent from '../PluginComponent';

@Component({
  props: {
  },
  components: {
    FormTextInput,
    LegendPlugin,
  },
})
export default class PrintPanelPlugin extends PluginComponent {
  static ID='PrintPanelPlugin';

  formats=[{ value: 'PDF', text: 'PDF' }, { value: 'PNG', text: 'PNG' }];

  orientationList = [{ value: 'Portrait', text: 'Portrait' }, { value: 'Paysage', text: 'Paysage' }];

  orientation = 'Paysage';

  paperFormatList = [{ value: 'a5', text: 'A5' },
    { value: 'a4', text: 'A4' },
    { value: 'a3', text: 'A3' },
    { value: 'a2', text: 'A2' },
    { value: 'a1', text: 'A1' },
    { value: 'a0', text: 'A0' },
  ];

  paperFormat = 'a4';

  qualityList = [{ value: 72, text: 'Basse' },
    { value: 150, text: 'Normale' },
    { value: 200, text: 'HD' },
    { value: 300, text: 'Ultra HD' }];

  quality = 150;

  title={ value: '', placeholder: 'Renseignez un titre (optionel)' };

  format={ value: 'PDF' };

  showPrintPreview=false;

  currentViewResolution:any;

  currentSize:any;

  formatdims:any = {
    a0: [1189, 841],
    a1: [841, 594],
    a2: [594, 420],
    a3: [420, 297],
    a4: [297, 210],
    a5: [210, 148],
  };

  rotation=0;

  legend:any;

  addLegend = true;

  logoUrl = null;

  mounted() {
    this.logoUrl = this.contextService.configuration.header.logo;
    const logo = this.plugin.getParameterValue('logo');
    if (logo && logo.length > 0) {
      this.logoUrl = logo;
    }
  }

  onChangePaperFormat(event:any) {
    console.log(event);
    this.paperFormat = event;
  }

  onChangeOrientation(event:any) {
    console.log(event);
    this.orientation = event;
  }

  onChangeQuality(event:any) {
    console.log(event);
    this.quality = event;
  }

  startQuickPrint() {
    console.log('starting print');
    this.showPrintPreview = true;
    this.rotation = -this.getOpenLayersMapService().map.getView().getRotation();
    EventBus.$emit(EVENTS.INFO, 'Impression en cours....');
    this.contextService.loading = true;
    // on a modifié la valeur de showPrintPreview
    // donc on attends que vuejs fasse le rendu du html puis on le déplace dans le body
    // (Avec un z-index négatif pour qu'il ne soit pas visible par l'utilisateur)
    // avant de lancer l'impression
    setTimeout(() => {
      // déplacement du div quickfootprint dans le body
      const newParent = document.body;
      const format = this.paperFormat;
      const resolution = this.quality;

      const map = this.getOpenLayersMapService().map;
      let dim = this.formatdims[format];
      if (this.orientation === 'Portrait') {
        dim = [...dim].reverse();
      }
      let width = Math.round((dim[0] * resolution) / 25.4);
      let height = Math.round((dim[1] * resolution) / 25.4);
      let size:any = map.getSize();
      this.currentSize = size;
      if (this.orientation === 'Portrait') {
        size = [...size].reverse();
      }
      this.currentViewResolution = map.getView().getResolution();

      const qfp:any = document.getElementById('quickfootprint');
      qfp.style.width = `${width}px`;
      qfp.style.height = `${height}px`;
      console.log([width, height]);
      const oldParent:any = document.getElementById('showPrintPreviewContainer');
      while (oldParent.childNodes.length > 0) {
        newParent.appendChild(oldParent.childNodes[0]);
      }

      // la taille de la carte à copier
      // depend de la taille de l'emplacement de la carte dans le template
      const cmap:any = document.getElementById('clonedMap');
      width = cmap.offsetWidth;
      height = cmap.offsetHeight;

      this.printLegend();
      this.getOpenLayersMapService().map.once('rendercomplete', (event:any) => {
        // Copie du canvas de la carte
        const newCanvas = document.createElement('canvas');
        const mapContext:any = newCanvas.getContext('2d');
        newCanvas.width = width;
        newCanvas.height = height;
        newCanvas.style.width = `${width}`;
        newCanvas.style.height = `${height}`;

        Array.prototype.forEach.call(
          document.querySelectorAll('.ol-layer canvas'),
          (canvas) => {
            if (canvas.width > 0) {
              console.log([canvas.width, canvas.height]);
              const { opacity } = canvas.parentNode.style;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
              const { transform } = canvas.style;
              // Get the transform parameters from the style's transform matrix
              const matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
              // Apply the transform to the export map context
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix,
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          },
        );

        // apply the old canvas to the new one
        // context.drawImage(mapcanvas, 0, 0);
        const clonedMapElem = document.getElementById('clonedMap');
        if (clonedMapElem != null) clonedMapElem.appendChild(newCanvas);

        // Copie de l'échelle
        const scale:any = document.getElementById('scale-line');
        if (scale != null) {
          html2canvas(scale, {
            scale: 1, // Adjusts your resolution
          }).then((canvas) => {
            const img = canvas.toDataURL('image/jpeg', 1);
            const scaleImgelement:any = document.getElementById('scalePreviewImg');
            if (scaleImgelement != null) {
              scaleImgelement.src = img;
            }

            this.restoreMapSize();
            /* Pour modifier le rendu et debugger,
              mettre en commentaire la ligne suivante et modifier le z-index de
              quickfootprint pour afficher la prévisualisation html
            */
            this.print();
          });
        } else {
          this.restoreMapSize();
          this.print();
        }
      });
      // map.setSize(printSize);
      map.getTargetElement().style.width = `${width}px`;
      map.getTargetElement().style.height = `${height}px`;
      map.updateSize();
      const scaling = Math.min(width / size[0], height / size[1]);
      map.getView().setResolution(this.currentViewResolution / scaling);
    }, 1000);
  }

  restoreMapSize() {
    const map = this.getOpenLayersMapService().map;
    map.getTargetElement().style.width = '';
    map.getTargetElement().style.height = '';
    map.setSize(this.currentSize);
    map.updateSize();
    map.getView().setResolution(this.currentViewResolution);
  }

  changeFormat(format:any) {
    this.format = format;
  }

  getSource() {
    const a = this.getMapService()
      .getOrderedLayerList().reverse()
      .filter((x:LayerConfig) => x.isLayerVisible() && x.Attribution && x.Attribution.Title)
      .map((x) => x.Attribution.Title);
    return Array.from(new Set(a)).join(', ');
    // return document.getElementsByClassName('ol-attribution')[0]
    // .getElementsByTagName('ul')[0].textContent;
  }

  printLegend() {
    const htmlContent:any = document.getElementsByClassName('quickfootprintLegend')[0];
    const w = htmlContent.offsetWidth;
    const h = htmlContent.offsetHeight;
    console.log([w, h]);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    html2canvas(htmlContent, {
      allowTaint: true,
      useCORS: true,
      scale: 1, // Adjusts your resolution
    }).then((canvas:any) => {
      if (this.format.value === 'PDF') {
        self.legend = {
          size: [w, h],
          img: canvas.toDataURL('image/jpeg', 1),
        };
      }
    });
  }

  print() {
    this.showPrintPreview = false;
    const htmlContent:any = document.getElementsByClassName('quickfootprintContent')[0];
    const w = htmlContent.offsetWidth;
    const h = htmlContent.offsetHeight;
    console.log([w, h]);
    // eslint-disable-next-line new-cap
    const doc = new jsPDF(this.orientation === 'Paysage' ? 'l' : 'p', 'px', [w, h]);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    html2canvas(htmlContent, {
      // allowTaint:true,
      scale: 2, // Adjusts your resolution
    }).then((canvas:any) => {
      if (self.format.value === 'PDF') {
        const img = canvas.toDataURL('image/jpeg', 1);
        doc.addImage(img, 'JPEG', 0, 0, w, h);
        if (self.addLegend) {
          console.log('add page', [self.legend.size[0], self.legend.size[1]]);
          if (self.legend.size[1] > self.legend.size[0]) {
            doc.addPage([self.legend.size[0], self.legend.size[1]], 'p');
          } else {
            doc.addPage([self.legend.size[0], self.legend.size[1]], 'l');
          }

          doc.addImage(self.legend.img, 'JPEG', 0, 0, self.legend.size[0], self.legend.size[1]);
        }

        doc.save('print.pdf');
      } else if ((window.navigator as any).msSaveBlob) {
        (window.navigator as any).msSaveBlob(canvas.msToBlob(), 'print.png');
      } else {
        canvas.toBlob((blob:any) => {
          FileSaver.saveAs(blob, 'print.png');
        });
      }
      EventBus.$emit(EVENTS.INFO, 'Impression terminée....');
      self.contextService.loading = false;
      document.body.removeChild(document.getElementsByClassName('quickfootprint')[0]);
    });
  }
}
