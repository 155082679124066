







































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-undef */
import BaseComponent from '@/components/BaseComponent';
import LayerConfig from '@/models/map-context/LayerConfig';
import AdministrativeArea from '@/models/shared/AdministrativeArea';
import DownloadProjection from '@/models/shared/DownloadProjection';
import { EVENTS, EventBus } from '@/services/EventBus';
import axios from 'axios';
import FileSaver from 'file-saver';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import { transformExtent } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import Component from 'vue-class-component';

// eslint-disable-next-line no-use-before-define
@Component<DownloadPanel>({
  props: {
    layer: LayerConfig,
  },
  watch: {},
  components: {},
})
export default class DownloadPanel extends BaseComponent {
  layer!: LayerConfig;

  formats:any=[];

  displayZoneChoice = true;

  displayDatasChoice = false;

  dataChoice = 'All';

  displayPrjectionChoice = true;

  administrativeAreas:AdministrativeArea[]=[];

  downloadProjections:DownloadProjection[]=[];

  selectedadministrativeAreas='Tout';

  selectedZone:any='';

  selectedProjection:any='EPSG:4326';

  zones= [];

  zoneSearch= '';

  wmsFormats=[
    { value: 'image/png', label: 'PNG', type: 'WMS' },
    { value: 'image/jpeg', label: 'JPEG', type: 'WMS' }];

  wfsFormats=[
    {
      value: 'SHAPE-ZIP', label: 'SHAPE', type: 'WFS', extension: 'zip',
    },
    {
      value: 'csv', label: 'CSV', type: 'WFS', extension: 'csv',
    },
    {
      value: 'application/json', label: 'JSON', type: 'WFS', extension: 'json',
    }];

  format={ };

  featureSource: any;

  olLayer!: VectorLayer<any>;

  selectedFeatureZone!:Feature|undefined;

  mounted():void{
    console.log(this.layer);
    this.administrativeAreas = [...this.contextService.getAdministrativeAreas()];
    this.downloadProjections = [...this.contextService.getDownloadProjections()];
    this.selectedProjection=this.downloadProjections[0].codeEpsg;
    this.administrativeAreas.unshift(new AdministrativeArea({
      label: 'Emprise courante',
    }));
    this.administrativeAreas.unshift(new AdministrativeArea({
      label: 'Tout',
    }));
    if (this.layer.isWMS()) {
      this.formats = []; // this.wmsFormats; # https://redmine.neogeo.fr/issues/12151
      if (this.layer.hasWFSCapacity()) {
        this.formats = this.formats.concat(this.wfsFormats);
      }
    }
    if (this.layer.isWFS()) {
      this.formats = this.formats.concat(this.wfsFormats);
    }
    if (this.layer.type === 'Vector') {
      this.formats.push({ value: 'GEOJSON', label: 'GEOJSON', type: 'Vector' });
      this.displayZoneChoice = false;
      this.displayPrjectionChoice = false;
    }
    if (this.layer.hasAnalyseDisplayedInLayerPanel()) {
      this.displayDatasChoice = true;
      this.dataChoice = 'Selection';
    }

    // TODO compare format in capabilities with list
    const self = this;
    this.capabilitiesService.getCapabilities(this.layer.getCapabilitiesUrl())
      .subscribe(
        (data:any) => {
          console.log(data);
          console.log(self);
        },
        (error:any) => {
          console.log(error);
        },
      );
  }

  changeFormat(format:any) {
    this.format = format;
  }

  get displayAdministrativeList() {
    if (!this.selectedadministrativeAreas) {
      return false;
    }
    // eslint-disable-next-line prefer-destructuring
    const selectedAdminType = this.selectedAdminType;

    return selectedAdminType!.attributeSearch.length > 0;
  }

  get selectedAdminType():AdministrativeArea|undefined {
    if (!this.selectedadministrativeAreas) {
      return undefined;
    }
    return this.administrativeAreas.find((x) => x.label === this.selectedadministrativeAreas);
  }

  addVectorLayer() {
    if (!this.featureSource) {
      this.featureSource = new VectorSource();

      this.olLayer = new VectorLayer({
        source: this.featureSource,
      });
      this.olLayer.setZIndex(80);
      this.getOpenLayersMapService().map.addLayer(this.olLayer);
    } else {
      this.featureSource.clear();
    }
  }

  getDisplayLabel(s:any) {
    return this.selectedAdminType?.getDisplayLabel(s);// s.properties[this.selectedAdminType!.attributeSearch];
  }

  selectZone(event:any) {
    console.log(event);
    EventBus.$emit(EVENTS.INFO, 'récupération de la géometrie....');
    this.contextService.loading = true;
    if (event.bbox) {
      const attributeSearch:any = this.selectedAdminType?.attributeSearch;
      this.selectedZone = event.properties[attributeSearch];
    } else {
      this.selectedZone = event;
    }
    if (this.selectedAdminType) {
      this.selectedFeatureZone = undefined;
      this.selectedAdminType.loadFeature(this.selectedZone).then((retour) => {
        console.log(retour);
        this.contextService.loading = false;
        EventBus.$emit(EVENTS.INFO, '');
        const featureList = new GeoJSON().readFeatures(retour.data);
        if (featureList && featureList.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.selectedFeatureZone = featureList[0];
          console.log('this.selectedFeatureZone');
          console.log(this.selectedFeatureZone);
          // display simplified geom for debug
          if (this.debug) {
            this.addVectorLayer();
            const simplified = this.selectedAdminType?.getSimplifiedGeometry(this.selectedFeatureZone);
            featureList[0].setGeometry(simplified);
            this.featureSource.addFeatures(featureList);
          }
        }
      });
    }
  }

  selectProjection(event:any) {
    console.log(event);
    this.selectedProjection = event;
  }

  getZones(query:string, loading:(monParametre: boolean) => void) {
    if (this.selectedAdminType && this.selectedAdminType.autocomplete) {
      loading(true);
      this.selectedAdminType.loadDatas(query).then((retour) => {
        this.zones = retour.data.features;
        console.log(this.zones);
      }).catch(() => {
        this.zones = [];
      }).finally(() => loading(false));
    }
  }

  download():void{
    const { format }:any = this;
    if (this.layer.type === 'Vector' && format.value === 'GEOJSON') {
      console.log(this.layer.jsonData);
      const blob = new Blob([this.layer.jsonData],
        { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, `${this.layer.title}.geojson`);
    } else {
      let url:any = this.layer.getDownloadUrl(format);
      let dataProjection = 'EPSG:2154';
      if (this.layer.dataProjection && this.layer.dataProjection.length > 0) {
        dataProjection = this.layer.dataProjection;
      }
      console.log(`dataProjection ${dataProjection}`);
      const geomAttribute = this.layer.getGeomAttributeName();
      if (this.selectedadministrativeAreas === 'Emprise courante') {
        const repojectedExtent = transformExtent(this.getMapService().getMapExtent(), 'EPSG:3857', dataProjection);
        const bboxDataProj = repojectedExtent.join(',');
        console.log(bboxDataProj);
        if (this.layer.getFilterValue().length > 0 && this.dataChoice === 'Selection') {
          url += `&CQL_FILTER=(${encodeURIComponent(this.layer.getFilterValue())} AND BBOX(${geomAttribute},${bboxDataProj}))`;
        } else {
          url += `&CQL_FILTER=BBOX(${geomAttribute},${bboxDataProj})`;
        }
      } else if (this.selectedadministrativeAreas !== 'Tout' && this.selectedAdminType && this.selectedFeatureZone) {
        console.log(this.selectedFeatureZone);
        const geom = this.selectedAdminType.getGeometricFilterValue(this.selectedFeatureZone, dataProjection);
        console.log(geom);
        url += `&CQL_FILTER=INTERSECTS(${geomAttribute} , ${geom})`;
        if (this.dataChoice === 'Selection' && this.layer.getFilterValue().length > 0) {
          url += `AND ${encodeURIComponent(this.layer.getFilterValue())}`;
        }
      } else if (this.layer.getFilterValue().length > 0 && this.dataChoice === 'Selection') {
        url += `&CQL_FILTER=${encodeURIComponent(this.layer.getFilterValue())}`;
      }
      url += `&srsName=${this.selectedProjection}`;
      // USE POST if URL too long
      if (url.length > 4000) {
        const urlArray = url.split('?');
        axios.post(urlArray[0], urlArray[1], { responseType: 'blob' }).then((result) => {
          FileSaver.saveAs(result.data, `${this.layer.title}.${format.extension}`);
        });
      } else {
        FileSaver.saveAs(url, `${this.layer.title}.${format.extension}`);
      }
    }
  }

  cancel() :void{
    this.$emit('close');
  }
}
