/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
export default class MapUtils {
  private static INCHES_PER_UNIT: any = { m: 39.37, dd: 4374754 };

  private static DOTS_PER_INCH = 90.714; // 72;

  static getScaleFromResolution(resolution: number, units: string, optRound: boolean): number {
    let scale = this.INCHES_PER_UNIT[units] * this.DOTS_PER_INCH * resolution;
    if (optRound) {
      scale = Math.round(scale);
    }
    return scale;
  }

  static getResolutionFromScale(scale: number, units: string): number {
    const resolution = scale / (this.INCHES_PER_UNIT[units] * this.DOTS_PER_INCH);
    return resolution;
  }

  static replaceSvgColor(svgData: string, fillcolor: string,
    strokecolor: string, strokeWidth: number): string {
    if (fillcolor.length > 0) {
      svgData = svgData.replaceAll('param(fill)', MapUtils.rgba2hex(fillcolor));
      svgData = svgData.replaceAll('param(fill-opacity)', MapUtils.rgba2opacity(fillcolor));
    }
    if (strokecolor.length > 0) {
      svgData = svgData.replaceAll(/param\(stroke\)\s*\w*\"/g, `${strokecolor}"`);
      svgData = svgData.replaceAll(/param\(stroke-width\)\s*\d*\"/g, `${strokeWidth}"`);
    }
    return svgData;
  }

  static rgba2hex(orig:any):string {
    let a;
    const rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    const alpha = (rgb && rgb[4] || '').trim();
    const hex = rgb
      ? `#${(rgb[1] | 1 << 8).toString(16).slice(1)
      }${(rgb[2] | 1 << 8).toString(16).slice(1)
      }${(rgb[3] | 1 << 8).toString(16).slice(1)}` : orig;

    if (alpha !== '') {
      a = alpha;
    } else { a = '01'; }

    // hex = hex + a;
    return hex;
  }

  static rgba2opacity(orig:any):string {
    let a;
    const rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    const alpha = (rgb && rgb[4] || '').trim();
    const hex = rgb
      ? (rgb[1] | 1 << 8).toString(16).slice(1)
                + (rgb[2] | 1 << 8).toString(16).slice(1)
                + (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

    if (alpha !== '') { a = alpha; } else { a = '1'; }

    // hex = hex + a;

    return a;
  }
}
