


































































































































import DashBoardTile, { IDashBoardTile, IParameterTile } from '@/models/map-context/DashBoardTile';
import { EVENTS, EventBus } from '@/services/EventBus';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {},
  components: {},
})
export default class DashBoardPanel extends BaseComponent {
  editingIndex:number|null=null;

  // !!la propriété suivant peut contenir un objet Cesium, il ne faut pas que cette propriété soit reactive,
  // cela rentre en conflit avec le fonctionnement de cesium et cause de gros pb de performances
  currentFeature:any=null;

  newTile = false;

  gridTemplateColumns = 'auto';

  gridAutoRows = 'auto';

  columnsTotal=1;

  title = '';

  display = false;

  // resize parameters
  resizing = false;

  leftColumnFlex = 65;

  rightColumnFlex = 35;

  isActive = false;

  resizable = false;

  timeout = 10;

  editingLink = false;

  parameterName = '';

  css = `
    .h2 {font-size:18px}
    .EmbedFrame-footer {display:none}
  `;

  items:IDashBoardTile[] = [];

  generateURL(url:string, parameters:IParameterTile[], currentFeature:any): string {
    // we will custom url only if a layer is selected and if there is at least one parameter
    if (url && parameters?.length > 0) {
      const layerId = currentFeature && currentFeature.layerConfig?.uid;
      const p:string[] = [];
      const n:string[] = parameters.map((param) => param.name);
      if (layerId) {
        parameters.forEach((param) => {
          const attribute = param.links && param.links[layerId];
          if (attribute) {
            p.push(`${param.name}=${currentFeature.get(attribute)}`);
          }
        });
      }
      let buildURL;
      const sd = url.split('#');
      const dieseRight = sd.length > 1 ? `&${sd[1]}` : '';
      if (p.length > 0) {
        const s = sd[0].split('?');
        const urlRight = s.length > 1 ? `&${s[1]}` : '';
        buildURL = `${s[0]}?${p.join('&')}${urlRight}`;
      } else if (n.length > 0) {
        buildURL = `${sd[0]}`;
      }
      if (buildURL) {
        // if the use already set the hide_parameters we don't need to redefine it
        if (dieseRight.indexOf('hide_parameters') === -1) {
          buildURL += `#hide_parameters=${n.join(',')}${dieseRight}`;
        } else {
          buildURL += `#${sd[1]}`;
        }
        console.log('buildURL', buildURL);
        return buildURL;
      }
    }
    return url;
  }

  created():void {
    EventBus.$on(EVENTS.DASHBOARD_UPDATED, this.onUpdatePlugin);
    EventBus.$on(EVENTS.DASHBOARD_DISPLAY, this.onUpdateDisplay);
    EventBus.$on(EVENTS.SELECT_FEATURE, this.onFeatureChange);
    EventBus.$on(EVENTS.SELECT_NO_FEATURE, this.onFeatureChange);
    EventBus.$on(EVENTS.PLUGIN_ACTIVATION_CHANGE, this.onUpdateActive);
    this.items = this.contextService.getCurrentContext().dashboard;
  }

  beforeDestroy():void {
    EventBus.$off(EVENTS.DASHBOARD_UPDATED, this.onUpdatePlugin);
    EventBus.$off(EVENTS.DASHBOARD_DISPLAY, this.onUpdateDisplay);
    EventBus.$off(EVENTS.SELECT_FEATURE, this.onFeatureChange);
    EventBus.$off(EVENTS.SELECT_NO_FEATURE, this.onFeatureChange);
    EventBus.$off(EVENTS.PLUGIN_ACTIVATION_CHANGE, this.onUpdateActive);
  }

  mounted():void {
    this.onUpdateActive();
  }

  injectStyles(event:Event): void {
    const iframe:any = event.target;
    if (iframe) {
      const contentDocument = iframe.contentDocument || iframe.contentWindow.document;
      if (contentDocument) {
        const styleElement = contentDocument.createElement('style');
        styleElement.type = 'text/css';
        styleElement.appendChild(contentDocument.createTextNode(this.css));
        const head = contentDocument.head || contentDocument.getElementsByTagName('head')[0];
        head.appendChild(styleElement);
      }
    }
  }

  refresh():void {
    setTimeout(() => {
      const event = new Event('resize');
      window.dispatchEvent(event);
    }, this.timeout);
  }

  onFeatureChange(features:any[]): void {
    this.currentFeature = features?.length > 0 ? features[0] : null;
  }

  onUpdateActive(): void {
    this.isActive = this.pluginService.isActive('DashBoardPlugin');
    this.refresh();
  }

  onUpdateDisplay(display:boolean):void {
    this.display = display;
    this.refresh();
  }

  onUpdatePlugin(plugin:any):void {
    if (plugin) {
      this.title = plugin.getParameterValue('title');
      this.columnsTotal = plugin.getParameterValue('columns');
      let rRight = Number(plugin.getParameterValue('percentageDashboard'));
      if (Number.isNaN(rRight) || rRight < 10 || rRight > 90) {
        rRight = 35;
      }
      this.rightColumnFlex = rRight;
      this.leftColumnFlex = 100 - rRight;
      this.gridTemplateColumns = `repeat(${this.columnsTotal}, auto)`;
      const rowsTotal = plugin.getParameterValue('rows');
      this.gridAutoRows = rowsTotal ? `calc((100% - ${(rowsTotal - 1) * 10}px) / ${rowsTotal})` : 'auto';
      this.resizable = plugin.getParameterValue('resizable');
    }
  }

  toggleLinks():void {
    this.editingLink = !this.editingLink;
  }

  toggleEditing():void {
    if (this.editingIndex !== null) {
      this.editingIndex = null;
      this.editingLink = false;
    } else {
      this.items.push(new DashBoardTile());
      this.editingIndex = this.items.length - 1;
    }
  }

  addParameter(event:Event):void {
    event.preventDefault();
    if (this.editingIndex !== null) {
      const params = this.items[this.editingIndex].parameters;
      if (params.filter((p) => p.name === this.parameterName).length > 0) {
        EventBus.$emit(EVENTS.ERROR, 'Paramètre déjà présent');
      } else {
        params.push({
          name: this.parameterName,
          links: {},
        } as IParameterTile);
        params.sort((a, b) => {
          let s = 0;
          if (a.name < b.name) {
            s = -1;
          } else if (a.name > b.name) {
            s = 1;
          }
          return s;
        });
      }
    }
  }

  removeParameter(index: number): void {
    if (this.editingIndex !== null) {
      const params = this.items[this.editingIndex].parameters;
      params.splice(index, 1);
    }
  }

  go(item:IDashBoardTile, current:number, index:number):void {
    if (index >= 0 && index < this.items.length) {
      this.items.splice(current, 1);
      this.items.splice(index, 0, item);
      this.editingIndex = index;
    }
  }

  remove(index:number):void {
    this.items.splice(index, 1);
    this.editingIndex = null;
  }

  edit(index:number): void {
    this.editingIndex = index;
    this.parameterName = '';
  }

  startResize():void {
    this.resizing = true;
    window.addEventListener('mousemove', this.resizeColumns);
    window.addEventListener('mouseup', this.stopResize);
  }

  stopResize():void {
    this.resizing = false;
    window.removeEventListener('mousemove', this.resizeColumns);
    window.removeEventListener('mouseup', this.stopResize);
    const event = new Event('resize');
    window.dispatchEvent(event);
  }

  resizeColumns(event:any):void {
    if (this.resizing) {
      const containerWidth = this.$el.clientWidth;
      const mouseX = event.clientX;
      // Calculate the new widths as percentages
      const leftColumnWidth = (mouseX / containerWidth) * 100;
      const rightColumnWidth = 100 - leftColumnWidth;
      // Update the flex values directly
      this.leftColumnFlex = leftColumnWidth;
      this.rightColumnFlex = rightColumnWidth;
    }
  }
}
