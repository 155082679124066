/* eslint-disable */
import { intersects } from 'ol/extent';
import { WFS } from 'ol/format';
import { writeFilter } from 'ol/format/WFS';
import CQL from './CQL';

export default class Filter {
    filter!:any;

    cql!:any;

    constructor() {

    }

    fromCQL(cql:string) {
      this.cql = cql;
      this.filter = (new CQL()).read(cql);
    }

    toSld():string {
      if (this.filter) {
        const wfsformat = new WFS();
        const filterxml:any = writeFilter(this.filter,'1.1.0');//,'2.0.0'
        console.log(filterxml);
        return filterxml.outerHTML;
      }
      return '';
    }

    evaluate(context:any, filter?:any):boolean {
      if (!filter) {
        filter = this.filter;
      }

      if (filter.tagName_ == 'PropertyIsLike'
            || filter.tagName_ == 'PropertyIsEqualTo'
            || filter.tagName_ == 'PropertyIsLessThan'
            || filter.tagName_ == 'PropertyIsLessThanOrEqualTo'
            || filter.tagName_ == 'PropertyIsBetween'
            || filter.tagName_ == 'PropertyIsGreaterThan'
            || filter.tagName_ == 'PropertyIsNull'

            || filter.tagName_ == 'PropertyIsGreaterThanOrEqualTo') {
        return this.evaluateComparison(context, filter);
      }
      if (filter.tagName_ == 'Or' || filter.tagName_ == 'And' || filter.tagName_ == 'Not') {
        return this.evaluateLogical(context, filter);
      } if (filter.tagName_ == 'Intersects') {
        return this.evaluateSpatial(context, filter);
      }

      console.error(`not recognised${this.filter}`);

      return false;
    }

    evaluateSpatial(context:any, filter:any):boolean {
      if(context.getExtent){
        return intersects(context.getExtent(), filter.geometry.getExtent());
      }
      return intersects(context.getGeometry().getExtent(), filter.geometry.getExtent()); // VectorTile
    }

    static evaluateExpression(expression:string, attributes:any) {
      let got;
      const child = [];
      if (expression.indexOf('(') >= 0) {
        let childs:any;
        if (attributes.childs) {
          childs = attributes.childs;
        }

        // pattern du type childs[@attibute=value]
        const filterChildByAttribute = new RegExp(/childs\[\@(\w*):([^\]]*)\]/g);
        const match = filterChildByAttribute.exec(expression);

        let express;
        if (match) {
          if (childs) {
            let i = 0;
            express = expression.replace(filterChildByAttribute, (a, b, c) => {
              const attribute = b;
              const searchValue = c;
              child.push(childs.find((x:any) => x[attribute] == eval(searchValue),
              ));
              const retour = `child[${i}]`;
              i++;
              return retour;
            });

            express = express.replace(/\{\{|\}\}/g, '');
          } else {
            return undefined;
          }
        } else {
          express = expression.replace(/\{\{(.*?)\}\}/g, (a, b) => {
            try {
              const value = attributes ? eval(`context.${b}`) : null;
              return value || b;
            } catch (e) {
              return b;
            }
          });
        }
        try {
          got = eval(express);
        } catch (e) {
          got = undefined;
        }
      } else {
        got = attributes[expression.replace(/\{\{|\}\}/g, '')];
      }
      return got;
    }

    evaluateComparison(context:any, filter:any):boolean {
      if (context.getProperties) {
        context = context.getProperties();
      }
      let result = false;
      const got = Filter.evaluateExpression(filter.propertyName, context);
      let exp;
      switch (filter.tagName_) {
        case 'PropertyIsEqualTo':
          exp = filter.expression;
          if (!filter.matchCase
                   && typeof got === 'string' && typeof exp === 'string') {
            result = (got.toUpperCase() == exp.toUpperCase());
          } else {
            result = (got == exp);
          }
          break;
        case 'PropertyIsNotEqualTo':
          exp = filter.expression;
          if (!filter.matchCase
                   && typeof got === 'string' && typeof exp === 'string') {
            result = (got.toUpperCase() != exp.toUpperCase());
          } else {
            result = (got != exp);
          }
          break;
        case 'PropertyIsLessThan':
          result = got < filter.expression;
          break;
        case 'PropertyIsGreaterThan':
          result = got > filter.expression;
          break;
        case 'PropertyIsLessThanOrEqualTo':
          result = got <= filter.expression;
          break;
        case 'PropertyIsGreaterThanOrEqualTo':
          result = got >= filter.expression;
          break;
        case 'PropertyIsBetween':
          result = (got >= filter.lowerBoundary)
                    && (got <= filter.upperBoundary);
          break;
        case 'PropertyIsLike':
          var regexp = new RegExp(this.value2regex(filter.pattern, filter.wildCard, filter.singleChar, filter.escapeChar), 'gi');
          result = regexp.test(got);
          break;
        case 'PropertyIsNull':
          result = (got === undefined || got === null);
          break;
      }
      return result;
    }

    evaluateLogical(context:any, filter:any) {
      let i; let
        len;
      switch (filter.tagName_) {
        case 'And':
          for (i = 0, len = filter.conditions.length; i < len; i++) {
            if (this.evaluate(context, filter.conditions[i]) == false) {
              return false;
            }
          }
          return true;

        case 'Or':
          for (i = 0, len = filter.conditions.length; i < len; i++) {
            if (this.evaluate(context, filter.conditions[i]) == true) {
              return true;
            }
          }
          return false;

        case 'Not':
          return (!this.evaluate(context, filter.condition));
      }
      return false;
    }

    /**
     * APIMethod: value2regex
     * Converts the value of this rule into a regular expression string,
     * according to the wildcard characters specified. This method has to
     * be called after instantiation of this class, if the value is not a
     * regular expression already.
     *
     * Parameters:
     * wildCard   - {Char} wildcard character in the above value, default
     *              is "*"
     * singleChar - {Char} single-character wildcard in the above value
     *              default is "."
     * escapeChar - {Char} escape character in the above value, default is
     *              "!"
     *
     * Returns:
     * {String} regular expression string
     */
    value2regex(value:any, wildCard:any, singleChar:any, escapeChar:any) {
      if (wildCard == '.') {
        throw new Error("'.' is an unsupported wildCard character for "
                            + 'OpenLayers.Filter.Comparison');
      }

      // set UMN MapServer defaults for unspecified parameters
      wildCard = wildCard || '*';
      singleChar = singleChar || '.';
      escapeChar = escapeChar || '!';

      value = value.replace(
        new RegExp(`\\${escapeChar}(.|$)`, 'g'), '\\$1',
      );
      value = value.replace(
        new RegExp(`\\${singleChar}`, 'g'), '.',
      );
      value = value.replace(
        new RegExp(`\\${wildCard}`, 'g'), '.*',
      );
      value = value.replace(
        new RegExp('\\\\.\\*', 'g'), `\\${wildCard}`,
      );
      value = value.replace(
        new RegExp('\\\\\\.', 'g'), `\\${singleChar}`,
      );

      return value;
    }

    /**
     * Method: regex2value
     * Convert the value of this rule from a regular expression string into an
     *     ogc literal string using a wildCard of *, a singleChar of ., and an
     *     escape of !.  Leaves the <value> property unmodified.
     *
     * Returns:
     * {String} A string value.
     */
    regex2value(value:any) {
      // replace ! with !!
      value = value.replace(/!/g, '!!');

      // replace \. with !. (watching out for \\.)
      value = value.replace(/(\\)?\\\./g, ($0:any, $1:any) => ($1 ? $0 : '!.'));

      // replace \* with #* (watching out for \\*)
      value = value.replace(/(\\)?\\\*/g, ($0:any, $1:any) => ($1 ? $0 : '!*'));

      // replace \\ with \
      value = value.replace(/\\\\/g, '\\');

      // convert .* to * (the sequence #.* is not allowed)
      value = value.replace(/\.\*/g, '*');

      return value;
    }
}
