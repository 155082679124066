



























import PluginComponent from '@/components/plugins/PluginComponent';
import LayerConfig from '@/models/map-context/LayerConfig';
import * as jsts from 'jsts';
import { Feature } from 'ol';
import { buffer, createEmpty, extend } from 'ol/extent';
import {
LineString, LinearRing, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon,
} from 'ol/geom';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

interface SearchQueries {
  [key: string]: string | undefined;
}

interface Attribute {
  text:string,
  value:string,
  results: string[]
}

interface FeatureCfg extends Feature {
  layerConfig?:any
}

@Component
export default class SearchLayerPlugin extends PluginComponent {
    static ID='SearchLayerPlugin';

    open = false;

    autoOpenInfos = false;

    hideNotFiltered = false;

    zoomFiltered = false;

    taggable = false;

    hasReset = false;

    features:Feature[] = [];

    featuresSelected:FeatureCfg[] = [];

    searchQueries = {} as SearchQueries;

    attributes: Attribute[] = [];

    parser:any;

    layer!:LayerConfig;

    hasResetBtn() {
      return this.hasReset && Object.keys(this.searchQueries).length > 0;
    }

    resetSearch() {
      this.searchQueries = {} as SearchQueries;
    }

    @Watch('searchQueries', { deep: true })
    updateSearch() {
      if (this.features) {
        this.featuresSelected = this.features.filter((feature: Feature):boolean => {
          // Parcourez l'ensemble des attributs de searchQueries
          for (const attribute in this.searchQueries) {
            const searchQueryValue = this.searchQueries[attribute];
            if (searchQueryValue) {
              const attributeValue = feature.get(attribute);
              if (attributeValue
              && (this.taggable
                ? !attributeValue.toLowerCase().includes(searchQueryValue.toLowerCase())
                : attributeValue !== searchQueryValue)) {
                // Si une condition n'est pas respectée, retournez false pour filtrer l'objet
                return false;
              }
            }
          }
          // Retournez true si toutes les conditions sont respectées pour cet objet
          return true;
        });
        if (this.attributes) {
          for (const attribute of this.attributes) {
            attribute.results = [...new Set(this.featuresSelected.map((feature) => feature.get(attribute.value)))];
          }
        }
        this.zoom();
        this.filterLayer();
      }
    }

    filterLayer():void {
      if (this.hideNotFiltered) {
        this.layer.getLayerSource().clear();
        this.layer.getLayerSource().addFeatures(this.featuresSelected);
      }
    }

    zoom(): void {
      const { featuresSelected } = this;
      if (!this.open || !this.zoomFiltered || featuresSelected.length === 0) {
        return;
      }
      let fitValue:any;
      if (featuresSelected.length === 1) {
        fitValue = featuresSelected[0].getGeometry();
        if (fitValue && fitValue.getType() === 'Point') {
          const jstsGeom = this.parser.read(fitValue);
          const buffered = jstsGeom.buffer(500); // create a buffer of X meters
          fitValue = this.parser.write(buffered);
        }
        if (this.autoOpenInfos) {
          this.featuresSelected[0].layerConfig = this.layer;
          this.getMapService().selectionService.selectFeatures(this.featuresSelected, false, {});
        }
      } else {
        const hasQueries = Object.values(this.searchQueries).filter((value) => value && value !== '').length > 0;
        if (hasQueries) {
          fitValue = this.featuresSelected.reduce((accum, feature) => {
            const geometry = feature?.getGeometry();
            if (geometry) {
              const featureExtent = geometry.getExtent();
              const extendedExtent = buffer(featureExtent, 500);
              extend(accum, extendedExtent);
            }
            return accum;
          }, createEmpty());
        } else {
          // We back to map zoom
          this.getMapService().zoomToInitialExtent();
        }
      }
      if (fitValue) {
        this.getOpenLayersMapService().map.getView().fit(fitValue);
      }
    }

    mounted():void {
      // eslint-disable-next-line no-undef
      this.parser = new jsts.io.OL3Parser();
      this.parser.inject(
        Point,
        LineString,
        LinearRing,
        Polygon,
        MultiPoint,
        MultiLineString,
        MultiPolygon,
      );
    }

    created():void {
      this.open = this.plugin.getParameterValue('open');
      this.autoOpenInfos = this.plugin.getParameterValue('autoOpenInfos');
      this.hideNotFiltered = this.plugin.getParameterValue('hideNotFiltered');
      this.zoomFiltered = this.plugin.getParameterValue('zoomFiltered');
      this.taggable = this.plugin.getParameterValue('taggable') === true;
      this.hasReset = this.plugin.getParameterValue('hasReset') === true;
      const layerUid = this.plugin.getParameterValue('layerUid');
      if (layerUid) {
        const attributesName = this.plugin.getParameterValue('attributesName');
        if (attributesName && attributesName.length > 0) {
          const layer = this.contextService.getCurrentContext().getOrderedLayerList().find((v) => v.uid === layerUid);
          if (layer) {
            this.layer = layer;
            const attributes: Attribute[] = [];
            attributesName.forEach((value:string) => {
              const attribute = layer.layerProperties.find((v) => v.name === value);
              if (attribute) {
                attributes.push({ text: attribute.label || value, value, results: [] });
              }
            });
            this.attributes = attributes;
            layer.getLayerSource().once('featuresloadend', this.updateFeatures);
            this.updateFeatures();
          }
        }
      }
    }

    updateFeatures(): void {
      this.features = this.layer.getLayerSource().getFeatures();
      this.updateSearch();
    }

    toggleOpen():void {
      this.open = !this.open;
    }
}
