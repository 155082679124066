export default class AlertMessage {
    type = 'warning';

    label!: string;

    closable = true;

    autoHide = false;

    countDown = 10;

    constructor(input:any) {
      if (input) {
        Object.assign(this, input);
      }
    }
}
