/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
export enum ParameterType
{
    Boolean,
    Select,
    Text,
    TextArea,
    Json,
    Number,
    LayerPicker,
    LayerGroupPicker,
    LayerAttributePicker,
    Autocomplete
}
