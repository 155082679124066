


























/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    value: {},
  },
  components: {

  },
})
export default class SvgSymbolList extends BaseComponent {
  categories=[];

  pictos:string[]=[];

  value!:string;

  selectMode = false;

  mounted() {
    console.log('SvgSymbolList');

    this.categories = this.contextService.svgPictos
      .themes.map((x:any) => ({ active: false, label: x.name }));

    this.changePicto();
    // this.active_svg_icon = this.svgIconUrl;
  }

  changePicto() :void{
    let categorie:any = this.categories[0];
    if (this.value !== undefined && this.value.length > 0) {
      categorie = this.getCatOfIcon(this.value);
      this.selectCategorieByName(categorie);
    } else {
      this.selectCategorie(categorie);
      this.selectMode = true;
    }
  }

  selectCategorie(cat:any) :void{
    this.categories.forEach((categorie:any) => categorie.active = false);
    cat.active = true;
    this.pictos = this.getIconList(cat.label);
  }

  selectCategorieByName(catName:string) :void{
    this.categories.forEach((categorie:any) => categorie.active = false);
    const cat:any = this.categories.find((categorie:any) => categorie.label === catName);
    cat.active = true;
    this.pictos = this.getIconList(cat.label);
  }

  isSelectedPictoInCat(cat:any) {
    if (this.value !== undefined && this.value.length > 0) {
      return this.getCatOfIcon(this.value) === cat.label;
    }
    return false;
  }

  selectPicto(picto:string) {
    this.$emit('input', picto);
    this.selectMode = false;
    this.$emit('symboChange', '');
  }

  getCatItemSize(cat:string):number {
    return this.getIconList(cat).length;
  }

  getCatOfIcon(icon:string):string {
    return this.contextService.svgPictos.themes.find((x:any) => x.icons.map((path:string) => `./styles/${path}`).indexOf(icon) >= 0).name;
  }

  getIconList(cat:string):string[] {
    return this.contextService.svgPictos.themes.find((x:any) => x.name === cat).icons.map((path:string) => `./styles/${path}`);
  }
}
