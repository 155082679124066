
















































/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DownloadPanel from '@/components/layers/DownloadPanel.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import Plugin from '@/models/plugin/Plugin';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import LayerRuleFilterList from '../plugins/legend/LayerRuleFilterList.vue';

@Component({
  props: {
    layer: LayerConfig,
    plugin: {},
  },
  components: {
    DownloadPanel,
    LayerRuleFilterList,
  },
})
export default class SingleLayerElementSimplified extends BaseComponent {
  layer!: LayerConfig;

  plugin!:Plugin;

  showDownLoad = false;

  target:any = null;


  is3Dtiles = false;


  mounted() {

    if (this.is3DView()) {
      if (this.layer.type.indexOf('3DTiles') >= 0) {
        this.is3Dtiles = true;
      }
    }
  }



  IsPhotomaillage() : boolean {
    return this.layer.type === '3DTiles_Photomaillage';
  }

  get visible() :boolean {
    return this.layer.visible;
  }

  set visible(visibility:boolean) {
    console.log('setVisible simple')
    this.layer.setVisible(!this.layer.visible);
  }

  get inRange() {
    // eslint-disable-next-line prefer-destructuring
    const currentScale = this.getMapService().currentScale;
    return this.layer.inRange(currentScale);
  }



  onChange() {
    console.log('onchange')
    this.layer.setVisible(!this.layer.visible);
  }

  mouseDown(event:any): void {
    this.target = event.target;
  }




  infoLayer() {
    this.contextService.setCurrentAction('infoLayer', this.layer);
  }

  zoomToLayer() {
    this.getMapService().zoomToLayer(this.layer);
  }

}
