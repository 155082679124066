




























import PluginComponent from '@/components/plugins/PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';

@Component
export default class PermalinkPlugin extends PluginComponent {
    static ID='PermalinkPlugin';

    permalink= 'test';

    copyDone = false;

    alertCountDown = 0;

    mounted():void {
      this.permalink = window.location.href;
      EventBus.$on(EVENTS.MAP_CURRENT_BBOX_CHANGE, (bbox:any) => this.onMoveEnd(bbox));
    }

    countDownChanged(dismissCountDown:any): void {
      this.alertCountDown = dismissCountDown;
    }

    onMoveEnd(bbox:any): void {
      this.permalink = window.location.href;
    }

    copy(): void{
      const copyText:any = this.$refs.textInput;
      copyText.select();
      copyText.setSelectionRange(0, 99999); // used for mobile phone
      document.execCommand('copy');
      this.alertCountDown = 5;
    }
}
