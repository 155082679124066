










import PluginComponent from '@/components/plugins/PluginComponent';
import EventBus, { EVENTS } from '@/services/EventBus';
import { Layer } from 'ol/layer';
import Component from 'vue-class-component';

@Component
export default class SwipePlugin extends PluginComponent {
    static ID='SwipePlugin';

    apiKey = '';

    viewer!:any;

    marker:any=null;

    layer:any;

    swipe: any = null;

    cutLeft = false;

    enabled = false;

    prerender(event:any) {
      if (this.enabled) {
        this.getMapService().preRenderSwipe(event, this.swipe?.value, this.cutLeft);
      }
    }

    postrender(event:any) {
      const ctx:any = event.context;
      ctx.restore();
    }

    created() {
      this.swipe = document.getElementById('swipe');
      this.cutLeft = this.plugin.getParameterValue('cutLeft') === true;
      this.layer = this.getLayer('layer');
      if (this.layer) {
        this.layer.un('prerender', this.prerender);
        this.layer.un('postrender', this.postrender);
        this.layer.on('prerender', this.prerender);
        this.layer.on('postrender', this.postrender);
        this.enabled = true;
      } else {
        this.enabled = false;
      }
      EventBus.$emit(EVENTS.SWIPE_ENABLED, this.enabled);
    }

    beforeDestroy() {
      if (this.layer) {
        this.layer.un('prerender', this.prerender);
        this.layer.un('postrender', this.postrender);
        this.layer.changed();
      }
    }    

    toggleActive() {
      this.enabled = !this.enabled;
      EventBus.$emit(EVENTS.SWIPE_ENABLED, this.enabled);
      this.layer.changed();
    }

    getLayer(layerPluginField:string): Layer | undefined {
      let layer;
      const layerUid = this.plugin.getParameterValue(layerPluginField);
      if (layerUid) {
        const layerConfig = this.getMapService().getOrderedLayerList().find((v) => v.uid === layerUid);
        if (layerConfig) {
          layer = layerConfig.getOlLayer();
        }
      }
      return layer;
    }
}
