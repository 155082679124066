import { render, staticRenderFns } from "./UserPanel.vue?vue&type=template&id=5a2035a4&scoped=true"
import script from "./UserPanel.vue?vue&type=script&lang=ts"
export * from "./UserPanel.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a2035a4",
  null
  
)

export default component.exports