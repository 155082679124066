























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-undef */
// eslint-disable-next-line import/extensions
import FormTextInput from '@/components/shared/FormTextInput.vue';
import ModalPanel from '@/components/shared/ModalPanel.vue';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import SvgSymbolList from './style/SvgSymbolList.vue';

@Component({
  props: {
    layer: LayerGroupConfig,
  },
  components: {
    ModalPanel,
    FormTextInput,
    SvgSymbolList,
  },
})
export default class GroupLayerInfos extends BaseComponent {
  layerBackup!: LayerGroupConfig;

  layer!: LayerGroupConfig

  mounted():void {
    // copie en cas d'annulation des modifications
    this.layerBackup = this.layer.toJson();
  }

  ok() :void{
    this.contextService.setCurrentAction('', []);
  }

  cancel() :void {
    this.layer.title = this.layerBackup.title;
    this.contextService.setCurrentAction('', []);
  }
}
