










/* eslint-disable class-methods-use-this */
import PluginComponent from '@/components/plugins/PluginComponent';
import LayerConfig from '@/models/map-context/LayerConfig';
import axios from 'axios';
import Component from 'vue-class-component';

import { Viewer, ViewerOptions } from 'mapillary-js';

@Component
export default class MapillaryPlugin extends PluginComponent {
    static ID='MapillaryPlugin';

    apiKey = '';

    viewer!:any;

    marker:any=null;

    layer:any;

    mounted():void {
      console.log('plugin mounted');
      this.apiKey = this.plugin.getParameterValue('api_key');
      document.body.insertAdjacentHTML('beforeend', '<div id="mly" style="width: 600px; height: 400px;position:absolute;top:200px;right:20px; pointer-events: none;"></div>');
    }

    onClickMapillary():void {
      if (this.viewer === undefined) {
        const options: ViewerOptions = {
          accessToken: this.apiKey,
          container: 'mly',
          component: { cover: false },
        };
        this.viewer = new Viewer(options);
        this.viewer.on('position', this.onPosition);
        console.log('plugin mounted');
        this.addMapillaryLayer();
      } else {
        this.clear();
      }
    }

    beforeDestroy(){
      if (this.viewer){
        this.clear();
      }
    }

    clear(){
        this.viewer.remove();
        this.viewer = undefined;
        this.getMapService().removeLayerFromList(this.layer);
        this.getMapService().removeLayerFromMap(this.layer);
    }

    onPosition():void{
      this.viewer.getPosition().then((pos:any) => {
        console.log(pos);
        this.marker = this.getMapService().addOverlay([pos.lng, pos.lat]);
      });
    }

    private addLayerToMap(newLayer: LayerConfig) {
      try {
        this.contextService.getCurrentContext().getLayers().unshift(newLayer);
        this.getMapService().addLayer(newLayer);
      } catch (error) {
        console.error(error);
      }
    }

    addMapillaryLayer():void {
      const at = this.apiKey;// 'MLY|5035089799946263|f73790daad65cba8bca9f3a3e422ebb1';
      const { viewer } = this;
      const renderFunction = (feature:any, popupDiv:any) => {
        let imageID = feature.get('image_id');
        if (imageID === undefined) {
          imageID = feature.get('id');
        }
        viewer.moveTo(imageID).catch((error:any) => console.warn(error));
        // eslint-disable-next-line no-param-reassign
        popupDiv.innerHTML = `<div style="width: 256px;height: 192px;padding-top: 61px;padding-left: 91px;"><div  style="width: 4rem; height: 4rem;" class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div></div>`;
        axios.get(`https://graph.mapillary.com/${imageID}?access_token=${at}&fields=thumb_1024_url,thumb_256_url,computed_rotation`).then((result:any) => {
          console.log(result);
          // this.imageURL = result.data.thumb_256_url;
          // eslint-disable-next-line no-param-reassign
          popupDiv.innerHTML = `<img src="${result.data.thumb_256_url}">`;
        });
      };
      this.layer = new LayerConfig({
        id: 'mapillary',
        layername: 'mapillary',
        url: `https://tiles.mapillary.com/maps/vtp/mly1_computed_public/2/{z}/{x}/{y}?access_token=${at}`,
        description: '',
        title: 'Mapillary',
        featureInfoContentRenderer: renderFunction,
        popupInfos: {
          content: renderFunction,
          active: false,
          edited: false,
        },
        style: {
          fillStyle: {
            fillSymbol: {},
            fillColor: 'rgba(15,222,111,0)',
            fillPattern: 'simple',
          },
          strokeStyle: {
            symbol: {}, color: 'rgba(15,222,111,1)', strokeWidth: '2', pattern: 'simple',
          },
          pointSymbol: { symbolStrokeColor: 'rgba(255,255,255,1)', symbolFillColor: 'rgba(15,222,111,1)' },
          textStyle: { text: '' },
        },
        protocol: 'TMS',
        type: 'VectorTile',
        visible: true,
        boundingBoxEPSG4326: [-175, -85, 175, 85],
        Attribution: { Title: '© Mapillary', OnlineResource: 'https://www.mapillary.com' },
      });
      this.addLayerToMap(this.layer);
    }
}
