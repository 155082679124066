





































import PluginComponent from '@/components/plugins/PluginComponent';
import LayerConfig from '@/models/map-context/LayerConfig';
import { EventBus, EVENTS } from '@/services/EventBus';
import AlertMessage from '@/models/shared/AlertMessage';
import { transformExtent } from 'ol/proj';
import axios from 'axios';
import Component from 'vue-class-component';
import FormTextInput from '../shared/FormTextInput.vue';

@Component({
  props: {
  },
  components: {
    FormTextInput,
  },
})
export default class OverPassPlugin extends PluginComponent {
    static ID='OverPassPlugin';

    config:any=null;

    request='';

    mounted():void {
      this.config = this.plugin.getParameterValue('config');
    }

    start():void{
      const datas = new FormData();
      const params = new URLSearchParams();
      params.append('data', '[out:json][timeout:25];(way["highway"](49.28597534861465,3.882851600646973,49.31169419614406,3.926839828491211);relation["building"](49.28597534861465,3.882851600646973,49.31169419614406,3.926839828491211););out meta;>;out meta qt;');
      console.log(this.request);
      datas.append('data', '%2F*%0AThis+query+looks+for+nodes%2C+ways+and+relations+%0Awith+the+given+key.%0AChoose+your+region+and+hit+the+Run+button+above!%0A*%2F%0A%5Bout%3Ajson%5D%2F*fixed+by+auto+repair*%2F%5Btimeout%3A25%5D%3B%0A%2F%2F+gather+results%0A(%0A++%2F%2F+query+part+for%3A+%E2%80%9Cbuilding%3D*%E2%80%9D%0A++way%5B%22highway%22%5D(49.28597534861465%2C3.882851600646973%2C49.31169419614406%2C3.926839828491211)%3B%0A++relation%5B%22building%22%5D(49.28597534861465%2C3.882851600646973%2C49.31169419614406%2C3.926839828491211)%3B%0A)%3B%0A%2F%2F+print+results%0Aout+meta%3B%2F*fixed+by+auto+repair*%2F%0A%3E%3B%0Aout+meta+qt%3B%2F*fixed+by+auto+repair*%2F');
      const request = axios.post('https://overpass-api.de/api/interpreter', params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
      request.then((resp:any) => {
        console.log(resp.data.elements);
        if (resp.data.elements) {
          resp.data.elements.forEach((element:any) => {
            this.processFeature(element);
          });
        }
      });
    }

    importer():void {
      // const featstring = (new GeoJSONFormat()).readFeatures(this.request);
      const newLayer = new LayerConfig({
        layername: '',
        title: 'Import Overpass',
        type: 'Vector',
        visible: true,
        boundingBoxEPSG4326: [-175, -85, 175, 85],
        jsonData: this.request,
        Attribution: { Title: '© OpenStreetMap',OnlineResource:'https://www.openstreetmap.org/copyright' },
        dataProjection: 'EPSG:4326',
      });

      try {
        this.contextService.getCurrentContext().getLayers().unshift(newLayer);
        this.getMapService().addLayer(newLayer);
        let extent = newLayer.getLayerSource().getExtent();
        extent = transformExtent(extent, this.getMapService().mapProjectionCode, 'EPSG:4326');
        newLayer.boundingBoxEPSG4326 = extent;
        this.getOpenLayersMapService().map.getView().fit(newLayer.getLayerSource().getExtent());
        const message = 'Les données ont bien été importées dans une couche nommée <b>"Import Overpass"</b>. <br>Vous pouvez la renommer et modifier le style des objets affichés depuis l\'onglet "Couche de données" ';
        EventBus.$emit(EVENTS.WARNING, new AlertMessage({
          label: message, countDown: 10, type: 'success', autoHide: false,
        }));
      } catch (error) {
      // this.error(error);
      }
    }

    processFeature(element: any):void {
      console.log(this.request);
      if (element.type === 'way') {
        // console.log(element);

      }
    }
}
