










































/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormTextInput from '@/components/shared/FormTextInput.vue';
import GeometryType from '@/models/map-context/GeometryType';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import BaseComponent from '../../BaseComponent';
import SingleStyleDefinition from '../style/SingleStyleDefinition.vue';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
    SingleStyleDefinition,
  },
})
export default class SingleLayerStyle extends BaseComponent {
  layer!: LayerConfig;

  errorTxt:string|null=null;
  
  styles=[];

  legendUrl='';
  
  geometryTypeList = [GeometryType.UNDEFINED,
    GeometryType.LINE, GeometryType.POINT, GeometryType.POLYGON];

  defaultSelectionColor ='';

  created(){
    this.defaultSelectionColor=this.getMapService().selectionService.defaultSelectionColor;
  }
  
  mounted() {
    console.log('Style');
    this.defaultSelectionColor=this.getMapService().selectionService.defaultSelectionColor;
    if(this.layer.styles 
      && this.layer.styles.length>0){
        this.styles=this.layer.styles;
      }
    
    if(
      (!this.layer.selectedStyle || this.layer.selectedStyle.length==0)
      && this.layer.styles 
      && this.layer.styles.length>0){
      this.layer.selectedStyle=this.layer.styles[0];
    }
    this.legendUrl=this.layer.getLegendImageUrl();

  }
  
  onChangeStyle(event: any){
    this.legendUrl=this.layer.getLegendImageUrl();
  }

  get geometryTypeNotDefined():boolean {
    return this.layer.geometryTypeNotDefined();
  }
}
