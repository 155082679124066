







































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-destructuring */
import BaseComponent from '@/components/BaseComponent';
import LayerConfig from '@/models/map-context/LayerConfig';
import Filter from '@/models/map-context/filter/Filter';
import AlertMessage from '@/models/shared/AlertMessage';
import { EVENTS, EventBus } from '@/services/EventBus';
import axios from 'axios';
import FileSaver from 'file-saver';
import { Feature } from 'ol';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { GeoJSON } from 'ol/format';
import WKT from 'ol/format/WKT';
import {
Geometry,
} from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { DragBox, Draw, Select } from 'ol/interaction';
import Modify from 'ol/interaction/Modify';
import VectorLayer from 'ol/layer/Vector';
import { Projection, addCoordinateTransforms, addProjection } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import {
Circle, Fill, Stroke, Style,
} from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import FormTextInput from '../shared/FormTextInput.vue';
import AddFilterPanel from './AddFilterPanel.vue';
import FeatureListTable from './FeatureListTable.vue';

@Component({
  props: {
    layer: LayerConfig
  },
  components: {
    AddFilterPanel,
    FormTextInput,
    FeatureListTable,
  },
})
export default class FeatureListPanel extends BaseComponent {
  layer!: LayerConfig;

  title = 'Liste des objets';

  attributes:any=[];

  currentPage = 1;

  perPage = 20;

  multipleSelection = false;

  maxFeatureLoaded = false;

  rows = 0;

  nbFeatureServerSide = -1;

  features:any=[];

  allFeatures:any=[];

  featureSource!:VectorSource<any>;

  selectionSource!:VectorSource<any>;

  olLayer!:any;

  loading = false;

  showAddFilterPanel = false;

  showFilterPanel = false;

  selectedFeatures:any=[];

  select!:Select;

  dragBox!:DragBox;

  modify:any;

  pagingInfos = '';

  selectForUpdate:any;

  defaultStyle = new Style({
    fill: new Fill({
      color: 'rgba(253,255,255,0.3)',
    }),
    stroke: new Stroke({
      color: '#FF0000',
      width: 2,
    }),
    image: new Circle({
      radius: 5,
      stroke: new Stroke({
        color: '#FF0000',
        lineDash: [],
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(253,255,255,0.3)',
      }),
    }),
  });

  selectStyle = new Style({
    fill: new Fill({
      color: 'rgba(253,255,154,0.2)',
    }),
    stroke: new Stroke({
      color: 'rgba(253,255,154,0.7)',
      width: 2,
    }),
    image: new Circle({
      radius: 7,
      stroke: new Stroke({
        color: 'rgba(253,255,154,0.7)',
        lineDash: [],
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(253,255,154,0.2)',
      }),
    }),
  });

  geomFilterSource:any;

  vector:any;

  draw:any;

  sortParameters:any;

  currentUrl = '';

  dismissCountDown = 0;

  @Watch('layer')
  updateTableLayer() {
    this.getOpenLayersMapService().map.getView().un('change', this.refreshWFS);
    this.onFeatureUnSelected(this.selectedFeatures, false);
    this.init();
  }

  init():void {
    this.refresh();
    EventBus.$emit(EVENTS.DISABLE_SELECTION);
    EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, '');
    addProjection(new Projection({
      code: 'WGS84',
      units: 'degrees',
    }));
    addCoordinateTransforms(
      'WGS84',
      'EPSG:4326',
      (coordinate) => [coordinate[1], coordinate[0]],
      (coordinate) => [coordinate[1], coordinate[0]],
    );
  }

  mounted():void {
    this.init();
  }

  removeFilters() {
    this.layer.tableFilter = '';
    this.refresh();
  }

  removeSpatialFilter() {
    this.layer.tableGeometricFilter = '';
    if (this.geomFilterSource) {
      this.geomFilterSource.clear();
    }

    this.refresh();
  }

  countDownChanged(dismissCountDown:any) {
    this.dismissCountDown = dismissCountDown;
  }

  refreshFromWFS() {
    console.log('refreshFromWFS');
    this.allFeatures = this.layer.getLayerSource().getFeatures();
    this.rows = this.allFeatures.length;
    if (this.allFeatures.length > this.perPage) {
      this.features = this.allFeatures.slice(0, this.perPage);
    } else {
      this.features = this.allFeatures;
    }
    this.refreshInfos();
  }

  refreshWFS() {
    console.log('refreshWFS');
    if (this.layer.isWFS()) {
      this.selectionSource.clear();
      this.selectionSource.once('featuresloadend', this.refreshFromWFS);
      this.selectionSource.refresh();
    }
  }

  refresh() {
    this.showFilterPanel = false;
    this.rows = 0;
    this.attributes = this.layer.layerProperties
      .filter((x) => x.name !== this.layer.getGeomAttributeName());
    this.perPage = 1000;
    if (this.layer.isWFS()) {
      this.selectionSource = this.layer.getLayerSource();
      const view = this.getOpenLayersMapService().map.getView();
      // Assuming "map" is your OpenLayers map object
      view.un('change', this.refreshWFS);
      view.on('change', this.refreshWFS);
      this.refreshWFS();
      this.addSelectInteraction();
    } else if (this.layer.isWMS() && this.layer.hasWFSCapacity()) {
      const urlGetFeature = this.layer.wmsUrlToWfs(this.layer.getFeatureWFSUrl());
      const maxFeatures = this.layer.maxFeatures;
      let url = `${urlGetFeature}&maxfeatures=${maxFeatures}&count=${maxFeatures}
        &typenames=${this.layer.layername}`
        + `&typename=${this.layer.layername}&outputFormat=json&srsname=EPSG:3857`;
      if (this.hasActiveFilter()) {
        url += `&CQL_FILTER=${encodeURIComponent(this.layer.getTableFilterValue())}`;
      }
      this.addVectorLayer();
      this.loading = true;
      this.currentUrl = url;
      axios.get(url).then((reponse:any) => {
        this.loading = false;
        this.allFeatures = new GeoJSON().readFeatures(reponse.data);
        if (reponse.data.numberMatched) {
          this.nbFeatureServerSide = reponse.data.numberMatched;
        }
        console.log(reponse.data);
        this.featureSource.addFeatures(this.allFeatures);
        this.rows = this.allFeatures.length;
        this.features = this.allFeatures.slice(0, this.perPage);
        this.getOpenLayersMapService().map.updateSize();
        this.refreshInfos();

        // this.getMapService().zoomToExtent(this.featureSource.getExtent());
        // voir s'il faut zoomer sur le resultat
      }, (error) => {
        this.loading = false;
        console.log(error);
        EventBus.$emit(EVENTS.ERROR, 'Problème de chargement des objets. Vérifiez que vous avez les droits de téléchargement sur cette couche');
      });
    } else {
      this.addVectorLayer();
      const extent = this.getMapService().getMapExtent();
      if (this.layer.getLayerSource().getFeatures) {
        this.allFeatures = this.layer.getLayerSource().getFeatures();
      } else {
        this.allFeatures = this.layer.getLayerSource().getFeaturesInExtent(extent);
      }
      if (this.hasActiveFilter()) {
        const filter = new Filter();
        console.log(this.layer.getTableFilterValue());
        filter.fromCQL(this.layer.getTableFilterValue());
        this.allFeatures = this.allFeatures.filter((x:any) => filter.evaluate(x));
      }
      this.featureSource.addFeatures(this.allFeatures);
      this.rows = this.allFeatures.length;
      this.features = this.allFeatures.slice(0, this.perPage);
      this.refreshInfos();
    }
  }

  addTableFilter(filter:any) {
    console.log(filter);
    if (this.layer.tableFilter && this.layer.tableFilter.length > 0) {
      this.layer.tableFilter = `(${this.layer.tableFilter} AND ${filter})`;
    } else {
      this.layer.tableFilter = filter;
    }

    this.showAddFilterPanel = false;
    this.refresh();
  }

  onClick(feature: Feature<any>) {
    if (!this.isSelected(feature)) {
      this.onFeatureSelected([feature], false);
    } else {
      this.onFeatureUnSelected([feature], false);
    }
  }

  onFeatureUnSelected(features:any, scrollIntoView:boolean) {
    const featureList = [...features];
    featureList.forEach((feature:Feature<any>) => {
      this.selectedFeatures
        .splice(this.selectedFeatures.indexOf(feature), 1);
      feature.setStyle(undefined);
    });
  }

  onFeatureSelected(features:any, scrollIntoView:boolean) {
    if (this.multipleSelection === false) {
      this.selectedFeatures.forEach((feature:Feature<any>) => {
        feature.setStyle(undefined);
      });
      this.selectedFeatures = [];
    }

    features.forEach((feature:Feature<any>) => {
      this.selectedFeatures.push(feature);
    });
    this.selectedFeatures.forEach((feature:Feature<any>) => {
      console.log(feature.getStyle());
      feature.setStyle(this.selectStyle);
      console.log(feature.getStyle());
    });

    // Si l'objet selectionné n'est pas dans la page courante, on change de page
    if (this.features.indexOf(this.selectedFeatures[0]) < 0) {
      const fIndex = this.getAllFeatures().indexOf(this.selectedFeatures[0]);
      if (fIndex >= 0) {
        const pageIndex = Math.floor(fIndex / this.perPage);
        console.log(Math.floor(fIndex / this.perPage));
        this.currentPage = pageIndex;
        this.pageChange(pageIndex + 1);
      }
    }
    // Ensuite on met en évidence l'objet dans le tableau
    const self = this;
    if (scrollIntoView) {
      setTimeout(() => {
        const el = self.$el.getElementsByClassName('selected')[0];
        if (el) {
          el.scrollIntoView(false);
        }
      }, 500);
    }
  }

  getAllFeatures() {
    let featureList = this.allFeatures;
    if (this.layer.isWFS()) {
      featureList = this.layer.getLayerSource().getFeatures();
    }
    if (this.sortParameters !== undefined) {
      featureList.sort(this.dynamicSort(this.sortParameters.attribute.name));
    }
    return featureList;
  }

  addGeometricFilter(filter:Geometry) {
    // eslint-disable-next-line no-underscore-dangle
    let _dataProjection = 'EPSG:2154';
    if (this.layer.dataProjection && this.layer.dataProjection.length > 0) {
      _dataProjection = this.layer.dataProjection;
    }
    console.log(filter);
    const reproj = filter.clone().transform('EPSG:3857', _dataProjection);

    let fProjection = _dataProjection;
    if (fProjection === 'EPSG:4326') {
      fProjection = 'WGS84'; // pour faire une inversion lat/lon => lon/lat
    }
    const geom = new WKT().writeGeometry(reproj,
      {
        featureProjection: fProjection,
        dataProjection: _dataProjection,
        decimals: 3,
      });
    console.log(filter);
    const geomAttribute = this.layer.getGeomAttributeName();
    const geomFilter = `INTERSECTS(${geomAttribute} , ${geom})`;
    this.layer.tableGeometricFilter = geomFilter;
    this.showAddFilterPanel = false;
  }

  download(format:string, extension:string) {
    FileSaver.saveAs(this.currentUrl.replace('outputFormat=json', `outputFormat=${format}`), `${this.layer.title}_export.${extension}`);
  }

  addPolygoneFilter() {
    EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, '');
    const message = 'Dessinez un polygone sur la carte pour définir un filtre spatial (double clic pour terminer la géométrie).<br> <b>Astuces :</b> <ul><li>Maintenez la touche shift enfoncée pour faire un dessin à main levée</li><li>Une fois le polygone dessiné il reste affiché et modifiable en cliquant sur son coutour</li><ul>';
    EventBus.$emit(EVENTS.WARNING, new AlertMessage({ label: message, type: 'success', autoHide: false }));
    this.addInteractionLayer();
    this.addInteraction('Polygon');
  }

  hasActiveFilter():boolean {
    return (this.layer.filter !== undefined && this.layer.filter.length > 0)
      || (this.layer.tableFilter !== undefined && this.layer.tableFilter.length > 0)
      || (this.layer.geometricFilter !== undefined && this.layer.geometricFilter.length > 0)
      || (this.layer.tableGeometricFilter !== undefined
        && this.layer.tableGeometricFilter.length > 0);
  }

  addPointFilter() {
    EventBus.$emit(EVENTS.OPEN_LEFT_SIDE_MENU, '');
    EventBus.$emit(EVENTS.INFO, 'Placez un point sur la carte pour définir un filtre spatial');
    this.addInteractionLayer();
    this.addInteraction('Point');
  }

  private removeDrawInteraction() {
    this.getOpenLayersMapService().map.removeInteraction(this.draw);
  }

  private addInteractionLayer() {
    EventBus.$emit(EVENTS.DISABLE_SELECTION);
    this.geomFilterSource = new VectorSource();
    if (this.vector != null) {
      this.getOpenLayersMapService().map.removeLayer(this.vector);
    }
    this.vector = new VectorLayer({
      source: this.geomFilterSource,
      zIndex: 1000,
      style: new Style({
        stroke: new Stroke({
          color: 'rgba(129, 219, 153,1)',
          width: 4,
        }),
      }),
    });

    this.getOpenLayersMapService().map.addLayer(this.vector);
  }

  private addInteraction(type:Type) {
    this.draw = new Draw({
      source: this.geomFilterSource,
      type,
      style: new Style({
        fill: new Fill({
          color: 'rgba(230, 230, 230, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(129, 219, 153,0.5)',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(95, 122, 103,0.5)',
          }),
          fill: new Fill({
            color: 'rgba(230, 230, 230, 0.2)',
          }),
        }),
      }),
    });

    this.getOpenLayersMapService().map.addInteraction(this.draw);

    this.selectForUpdate = new Select({
      // style: this.drawStyle
      layers: [this.vector],
    });
    this.modify = new Modify({
      // style: this.drawStyle,
      features: this.selectForUpdate.getFeatures(),
    });
    this.getOpenLayersMapService().map.addInteraction(this.selectForUpdate);
    this.getOpenLayersMapService().map.addInteraction(this.modify);

    const self = this;
    console.log('end');
    this.draw.on('drawend', (event:any) => {
      EventBus.$emit(EVENTS.INFO, '');
      console.log(event.feature.getGeometry());
      self.addGeometricFilter(event.feature.getGeometry());
      self.removeDrawInteraction();
      this.refresh();
    });

    this.modify.on('modifyend', (evt: any) => {
      const feature = evt.features.getArray()[0];
      self.addGeometricFilter(feature.getGeometry());
      this.refresh();
    });
  }

  applyFilters() {
    this.refresh();
  }

  refreshInfos() {
    this.maxFeatureLoaded = this.rows === this.layer.maxFeatures;
    if (this.maxFeatureLoaded) {
      this.dismissCountDown = 10;
      const message = `<i>Nombre maximum d'objets atteint pour cette couche <b>(${this.layer.maxFeatures} sur ${this.nbFeatureServerSide})</b>,
        Vous pouvez rajouter un filtre ou augmenter la limite dans la configuration de la couche</i>`;
      EventBus.$emit(EVENTS.WARNING, new AlertMessage({ label: message, autoHide: true }));
    }
    this.title = this.layer.title || '';
    if (this.nbFeatureServerSide >= 0 && this.rows !== this.nbFeatureServerSide) {
      this.pagingInfos = `Carte: ${this.rows} résultats sur ${this.nbFeatureServerSide}`;
    } else {
      this.pagingInfos = `Carte: ${this.rows} résultats`;
    }
  }

  close() {
    this.contextService.tablelayer = null;
  }

  beforeDestroy() {
    this.removeSelectInteraction();
    // unselect all features
    this.onFeatureUnSelected(this.selectedFeatures, false);
    EventBus.$emit(EVENTS.ENABLE_SELECTION);
    this.layer.tableFilter = '';
    this.layer.tableGeometricFilter = '';

    if (this.featureSource) {
      this.featureSource.clear();
      this.getOpenLayersMapService().map.removeLayer(this.olLayer);
    }
    if (this.vector != null) {
      this.getOpenLayersMapService().map.removeLayer(this.vector);
    }

    setTimeout(() => {
      this.getOpenLayersMapService().map.updateSize();
    }, 1000);
  }

  addVectorLayer() {
    if (!this.featureSource) {
      this.featureSource = new VectorSource();
      this.selectionSource = this.featureSource;
      this.olLayer = new VectorLayer({
        source: this.featureSource,
        style: this.defaultStyle,
      });
      this.olLayer.setZIndex(80);
      this.getOpenLayersMapService().map.addLayer(this.olLayer);
      this.addSelectInteraction();
    } else {
      this.featureSource.clear();
    }
  }

  removeSelectInteraction() {
    if (this.select) {
      this.getOpenLayersMapService().map.removeInteraction(this.select);
      this.getOpenLayersMapService().map.removeInteraction(this.dragBox);
    }
  }

  addSelectInteraction() {
    if (!this.select) {
      this.select = new Select({
        style: this.selectStyle,
      });
      this.getOpenLayersMapService().map.addInteraction(this.select);
      const self = this;
      this.select.on('select', (e:any) => {
        const features = e.target.getFeatures();
        if (features.getLength() > 0) {
          self.onFeatureSelected(features, true);
        }
      });

      this.dragBox = new DragBox({
        condition: platformModifierKeyOnly,
      });
      this.getOpenLayersMapService().map.addInteraction(this.dragBox);
      this.dragBox.on('boxend', (e:any) => {
        const extent = this.dragBox.getGeometry().getExtent();
        const boxFeatures = this.selectionSource
          .getFeaturesInExtent(extent)
          .filter((feature:any) => feature.getGeometry().intersectsExtent(extent));

        // features that intersect the box geometry are added to the
        // collection of selected features

        // if the view is not obliquely rotated the box geometry and
        // its extent are equalivalent so intersecting features can
        // be added directly to the collection
        const rotation = this.getOpenLayersMapService().map.getView().getRotation();
        const oblique = rotation % (Math.PI / 2) !== 0;

        // when the view is obliquely rotated the box extent will
        // exceed its geometry so both the box and the candidate
        // feature geometries are rotated around a common anchor
        // to confirm that, with the box geometry aligned with its
        // extent, the geometries intersect
        if (oblique) {
          const anchor = [0, 0];
          const geometry = this.dragBox.getGeometry().clone();
          geometry.rotate(-rotation, anchor);
          const extentG = geometry.getExtent();
          boxFeatures.forEach((feature:any) => {
            const geometryG = feature.getGeometry().clone();
            geometryG.rotate(-rotation, anchor);
            if (geometryG.intersectsExtent(extentG)) {
              self.onFeatureSelected([feature], true);
            }
          });
        } else {
          // selectedFeatures.extend(boxFeatures);
          self.onFeatureSelected(boxFeatures, true);
        }
      });
    }
  }

  getLabel(k:any):string {
    if (k.label) return k.label;
    return k.name;
  }

  dynamicSort(property:string) {
    let sortOrder = 1;
    if (this.sortParameters.ascending === false) {
      sortOrder = -1;
    }
    return function (a:any, b:any) {
      let aProp = a.getProperties()[property];
      if (aProp == null || aProp === undefined) { aProp = ''; }
      let bProp = b.getProperties()[property];
      if (bProp == null || bProp === undefined) { bProp = ''; }
      // eslint-disable-next-line no-nested-ternary
      const result = (aProp < bProp) ? -1
        : (aProp > bProp) ? 1 : 0;
      return result * sortOrder;
    };
  }

  onSortChange(event:any) {
    this.sortParameters = event;
    console.log(this.sortParameters);
    this.pageChange(this.currentPage);
  }

  pageChange(event:number) {
    console.log(event);
    const page = event - 1;
    const featureSource = this.getAllFeatures();
    this.features = featureSource
      .slice(page * this.perPage, page * this.perPage + this.perPage);
  }

  isSelected(feature: any):boolean {
    return this.selectedFeatures.indexOf(feature) >= 0;
  }
}
