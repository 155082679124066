import { EventBus, EVENTS } from '@/services/EventBus';
import PluginConfig from './PluginConfig';
import PluginParameter from './parameter/PluginParameter';
import { PluginJsonConfig } from './PluginJsonConfig';
import { ParameterType } from './ParameterType';

export default class Plugin {
    id: string;

    label: string;

    description: string;

    componentName: string;

    parameters: PluginParameter<any>[];

    configuration: PluginConfig;

    defaultConfiguration: PluginConfig;

    constructor(id: string, label: string, description: string, componentName:string,
      parameters: PluginParameter<any>[],
      defaultConfiguration: PluginConfig) {
      this.id = id;
      this.label = label;
      this.description = description;
      this.componentName = componentName;
      this.parameters = parameters;
      this.defaultConfiguration = defaultConfiguration;
      this.configuration = defaultConfiguration.clone();
    }

    getParameterValue(parameterId:string) :any {
      const searchParam = this.parameters
        .filter((param:PluginParameter<any>) => param.id === parameterId);
      if (searchParam && searchParam.length === 1) {
        if (searchParam[0].type === ParameterType.Json && typeof searchParam[0].value === 'string') {
          return JSON.parse(searchParam[0].value);
        }
        return searchParam[0].value;
      }
      return null;
    }

    toJson(): PluginJsonConfig {
      return {
        plugin: this.id,
        configuration: { isActive: this.isActive },
        parameters: this.parameters.map((p) => ({
          id: p.id,
          value: p.value,
        })),
      };
    }

    init():void {
      const cssParameterValue = this.getParameterValue('css');
      if (cssParameterValue != null) {
        const styleSheet = document.createElement('style');
        styleSheet.innerText = cssParameterValue;
        document.head.appendChild(styleSheet);
      }
    }

    public set isActive(value: boolean) {
      if (this.configuration.active !== value) {
        this.configuration.active = value;
        EventBus.$emit(EVENTS.PLUGIN_ACTIVATION_CHANGE, this);
      }
    }

    public get isActive(): boolean {
      return this.configuration.active;
    }

    public get isOnly3D(): boolean {
      return this.configuration.only3d;
    }

    reset(): void {
      this.configuration = this.defaultConfiguration.clone();
    }

    fromJson(data: PluginJsonConfig):void {
      this.reset();
      if (data && data.plugin === this.id) {
        this.configuration.fromJson(data.configuration);
        this.parameters.forEach((p:PluginParameter<any>) => {
          const targetParamData = data.parameters.find((pp) => pp.id === p.id);
          if (targetParamData && targetParamData.value !== undefined) {
            p.setValue(targetParamData.value);
          }
        });
      }
    }
}
