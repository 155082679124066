/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
import WMSCapabilities from 'ol/format/WMSCapabilities';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import { transformExtent } from 'ol/proj';
import { boundingExtent } from 'ol/extent';
import { ICapabilitiesResponse, OGCLayer } from './RemoteServer';

export default class CapabilitiesParser {
  parseWMC(data: string) {
    const doc = new DOMParser().parseFromString(data, 'text/xml');
    const wmc = this.xmlToJson(doc);
    console.log('wmc', wmc);
    const mapcontext = {
      projection: 'EPSG:3857',
      version: '1.0',
      bbox: {
        minx: parseFloat(wmc.ViewContext.General.BoundingBox['@attributes'].minx),
        miny: parseFloat(wmc.ViewContext.General.BoundingBox['@attributes'].miny),
        maxx: parseFloat(wmc.ViewContext.General.BoundingBox['@attributes'].maxx),
        maxy: parseFloat(wmc.ViewContext.General.BoundingBox['@attributes'].maxy),
        srs: wmc.ViewContext.General.BoundingBox['@attributes'].SRS,
      },
      layers: [],
    };
    console.log(wmc.ViewContext.LayerList.Layer);
    let counter = 0;
    wmc.ViewContext.LayerList.Layer.forEach((element:any) => {
      counter += 1;
      console.log(element);
      const bboxElem = element.Extension['ol:maxExtent']['@attributes'];
      let ext = boundingExtent([[Number(bboxElem.minx),
        Number(bboxElem.miny)],
      [Number(bboxElem.maxx), Number(bboxElem.maxy)]]);
      ext = transformExtent(ext, 'EPSG:2154',
        // mapcontext.bbox.srs,
        'EPSG:4326');

      const layer:any = {
        layername: element.Name['#text'],
        layerIndex: counter,
        title: element.Title['#text'],
        url: element.Server.OnlineResource['@attributes']['xlink:href'],
        type: 'WMS',
        boundingBoxEPSG4326: ext,
        isLegendDisplayed: true,
        visible: element['@attributes'].hidden == 0,
        queryable: element['@attributes'].queryable != 0,
      };
      console.log(layer);
      if (element.Abstract) {
        layer.description = element.Abstract['#text'];
      }
      if (element.MetadataURL) {
        layer.metadataUrl = element.MetadataURL.OnlineResource['@attributes']['xlink:href'];
      }
      if (element.Extension['ol:attribution']) {
        layer.Attribution = {
          Title: element.Extension['ol:attribution'].Title['#text'],
        };
        if (element.Extension['ol:attribution'].OnlineResource) {
          layer.Attribution.OnlineResource = element.Extension['ol:attribution'].OnlineResource['@attributes']['xlink:href'];
        }
      }
      if (element['sld:MaxScaleDenominator']) {
        layer.maxScaleDenominator = Number(parseFloat(element['sld:MaxScaleDenominator']['#text']).toFixed(0));
      }
      if (element['sld:MinScaleDenominator']) {
        layer.minScaleDenominator = Number(parseFloat(element['sld:MinScaleDenominator']['#text']).toFixed(0));
      }
      (<any>mapcontext.layers).unshift(layer);
    });
    return mapcontext;
  }

  parseDescribeFeature(data: string) {
    console.log(data);
    const doc = new DOMParser().parseFromString(data, 'text/xml');
    const capabilities = this.xmlToJson(doc);
    console.log('Describe feature', capabilities);
    return capabilities;
  }

  parseRecords(data: string, url: string): any {
    console.log(data);
    data = data.replace(/csw:/g, '');
    const doc = new DOMParser().parseFromString(data, 'text/xml');

    const capabilitiesFromXML = this.xmlToJson(doc);
    console.log(capabilitiesFromXML.GetRecordsResponse.SearchResults.Record);

    const nbTotalResults = Number(
      capabilitiesFromXML.GetRecordsResponse.SearchResults['@attributes']
        .numberOfRecordsMatched,
    );
    const nextRecord = Number(
      capabilitiesFromXML.GetRecordsResponse.SearchResults['@attributes']
        .nextRecord,
    );
    let layers:any = [];
    if (nbTotalResults == 0) {
      return {
        nbTotalResults,
        nextRecord,
        layers,
      };
    }
    layers = capabilitiesFromXML.GetRecordsResponse.SearchResults.Record.filter(
      (x:any) => {
        // filter record that don't contain WMS layer name or url
        if (!Array.isArray(x['dc:URI'])) {
          x['dc:URI'] = [x['dc:URI']];
        }
        if (!x['dc:URI']) {
          return false;
        }
        const wmsRessource = x['dc:URI'].find(
          (uri:any) => uri
                  && uri['@attributes']
                  && uri['@attributes'].protocol
                  && uri['@attributes'].protocol.indexOf('OGC:WMS') >= 0,
        );
        return (
          wmsRessource != undefined
                  && wmsRessource['#text'].indexOf('application.i2') < 0
                  && wmsRessource['@attributes'].name
                  && wmsRessource['@attributes'].name.indexOf(' ') < 0
                  && wmsRessource['@attributes'].name.length > 0
        );
      },
    ).map((x:any) => {
      if (!Array.isArray(x['dc:URI'])) {
        x['dc:URI'] = [x['dc:URI']];
      }
      const wmsRessource = x['dc:URI'].find(
        (uri:any) => uri['@attributes']
              && uri['@attributes'].protocol
              && uri['@attributes'].protocol.indexOf('OGC:WMS') >= 0,
      );
      const recordUrl = wmsRessource['#text'];
      let name = wmsRessource['@attributes'].name;
      let title = x['dc:title']['#text'];
      if (recordUrl.indexOf('&layers') > 0) {
        // GeoIDe Case
        const sp = new URLSearchParams(recordUrl);
        console.log(sp);
        if (!title) {
          title = name;
        }
        name = sp.get('layers');
      }
      console.log(wmsRessource);

      const bottomLeft = x['ows:BoundingBox']['ows:LowerCorner']['#text'].split('\ ');
      const upperRight = x['ows:BoundingBox']['ows:UpperCorner']['#text'].split('\ ');
      const retour = {
        Name: name,
        Url: recordUrl,
        Type: 'WMS',
        Title: title,
        ThumbnailUrl: undefined,
        EX_GeographicBoundingBox: [Number(bottomLeft[0]), Number(bottomLeft[1]), Number(upperRight[0]), Number(upperRight[1])],
        Abstract: x['dc:description']['#text'],
      };
      console.log(retour.Name);

      const thumbnail = x['dc:URI'].find(
        (uri:any) => uri['@attributes']
              && uri['@attributes'].name
              && uri['@attributes'].name.indexOf('thumbnail') >= 0,
      );
      if (thumbnail) {
        retour.ThumbnailUrl = thumbnail['#text'];
      }
      return retour;
    });

    return {
      nbTotalResults,
      nextRecord,
      layers,
    };
  }

  parse(data: string, url: string): ICapabilitiesResponse {
    data = data.replace(/https:\/\/www.opengis/g, 'http://www.opengis');
    data = data.replace(/https:\/\/www.w3/g, 'http://www.w3');
    let capabilities;
    let capabilitiesFromXML;
    if (data.indexOf('WFS_Capabilities') > 0) {
      const doc = new DOMParser().parseFromString(data, 'text/xml');
      capabilities = this.xmlToJson(doc);
      capabilities.type = 'WFS';
      this.addWfsInfosLayer(capabilities);
    } else {
      const doc = new DOMParser().parseFromString(data, 'text/xml');
      capabilitiesFromXML = this.xmlToJson(doc);
      if (data.indexOf('OGC WMTS') > 0) {
        capabilities = new WMTSCapabilities().read(data);
        capabilities.type = 'WMTS';
        capabilities.layers = capabilities.Contents.Layer;
      } else if(data.indexOf('<Service') > 0) {
        capabilities = new WMSCapabilities().read(data);
        capabilities.type = 'WMS';
        this.addWmsInfosLayer(capabilities, capabilitiesFromXML, data);
      }
    }
    return capabilities;
  }

  private addWfsInfosLayer(capa: any) {
    console.log(capa);
    let rootCapa = capa.WFS_Capabilities;
    if (capa['wfs:WFS_Capabilities']) {
      rootCapa = capa['wfs:WFS_Capabilities'];
    }
    const { version } = rootCapa['@attributes'];
    const formats = this.getWfsFormats(version, rootCapa);

    let outputFormat = formats[0];
    if (formats.indexOf('application/json') >= 0) {
      outputFormat = 'application/json';
    } else if (formats.indexOf('GEOJSON') >= 0) {
      outputFormat = 'GEOJSON';
    } else if (formats.indexOf('application/json; subtype=geojson') >= 0) {
      outputFormat = 'application/json; subtype=geojson';
    }

    const featuresList = rootCapa.FeatureTypeList ? rootCapa.FeatureTypeList : rootCapa['wfs:FeatureTypeList'];

    let features = featuresList.FeatureType ? featuresList.FeatureType : featuresList['wfs:FeatureType'];
    if (!Array.isArray(features)) {
      features = [features];
    }
    capa.layers = [];
    features.forEach((element: any) => {
      let bottomLeft; let upperRight; let abstract; let title; let
        name;
      let otherProj = [];
      if (version == '1.0.0') {
        bottomLeft = [element.LatLongBoundingBox['@attributes'].minx, element.LatLongBoundingBox['@attributes'].miny];
        upperRight = [element.LatLongBoundingBox['@attributes'].maxx, element.LatLongBoundingBox['@attributes'].maxy];

        abstract = element.Abstract ? element.Abstract['#text'] : '';
        title = element.Title ? element.Title['#text'] : element['wfs:Title']['#text'];
        name = element.Name ? element.Name['#text'] : element['wfs:Name']['#text'];
      } else {
        bottomLeft = element['ows:WGS84BoundingBox']['ows:LowerCorner']['#text'].split('\ ');
        upperRight = element['ows:WGS84BoundingBox']['ows:UpperCorner']['#text'].split('\ ');
        // console.log(bottomLeft);
        abstract = element.Abstract ? element.Abstract['#text'] : '';
        title = element.Title ? element.Title['#text'] : element['wfs:Title']['#text'];
        name = element.Name ? element.Name['#text'] : element['wfs:Name']['#text'];
        const defaultProjection = element.DefaultCRS ? element.DefaultCRS['#text'] : element['wfs:DefaultCRS']['#text'];
        if (element.OtherCRS) {
          otherProj = element.OtherCRS.map((x:any) => x['#text']);
        } else if (element['wfs:OtherCRS']) {
          otherProj = element['wfs:OtherCRS'].map((x:any) => x['#text']);
        }
        otherProj.push(defaultProjection);
        otherProj = otherProj.map((x:any) => x.replace('urn:ogc:def:crs:', '').replace('EPSG::', 'EPSG:'));
      }

      capa.layers.push({
        Abstract: abstract,
        Title: title,
        AllowedFormats: formats,
        outputFormat,
        CRS: otherProj,
        Name: name,
        EX_GeographicBoundingBox: [Number(bottomLeft[0]), Number(bottomLeft[1]), Number(upperRight[0]), Number(upperRight[1])],
      });
    });
  }

  private getWfsFormats(version: any, rootCapa: any) :string[] {
    let formats: any = ['text/xml'];

    if (version == '1.0.0') {
      formats = Object.keys(rootCapa.Capability.Request.GetFeature.ResultFormat).filter((x) => {
        console.log(x);
        return x.indexOf('#') < 0;
      });
      console.log(formats);
    } else {
      const getFeatureElement = rootCapa['ows:OperationsMetadata']['ows:Operation'].filter((x: any) => x['@attributes'].name == 'GetFeature');

      if (getFeatureElement) {
        formats = getFeatureElement[0]['ows:Parameter'];
        if (Array.isArray(formats)) {
          formats = formats.filter((x) => x['@attributes'].name == 'outputFormat')[0];
        }

        if (version == '1.1.0') {
          formats = formats['ows:Value'];
        } else {
          formats = formats['ows:AllowedValues']['ows:Value'];
        }

        if (Array.isArray(formats)) {
          formats = formats.map((x) => x['#text']);
        } else {
          formats = [formats['#text']];
        }
      }
    }
    return formats;
  }

  private addWmsInfosLayer(capa: any, xmlCapabilities: any, rawData:string) {
    console.log(xmlCapabilities);
    if (capa.Capability.Layer.Layer) {
      capa.layers = capa.Capability.Layer.Layer;
    } else {
      capa.layers = [];
    }
    const isWMS111 = xmlCapabilities.WMT_MS_Capabilities && capa.version == '1.1.1';
    const isMapServer = rawData.indexOf('MapServer version') > 0;

    // add workspace prefix to layer name if not present
    // si le layerName n'a pas de préfix mais que son style à un prefix, on le récupère
    // Ceci arrive quand on interoge le capabilities a partir du workspace et pas du service wms racine
    // par ex: geoserver/rte/wms?request=GetCapabilities
    capa.layers.forEach((element: OGCLayer) => {
      element.status = '';

      // ce hack pour récupérer le nom du workspace a partir du style ne fonctionne pas avec mapserver
      if (!isMapServer && element.Style
        && element.Style.length > 0
        && element.Name.indexOf(':') < 0
        && element.Style[0].Name.indexOf(':') > 0) {
        element.Name = `${element.Style[0].Name.split(':')[0]}:${element.Name}`;
      }
    });
    if (isWMS111) {
      const layers = xmlCapabilities.WMT_MS_Capabilities[1].Capability.Layer.Layer;
      capa.layers.forEach((element: any, index: number) => {
        if (element.EX_GeographicBoundingBox == undefined) {
          let coords;
          if (layers[index].LatLonBoundingBox) {
            coords = layers[index].LatLonBoundingBox['@attributes'];
          } else {
            if (Array.isArray(layers[index].BoundingBox)) {
              coords = layers[index].BoundingBox[0]['@attributes'];
            } else {
              coords = layers[index].BoundingBox['@attributes'];
            }
            if (coords.SRS != 'EPSG:4326') {
              coords = {
                minx: -179, miny: -89, maxx: 179, maxy: 90,
              };
            }
          }

          element.EX_GeographicBoundingBox = [Number(coords.minx), Number(coords.miny), Number(coords.maxx), Number(coords.maxy)];
        }
      });
    }
  }

  xmlToJson(xml: any) {
    let obj: any = {};

    if (xml.nodeType == 1) { // element
      if (xml.attributes.length > 0) {
        obj['@attributes'] = {};
        for (let j = 0; j < xml.attributes.length; j += 1) {
          const attribute = xml.attributes.item(j);
          obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) { // text
      obj = xml.nodeValue;
    }

    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i += 1) {
        const item = xml.childNodes.item(i);
        const { nodeName } = item;
        if (typeof (obj[nodeName]) == 'undefined') {
          obj[nodeName] = this.xmlToJson(item);
        } else {
          if (typeof (obj[nodeName].push) == 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJson(item));
        }
      }
    }
    return obj;
  }
}
