





import Component from 'vue-class-component';
import PluginComponent from '@/components/plugins/PluginComponent';
import { ScaleLine } from 'ol/control';
import MapService from '@/services/MapService';

@Component({
  props: {
  },
  components: {
  },
})
export default class ScalePlugin extends PluginComponent {
  scaleLine!:ScaleLine;

  mounted():void {
    if (this.getMapService() instanceof MapService) {
      this.addScale();
    }
  }

  beforeDestroy():void {
    if (this.getMapService() instanceof MapService) {
      if (this.getOpenLayersMapService().map) {
        this.getOpenLayersMapService().map.removeControl(this.scaleLine);
      }
    }
  }

  addScale():void {
    const el:any = this.$refs.scaleLine;
    this.scaleLine = new ScaleLine(
      {
        target: el,
        bar: this.plugin.getParameterValue('mode'),
        steps: this.plugin.getParameterValue('nbBars'),
      },
    );
    this.getOpenLayersMapService().map.addControl(this.scaleLine);
  }
}
