















/* eslint-disable class-methods-use-this */
import PluginComponent from '@/components/plugins/PluginComponent';
import Component from 'vue-class-component';
import LayerConfig from '@/models/map-context/LayerConfig';


@Component
export default class LocationPlugin extends PluginComponent {
  static ID='LocationPlugin';
  
  timeLoc = 10*1000;

  layer!:LayerConfig;

  type = 'ADD_POINT '
  
  loc = [0,0]

  lon = 0
  
  lat = 0
    
  marker:any = null;

  running = false;

  timeoutId = 0;

  //zoomlevel = 16
  mounted(){
    this.timeLoc = this.plugin.getParameterValue('timeLocation')*1000;
  }
  onClick():void {
    this.running = !this.running;
    this.mainloopLocation();
  }
  mainloopLocation(){
    if (this.running) {
        this.setPoint()
        this.timeoutId = setTimeout(this.mainloopLocation, this.timeLoc);
    }
    else{
      this.getMapService().addOverlay([0,0]);
      clearTimeout(this.timeoutId)

    }
  }

  getPosition() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const loc = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          resolve(loc);
        },
        (error) => {
          console.error("Erreur de géolocalisation : ", error.message);
          reject(error);
        }
      );
    } else {
      console.error("La géolocalisation n'est pas prise en charge par ce navigateur.");
      reject(new Error("La géolocalisation n'est pas prise en charge par ce navigateur."));
    }
  });
}

setPoint (){
  this.getPosition()
  .then((loc:any) => {
    this.loc = [loc.longitude,loc.latitude]
    if(loc.latitude){
      this.marker=this.getMapService().addOverlay(this.loc);
      this.lon = loc.longitude
      this.lat = loc.latitude
      this.getMapService().panTo(this.loc,this.lon,this.lat)
    }
  })
  .catch((error:any) => {
    console.error(error);
  });
}

  beforeDestroy() {
    if(this.timeoutId!=0){
      clearTimeout(this.timeoutId);
    }
    
  }


}


