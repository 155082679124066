

















































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import GroupLayerElement from '@/components/layers/GroupLayerElement.vue';
import SingleLayerElement from '@/components/layers/SingleLayerElement.vue';
import AbstractLayerTreeElement from '@/models/map-context/AbstractLayerTreeElement';
import LayerConfig from '@/models/map-context/LayerConfig';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import EventBus, { EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';
import PluginComponent from '../plugins/PluginComponent';

@Component({
  props: {
  },
  components: {
    GroupLayerElement,
    SingleLayerElement,
  },
})
export default class LayerListPanel extends PluginComponent {
  static ID='LayerListPanel';


  showAddLayerBtn = true;

  created() {
    console.log(this.contextService.currentContext);
  }

  mounted() {
    this.showAddLayerBtn = this.contextService.configuration.addLayers !== undefined;
    this.showAddLayerBtn = this.showAddLayerBtn && this.plugin && this.plugin.getParameterValue('showAddLayerBtn');
  }

  getLayersAndGroups():AbstractLayerTreeElement[] {
    return this.contextService.getCurrentContext().layers;
  }

  addLayer() {
    this.contextService.setCurrentAction('addLayer', {});
  }

  addGroupLayerElement() {
    this.contextService.getCurrentContext().getLayers().unshift(new LayerGroupConfig({
      title: 'Nouveau Groupe',
      childs: [],
    }));
  }
  pasteLayer(){
    let newLayer = new LayerConfig(this.contextService.clipboard);
    this.addLayerToMap(newLayer);
    this.contextService.clearClipBoad();
  }

  private addLayerToMap(newLayer: LayerConfig) {
    try {
      this.contextService.getCurrentContext().getLayers().unshift(newLayer);
      this.getMapService().addLayer(newLayer);
    } catch (error:any) {
      newLayer.addError(error);
      EventBus.$emit(EVENTS.ERROR, error);
    }
  }
  
  addVectorLayer() {
    const newLayer:LayerConfig = new LayerConfig({
      title: 'Nouveau Dessin',
      type: 'Vector',
      visible: true,
      editable: true,
      layerProperties: [],
      boundingBoxEPSG4326: [-180, -90, 180, 90],
    });

    console.log(newLayer);
    this.addLayerToMap(newLayer);
  }
}
