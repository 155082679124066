

























/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormHtmlEditor from '@/components/shared/FormHtmlEditor.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
    FormHtmlEditor
  },
})
export default class SingleLayerPopupInfos extends BaseComponent {
  layer!: LayerConfig;

  errorTxt:string|null=null;

  editorLoaded = false;

  mounted() {
    // this.editor = ClassicEditor;
    console.log('SingleLayerPopupInfos');
    if (this.layer.popupInfos.active) {
      this.editorLoaded = true;
    }
  }

  onChange() {
    if (this.layer.popupInfos.active && (this.layer.popupInfos.content === '' || this.layer.popupInfos.content === undefined)) {
      this.initInfoBulles();
    }
    this.editorLoaded = true;
  }

  initInfoBulles() {
    this.layer.popupInfos.content = '';
    if (this.layer.layerProperties) {
      for (let i = 0; i < this.layer.layerProperties.length; i += 1) {
        this.layer.popupInfos.content += `<p>${this.layer.layerProperties[i].name}:{${this.layer.layerProperties[i].name}}</p>`;
      }
    }
  }
}
