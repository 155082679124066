

























































































































































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import AddFilterPanel from '@/components/layers/AddFilterPanel.vue';
import AuthenticationInfoPanel from '@/components/shared/AuthenticationInfoPanel.vue';
import FormNumberInput from '@/components/shared/FormNumberInput.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import GeometryType from '@/models/map-context/GeometryType';
import LayerConfig from '@/models/map-context/LayerConfig';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    layer: LayerConfig,
  },
  components: {
    FormTextInput,
    FormNumberInput,
    AuthenticationInfoPanel,
    AddFilterPanel,
  },
})
export default class SingleLayerMainInfos extends BaseComponent {
  layer!: LayerConfig;

  showAuthInfos = false;

  showFilterPanel = false;

  is3Dtiles = false;

  geometryTypeList = [GeometryType.UNDEFINED,
    GeometryType.LINE, GeometryType.POINT, GeometryType.POLYGON];

  altitudeTypeList = [
    { display: 'OnTerrain', value: 'onterrain' },
    { display: 'Relative To Ground', value: 'relativeground' },
    { display: 'Absolute', value: 'absolute' }];

  addFilter(filter:string) :void{
    if (this.layer.filter && this.layer.filter.length > 0) {
      this.layer.filter = `(${this.layer.filter} AND ${filter})`;
    } else {
      this.layer.filter = filter;
    }

    this.showFilterPanel = false;
  }

  useMapBounds() {
    const mapExtent = this.getMapService().getMapBoundsWGS84();
    console.log(mapExtent);
    this.layer.boundingBoxEPSG4326 = mapExtent;
  }

  mounted() {
    if (this.layer.type.indexOf('3DTiles') >= 0) {
      this.is3Dtiles = true;
    }
  }

  get scaleZMin():string {
    return `${this.layer.getScaleZMin()}`;
  }

  set scaleZMin(scale: string) {
    this.layer.setScaleZMin(parseFloat(scale));
  }

  get scaleZMax():string {
    return `${this.layer.getScaleZMax()}`;
  }

  set scaleZMax(scale: string) {
    this.layer.setScaleZMax(parseFloat(scale));
  }

  set offsetZMin(offset: string) {
    this.layer.setOffsetZMin(parseFloat(offset));
  }

  get offsetZMin():string {
    return `${this.layer.getOffsetZMin()}`;
  }

  set offsetZMax(offset: string) {
    this.layer.setOffsetZMax(parseFloat(offset));
  }

  get offsetZMax():string {
    return `${this.layer.getOffsetZMax()}`;
  }
}
