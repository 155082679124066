























import PluginComponent from '@/components/plugins/PluginComponent';
import Component from 'vue-class-component';

@Component
export default class NavigationPlugin extends PluginComponent {
  showZoomButton = true;

  showZoomToExtentButton = true;

  mounted(): void {
    console.log('Mesure Plugin Mounted');
    this.showZoomButton = this.plugin.getParameterValue('zoomInOut');
    this.showZoomToExtentButton = this.plugin.getParameterValue('zoomToMaxExtent');
  }

  onClickZoomIn():void {
    const currentZoom = this.getOpenLayersMapService().map.getView().getZoom();
    if (currentZoom) {
      this.getOpenLayersMapService().map.getView().setZoom(currentZoom + 1);
    }
  }

  onClickZoomOut():void {
    const currentZoom = this.getOpenLayersMapService().map.getView().getZoom();
    if (currentZoom) {
      this.getOpenLayersMapService().map.getView().setZoom(currentZoom - 1);
    }
  }

  onClickZoomToExtent():void {
    this.getMapService().zoomToInitialExtent();
  }
}
