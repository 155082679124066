/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import axios, { AxiosResponse } from 'axios';
import { contexteServiceInstance, ContextService } from '@/services/ContextService';
import User from '@/models/user/User';
import QueryParams, { IQueryParams } from '@/models/crud/QueryParams';
import Group from '@/models/user/Group';
import { EventBus, EVENTS } from './EventBus';
import TreeElement from '@/models/files/TreeElement';

class AdminService {
 
 
 
  contextService=contexteServiceInstance;

  getUsers(queryParams?:any):Promise<AxiosResponse<any>> {
    let url = `${this.contextService.getBaseApiUrl()}users/`;
    if (queryParams) {
      const u = new URLSearchParams(queryParams).toString();
      url = `${this.contextService.getBaseApiUrl()}users?${u}`;
    }

    return axios.get(url);
  }

  getUsersFromGroup(id: string, queryParams?: any) {
    let url = `${this.contextService.getBaseApiUrl()}users/`;
    if (queryParams) {
      const u = new URLSearchParams(queryParams).toString();
      url = `${this.contextService.getBaseApiUrl()}groups/${id}/users?${u}`;
    }

    return axios.get(url);
  }

  getGroups(cp?: any):Promise<AxiosResponse<any>> {
    let url = `${this.contextService.getBaseApiUrl()}groups/`;
    if (cp) {
      const u = new URLSearchParams(cp).toString();
      url = `${this.contextService.getBaseApiUrl()}groups?${u}`;
    }

    return axios.get(url);
  }

  getListGroup(){
    let url = `${this.contextService.getBaseApiUrl()}groups/`;
    return axios.get(url);
  }

  getMapAccess(idMap: number){
    let url = `${this.contextService.getBaseApiUrl()}map/${idMap}/access`;
    return axios.get(url);
  }

  getUser(id: string):Promise<AxiosResponse<any>> {
    return axios.get(`${this.contextService.getBaseApiUrl()}users/${id}`);
  }

  getGroup(id: string):Promise<AxiosResponse<any>> {
    return axios.get(`${this.contextService.getBaseApiUrl()}groups/${id}`);
  }

  listFiles(group: Group, path: string): Promise<AxiosResponse<any>> {
    return axios.get(`${this.contextService.getBaseApiUrl()}/files/groups/${group.id}?path=${path}`);
  }

  uploadFile(group: Group, path: string,selectedFile: File) {
    const formData = new FormData();
    formData.append('file', selectedFile);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        //'X-CSRFToken': csrfToken,
      },
    };
    return axios.post(`${this.contextService.getBaseApiUrl()}files/groups/${group.id}?path=${path}`, formData, config);
  }

  deleteFileOrDir(group: Group, path: string) {
    return axios.delete(`${this.contextService.getBaseApiUrl()}/files/groups/${group.id}?path=${path}`);
  }

  createDir(group: Group, path: string,newDir: null) {
    return axios.put(`${this.contextService.getBaseApiUrl()}/files/groups/${group.id}?path=${path}&dir=${newDir}`);
  }

  getGroupDirContent(id: string,path:string):Promise<AxiosResponse<any>> {
    return axios.get(`${this.contextService.getBaseApiUrl()}groups/${id}`);
  }

  confirmUser(token: string):Promise<AxiosResponse<any>> {
    return axios.get(`${this.contextService.getBaseApiUrl()}/users/confirm?token=${token}`);
  }

  addUserToGroup(group: Group, user: User):Promise<AxiosResponse<any>> {
    return this.addErrorHandler(axios.post(`${this.contextService.getBaseApiUrl()}groups/${group.id}/users/${user.id}`));
  }

  removeUserFromGroup(group: Group, user: User):Promise<AxiosResponse<any>> {
    return this.addErrorHandler(axios.delete(`${this.contextService.getBaseApiUrl()}groups/${group.id}/users/${user.id}`));
  }

  addErrorHandler(axiosReponse:Promise<AxiosResponse<any>>):Promise<AxiosResponse<any>> {
    return axiosReponse.catch((error) => {
      console.log(error);
      EventBus.$emit(EVENTS.ERROR, 'problème');
      return Promise.reject(error);
    });
  }

  addError(axiosReponse:Promise<AxiosResponse<any>>):Promise<AxiosResponse<any>> {
    return axiosReponse.catch((error) => {
      console.log(error);
      EventBus.$emit(EVENTS.ERROR, 'Le login existe deja');
      return Promise.reject(error);
    });
  }

  addGroupError(axiosReponse:Promise<AxiosResponse<any>>):Promise<AxiosResponse<any>> {
    return axiosReponse.catch((error) => {
      console.log(error);
      EventBus.$emit(EVENTS.ERROR, 'Le group existe deja');
      return Promise.reject(error);
    });
  }

  deleteUser(user: User):Promise<AxiosResponse<any>> {
    console.log('delete');
    return this.addErrorHandler(axios.delete(`${this.contextService.getBaseApiUrl()}/users/${user.id}`));
  }

  deleteGroup(user: Group):Promise<AxiosResponse<any>> {
    console.log('delete group');
    return this.addErrorHandler(axios.delete(`${this.contextService.getBaseApiUrl()}groups/${user.id}`));
  }

  createOrUpdateGroup(user: Group):Promise<AxiosResponse<any>> {
    let request;
    if (user.id !== undefined) {
      request = this.addGroupError(axios.put(`${this.contextService.getBaseApiUrl()}groups/${user.id}/`,
        user));
    } else {
      request = this.addGroupError(axios.post(`${this.contextService.getBaseApiUrl()}groups/`, user));
    }

    return request;
  }

  createOrUpdateUser(user: User):Promise<AxiosResponse<any>> {
    let request;
    if (user.id !== undefined) {
      request = this.addError(axios.put(`${this.contextService.getBaseApiUrl()}users/${user.id}/`,
        user));
    } else {
      request = this.addError(axios.post(`${this.contextService.getBaseApiUrl()}users/`, user));
    }

    return request;
  }
}

const adminServiceInstance = new AdminService();
export default AdminService;
export { AdminService, adminServiceInstance };
