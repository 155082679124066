




/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import * as Editor from 'ckeditor';

@Component({
  props: {
    label: String,
    cols: { default: 8 },
    value: {},
  },
})
export default class FormHtmlEditor extends BaseComponent {
  label!:string;

  value:any;

  editor = Editor;

  editorData= '<p>Content of the editor.</p>';

  editorLoaded = false;

  editorConfig= {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'alignment',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageInsert',
        '|',
        'blockQuote',
        'insertTable',
        // 'mediaEmbed',
        // 'sourceEditing',
        'undo',
        'redo',
        '|',
        'fontColor',
        'fontFamily',
        'fontSize',
        'highlight',
        'specialCharacters',
        'horizontalLine',
      ],
    },
    language: 'fr',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:full',
        'imageStyle:side',
        'resizeImage',
        'linkImage',
      ],
      styles: [
        'full',
        'side',
      ],
    },
    link: {
      decorators: {
        openInNewTab: {
          mode: 'manual',
          label: 'Ouvrir dans un nouvel onglet',
          defaultValue: true,
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
      },
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
      ],
    },
    licenseKey: '',
  };

  // model:any=null;
  get inputValue() {
    return this.value;
  }

  set inputValue(val) {
    this.$emit('input', val);
  }
}
