/* eslint-disable import/extensions */
/* eslint-disable no-restricted-properties */
/* eslint-disable max-len */
/* eslint-disable dot-notation */
/* eslint-disable prefer-template */
/* eslint-disable no-continue */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-named-as-default */
/* eslint-disable class-methods-use-this */

import { optionsFromCapabilities } from 'ol/source/WMTS';
import MapContext from '@/models/map-context/MapContext';
import LayerConfig from '@/models/map-context/LayerConfig';
import MapUtils from '@/models/map-context/MapUtils';
import {
  transform, get, transformExtent, fromLonLat, toLonLat, toUserCoordinate,
} from 'ol/proj.js';
import { boundingExtent } from 'ol/extent';
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import { defaults, MousePosition } from 'ol/control';
import axios, { AxiosResponse } from 'axios';
import {
  Observable, of, pipe, from,
} from 'rxjs';
import {
  ignoreElements,
  map, subscribeOn,
} from 'rxjs/operators';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import AbstractLayerTreeElement from '@/models/map-context/AbstractLayerTreeElement';
import { Coordinate, CoordinateFormat, format } from 'ol/coordinate';
import { contexteServiceInstance, ContextService } from '@/services/ContextService';
import {
  Circle, Geometry, LineString, Point, Polygon,
} from 'ol/geom';
import LayerStyle from '@/models/map-context/LayerStyle';
import StyleRule from '@/models/map-context/style/StyleRule';
import CesiumDecoupMeshService from '@/services/CesiumDecoupMeshService';
import CesiumEditionService from './CesiumEditionService';
import CesiumSelectionService from './CesiumSelectionService';
import CesiumPerformanceService from './CesiumPerformanceService';
import AbstractMapService from './AbstractMapService';
import EventBus, { EVENTS } from './EventBus';

declare let Cesium: any;

class CesiumMapService extends AbstractMapService {
  layers: any = {};

  viewer: any;

  moveScaleFactor = 1;

  contextService = contexteServiceInstance;

  wmtsResult: any

  public idLayersCount = 1;

  public currentZoom = 0;

  public mapProjectionCode = 'EPSG:3857';

  public initLocation = [5.1, 47.29];

  public initZoom = 9;

  currentScale = 0;

  public mousePosition!: MousePosition;

  selectionService!: CesiumSelectionService;

  editionService!: CesiumEditionService;

  performanceService! : CesiumPerformanceService;

  selectedEntity: any;

  debug = false;

  imgNord:any;

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  initMap(currentAppConfig: MapContext,target:any,popupContainer:any, isDebug:boolean) :void {
    // Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1YTBlZTFmNi1mNjBjLTQ5MzUtOTg0Zi04Y2Y0Mjk2ZTQ2OTgiLCJpZCI6NDU2NDksImlhdCI6MTYxNTI4Njg2MX0.QJjcfV4WJwX2Ht111wFsFYAGlD2nsKIK9Z9_xPLuYgM";
    this.config = currentAppConfig;
    this.debug = isDebug;

    const options = {
      animation: false,
      homeButton: false,
      baseLayerPicker: false,
      geocoder: false,
      infoBox: false,
      timeline: false,
      sceneModePicker: false,
      requestRenderMode: true,
      imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
        url: 'https://data.geopf.fr/wmts',
        layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
        style: 'normal',
        format: 'image/jpeg',
        tileMatrixSetID: 'PM',
      }),
    };

    const timelineParam = currentAppConfig.cesiumParameters.timeline;
    if (timelineParam !== undefined && timelineParam === true) {
      options.timeline = timelineParam;
    }
    this.viewer = new Cesium.Viewer(target, options);
    const shadowsParam = currentAppConfig.cesiumParameters.shadows;
    if (shadowsParam !== undefined && shadowsParam === true) {
      this.viewer.shadows = shadowsParam;
    }
    const fxaaParam = currentAppConfig.cesiumParameters.fxaa;
    if (fxaaParam !== undefined && fxaaParam === true) {
      this.viewer.scene.postProcessStages.fxaa.enabled = fxaaParam;
    }
    const fogParam = currentAppConfig.cesiumParameters.fog;
    if (fogParam !== undefined && fogParam === true) {
      this.viewer.scene.fog.enabled = fogParam;
    }
    if (this.contextService.configuration['3d']
      && this.contextService.configuration['3d'].terrainProviderUrl
      && this.contextService.configuration['3d'].terrainProviderUrl.length > 0) {
      const terrainOptions:any = {
        url: this.contextService.configuration['3d'].terrainProviderUrl,
        requestMetadata: false,
      };
      if (this.contextService.configuration['3d'].requestVertexNormals !== undefined) {
        terrainOptions.requestVertexNormals = this.contextService.configuration['3d'].requestVertexNormals;
      }
      const terrainProvider = new Cesium.CesiumTerrainProvider(terrainOptions);

      // url: 'http://51.210.185.61/datas/tiles',
      // this.viewer.extend(Cesium.viewerCesiumInspectorMixin);
      this.viewer.scene.terrainProvider = terrainProvider;
    }
    if (this.contextService.configuration['3d'].google_api_key !== undefined) {
      const tileset = this.viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
        url: 'https://tile.googleapis.com/v1/3dtiles/root.json?key=' + this.contextService.configuration['3d'].google_api_key,
        showCreditsOnScreen: true,
      }));
    }
    // this.viewer.scene.globe.show = false;
    if (this.contextService.configuration['3d']
    && this.contextService.configuration['3d']['globe.maximumScreenSpaceError']) {
      this.viewer.scene.globe.maximumScreenSpaceError = this.contextService.configuration['3d']['globe.maximumScreenSpaceError'];
    }
    this.viewer.scene.globe.depthTestAgainstTerrain = true;
    this.viewer.scene.postProcessStages.fxaa.enabled = true;
    Cesium.CreditDisplay.cesiumCredit = new Cesium.Credit(`<a href="https://cesium.com/" target="_blank"><img src="${Cesium.buildModuleUrl('Assets/Images/cesium_credit.png')}" title="Cesium"/></a>`);
    const west = 0.57;
    const south = 42.78;
    const east = 0.6;
    const north = 42.8;
    let rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);

    this.postInit();
    console.log('postInit');
    if (this.config.bbox) {
      const { bbox } = this.config;
      const ext = boundingExtent([[bbox.minx, bbox.miny], [bbox.maxx, bbox.maxy]]);
      const repojectedExtent = transformExtent(ext, bbox.srs, 'EPSG:4326');
      console.log(repojectedExtent);
      rectangle = Cesium.Rectangle.fromDegrees(repojectedExtent[0], repojectedExtent[1], repojectedExtent[2], repojectedExtent[3]);
      this.viewer.camera.setView({
        destination: rectangle,
      });
    } else {
      // A revoir !! il aurait mieux fallu avoir une bbox en config plutot qu'un centre + niveau de zoom
      const altitude = Math.pow(2.0, (29 - this.config.zoom));
      const transform = Cesium.Transforms.eastNorthUpToFixedFrame(Cesium.Cartesian3.fromDegrees(this.config.coords[0], this.config.coords[1]));
      const heading = Cesium.Math.toRadians(this.config.cesiumParameters.angles[0]);
      const pitch = Cesium.Math.toRadians(this.config.cesiumParameters.angles[1]);
      const range = altitude;
      this.viewer.camera.lookAtTransform(transform, new Cesium.HeadingPitchRange(heading, pitch, range));
      this.viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
    }

    const { scene } = this.viewer;
    scene.globe.depthTestAgainstTerrain = true;

    // Color a feature yellow on hover.
    // viewer.extend(Cesium.viewerCesium3DTilesInspectorMixin);
    EventBus.$emit(EVENTS.MAP_INIT_END);
  }

  addOtherLayerType(layer: LayerConfig): void {
    if (layer.is3DModel()) {
      this.addModel(layer);
    } else if (layer.type === '3DTiles') {
      this.addTileSetLayer(layer, 0);
    } else if (layer.type === '3DTiles_Photomaillage') {
      this.addTileSetLayer(layer, 0);
    } else if (layer.type === '3DTilesPointCloud') {
      this.addTileSetPointCloudLayer(layer);
    }
  }

  addTileSetPointCloudLayer(layer: LayerConfig) :void {
    const id = layer.layername;
    const tileset: any = this.addTileSetLayer(layer, 0);
    const pointSize = 2.0;
    // eslint-disable-next-line no-template-curly-in-string
    const customColor = '${COLOR}';
    /* TODO mettre en config les paramères pour les nuages de point
    if (layer.layer3dConfig.pointSize !== undefined) {
      pointSize = layer.layer3dConfig.pointSize;
    }
    if (layer.layer3dConfig.color !== undefined) {
      customColor = layer.layer3dConfig.color;
    }
    */
    this.layers[id] = tileset;
    tileset.style = new Cesium.Cesium3DTileStyle({
      color: customColor,
      pointSize,
    });
    return tileset;
  }

  updateView() {
    this.viewer.scene.requestRender();
  }

  addTileSetLayer(layer: LayerConfig, layerOffset: any) :void {
    const self: any = this;
    const options:any = {
      url: layer.url,
    };
    if (layer.layer3dConfig.maximumScreenSpaceError && layer.layer3dConfig.maximumScreenSpaceError.length > 0) {
      options.maximumScreenSpaceError = parseFloat(layer.layer3dConfig.maximumScreenSpaceError);
    }
    const ctileset = new Cesium.Cesium3DTileset(options);
    ctileset.readyPromise
      .then((tileset: {
        boundingSphere: { center: any; radius: number };
        modelMatrix: any;
      }) => {
        if (layerOffset) {
          const cartographic = Cesium.Cartographic.fromCartesian(
            tileset.boundingSphere.center,
          );
          const surface = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            0.0,
          );
          const offset = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            layerOffset,
          );
          const translation = Cesium.Cartesian3.subtract(
            offset,
            surface,
            new Cesium.Cartesian3(),
          );
          tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
        }
        self.viewer.scene.primitives.add(tileset);
        layer.setCesium_Layer(tileset);
        layer.setVisible(layer.visible);
        if (layer.layer3dConfig.listeTrou.length > 0) {
          const decoupmeshService = new CesiumDecoupMeshService(this, layer, null, null);
          const contourCartesian:any[] = [];
          layer.layer3dConfig.listeTrou[0].forEach((element:any) => {
            const coord = Cesium.Cartesian3.fromDegrees(element.longitude, element.latitude, 0);
            contourCartesian.push(coord);
          });
          console.log('holePlanes');
          decoupmeshService.holePlanes(contourCartesian);
        }
      })
      .otherwise((error: any) => {
        console.log(error);
      });

    return ctileset;
  }

  addcoupeZ(layer: LayerConfig): void {
    console.log('addcoupeZ');
    const ctileset = layer.getCesiumLayer();
    if (ctileset) {
      let model;
      if (ctileset.model) {
        model = ctileset.model;
      } else {
        model = ctileset;
      }

      const tabplane:any = [];
      layer.layer3dConfig.listeCoupe.forEach((coupe: { altitude: any;active: any; direction: any }) => {
        if (coupe.active === true) {
          let clippingPlane;
          if ((coupe.direction && coupe.direction.toLowerCase() === 'z') || coupe.direction === undefined) {
            clippingPlane = new Cesium.Plane(Cesium.Cartesian3.negate(Cesium.Cartesian3.UNIT_Z, new Cesium.Cartesian3()), coupe.altitude);
          } else if (coupe.direction && coupe.direction.toLowerCase() === 'y') {
            clippingPlane = new Cesium.Plane(Cesium.Cartesian3.negate(Cesium.Cartesian3.UNIT_Y, new Cesium.Cartesian3()), coupe.altitude);
          } else if (coupe.direction && coupe.direction.toLowerCase() === 'x') {
            clippingPlane = new Cesium.Plane(Cesium.Cartesian3.negate(Cesium.Cartesian3.UNIT_X, new Cesium.Cartesian3()), coupe.altitude);
          }

          tabplane.push(clippingPlane);
        }
      });

      const planeCollection = new Cesium.ClippingPlaneCollection({
        planes: tabplane,
      });
      model.clippingPlanes = planeCollection;
      this.viewer.scene.requestRender();
    } else {
      console.log("Le Cesium3DTileset n'a pas été créé dans addTileSetLayer.");
    }
  }

  destroyMap(): void {
    this.clearLayers();
    (<any> this.viewer) = null;
  }

  updateConfigLocationFromMap() : void{
    const me = this.getMapExtent();
    const loca:number[] = [];
    const centerX = (parseFloat(me[0]) + parseFloat(me[2])) / 2;
    const centerY = (parseFloat(me[1]) + parseFloat(me[3])) / 2;
    loca.push(centerX);
    loca.push(centerY);
    this.config.coords = loca;
    this.config.cesiumParameters.angles[0] = parseFloat(Cesium.Math.toDegrees(this.viewer.camera.heading).toFixed(1));
    this.config.cesiumParameters.angles[1] = parseFloat(Cesium.Math.toDegrees(this.viewer.camera.pitch).toFixed(1));
    const altitudes = [0, 50, 100, 250, 500, 1000, 2500, 5000, 7500, 10000, 20000, 30000, 50000, 100000, 1000000, 2000000, 10000000];
    const findRange:any = altitudes.find((x, index) => {
      if (index === altitudes.length - 1 && this.currentScale > x) {
        return true;
      }
      return this.currentScale > x && this.currentScale < altitudes[index + 1];
    });
    const zoom = altitudes.indexOf(findRange);
    this.config.zoom = 22 - zoom;
  }

  scale(number:number, inMin:number, inMax:number, outMin:number, outMax:number):number {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  clearLayers(): void {
    const layers = this.viewer.imageryLayers;
    let i = 0;
    for (i = 0; i < layers.length; i += 1) {
      const layer = layers[i];
      this.viewer.imageryLayers.remove(layer);
    }
  }

  postInit(): void {
    this.computeCurrentScale();
    this.selectionService = new CesiumSelectionService(this);
    this.editionService = new CesiumEditionService(this);
    this.performanceService = new CesiumPerformanceService(this, this.debug);
    this.viewer.camera.moveEnd.addEventListener(this.onZoom.bind(this));
    this.viewer.camera.changed.addEventListener(this.onMove.bind(this));
    this.viewer.camera.percentageChanged = 0.01;
    this.initLayersFromConfig();
    this.computeZIndexAndVisibility();
  }

  computeCurrentScale(): void {
    const cameraPosition = this.viewer.scene.camera.positionWC;
    const ellipsoidPosition = this.viewer.scene.globe
      .ellipsoid.scaleToGeodeticSurface(cameraPosition);
    const distance = Cesium.Cartesian3.magnitude(Cesium.Cartesian3
      .subtract(cameraPosition, ellipsoidPosition, new Cesium.Cartesian3()));
    this.currentScale = distance;
  }

  onZoom(event: any): void {
    this.computeCurrentScale();
    console.log(this.currentScale);
    this.onMoveEnd(this.getMapExtent(), this.viewer.camera);
  }

  onMove(event: any): void {
    const camyaw = Cesium.Math.toDegrees(this.viewer.scene.camera.heading) * -1.0;
    if (this.imgNord && this.imgNord.style) {
      this.imgNord.style = `transform:rotate(${camyaw}deg)`;
    }
  }

  getMapExtent(): any[] {
    const scratchRectangle = new Cesium.Rectangle();
    const rect = this.viewer.camera.computeViewRectangle(this.viewer.scene.globe.ellipsoid,
      scratchRectangle);
    const mapExtent = [
      Cesium.Math.toDegrees(rect.west).toFixed(4),
      Cesium.Math.toDegrees(rect.south).toFixed(4),
      Cesium.Math.toDegrees(rect.east).toFixed(4),
      Cesium.Math.toDegrees(rect.north).toFixed(4),
    ];
    return mapExtent;
  }

  onMoveEnd(bbox: any, camera: any): void {
    const url = new URL(window.location.href);
    const hash = window.location.hash.substr(1);
    const hashSplitted = window.location.hash.substr(1).split('?');
    let hashParams: any = {};
    if (hashSplitted.length > 1) {
      hashParams = hashSplitted[1].split('&').reduce((res: any, item) => {
        const parts = item.split('=');
        // eslint-disable-next-line prefer-destructuring
        res[parts[0]] = parts[1];
        return res;
      }, {});
    }
    hashParams.bbox = `${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}`;
    hashParams.camera_pos = `${camera.position.x},${camera.position.y},${camera.position.z}`;
    hashParams.camera_heading = camera.heading;
    hashParams.camera_pitch = camera.pitch;
    hashParams.camera_roll = camera.roll;
    const stringHashParams = Object.keys(hashParams).map((key) => [key, hashParams[key]].join('=')).join('&');
    url.hash = `#${hashSplitted[0]}?${stringHashParams}`;

    window.history.replaceState('', '', url.href);
  }

  setCameraView(position: number[], heading: number, pitch: number, roll: number): void {
    this.viewer.camera.setView({
      destination: new Cesium.Cartesian3(position[0], position[1], position[2]),
      orientation: {
        heading,
        pitch,
        roll,
      },
    });
  }

  zoomToBboxWGS84(bbox: number[]): void {
    const ext = boundingExtent([[bbox[0], bbox[1]], [bbox[2], bbox[3]]]);
    const rectangle = Cesium.Rectangle.fromDegrees(ext[0], ext[1], ext[2], ext[3]);

    this.viewer.camera.setView({
      destination: rectangle,
      orientation: {
        heading: Cesium.Math.toRadians(0.0), // east, default value is 0.0 (north)
        pitch: Cesium.Math.toRadians(-60), // default value -90.0  (looking down)
        roll: 0.0, // default value 0.0
      },
    });
  }

  zoomToLayer(layer: LayerConfig) : void {
    if (layer !== undefined && layer.boundingBoxEPSG4326 !== undefined) {
      const [minx, miny, maxx, maxy] = <any>layer.boundingBoxEPSG4326;
      const ext = boundingExtent([[minx, miny], [maxx, maxy]]);
      const rectangle = Cesium.Rectangle.fromDegrees(ext[0], ext[1], ext[2], ext[3]);

      this.viewer.camera.setView({
        destination: rectangle,
      });
    } else if (layer !== undefined && layer.cesium_Layer.position !== undefined) {
      const cartographic = Cesium.Cartographic.fromCartesian(layer.cesium_Layer.position.getValue());
      if (Cesium.defined(cartographic)) {
        this.viewer.camera.setView({
          destination: new Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 500),
          orientation: {
            heading: 0,
            pitch: Cesium.Math.toRadians(-89),
            roll: 0,
          },
        });
      }
    }
  }

  zoomTo(location: Coordinate, zoomlevel: number, lon?: number, lat?: number): void {
    if (lon && lat) {
      location = [+lon, +lat];
    }
    this.viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(location[0], location[1], 5000.0),
      duration: 20.0,
    });
  }
  panTo(location: Coordinate, lon?: number, lat?: number): void {
    if (lon && lat) {
      location = [+lon, +lat];
    }
    this.viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(location[0], location[1], 5000.0),
      duration: 20.0,
    });
  }

  addOverlay(loc: Coordinate): void {
    console.log('not implemented');
  }

  initLocaleConfig(): void{
    this.mapProjectionCode = this.config.projection;
    this.initZoom = this.config.zoom;
    this.initLocation = this.config.coords;
  }

  addOSMLayer(layercfg: LayerConfig): void {
    const osm = new Cesium.OpenStreetMapImageryProvider();
    const imageryLayer = this.viewer.imageryLayers.addImageryProvider(osm);
    layercfg.setCesium_Layer(imageryLayer);
    // layercfg.baseLayer = true;
    this.setZlevelOpacityAndResolutions(imageryLayer, layercfg);
  }

  rewriteProjToEPSG(fromProj: string): string {
    let epsgSTr = 'EPSG:4326';

    if (fromProj.indexOf('urn:') > -1) {
      // urn:ogc:def:objectType:authority:version:code
      // urn:ogc:def:crs:EPSG::3857
      const arr = fromProj.split(':');
      if (arr[3] === 'crs' && arr[4].toUpperCase() === 'EPSG') {
        // eslint-disable-next-line prefer-template
        epsgSTr = 'EPSG:' + arr[arr.length - 1];
      }
    } else if (fromProj.indexOf('EPSG:') > -1) {
      epsgSTr = fromProj;
    }

    return epsgSTr;
  }

  addPointTo3D(cleanProjFrom: string, pt: Point, properties: any, config: LayerConfig): any {
    // reprojection du point lat/lng
    const coords: number[] = pt.getCoordinates();
    let coordsReproj: any;
    let position: any;
    // const configText: Text = config.style.getText();

    if (coords.length === 2) {
      coordsReproj = proj4(cleanProjFrom, 'EPSG:4326', [pt.getCoordinates()[0], pt.getCoordinates()[1]]);

      let offsetZ = config.style.pointSymbol.getOffsetZ();
      if (offsetZ !== undefined && offsetZ.indexOf('{') !== -1 && offsetZ.indexOf('}') !== -1) {
        const propName = offsetZ.replace('{', '').replace('}', '').trim();
        const proVal = this.getPropertie(properties, propName);
        if (proVal !== undefined) {
          offsetZ = proVal;
        }
      }
      position = Cesium.Cartesian3.fromDegrees(coordsReproj[0], coordsReproj[1], offsetZ);
    } else if (coords.length === 3) {
      coordsReproj = proj4(cleanProjFrom, 'EPSG:4326', [pt.getCoordinates()[0], pt.getCoordinates()[1], pt.getCoordinates()[2]]);
      position = Cesium.Cartesian3.fromDegrees(coordsReproj[0], coordsReproj[1], coordsReproj[2] !== undefined && coordsReproj[2] !== null ? coordsReproj[2] : 0);
    }

    let obj: any = {};

    obj.position = position;
    obj.id = properties.id;
    obj.name = properties.name;
    obj.type = 'Point';
    obj.altitudeType = config.layer3dConfig.altitudeType;
    obj.altitudeOffset = config.layer3dConfig.altitudeOffset;
    obj.coordsReproj = coordsReproj;
    obj.properties = properties;

    const alphaItm: number = config.getOpacity();
    this.addFeaturePropertiesFunctions(obj);
    obj = this.defineStyle(obj, config, alphaItm, coordsReproj);

    return obj;
  }

  addPolylineTo3D(cleanProjFrom: string, line: LineString, properties: any, config: LayerConfig): any {
    const linePts: number[] = [];
    line.getCoordinates().forEach((point: any) => {
      if (point.length === 2) {
        const coordsReproj: any = proj4(cleanProjFrom, 'EPSG:4326', [point[0], point[1]]);
        linePts.push(coordsReproj[0]);
        linePts.push(coordsReproj[1]);
      } else if (point.length === 3) {
        const coordsReproj: any = proj4(cleanProjFrom, 'EPSG:4326', [point[0], point[1], point[2]]);
        linePts.push(coordsReproj[0]);
        linePts.push(coordsReproj[1]);
        linePts.push(coordsReproj[2]);
      }
    });

    let obj: any = {};

    if (config.layer3dConfig.altitudeType === 'absolute') { // besoin que les coords du GeoJSON contiennent le Z
      obj = {
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(linePts),
          width: 3,
          classificationType: Cesium.ClassificationType.TERRAIN,
        },
      };
    } else { // onterrain + absolute
      obj = {
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(linePts),
          width: 3,
          clampToGround: true,
          classificationType: Cesium.ClassificationType.BOTH,
        },
      };
    }

    obj.id = properties.id;
    obj.name = properties.name;
    obj.type = 'LineString';
    obj.altitudeType = config.layer3dConfig.altitudeType;
    obj.altitudeOffset = config.layer3dConfig.altitudeOffset;
    obj.properties = properties;

    const alphaItm: number = config.getOpacity();
    this.addFeaturePropertiesFunctions(obj);
    obj = this.defineStyle(obj, config, alphaItm);

    return obj;
  }

  addPolygonTo3D(cleanProjFrom: string, poly: Polygon, properties: any, config: LayerConfig): any {
    const polPts: number[] = [];
    poly.getCoordinates().forEach((points: any) => {
      points.forEach((point: any) => {
        if (point.length === 2) {
          const coordsReproj: any = proj4(cleanProjFrom, 'EPSG:4326', [point[0], point[1]]);
          polPts.push(coordsReproj[0]);
          polPts.push(coordsReproj[1]);
        } else if (point.length === 3) {
          const coordsReproj: any = proj4(cleanProjFrom, 'EPSG:4326', [point[0], point[1], point[2]]);
          polPts.push(coordsReproj[0]);
          polPts.push(coordsReproj[1]);
          polPts.push(coordsReproj[2]);
        }
      });
    });

    let obj: any = {};
    // cas du onterrain
    if (config.layer3dConfig.altitudeType === 'onterrain') {
      obj = {
        polygon: {
          hierarchy: { positions: Cesium.Cartesian3.fromDegreesArray(polPts) },
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          // extrudedHeight: 250.0,
          classificationType: Cesium.ClassificationType.BOTH,
        },
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(polPts),
          width: 3,
          clampToGround: true,
          classificationType: Cesium.ClassificationType.BOTH,
        },
      };
    } else if (config.layer3dConfig.altitudeType === 'relativeground') {
      obj = {
        polygon: {
          hierarchy: { positions: Cesium.Cartesian3.fromDegreesArray(polPts) },
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          height: config.layer3dConfig.altitudeOffset,
          classificationType: Cesium.ClassificationType.TERRAIN,
        },
      };
    } else if (config.layer3dConfig.altitudeType === 'absolute') { // besoin que les coords du GeoJSON contiennent le Z
      obj = {
        polygon: {
          hierarchy: { positions: Cesium.Cartesian3.fromDegreesArrayHeights(polPts) },
          heightReference: Cesium.HeightReference.NONE,
          classificationType: Cesium.ClassificationType.TERRAIN,
          perPositionHeight: true,
        },
      };
    }
    obj.id = properties.id;
    obj.name = properties.name;
    obj.type = 'Polygon';
    obj.altitudeType = config.layer3dConfig.altitudeType;
    obj.altitudeOffset = config.layer3dConfig.altitudeOffset;
    obj.properties = properties;

    const alphaItm: number = config.getOpacity();
    this.addFeaturePropertiesFunctions(obj);
    obj = this.defineStyle(obj, config, alphaItm);
    return obj;
  }

  getStyleForFeature(itemVector: any, layerCfg: LayerConfig) :LayerStyle {
    let styleRule = layerCfg.style;

    if (layerCfg.complexStyle && layerCfg.complexStyle.styleRules !== undefined && layerCfg.complexStyle.styleRules.length > 0 && itemVector.properties !== undefined) {
      const rule = layerCfg.complexStyle.getStyleForFeature(itemVector);
      if (rule) {
        styleRule = rule;
      }
    }

    return styleRule;
  }

  getPropertie(properties: any, Name: string) : string {
    let propVal;
    if (properties[Name] !== undefined && properties[Name] !== null) {
      if (properties[Name]._value !== undefined) {
        propVal = properties[Name]._value;
      } else {
        propVal = properties[Name];
      }
    }

    return propVal;
  }

  defineTextStyle(itemVector: any, style:LayerStyle, layerCfg: LayerConfig, alpha: number, coordsReproj?: number[]):any {
    const configText:any = style.textStyle;
    let lblTxt:any = configText.getText();
    if (style.textStyle.text !== undefined && style.textStyle.text.indexOf('{') !== -1 && style.textStyle.text.indexOf('}') !== -1) {
      lblTxt = style.textStyle.text.replace(/{(.*?)}/g, (a, b) => {
        const value = this.getPropertie(itemVector.properties, b);

        return value || '';
      });
    }

    let zoffset:any = configText.getOffsetZ();
    if (zoffset !== undefined && zoffset.indexOf('{') !== -1 && zoffset.indexOf('}') !== -1) {
      const propName = zoffset.replace('{', '').replace('}', '').trim();
      const proVal = this.getPropertie(itemVector.properties, propName);
      if (proVal !== undefined) {
        zoffset = proVal;
      }
    }

    const bgPadding = configText.getTextPadding();
    const bgFill = configText.getTextBackGroundFillColor();
    const colorLabelBckg = (bgFill !== '' ? Cesium.Color.fromCssColorString(configText.getTextBackGroundFillColor()) : Cesium.Color.BLACK.withAlpha(0));
    const colorPropertyLabelBckg = new Cesium.ColorMaterialProperty();
    const colorPropertyLabelFill = new Cesium.ColorMaterialProperty();
    colorPropertyLabelBckg.color = new Cesium.CallbackProperty((() => {
      colorLabelBckg.alpha = alpha;
      return colorLabelBckg;
    }), false);

    const colorLabelFill = Cesium.Color.fromCssColorString(configText.getTextFillColor());
    colorPropertyLabelFill.color = new Cesium.CallbackProperty((() => {
      colorLabelFill.alpha = alpha;
      return colorLabelFill;
    }), false);

    itemVector.label = {
      text: lblTxt,
      font: 'ARIAL',
      fillColor: colorPropertyLabelFill.color,
      // outlineColor:  Cesium.Color.fromCssColorString(configText.getStroke().getColor()),
      // outlineWidth: configText.getStroke().getWidth(),
      style: Cesium.LabelStyle.FILL, // Cesium.LabelStyle.FILL_AND_OUTLINE,
      verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
      // eyeOffset: new Cesium.Cartesian3(0.0, 0.0, parseFloat(style.textStyle.getOffsetZ())),
      eyeOffset: new Cesium.Cartesian3(0.0, parseFloat(zoffset), 0.0),
      // pixelOffset : new Cesium.Cartesian2(0,  Math.abs(configText.getOffsetY()) ),
      showBackground: bgFill !== null,
      backgroundColor: colorPropertyLabelBckg.color,
      backgroundPadding: (bgPadding !== undefined && bgPadding !== null ? new Cesium.Cartesian2(bgPadding[0], bgPadding[1]) : new Cesium.Cartesian2(0, 0)),
      scaleByDistance: new Cesium.NearFarScalar(1500, 1, 50000, 1.2),
      translucencyByDistance: new Cesium.NearFarScalar(1500, 1.0, 60000, 0.5),
      pixelOffsetScaleByDistance: new Cesium.NearFarScalar(1500, 0.0, 60000, 10.0),
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 60000.0),
    };
    if (parseFloat(zoffset) > 50) {
      itemVector.cylinder = {
        length: parseFloat(zoffset) * 0.95,
        topRadius: 2,
        bottomRadius: 2,
        material: Cesium.Color.fromCssColorString('#222222').withAlpha(0.8),
        heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        slices: 20,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(500.0, 10000.0),
      };
    }
  }

  fixStyleForCesium(layerconfig: LayerConfig):void {
    const { style } = layerconfig;
    const listToFix = [style];
    if (layerconfig.complexStyle && layerconfig.complexStyle.styleRules.length > 0) {
      layerconfig.complexStyle.styleRules.forEach((rule:StyleRule) => {
        listToFix.push(rule.style);
      });
    }
    listToFix.forEach((fixstyle:LayerStyle) => {
      if (fixstyle.pointSymbol) {
        if (fixstyle.pointSymbolType === undefined || fixstyle.pointSymbolType === '') {
          // image = './styles/3d-icons/circle.svg';
          fixstyle.pointSymbol.svgIconUrl = './styles/3d-icons/circle.svg';
        } else if (fixstyle.pointSymbolType === 'svg' && fixstyle.pointSymbol.svgIconUrl !== '') {
          console.log('svg ok');
        } else {
          fixstyle.pointSymbol.svgIconUrl = './styles/3d-icons/' + fixstyle.pointSymbolType + '.svg';
        }
        fixstyle.clearCache();
      }
    });
  }

  defineStyle(itemVector: any, layerCfg: LayerConfig, alpha: number, coordsReproj?: number[]): any {
    // DDS - mutualiser avec le code de la 2D qui récupère le style à afficher sur un objet
    const style = this.getStyleForFeature(itemVector, layerCfg);

    // DDS - si on recupere ici le style a appliquer a partir d'un style complexe
    // on devrait utiliser tt le temps infosStyle.style dans le code en dessous ?
    if (style.pointSymbol) {
      // const colorPropertyBillbord = new Cesium.ColorMaterialProperty();

      const image = style.pointSymbol.imgcache;
      image.width = style.pointSymbol.getSize();
      image.height = style.pointSymbol.getSize();
      if (image !== undefined) {
        if (!itemVector.billboard) {
          itemVector.billboard = {};
        }
        itemVector.billboard.image = image;
        itemVector.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER;
        itemVector.billboard.horizontalOrigin = Cesium.VerticalOrigin.CENTER;
        // itemVector.billboard.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND;// RELATIVE_TO_GROUND
        itemVector.billboard.heightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;
        // itemVector.billboard.scaleByDistance = new Cesium.NearFarScalar(1.5e2, 0.8, 8.0e3, 0.5);
        // itemVector.billboard.translucencyByDistance = new Cesium.NearFarScalar(1.5e2, 1.0, 8.0e6, 1.0);
        itemVector.billboard.width = style.pointSymbol.getSize();
        itemVector.billboard.height = style.pointSymbol.getSize();
        itemVector.billboard.disableDepthTestDistance = Number.POSITIVE_INFINITY;
      }
      // si etiquette
      const configText:any = style.textStyle; // renseigne auto la font-family et le 'poids' en dur (présent dans TextStyle.ts)
      if (configText !== undefined && configText !== null) {
        this.defineTextStyle(itemVector, style, layerCfg, alpha, coordsReproj);
      }
    }

    if (itemVector.polygon) {
      // pattern de remplissage
      const fillsymbol = style.fillStyle.fillSymbol;
      const pattern = style.fillStyle.fillPattern;
      const { fillExtrudeHeight } = style.fillStyle;
      if (pattern === 'slash' || pattern === 'backslash') {
        // hachures
        const colorPropertyEven = new Cesium.ColorMaterialProperty();
        const colorEven = Cesium.Color.fromCssColorString(style.fillStyle.getFillColor()); // infosStyle.style.getFill().getColor()
        colorPropertyEven.color = new Cesium.CallbackProperty((() => {
          // You can change the alpha/red/green/blue components dynamically here.
          colorEven.alpha = alpha;
          return colorEven;
        }), false);

        const colorPropertyOdd = new Cesium.ColorMaterialProperty();
        const colorOdd = Cesium.Color.fromCssColorString(fillsymbol.getSymbolFillColor()); // infosStyle.style.getStroke().getColor()
        colorPropertyOdd.color = new Cesium.CallbackProperty((() => {
          // You can change the alpha/red/green/blue components dynamically here.
          colorOdd.alpha = alpha;
          return colorOdd;
        }), false);

        itemVector.polygon.material = new Cesium.StripeMaterialProperty({
          evenColor: colorEven,
          oddColor: colorOdd,
          repeat: 30.0,
          orientation: pattern === 'slash' ? Cesium.StripeOrientation.HORIZONTAL : Cesium.StripeOrientation.VERTICAL,
        });
      } else if (pattern === 'none') { // pas de remplissage
        itemVector.polygon.material = Cesium.Color.WHITE.withAlpha(0);
      } else if (pattern === 'simple') {
        const colorProperty = new Cesium.ColorMaterialProperty();
        const color = Cesium.Color.fromCssColorString(style.fillStyle.getFillColor());

        colorProperty.color = new Cesium.CallbackProperty((() => {
          // You can change the alpha/red/green/blue components dynamically here.
          color.alpha = alpha;
          return color;
        }), false);

        itemVector.polygon.material = colorProperty;
      }
      if (fillExtrudeHeight && fillExtrudeHeight > 0) {
        itemVector.polygon.extrudedHeight = fillExtrudeHeight;
      }
      // contour si pas onterrain
      if (layerCfg.layer3dConfig.altitudeType !== 'onterrain') { // absolute/relative
        itemVector.polygon.outline = true;
        itemVector.polygon.outlineColor = Cesium.Color.fromCssColorString(style.strokeStyle.getColor());
        itemVector.polygon.outlineWidth = style.strokeStyle.getStrokeWidth();
      } else {
        // citemVector.polyline.outlineColor = Cesium.Color.fromCssColorString(style.strokeStyle.getColor());

        const colorProperty = new Cesium.ColorMaterialProperty();
        const color = Cesium.Color.fromCssColorString(style.strokeStyle.getColor());

        colorProperty.color = new Cesium.CallbackProperty((() => {
          // You can change the alpha/red/green/blue components dynamically here.
          color.alpha = alpha;
          return color;
        }), false);

        itemVector.polyline.material = new Cesium.PolylineOutlineMaterialProperty({
          color: colorProperty.color,
        });

        itemVector.polyline.width = style.strokeStyle.getStrokeWidth();
      }
    } else if (itemVector.polyline) {
      const patternLine = style.strokeStyle.pattern;
      if (patternLine === 'slash' || patternLine === 'backslash') { // hachure
        const colorPropertyEven = new Cesium.ColorMaterialProperty();
        const colorEven = Cesium.Color.fromCssColorString(style.strokeStyle.getColor());
        colorPropertyEven.color = new Cesium.CallbackProperty((() => {
          // You can change the alpha/red/green/blue components dynamically here.
          colorEven.alpha = alpha;
          return colorEven;
        }), false);

        const colorPropertyOdd = new Cesium.ColorMaterialProperty();
        const colorOdd = Cesium.Color.fromCssColorString(style.strokeStyle.getColor());
        colorPropertyOdd.color = new Cesium.CallbackProperty((() => {
          // You can change the alpha/red/green/blue components dynamically here.
          colorOdd.alpha = alpha;
          return colorOdd;
        }), false);

        itemVector.polyline.material = new Cesium.StripeMaterialProperty({
          evenColor: colorEven,
          oddColor: colorOdd,
          repeat: 30.0,
        });
      } else if (patternLine === 'simple') {
        const lstLinedash: string[] = style.strokeStyle.getLineDash()!.split(' ');
        if (lstLinedash && lstLinedash.length > 1) { // pointillé pattern
          const colorProperty = new Cesium.ColorMaterialProperty();
          const colorPatter = Cesium.Color.fromCssColorString(style.strokeStyle.getColor());
          colorProperty.color = new Cesium.CallbackProperty((() => {
            // You can change the alpha/red/green/blue components dynamically here.
            colorPatter.alpha = alpha;
            return colorPatter;
          }), false);

          let motifLen = 0;
          let motif = '';
          let i; let j = 0;
          for (i = 0; i < lstLinedash.length; i += 1) {
            const charMot = i % 2 === 1 ? '0' : '1';
            for (j = 0; j < Number(lstLinedash[i]); j += 1) {
              motifLen += 1;
              motif += charMot;
            }
          }
          itemVector.polyline.material = new Cesium.PolylineDashMaterialProperty({
            color: colorProperty.color,
            dashLength: motifLen,
            dashPattern: parseInt(motif, 2),
          });
        } else {
          const colorProperty = new Cesium.ColorMaterialProperty();
          const color = Cesium.Color.fromCssColorString(style.strokeStyle.getColor());

          colorProperty.color = new Cesium.CallbackProperty((() => {
            // You can change the alpha/red/green/blue components dynamically here.
            color.alpha = alpha;
            return color;
          }), false);

          itemVector.polyline.material = new Cesium.PolylineOutlineMaterialProperty({
            color: colorProperty.color,
          });
        }
      }

      itemVector.polyline.width = style.strokeStyle.getStrokeWidth();
    }

    return itemVector;
  }

  forceUpdateStyle(infosStyle: LayerConfig): any {
    // boucle sur les x entities pour appliquer le nouveau style
    const layer: any = infosStyle.getCesiumLayer();
    if (layer) {
      const lstEntities = layer.entities;

      if (lstEntities && lstEntities.values && lstEntities.values.length > 0) {
        if (lstEntities.values[0].altitudeType !== infosStyle.layer3dConfig.altitudeType || lstEntities.values[0].altitudeOffset !== infosStyle.layer3dConfig.altitudeOffset) {
          const dtsrcName: string = infosStyle.cesium_Layer.name;
          const dts: any = this.viewer.dataSources.getByName(dtsrcName);
          for (let i = 0; i < dts.length; i += 1) {
            const isDeleted: boolean = this.viewer.dataSources.remove(dts[i], true);
            if (isDeleted) {
              this.addLayer(infosStyle);
            }
          }
        } else {
          const alphaItm: number = infosStyle.getOpacity();
          lstEntities.values.forEach((entity: any) => {
            this.defineStyle(entity, infosStyle, alphaItm, entity.coordsReproj);
          });
        }
      }
    }
  }

  addVectorFeatures(objJson:any, layerconfig:LayerConfig, cleanProjFrom:string) {
    const dataSource = new Cesium.CustomDataSource('datasrc_' + objJson.name);

    dataSource.getFeatures = function () {
      if (this.entities !== undefined && this.entities.values.length > 0) {
        return this.entities.values;
      }
      return null;
    };

    this.viewer.dataSources.add(dataSource);

    // on applique la valeur "alpha" par défaut renseigné dans la config
    // ou celle de la réglette "Opacité"
    let alphaItm: number = layerconfig.opacity ? layerconfig.opacity : 1;
    if (layerconfig.cesium_Layer) {
      // si le layer etait déjà déf => reprend son opacité
      alphaItm = layerconfig.getOpacity();
    }
    layerconfig.setCesium_Layer(dataSource);

    layerconfig.setOpacity(alphaItm);
    // on boucle sur les x items pour :
    // - reprojeter position en WGS84
    // - appliquer style de la couhce
    // - afficher dans la 3D
    objJson.features.forEach((item: any) => {
      this.addFeaturetoCesium(item, dataSource, cleanProjFrom, layerconfig);
    });
  }

  addFeaturetoCesium(item:any, dataSource:any, cleanProjFrom:any, layerconfig:any) {
    const geom = item;
    if (geom !== null && geom !== undefined) {
      // const type:string = geom.getType();
      let type = '';
      let coords: number[] = [];
      let properties = {};
      // Si c'est déjà un objet feature...
      if (item.getGeometry) {
        type = item.getGeometry().getType();
        coords = item.getGeometry().getCoordinates();
        properties = item.getGeometry().getProperties();
      } else {
        type = item['geometry']['type'];
        coords = item['geometry']['coordinates'];
        properties = item['properties'];
      }
      if (type === 'Point') {
        const pt: Point = new Point(coords);
        const obj = this.addPointTo3D(cleanProjFrom, pt, properties, layerconfig);
        obj.layerConfig = layerconfig;
        dataSource.entities.add(obj);
      } else if (type === 'MultiPoint') {
        coords.forEach((element: any) => {
          const pt: Point = new Point(element);
          const obj = this.addPointTo3D(cleanProjFrom, pt, properties, layerconfig);
          obj.layerConfig = layerconfig;
          dataSource.entities.add(obj);
        });
      } else if (type === 'Polygon') {
        const polygon: Polygon = new Polygon(coords);
        const obj = this.addPolygonTo3D(cleanProjFrom, polygon, properties, layerconfig);
        obj.layerConfig = layerconfig;
        dataSource.entities.add(obj);
      } else if (type === 'MultiPolygon') {
        coords.forEach((element: any) => {
          const polygon: Polygon = new Polygon(element);
          const obj = this.addPolygonTo3D(cleanProjFrom, polygon, properties, layerconfig);
          obj.layerConfig = layerconfig;
          dataSource.entities.add(obj);
        });
      } else if (type === 'LineString') {
        const line: LineString = new LineString(coords);
        const obj = this.addPolylineTo3D(cleanProjFrom, line, properties, layerconfig);
        obj.layerConfig = layerconfig;
        dataSource.entities.add(obj);
      } else if (type === 'MultiLineString') {
        coords.forEach((element: any) => {
          const line: LineString = new LineString(element);
          const obj = this.addPolylineTo3D(cleanProjFrom, line, properties, layerconfig);
          obj.layerConfig = layerconfig;
          dataSource.entities.add(obj);
        });
      }
    }
  }

  addVectorLayer(layerconfig: LayerConfig): LayerConfig {
    this.fixStyleForCesium(layerconfig);
    if (layerconfig.jsonData && layerconfig.jsonData.length > 0) {
      // let feats = (new GeoJSON()).readFeatures(layerconfig.jsonData);
      // var promise = Cesium.GeoJsonDataSource.load(url);
    } else if (layerconfig.url !== undefined) {
      fetch(layerconfig.url).then((data) => {
        data.text().then((dataJson) => {
          // parse le JSON pour récupérer infos de la projection des données lues
          const objJson = JSON.parse(dataJson);

          let proj = 'EPSG:4326'; // try default proj
          if (objJson['crs'] !== undefined) {
            proj = objJson['crs']['properties'].name !== null && objJson['crs']['properties'].name !== undefined ? objJson['crs']['properties'].name : '';
          }
          const cleanProjFrom = this.rewriteProjToEPSG(proj);
          this.addVectorFeatures(objJson, layerconfig, cleanProjFrom);
          layerconfig.setVisible(layerconfig.visible);
        });
      });
    }
    return layerconfig;
  }

  addFeaturePropertiesFunctions(entity:any):void{
    if (!entity.getProperties) {
      entity.getProperties = () => {
        const obj:any = {};
        if (entity.properties.propertyNames) {
          entity.properties.propertyNames.forEach((key:string) => {
            obj[key] = entity.properties[key].getValue();
          });
          return obj;
        }

        return entity.properties;
      };
      entity.get = function (this: any, Name: string) {
        return this.getPropertie(entity.properties, Name);
      }.bind(this);
    }
  }

  addWFSLayer(layerconfig: LayerConfig) : LayerConfig {
    const urlGetFeature = layerconfig.wmsUrlToWfs(layerconfig.getFeatureWFSUrl());
    const url = `${urlGetFeature
    }&typenames=${layerconfig.layername}`
      + `&typename=${layerconfig.layername}&`
      + 'outputFormat=json&srsname=EPSG:4326';
    this.setAttributeDefinition(layerconfig);
    /*
    // 1 - LOAD AS GEOJSON
    const promise = Cesium.GeoJsonDataSource.load(url).then(
      (dataSource:any) => {
        const featureList = dataSource.entities.values;
        layerconfig.setCesium_Layer(dataSource);
        featureList.forEach((entity: any) => {
          this.addFeaturePropertiesFunctions(entity);
          entity.layerConfig = layerconfig;
          this.defineStyle(entity, layerconfig, layerconfig.getOpacity(), entity.coordsReproj);
        });
        this.viewer.dataSources.add(dataSource);
      },
    );
    */
    // or 2 - load as Vector
    this.fixStyleForCesium(layerconfig);

    fetch(url).then((data) => {
      data.text().then((dataJson) => {
        const objJson = JSON.parse(dataJson);
        this.addVectorFeatures(objJson, layerconfig, 'EPSG:4326');
        layerconfig.setVisible(layerconfig.visible);
      });
    });
    return layerconfig;
  }

  addWMTSLayerFromCapabilities(layerconfig: LayerConfig, wmtsCapabilities: any) : LayerConfig {
    const { layername } = layerconfig;
    const { id } = layerconfig;

    if (this.getLayersById(id) == null) {
      let options: any;
      try {
        if (layerconfig.outputFormat) {
          options = optionsFromCapabilities(wmtsCapabilities, {
            layer: layername,
            format: layerconfig.outputFormat,
          });
        } else {
          options = optionsFromCapabilities(wmtsCapabilities, {
            layer: layername,
          });
        }
      } catch (e: any) {
        console.log(e);
        if (e.message === 'projection is null') {
          layerconfig.addError('Projection non reconnue');
        } else {
          layerconfig.addError('Problème d\'analyse du getCapabilities');
        }
        return layerconfig;
      }

      if (layerconfig.ignoreUrlInCapabiltiesResponse) {
        const searchMask = 'request(=|%3D)getCapabilities';
        const regEx = new RegExp(searchMask, 'ig');
        const replaceMask = '';
        options.urls[0] = layerconfig.url.replace(regEx, replaceMask);
      }
      options.attributions = layerconfig.getAttribution();
      options.crossOrigin = 'anonymous';
      // var layerSource = new WMTS(/** @type {!olx.source.WMTSOptions} */(options));

      // this.layerManager.addLoadingFunction(layerSource, layerconfig);
      // this.layerManager.addLoadingListener(layerSource, layerconfig);
      // let layerOl = new TileLayer({
      //  source: layerSource
      // });
      console.log(options);
      const cesiumLayer = new Cesium.WebMapTileServiceImageryProvider({
        url: options.urls[0],
        layer: options.layer,
        style: options.style,
        format: options.format,
        tileMatrixSetID: options.matrixSet,
        tileMatrixLabels: options.tileGrid.matrixIds_,
      });
      const imageryLayer = this.viewer.imageryLayers.addImageryProvider(cesiumLayer);
      this.setZlevelOpacityAndResolutions(imageryLayer, layerconfig);
      layerconfig.setCesium_Layer(imageryLayer);
      // self.map.addLayer(layerOl);
    } else {
      (<any>layerconfig).ol_Layer = this.getLayersById(id);
      this.setZlevelOpacityAndResolutions(layerconfig.getCesiumLayer(), layerconfig);
    }
    layerconfig.getCesiumLayer().show = layerconfig.isLayerVisible();

    return layerconfig;
  }

  getMapBoundsWGS84(): any[] {
    // TODO
    return [];
  }

  addVectorTileLayerFromCapabilities(layerconfig: LayerConfig, wmtsCapabilities: any) : LayerConfig {
    // TODO
    return layerconfig;
  }

  sortByZIndexgetLayersAsArray(): LayerConfig[] {
    const clonedList = this.getOrderedLayerList().map((x) => x);
    const self = this;
    clonedList.sort((a, b) => a.getCesiumLayer()
      && b.getCesiumLayer()
      && self.viewer.imageryLayers.indexOf(a.getCesiumLayer()) - self.viewer.imageryLayers.indexOf(b.getCesiumLayer()));
    clonedList.reverse();

    return clonedList;
  }

  removeLayerFromMap(layer: LayerConfig) : void {
    if (layer.getCesiumLayer() && layer.isWMS()) {
      this.viewer.imageryLayers.remove(layer.getCesiumLayer());
      delete layer.cesium_Layer;
    } else if (layer.getCesiumLayer() && (layer.isVector() || layer.isWFS())) {
      this.viewer.dataSources.remove(layer.getCesiumLayer());
      delete layer.cesium_Layer;
    } else if (layer.getCesiumLayer() && layer.is3Dtiles()) {
      this.viewer.scene.primitives.remove(layer.getCesiumLayer());
      delete layer.cesium_Layer;
    } else if (layer.getCesiumLayer() && layer.is3DModel()) {
      this.viewer.entities.remove(layer.getCesiumLayer());
      delete layer.cesium_Layer;
    }
  }

  getFeaturesTypes(layer: LayerConfig): Observable<any> {
    const headers: any = {};
    if (layer.authenticationInfos
      && layer.authenticationInfos.isFilled()) {
      headers.Authorization = layer.authenticationInfos.getBasicAutorisationValue();
    }
    if (layer.getDescribeFeatureUrl() !== '') {
      return from(axios.get(layer.getDescribeFeatureUrl(), { headers }))
        .pipe(
          map(
            (reponse:any) => {
              if (reponse.data.indexOf && reponse.data.indexOf('ExceptionText') > 0) {
                console.log(reponse.data);
                // eslint-disable-next-line no-throw-literal
                throw 'Impossible de récupérer lire la réponse';
              }
              return reponse.data;
            },
          ),
        );
    }
    /*
    else if (layer.type == 'Vector' && layer.url != undefined && layer.getLayerSource()) {
      let feats = layer.getLayerSource().getFeatures();
      if (feats.length > 0) {
        let properties = feats[0].getKeys().map((x:any) => { return { "name": x, "type": 'xsd:string' } });
        return of({ "featureTypes": [{ "properties": properties }] });
      }
      return of(undefined);
    } */
    return of(undefined);
  }

  addWMSLayer(layerconfig: LayerConfig) : void {
    if (this.getLayersById(layerconfig.id) == null) {
      let urlwms = '';
      if (layerconfig.url !== undefined && layerconfig.url !== '') {
        urlwms = layerconfig.url;
      }
      const searchMask = 'request(=|%3D)getCapabilities';
      const regEx = new RegExp(searchMask, 'ig');
      const replaceMask = '';

      urlwms = urlwms.replace(regEx, replaceMask);

      let projection = this.mapProjectionCode;
      if (layerconfig.projection) {
        projection = layerconfig.projection;
      }

      const attributions = layerconfig.getAttribution();

      const { imageryLayers } = this.viewer;

      const wmsLayer = new Cesium.WebMapServiceImageryProvider({
        url: urlwms,
        layers: layerconfig.layername,
        credit: attributions,
        getFeatureInfoFormats: [new Cesium.GetFeatureInfoFormat('xml', 'application/vnd.ogc.gml')],
        parameters: {
          transparent: true,
          format: 'image/png',
        },
      });

      const imageryLayer = imageryLayers.addImageryProvider(wmsLayer);
      this.layers[layerconfig.layername] = imageryLayer;

      // this.layerManager.addLoadingFunction(source, layerconfig);
      // this.layerManager.addLoadingListener(source, layerconfig);
      if (layerconfig.layerIndex) {
        this.setZlevelOpacityAndResolutions(imageryLayer, layerconfig);
      }

      layerconfig.setCesium_Layer(imageryLayer);

      if (layerconfig.selectedStyle && layerconfig.selectedStyle.length>0) {
        wmsLayer.parameters.updateParams({ STYLES: layerconfig.selectedStyle });
      }
      else if (layerconfig.styles) {
        wmsLayer.parameters.updateParams({ STYLES: layerconfig.styles[0].style });
        // eslint-disable-next-line prefer-destructuring
        layerconfig.selectedStyle = layerconfig.styles[0];
      }
      if (layerconfig.filter) {
        wmsLayer.parameters.updateParams({ CQL_FILTER: layerconfig.getFilterValue() });
      }
      if (layerconfig.useSldBody) {
        const sld = layerconfig.styletoSld();
        wmsLayer.parameters.updateParams({ SLD_BODY: sld });
      }
    }
    this.refreshSyles(layerconfig);
    const layer = this.getLayersById(layerconfig.id);
    if (layer !== null) {
      // imageryLayer.show = layerconfig.isLayerVisible();
      layer.show = layerconfig.isLayerVisible();
    }
  }

  getLayersById(id: string): any | null {
    const layerList = this.getOrderedLayerList().filter((layer) => layer.id === id);
    if (layerList !== undefined && layerList.length > 0) {
      return layerList[0].getCesiumLayer();
    }

    return null;
  }

  changeLayerOrder(source: AbstractLayerTreeElement, target: AbstractLayerTreeElement, location: string) : void {
    if (source instanceof LayerGroupConfig && source.getAllChilds().indexOf(target) > 0) {
      console.error('cannot drop in child');
      return;
    }
    this.config.changeLayerOrder(source, target, location);
    this.computeZIndexAndVisibility();
  }

  computeZIndexAndVisibility() : void {
    const layers = this.config.getOrderedLayerList().reverse();
    // Sauvegarde du zIndex de la couche.
    for (let i = 0; i < layers.length; i += 1) {
      layers[i].layerIndex = i + 1;
      if (layers[i].getCesiumLayer() === undefined) {
        console.error('Cesium layer non présent', layers[i]);
      }
      if (!layers[i].baseLayer && layers[i].getCesiumLayer() !== undefined) {
        layers[i].getCesiumLayer().show = layers[i].isLayerVisible();
      }
    }
    for (let i = 0; i < layers.length; i += 1) {
      this.applyChangeLayerOrder(layers[i].getCesiumLayer(), layers[i]);
    }
  }

  applyChangeLayerOrder(layer: any, layerconfig: LayerConfig) : void {
    /// Ne plus faire ces boucles pour le ZINDEX
    let currentIndex = this.viewer.imageryLayers.indexOf(layer);
    if (currentIndex >= 0 && currentIndex < layerconfig.layerIndex) {
      if (layerconfig.layerIndex >= this.viewer.imageryLayers.length) {
        this.viewer.imageryLayers.raiseToTop(layer);
      } else {
        while (currentIndex < layerconfig.layerIndex) {
          this.viewer.imageryLayers.raise(layer);
          currentIndex = this.viewer.imageryLayers.indexOf(layer);
        }
      }
    } else if (currentIndex > layerconfig.layerIndex) {
      while (currentIndex > layerconfig.layerIndex) {
        this.viewer.imageryLayers.lower(layer);
        currentIndex = this.viewer.imageryLayers.indexOf(layer);
      }
    }
  }

  // dans la 3D cette fct ne  change plus le zindex des couches
  setZlevelOpacityAndResolutions(layer: any, layerconfig: LayerConfig) : void {
    // layer.setZIndex(layerconfig.layerIndex);
    console.log(layerconfig);
    /*
    const maxResolution = this.getMinMaxResolutionInfos(layerconfig).maxResolution;
    if (maxResolution) {
      layer.setMaxResolution(maxResolution);
    }

    const minResolution = this.getMinMaxResolutionInfos(layerconfig).minResolution;
    if (minResolution) {
      layer.setMinResolution(minResolution);
    }
  */

    // Gestion de l'opacité de la couche
    if (layerconfig.opacity) {
      layer.alpha = layerconfig.opacity;
      layerconfig.setOpacity(layerconfig.opacity);
    }
  }

  addModel(layer: LayerConfig, position = null) {
    // const { viewer } = this;
    /* const { canvas } = viewer.scene;

    const ray = viewer.camera.getPickRay(new Cesium.Cartesian2(
      Math.round(canvas.clientWidth / 2),
      Math.round(canvas.clientHeight / 2),
    )); */
    // const position = viewer.scene.globe.pick(ray, viewer.scene);
    const isUserAdded = position !== null;
    if (position == null) {
      const positionWGS84 = this.reprojectPoint(layer.modelConfig.position, layer.modelConfig.projection, 'EPSG:4326');
      position = Cesium.Cartesian3.fromDegrees(positionWGS84[0], positionWGS84[1], layer.modelConfig.position[2]);
    }

    if (Cesium.defined(position)) {
      const cartesianPos = position;
      // cartesianPos.z += 3;
      const heading = Cesium.Math.toRadians(layer.modelConfig.orientation);
      const pitch = Cesium.Math.toRadians(0);
      const roll = Cesium.Math.toRadians(0);
      const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
      const orient = Cesium.Transforms.headingPitchRollQuaternion(cartesianPos, hpr);
      const entity = this.viewer.entities.add({
        position: cartesianPos,
        orientation: orient,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        model: {
          uri: layer.url,
          scale: layer.modelConfig.scale,
          silhouetteColor: Cesium.Color.YELLOW,
          silhouetteAlpha: 1.0,
          silhouetteSize: 0.0,
        },
      });
      this.layers[layer.id] = entity;
      entity.layerConfig = layer;
      layer.setCesium_Layer(entity);
      if (isUserAdded === true) {
        this.viewer.flyTo(entity);
      } 
      // on ne peut pas acceder aux materiaux tant que la primitive n'est pas chargée
      this.checkModelReady(entity,layer);
     
    }
  }

  checkModelReady(entity:any,layer:any){
    var boundingSphere = new Cesium.BoundingSphere();
    let state='';
    try{
      state = this.viewer.dataSourceDisplay.getBoundingSphere(entity, false, boundingSphere);
    }catch(e){
      console.log(e);
    }
    
    console.log(state);
    // controle que la primitive est chargée
    if(state !== Cesium.BoundingSphereState.DONE){
      setTimeout(()=>{
        this.checkModelReady(entity,layer);
      },100);
    }else{
      console.log('ready!')
      let model=this.getModelForEntity(entity);
      console.log(model)
      let matCfg:any=layer.modelConfig.materials;
      Object.keys(matCfg).forEach(function(key) {
      
      if(model){
        const material = model.getMaterial(key);
        let colorValue=matCfg[key];
        entity.model.color = Cesium.Color.fromAlpha(Cesium.Color.WHITE, Cesium.Color.fromCssColorString(colorValue).alpha);
        material.setValue('baseColorFactor', Cesium.Cartesian4.fromColor(Cesium.Color.fromCssColorString(colorValue)));
      }

      });
    }
  }

  getModelForEntity(entity:any) {
    var primitives = this.viewer.scene.primitives;
    for (var i = 0; i < primitives.length; i++) {
      var primitive = primitives.get(i);
      if (primitive instanceof Cesium.Model && primitive.id === entity) {
        return primitive;
      }
    }
    
  }

  getMinMaxResolutionInfos(layerconfig: LayerConfig) : any {
    let maxResolution;
    let minResolution;

    if (layerconfig.maxScaleDenominator) {
      maxResolution = MapUtils.getResolutionFromScale(layerconfig.maxScaleDenominator, 'm');
    }
    if (layerconfig.minScaleDenominator) {
      minResolution = MapUtils.getResolutionFromScale(layerconfig.minScaleDenominator, 'm');
    }
    return {
      maxResolution,
      minResolution,
    };
  }

  updateLayerIGO(layer: LayerConfig) : any {
    // si type geojson : on rebuild à la main
    // sinon update classique
    if (layer.type === 'Vector') {
      // layer.updateStyle();  // IGO TODO : si besoin de récup contenu svg selected => décommenter
      this.forceUpdateStyle(layer);
    } else {
      layer.updateStyle();
    }

    // TODO adaptations cesium
    /*
    if (layer.authenticationInfos && layer.getOlLayer()) {
      this.layerManager.addLoadingFunction(layer.getLayerSource(), layer, this.contextService.getCsrfToken());
      let key = layer.url;
      if (layer.authenticationInfos.store) {
        this.contextService.addAuthInfo(key, layer.authenticationInfos);
      }
      else {
        this.contextService.removeAuthInfo(key);
      }
    }

    if (layer.Attribution && layer.getOlLayer()) {
      layer.getLayerSource().setAttributions(layer.getAttribution())
    }
    if (layer.getOlLayer()) {
      this.setZlevelOpacityAndResolutions(layer.getOlLayer(), layer);
    }
    */
    const bbox = layer.boundingBoxEPSG4326;
    if (layer.maxScaleDenominator && layer.minScaleDenominator && layer.maxScaleDenominator < layer.minScaleDenominator) {
      return {
        success: false,
        message: 'L\'échelle maximum de rendu doit être supérieure à l\'échelle minimum.',
      };
    }

    return { success: true };
  }

  updateLayer(layer: LayerConfig):any {
    this.removeLayerFromMap(layer);
    this.addLayer(layer);
    return { success: true, message: '' };
  }

  getVectorFeatureAtPixel(event:any, layer:LayerConfig) {
    console.log(event);
    const { viewer } = this;
    const picked = viewer.scene.pick(event.position);
    if (Cesium.defined(picked)) {
      const id = Cesium.defaultValue(picked.id, picked.primitive.id);
      if (id instanceof Cesium.Entity) {
        return [picked]; // id;
      }
    }
    return [];
  }

  setOffsetZ(layer: LayerConfig) : void {
    const transEgm = Number(layer.layer3dConfig.getOffsetZ());
    const cartographic:any = this.get3DtilesOrigin(layer.cesium_Layer);

    const surface = Cesium.Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      0.0,
    );

    const cartesianoffset = Cesium.Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      transEgm,
    );

    const translation = Cesium.Cartesian3.subtract(
      cartesianoffset,
      surface,
      new Cesium.Cartesian3(),
    );

    layer.cesium_Layer.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
  }

  setScaleZ(layer: LayerConfig) : void {
    layer.cesium_Layer.root.transform = this.getScaleTransform(layer.cesium_Layer, Number(layer.layer3dConfig.scaleZ / 100), layer.layer3dConfig.offsetZ);
  }

  getEastNorthUpTransform(tileset: any, relativeHeight: number) : void {
    const cartographic = this.get3DtilesOrigin(tileset);
    // cartographic.height = relativeHeight;
    const origin = Cesium.Cartographic.toCartesian(cartographic);
    const enu = Cesium.Transforms.eastNorthUpToFixedFrame(origin);
    return enu;
  }

  getScaleTransform(tileset: any, scale: number, relativeHeight: number) : void {
    const toGlobal = this.getEastNorthUpTransform(tileset, relativeHeight);
    const toLocal = Cesium.Matrix4.inverse(toGlobal, new Cesium.Matrix4());
    const localScale = new Cesium.Cartesian3(1.0, 1.0, scale); //
    const localScaleMatrix = Cesium.Matrix4.fromScale(localScale);
    const transformMat = Cesium.Matrix4.multiply(toGlobal, Cesium.Matrix4.multiply(localScaleMatrix, toLocal, new Cesium.Matrix4()), new Cesium.Matrix4());
    return transformMat;
  }

  get3DtilesOrigin(tileset: any) : void {
    let origin = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromDegrees(2, 43, 0));
    if (tileset !== undefined && tileset.boundingSphere !== undefined) {
      if (tileset.boundingSphere_origin === undefined) {
        tileset.boundingSphere_origin = tileset.boundingSphere.center.clone();
      }
      origin = tileset.boundingSphere_origin; // tileset.boundingSphere.center;
      const cartographic = Cesium.Cartographic.fromCartesian(origin);
      if (Cesium.defined(cartographic)) {
        origin = cartographic.clone();
      }
    }
    return origin;
  }

  isTilesetScalable(layer: LayerConfig): boolean {
    const tileset = layer.cesium_Layer;

    const origin = tileset.boundingSphere.center;
    const cartographic = Cesium.Cartographic.fromCartesian(origin);

    if (Cesium.defined(cartographic)) {
      return true;
    }
    return false;
  }

  addTMSLayer(layer: LayerConfig): void {
    throw new Error('Method not implemented.');
  }

  zoomToInitialExtent(): void {
    throw new Error('Method not implemented.');
  }

  zoomToExtent(extent: number[]): void {
    this.zoomToBboxWGS84(extent);
  }

  setNord(): void {
    if (this.viewer === undefined) return;
    const new_lat = Cesium.Math.toDegrees(Cesium.Cartographic.fromCartesian(this.viewer.scene.camera.position).latitude);
    const new_lon = Cesium.Math.toDegrees(Cesium.Cartographic.fromCartesian(this.viewer.scene.camera.position).longitude);
    const alt = Cesium.Cartographic.fromCartesian(this.viewer.scene.camera.position).height;

    this.viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(new_lon, new_lat, alt),
      orientation: {
        heading: Cesium.Math.toRadians(0),
        pitch: this.viewer.scene.camera.pitch,
        roll: this.viewer.scene.camera.roll,
      },
    });
  }

  preRenderSwipe(event:any) {
    throw new Error('Method not implemented.');
  }

  forceRender() {
    throw new Error('Method not implemented.');
  }
}
const cesiumMapServiceInstance = new CesiumMapService();
export default CesiumMapService;
export { CesiumMapService, cesiumMapServiceInstance };
