export default class LayerAttribute {
    name!: string;

    label!: string;

    maxOccurs!: number;

    minOccurs!: number;

    nillable!: boolean;

    type!: string;

    widgetType!: string;

    widgetConfig!: any;

    localType!: string;

    display!: boolean;

    editable!: boolean;

    constructor(input?: unknown) {
      if (input) {
        Object.assign(this, input);
      }
    }

    isBoolean():boolean {
      return this.type === 'xsd:boolean';
    }

    isText():boolean {
      return this.type === 'xsd:string';
    }

    isDate():boolean {
      return this.type === 'xsd:date';
    }

    isDateTime():boolean {
      return this.type === 'xsd:date-time';
    }

    isNumber():boolean {
      return this.type === 'xsd:number' || this.type === 'xsd:double' || this.type === 'xsd:int';
    }

    isMandatory():boolean{
      let nillable:any=this.nillable;
      return nillable==false||nillable=="false";
    }

    toJson():any {
      return {
        name: this.name,
        label: this.label,
        widgetType: this.widgetType,
        widgetConfig:this.widgetConfig,
        maxOccurs: this.maxOccurs,
        minOccurs: this.minOccurs,
        nillable: this.nillable,
        type: this.type,
        localType: this.localType,
        display: this.display,
        editable: this.editable,
      };
    }
}
