











































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import LayerListPanel from '@/components/layers/LayerListPanel.vue';
import FeatureInfosPanel from '@/components/map/FeatureInfosPanel.vue';
import PluginListPanel from '@/components/plugins/PluginListPanel.vue';
import SettingsPanel from '@/components/settings/SettingsPanel.vue';
import Plugin from '@/models/plugin/Plugin';
import { PluginLocation } from '@/models/plugin/PluginLocation';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';
import BaseComponent from './BaseComponent';
import PresentationPanel from './PresentationPanel.vue';



@Component({
  props: {},
  components: {
    LayerListPanel,
    PresentationPanel,
    PluginListPanel,
    FeatureInfosPanel,
    SettingsPanel,
  },
})
export default class LeftSideBar extends BaseComponent {
  visiblePanel='';

  dividerPosition= '30';

  visiblePluginPanel:Plugin|null=null;

  pluginsToolBar:Plugin[]=[];

  PluginLocation=PluginLocation;

  firstleftSideBarItems= 
    {
      icon: 'info',
      label: 'Informations sur la carte',
      editionOnly: false,
      panel: PresentationPanel.ID,
    }
  ;

  leftSideBarItems = [
    {
      icon: 'gear',
      label: 'Réglages',
      editionOnly: true,
      panel: SettingsPanel.ID,
    },
    {
      icon: 'puzzle',
      label: 'Modules',
      editionOnly: true,
      panel: PluginListPanel.ID,
    },
  ];

  onlyLeftActive(item:Plugin) {
    return (item.isActive ||(item.defaultConfiguration.activeInEdition && this.contextService.isEditionActive)) && item.configuration.position === PluginLocation.LeftToolBar && !(item.isOnly3D && !this.is3DView());
  }

  created() {
    this.loadPlugins();
  }

  mounted() {
    EventBus.$on(EVENTS.OPEN_LEFT_SIDE_MENU, (menu:any) => this.openMenu(menu));
    EventBus.$on(EVENTS.SELECT_FEATURE, (features:any) => this.onSelectFeature(features));

    console.log('show pres')
    if( this.contextService.currentContext && this.contextService.currentContext.showPresentation){

      this.visiblePanel = PresentationPanel.ID;
    }
  }


  get showInfoMenu(){
    let content:any = this.contextService.currentContext?.presentation;
    if(content==undefined||content.length==0){
      return false;
    }
    return true;
  }
  isIconCustomImg(item:any) {
    return item.configuration.icon && item.configuration.icon.indexOf('.png') > 0;
  }

  onSelectFeature(features:any) {
    if (this.pluginService.isActive(FeatureInfosPanel.NAME)
      && !this.pluginService.getBooleanParameter(FeatureInfosPanel.NAME, FeatureInfosPanel.MOVABLE)) {
      this.openMenu(FeatureInfosPanel.NAME);
    }
  }

  openMenu(menu:string) {
    this.visiblePanel = menu;
  }

  menuActivated(item:any) {
    console.log(item);
    this.dividerPosition = '30';
    if (item.panel && this.visiblePanel !== item.panel) {
      this.visiblePanel = item.panel;
      this.visiblePluginPanel = null;
    } else {
      this.visiblePanel = '';
      this.visiblePluginPanel = null;
    }
  }

  menuPluginActivated(item:Plugin) {
    console.log(item);
    if (item.componentName && this.visiblePanel !== item.componentName) {
      this.visiblePanel = item.componentName;
      this.visiblePluginPanel = item;
      if (item.id == 'dataviz'){
        this.dividerPosition = '40'
      }else{
        this.dividerPosition = '30'
      }
    } else {
      this.visiblePanel = '';
      this.visiblePluginPanel = null;
    }
  }
}
