





















/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';
import AlertMessage from '../../models/shared/AlertMessage';
import BaseComponent from '../BaseComponent';

@Component
export default class AlertPanel extends BaseComponent {
  message='';

  notif='';

  showDismissibleAlert=false;

  variant = 'danger';

  dismissCountDown = 10;

  alertConfig:AlertMessage|undefined=new AlertMessage({});

  mounted() {
    EventBus.$on(EVENTS.ERROR, (error:any) => this.onError(error));
    EventBus.$on(EVENTS.WARNING, (message:AlertMessage) => this.onWarning(message));
    EventBus.$on(EVENTS.INFO, (error:any) => this.onInfo(error));
    EventBus.$on(EVENTS.NOTIFICATION, (notif:string) => this.onNotification(notif));
  }

  onClose() {
    this.dismissCountDown = 0;
    this.showDismissibleAlert = false;
  }

  onError(error:any) {
    this.alertConfig = undefined;
    console.log(error);
    if (error === '') {
      this.showDismissibleAlert = false;
    } else {
      this.variant = 'danger';
      this.message = `${error}`;
      this.showDismissibleAlert = true;
    }
  }

  countDownChanged(dismissCountDown:any) {
    if (this.alertConfig && this.alertConfig.autoHide) {
      this.dismissCountDown = dismissCountDown;
    }
  }

  onWarning(message: AlertMessage) {
    this.message = message.label;
    this.alertConfig = message;
    this.dismissCountDown = message.countDown;
    this.showDismissibleAlert = true;
  }

  onInfo(error:any) {
    this.alertConfig = undefined;
    console.log(error);
    if (error === '') {
      this.showDismissibleAlert = false;
    } else {
      this.variant = 'success';
      this.message = `${error}`;
      this.showDismissibleAlert = true;
    }
  }

  onNotification(notif: string) {
    console.log('notif');
    this.notif = notif;
    setTimeout(() => {
      this.notif = '';
    },
    10000);
  }

  get isError() {
    return this.variant === 'danger';
  }

  get isNotif() {
    return this.notif !== '';
  }
}
