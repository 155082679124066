import Vue from 'vue';

const EventBus = new Vue({
  methods: {
    traceListener:function() {
    console.log((<any>this)._events)
  }
}
}
  
);

const EVENTS = {
  CONFIG_LOADED: 'CONFIG_LOADED',
  ERROR: 'ERROR',
  INFO: 'INFO',
  NOTIFICATION: 'NOTIFICATION',
  WARNING: 'WARNING',
  MAP_INIT_END: 'MAP_INIT_END',
  MAP_CURRENT_BBOX_CHANGE: 'MAP_CURRENT_BBOX_CHANGE',
  MAP_CURRENT_ZOOM_LEVEL_CHANGE: 'MAP_CURRENT_ZOOM_LEVEL_CHANGE',
  PLUGIN_ACTIVATION_CHANGE: 'PLUGIN_ACTIVATION_CHANGE',
  OPEN_ATTRIBUTES: 'OPEN_ATTRIBUTES',
  OPEN_LAYER_CONFIG_TAB:'OPEN_LAYER_CONFIG_TAB',
  START_EDITION: 'START_EDITION',
  STOP_EDITION: 'STOP_EDITION',
  ENABLE_SELECTION: 'ENABLE_SELECTION',
  DISABLE_SELECTION: 'DISABLE_SELECTION',
  SELECT_FEATURE: 'SELECT_FEATURE',
  SELECT_NO_FEATURE: 'SELECT_NO_FEATURE',
  LAYER_ADDED: 'LAYER_ADDED',
  LAYER_UPDATED: 'LAYER_UPDATED',
  LAYER_REMOVED: 'LAYER_REMOVED',
  LAYER_ORDER_CHANGE: 'LAYER_ORDER_CHANGE',
  OPEN_LEFT_SIDE_MENU: 'OPEN_LEFT_SIDE_MENU',
  START_PHOTOMAILLAGE_EDITION: 'START_PHOTOMAILLAGE_EDITION',
  STOP_PHOTOMAILLAGE_EDITION: 'STOP_PHOTOMAILLAGE_EDITION',
  DASHBOARD_DISPLAY: 'DASHBOARD_DISPLAY',
  DASHBOARD_UPDATED: 'DASHBOARD_UPDATED',
  SWIPE_ENABLED: 'SWIPE_ENABLED'
};

export default EventBus;
export { EventBus, EVENTS };
