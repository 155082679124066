


















































import BaseComponent from '@/components/BaseComponent';
import FormNumberInput from '@/components/shared/FormNumberInput.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import Plugin from '@/models/plugin/Plugin';
import Component from 'vue-class-component';
import PluginEditPanel from './PluginEditPanel.vue';

@Component({
  props: {},
  components: {
    FormTextInput,
    FormNumberInput,
    PluginEditPanel,
  },
})
export default class PluginListPanel extends BaseComponent {
  static ID = 'PluginListPanel';

  plugins: Plugin[] = [];

  selectedPlugin: Plugin | null = null;

  mounted(): void {
    this.plugins = this.pluginService.plugins;
  }

  onEdit(plugin: Plugin): void {
    this.selectedPlugin = plugin;
  }
}
