




























































































/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SingleLayerElement from '@/components/layers/SingleLayerElement.vue';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import Plugin from '@/models/plugin/Plugin';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  name: 'GroupLayerElement',
  props: {
    layer: LayerGroupConfig,
    plugin: {},
  },
  components: {
    SingleLayerElement,
    GroupLayerElement,
  },
})
export default class GroupLayerElement extends BaseComponent {
  layer!: LayerGroupConfig;

  plugin!:Plugin;

  showConfigBtn = true;

  collapsed = false;

  mounted() {
    this.showConfigBtn = this.plugin && this.plugin.getParameterValue('showConfigBtn');
    this.collapsed = this.layer.collapsed;
  }

  get childs() {
    return this.layer.childs;
  }

  get visible(): boolean {
    return this.layer.visible;
  }
  
  duplicate(){
    let jsonDef=this.layer.toJsonDuplication();
    console.log(jsonDef);
    let newLayerGroup = new LayerGroupConfig(jsonDef);
    this.contextService.getCurrentContext().getLayers().unshift(newLayerGroup);
    this.getMapService().addLayerGroup(newLayerGroup);
  }

  isFirstElementInTree() {
    return (
      this.contextService.getCurrentContext().getLayers()[0] === this.layer
      || (this.layer.parent !== undefined
        && this.layer.parent.childs[0] === this.layer)
    );
  }

  isEmptyGroup() {
    return this.layer.childs.length === 0;
  }

  dragstartHandler(event: any): void {
    console.log('drag start');
    // setTimeout for chrome bug => see https://stackoverflow.com/questions/19639969/html5-dragend-event-firing-immediately
    setTimeout(()=>{
      this.contextService.setDraggingLayer(this.layer);
      event.dataTransfer.setData('text/plain', this.layer.id);
      event.dataTransfer.effectAllowed = 'move'; // only allow moves
    },10)

  }

  showDropTarget(): boolean {
    return (
      this.contextService.getDraggingLayer() !== null
      && this.contextService.getDraggingLayer() !== this.layer
    );
  }

  dragendHandler(event: any): void {
    event.preventDefault();
    console.log('drag group end');
    this.contextService.setDraggingLayer(null);
  }

  dropHandler(event: any, dropLocation: string) {
    event.preventDefault();
    // console.log('drop end');
    console.log(`drop ${dropLocation} ${this.layer.title}`);
    if (event.target !== undefined) {
      event.target.classList.remove('overed');
    }
    const dragLayer = this.contextService.getDraggingLayer();
    if (dragLayer !== null && dragLayer !== this.layer) {
      this.getMapService().changeLayerOrder(dragLayer, this.layer, dropLocation);
    }
   
  }

  dragEnterHandler(event: any) {
    event.preventDefault();
    // console.log('drag enter');
    // console.log(event);
    if (event.target !== undefined && event.target.style !== undefined) {
      event.target.classList.add('overed');
    }
  }

  dragLeaveHandler(event: any) {
    event.preventDefault();
    // console.log('drag leave');
    // console.log(event);
    if (event.target !== undefined && event.target.style !== undefined) {
      event.target.classList.remove('overed');
    }
  }

  dragOverHandler(event: any) {
    event.preventDefault();
    // console.log('drag over');
    if (event.dataTransfer !== null) {
      event.dataTransfer.dropEffect = 'move';
    }
  }

  infoGroup() {
    this.contextService.setCurrentAction('infoGroup', this.layer);
  }

  deleteGroup(): void {
    this.getMapService().removeGroupFromList(this.layer);
  }

  onChange(): void {
    this.layer.setVisible(!this.layer.visible);
  }

  toggle(): void {
    this.layer.collapsed = !this.layer.collapsed;
    this.collapsed = this.layer.collapsed;
  }
}
