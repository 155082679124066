







/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import Vue from 'vue';
import chroma from 'chroma-js';
import ColorPalette from '@/models/ColorPalette';

// eslint-disable-next-line no-use-before-define
@Component<ColorPalettePanel>({
  props: {
    colors: [],
    colorPalette: ColorPalette,
    nbColors: Number,
  },
  watch: {
    nbColors(nb: number) {
      this.refreshColors();
    },
  },
})
export default class ColorPalettePanel extends Vue {
  colors:any;

  colorList=[];

  colorPalette!:ColorPalette;

  nbColors!: number;

  mounted() {
    if (this.colors !== undefined) {
      this.colorList = this.colors;
    }
    this.refreshColors();
  }

  refreshColors() {
    if (this.colorPalette.name !== undefined) {
      this.colorList = chroma.scale(this.colorPalette.name).colors(this.nbColors);
    }
  }

  getStyle(color:string) {
    return { backgroundColor: color, width: `${100 / this.nbColors}%` };
  }
}
