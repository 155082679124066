import LayerConfig from '@/models/map-context/LayerConfig';
import axios, { AxiosResponse }  from 'axios';
import GeoJSONFormat from 'ol/format/GeoJSON';
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import {
  forkJoin,
  from,
  of,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';
import AbstractMapService from './AbstractMapService';


abstract class AbstractSelectionService {
    public selectedFeature: any;

    mapService!: AbstractMapService;

    defaultSelectionColor='rgba(255,244,8,0.1)';

    abstract addSelectionLayer():any;

    abstract addToSelection(feature:any):void;

    abstract cleanSelection():any;

    abstract cleanInfoBulle():any;

    abstract getFeatureInfoUrl(event: any,
        wmsOptions: any, layer: LayerConfig):string|undefined;

    abstract selectFeatures(allFeatures:any, infobulle: boolean, event:any):any;

    selectFeaturesAtPixel(event:any, infobulle = false): void {
      console.log('selectFeaturesAtPixel');
      this.addSelectionLayer();

      if (this.selectedFeature && !infobulle) {
        // on réinitialise vectorSource
        this.cleanSelection();
        this.selectedFeature = null;
      }

      // Toutes les couches sauf le fond de plan
      let layerList = this.mapService.getOrderedLayerList()
        .filter((layer:any) => !layer.baseLayer && layer.isLayerVisible() && layer.queryable && layer.inRange(this.mapService.currentScale));

      if (infobulle) {
        layerList = layerList.filter((layer:any) => layer.popupInfos && layer.popupInfos.active);
      }
      if (layerList && layerList.length > 0) {
        console.log('get feature infos');
        const calls = [];
        let vectorFeatures:any=[];
        for (let i = 0; i < layerList.length; i += 1) {
          const layer = layerList[i];
          // Fist check if a vector feature is selected
          const features = this.mapService.getVectorFeatureAtPixel(event, layer);
          if (features && features.length) {
            vectorFeatures=vectorFeatures.concat(features);
          }
          else if (!layer.isVector() && !layer.is3DModel()) {
            const format = layer.getFeatureInfoFormat;

            const layerListquery = layer.layername.split(',').reverse().join(',');
            const wmsOptions:any = {
              INFO_FORMAT: format,
              QUERY_LAYERS: layerListquery,
              BUFFER: layer.getBuffer(),
              FEATURE_COUNT: '100',
              FI_LINE_TOLERANCE: '10',
              EXCEPTIONS: 'XML',
              FI_POINT_TOLERANCE: '25',
              FI_POLYGON_TOLERANCE: '5',
              STYLES:''
            };
            if (layer.filter && layer.filter.length > 0) {
              wmsOptions.CQL_FILTER = layer.getFilterValue();
            }
            // Pour supprimer le paramètre SLD_BODY s'il était éventuellement présent
            wmsOptions.SLD_BODY = undefined;
            const url = this.getFeatureInfoUrl(event, wmsOptions, layer);
            if (url) {
              const headersObject:any = {

              };
              if (layer.authenticationInfos
                && layer.authenticationInfos.isFilled()) {
                headersObject.Authorization = layer.authenticationInfos.getBasicAutorisationValue();
              }
              this.mapService.layerManager.nbtileLoading += 1;
              calls.push(from(axios.get(url, { headers: headersObject/* responseType: 'text' */ }))
                .pipe(map((res:any) => {
                  this.mapService.layerManager.nbtileLoading -= 1;
                  // eslint-disable-next-line no-param-reassign
                  res.layerConfig = layer;
                  if ((res.data instanceof String || typeof res.data === 'string')
                    && res.data.indexOf('ServiceException') > 0) {
                  // throw 'Error';
                    layer.addError('ServiceException: Impossible d\'interroger les objets de la couche');
                    return { isError: true, error: 'ServiceException', layerConfig: layer };
                  }

                  if ((res.data instanceof String || typeof res.data === 'string')
                    && res.data.trim().length === 0) {
                  // throw 'Error';
                    layer.addError('Réponse vide: Impossible d\'interroger les objets de la couche');
                    return { isError: true, error: layer.error, layerConfig: layer };
                  }
                  return res;
                }))
                .pipe(
                  catchError((err) => {
                    this.mapService.layerManager.nbtileLoading -= 1;
                    console.warn(err);
                    layer.addError(err.message);
                    return of({ isError: true, error: err });
                  }),
                ));
            }
          }
        }

        if(calls.length>0){
          forkJoin(calls).subscribe((res) => {
            let allFeatures:any[] = [];
            for (let i = 0; i < res.length; i += 1) {
              const response:any = res[i];
              if (!response.isError) {
                let feats:any = [];
                if (response.layerConfig.getFeatureInfoFormat === 'application/vnd.ogc.gml') {
                  feats = (new WMSGetFeatureInfo()).readFeatures(response.data);
                } else {
                  feats = (new GeoJSONFormat()).readFeatures(response.data);
                }
                feats = feats.map((f:any) => { f.layerConfig = response.layerConfig; return f; });
                allFeatures = allFeatures.concat(feats);
              }
            }
            allFeatures = vectorFeatures.concat(allFeatures);
            // Si aucun resultat on ferme l'infobulle
            if (infobulle && allFeatures.length === 0) {
              this.cleanInfoBulle();
            }
            this.selectFeatures(allFeatures, infobulle, event);
          },
          (error) => {
            console.log(error);
          });
        }else{
          if (infobulle && vectorFeatures.length === 0) {
            this.cleanInfoBulle();
          }
          this.selectFeatures(vectorFeatures, infobulle, event);
        }

      }
    }


}

export default AbstractSelectionService;
