















/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    label: String,
    options: { default: [] },
    cols: { default: 8 },
    labelcols: { default: 4 },
    value: {},
  },
})
export default class FormComboInput extends BaseComponent {
  label!:string;

  value:any;

  // cols!:number;

  // model:any=null;
  get inputValue() {
    return this.value;
  }

  set inputValue(val) {
    this.$emit('input', val);
  }
}
