import { DEVICE_PIXEL_RATIO } from 'ol/has';
import MapUtils from '../MapUtils';
// eslint-disable-next-line import/no-cycle
import LayerStyle from '../LayerStyle';

export default class SymbolStyle {
    public baseStyle!: SymbolStyle;

    svgIconUrl!: string;

    symbolStrokeColor!: string;

    symbolFillColor!: string;

    symbolStrokeWidth!: number;

    imgcache: any;

    svgData!: string;

    size: any;

    offsetZ = '0';

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
      if (LayerStyle.defaultStyle !== undefined) {
        this.setBaseStyle(LayerStyle.defaultStyle.pointSymbol);
      }
    }

    public setBaseStyle(baseStyle: SymbolStyle) : void {
      this.baseStyle = baseStyle;
    }

    getSvgContent():any {
      if (this.svgData) {
        return MapUtils.replaceSvgColor(this.svgData,
          this.getSymbolFillColor(),
          this.getSymbolStrokeColor(),
          this.getSymbolStrokeWidth());
      }
      return undefined;
    }

    setDefaultValues() :void {
      this.symbolFillColor = 'rgba(51, 153, 204, 0.5)';
      this.symbolStrokeColor = '#000000';
      this.symbolStrokeWidth = 1;
      this.svgIconUrl = '';
      this.size = '16';
      this.offsetZ = '0';
    }

    toJson():any {
      return {
        svgIconUrl: this.svgIconUrl,
        size: this.size,
        offsetZ: this.offsetZ,
        symbolStrokeColor: this.symbolStrokeColor,
        symbolFillColor: this.symbolFillColor,
        symbolStrokeWidth: this.symbolStrokeWidth,
      };
    }

    getImage() {
      let img;
      if (this.imgcache && this.imgcache.loadedUrl === this.getSvgSymbolUrl()) {
        // useCache=false;
        img = this.imgcache;
      } else {
        const svgurl = this.getSvgIconUrl();
        if (svgurl.length > 0) {
          // useCache=false;
          return this.fetchIcon();
        }
      }
      return this.imgcache;
    }

    fetchIcon() : HTMLImageElement {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const svgurl = this.getSvgIconUrl();
      const img = new Image();
      fetch(svgurl)
        .then(
          (response) => {
            if (response.status !== 200 && response.status !== 304) {
              return;
            }
            // Examine the text in the response
            response.text().then((data) => {
              let svgData = data;

              svgData = svgData.replace('<?xml version="1.0" encoding="UTF-8" standalone="no"?>', '')
                .replace('<?xml version="1.0" encoding="UTF-8"?>', '');

              self.svgData = svgData;
              svgData = self.getSvgContent();

              img.src = `data:image/svg+xml; charset=utf8, ${encodeURIComponent(svgData)}`;
              self.imgcache = img;
              self.imgcache.loadedUrl = self.getSvgSymbolUrl();
            });
          },
        )
        .catch((err) => {
          console.log('Fetch Error :-S', err);
        });
      return img;
    }

    getSvgIconUrl(): string {
      return this.svgIconUrl !== undefined ? this.svgIconUrl : this.baseStyle.getSvgIconUrl();
    }

    getSvgSymbolUrl(): string {
      let url = window.location.origin + window.location.pathname;
      const fillcolor = MapUtils.rgba2hex(this.getSymbolFillColor());
      const strokecolor = MapUtils.rgba2hex(this.getSymbolStrokeColor());
      url += `${this.getSvgIconUrl()}?`;
      if (fillcolor.length > 0) {
        url += `&amp;fill=${fillcolor}&amp;fill-opacity=${MapUtils.rgba2opacity(this.getSymbolFillColor())}`;
      }
      if (strokecolor.length > 0) {
        url += `&amp;stroke=${strokecolor}&amp;stroke-width=${this.getSymbolStrokeWidth()}`;
      }
      return url;
    }

    getSymbolFillColor(): string {
      return (this.symbolFillColor !== undefined && this.symbolFillColor.length > 0)
        ? this.symbolFillColor : this.baseStyle.getSymbolFillColor();
    }

    getSymbolStrokeColor(): string {
      return (this.symbolStrokeColor !== undefined && this.symbolStrokeColor.length > 0)
        ? this.symbolStrokeColor : this.baseStyle.getSymbolStrokeColor();
    }

    getSymbolStrokeWidth(): number {
      return this.symbolStrokeWidth !== undefined
        ? this.symbolStrokeWidth : this.baseStyle.getSymbolStrokeWidth();
    }

    getSize(): any {
      return this.size !== undefined ? this.size : this.baseStyle.getSize();
    }

    getOffsetZ(): any {
      return this.offsetZ !== undefined ? this.offsetZ : this.baseStyle.getOffsetZ();
    }
}
