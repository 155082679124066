











































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { OGCLayer } from '@/models/RemoteServer';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  name: 'OgcLayerResult',
  props: {
    layer: {},
    layerType: String,
    selectedLayerList: {},
    selectedStyle: {},
  },
})
export default class OgcLayerResult extends BaseComponent {
  layer!:any;

  layerType!:string;

  selectedLayerList !:any;

  selectedStyle !:any;

  layerGroup = false;

  hasMultipleStyle = false;

  showSubLayers = true;

  toggle() :void {
    this.showSubLayers = !this.showSubLayers;
  }

  isSelected(result:OGCLayer): boolean {
    if (this.selectedLayerList.length === 0) {
      return false;
    }
    return this.selectedLayerList.find((x:OGCLayer) => x.Title === result.Title) !== undefined;
  }

  mounted() {
    console.log(this.layer);
    if (this.layer.Style === undefined && this.layer.Layer === undefined) {
      this.layerGroup = true;
    }
    if (this.layer.Style !== undefined && this.layer.Style.length > 1) {
      this.hasMultipleStyle = true;
    }
  }

  public get hasSubLayers():boolean {
    if (this.layer.Layer !== undefined && this.layer.Layer.length > 0) {
      return true;
    }
    return false;
  }

  selectLayer(layer:any) :void{
    console.log(this.layer);
    this.$emit('selectLayer', layer);
  }
}

