





























/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/*  eslint-disable no-param-reassign */
import Component from 'vue-class-component';
import { adminServiceInstance, AdminService } from '@/services/AdminService';
import User from '@/models/user/User';
import EventBus, { EVENTS } from '@/services/EventBus';
import ActionButton from '@/components/shared/ActionButton.vue';
import BaseComponent from '../../BaseComponent';
import UserListTable from './ListUserTable.vue';

@Component({
  props: {
  },
  components: {
    ActionButton,
    UserListTable,
  },
})
export default class UserListPanel extends BaseComponent {
  adminService=adminServiceInstance;

  mounted() {
    // this.loadUsers();
  }

  ModalConfirmation = false;

  userToDelete= null as User | null;

  prepareDeleteUser(user: User) {
    // Prépare la suppression en stockant l'élément à supprimer
    this.userToDelete = user;
    this.ModalConfirmation = true;
  }

  deleteUser(user:User) {
    this.adminService.deleteUser(user).then((reponse:any) => {
      console.log(reponse);
      EventBus.$emit(EVENTS.INFO, 'Sauvegarde effectuée');
      // this.loadUsers();
      this.ModalConfirmation = false;
      this.userToDelete = null;
      const t:any = this.$refs.userList;
      t.refresh();
    });
  }

  openUser(user:User) {
    this.$router.push({ path: `/users/${user.id}` });
  }
}
