















































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import SymbolStyleDefinition from '@/components/layers/style/SymbolStyleDefinition.vue';
import FormTextInput from '@/components/shared/FormTextInput.vue';
import StrokeStyle from '@/models/map-context/style/StrokeStyle';
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseComponent from '../../BaseComponent';

@Component({
  props: {
    strokeStyle: StrokeStyle,
    is3DView: Boolean,
  },
  components: {
    SymbolStyleDefinition,
    FormTextInput,
  },
})
export default class StrokeStyleDefinition extends Vue {
  strokeStyle!:StrokeStyle;

  motifList=StrokeStyle.PATTERN_TYPES;

  lineCapList=StrokeStyle.LINECAP_TYPES;

  lineJoinList=StrokeStyle.LINEJOIN_TYPES;

  lineJoin=this.lineJoinList.find((x) => x.id === this.strokeStyle.getLineJoin());

  get hasMotif() {
    return this.strokeStyle.pattern !== undefined
    && this.strokeStyle.pattern !== ''
    && this.strokeStyle.pattern !== 'simple';
  }

  get pattern() {
    return this.strokeStyle.getPattern();
  }

  onChangeType(event:any) {
    this.strokeStyle.pattern = event;
  }

  get lineCap() {
    return this.strokeStyle.getLineCap();
  }

  onChangeLineCap(event:any) {
    this.strokeStyle.lineCap = event;
  }

  onChangeLineJoin(event:any) {
    console.log('change LineJoin');
    this.strokeStyle.lineJoin = event.id;
    this.inputChange();
  }

  inputChange() {
    this.$emit('symboChange', '');
  }
}
