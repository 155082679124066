















































































































































































































/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import FormTextInput from '@/components/shared/FormTextInput.vue';
import MapContext from '@/models/map-context/MapContext';
import { adminServiceInstance } from '@/services/AdminService';
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';
import FormHtmlEditor from '../shared/FormHtmlEditor.vue';

interface droitAccess {
  id: number;
  group: string;
  access: string;
  // Autres propriétés spécifiques à l'objet
}

@Component({
  props: {},
  components: {
    FormTextInput,
    FormHtmlEditor
  },
})
export default class SettingsPanel extends BaseComponent {
  static ID = 'SettingsPanel';

  config:MapContext|null = null;

  blobCapture:any = null;

  adminService=adminServiceInstance;

  selectedgroup= '';

  selectedAccess= '';

  ListDroit: droitAccess[] = [];

  canShowEditRights = false;

  itemId= 1;

  groups = [];

  mounted() {
    this.config = this.getMapService().config;
    this.canShowEditRights = this.contextService.configuration.standalone;
    this.remoteListGroup();
    this.remoteListDroit();
  }

  removeDroit(index: number) {
    this.ListDroit.splice(index, 1);
    this.contextService.setAccessList(this.ListDroit);
  }

  refreshDroit(index: number, nouvelAccess: string) {
    this.ListDroit[index].access = nouvelAccess;
  }

  addAutorisation() {
    this.ListDroit.push({
      // eslint-disable-next-line no-plusplus
      id: this.itemId++,
      access: this.selectedAccess,
      group: this.selectedgroup,
    });

    this.contextService.setAccessList(this.ListDroit);

    // Réinitialise les champs
    this.selectedAccess = '';
    this.selectedgroup = '';
  }


  readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  async changeThumbmail(event:any) {
    const selectedFile:any = event?.target?.files?.[0];
    if (selectedFile && selectedFile instanceof File) {
      this.blobCapture = await this.readFileAsDataURL(selectedFile);
    } 
  }

  captureScreen() {
    const newCanvas = document.createElement('canvas');
    const mapContext:any = newCanvas.getContext('2d');
    if (!this.is3DView()) {
      const mapcanvas:any = document.getElementsByClassName('ol-layer')[0].firstChild;
      // set dimensions
      const dim:any = this.getOpenLayersMapService().map.getSize();
      console.log(dim);

      const delta = (window.innerWidth * 30) / 100;
      // warning: since OL7 canvas size is not the same as map.getSize()
      const mapDelta = Math.round((mapcanvas.width * 30) / 100);

      const width = Math.round(dim[0] - delta);
      const height = Math.round(dim[1]);
      newCanvas.width = width;
      newCanvas.height = height;
      newCanvas.style.width = `${width}`;
      newCanvas.style.height = `${height}`;

      Array.prototype.forEach.call(
        document.querySelectorAll('.ol-layer canvas'),
        (canvas) => {
          if (canvas.width > 0) {
            const { opacity } = canvas.parentNode.style;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            const { transform } = canvas.style;
            // Get the transform parameters from the style's transform matrix
            const matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(',')
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix,
            );
            // décalage de l'image pour ne pas afficher la zone cachée par le panneau de gauche
            mapContext.drawImage(canvas, -mapDelta, 0);
          }
        },
      );
      this.blobCapture = newCanvas.toDataURL('image/jpeg', 0.5);
      const blob = this.dataURItoBlob(this.blobCapture);
      const app:any = this.contextService.currentApplication;
      app.setTumbnail(blob);
    } else {
      this.getCesiumMapService().viewer.render();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const screenshot = function (scene:any) {
        const { canvas } = scene;
        self.blobCapture = canvas.toDataURL('image/jpeg', 0.5);
        const blob = self.dataURItoBlob(self.blobCapture);
        const app:any = self.contextService.currentApplication;
        app.setTumbnail(blob);
        scene.postRender.removeEventListener(screenshot);
      };
      this.getCesiumMapService().viewer.scene.postRender.addEventListener(screenshot);

      this.getCesiumMapService().viewer.scene.requestRender();
      /*
      const mapDiv:any = document.getElementById('cesiumContainer');
      html2canvas(mapDiv, {
        allowTaint: false,
        useCORS: true,
      }).then((canvas) => {
        const dataImg = new Image();
        // console.log(canvas.toDataURL('image/png', 1.0));
        this.blobCapture = canvas.toDataURL('image/jpeg', 0.5);
        const blob = this.dataURItoBlob(this.blobCapture);
        const app:any = this.contextService.currentApplication;
        app.setTumbnail(blob);
      }); */
    }

    // mapContext.globalAlpha = 1;
    // mapContext.setTransform(1, 0, 0, 1, 0, 0);
  }

  dataURItoBlob(dataURI:any) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  useMapBounds() {
    this.getMapService().updateConfigLocationFromMap();
    this.config = this.getMapService().config;
  }

  remoteListGroup() {
    this.adminService.getListGroup().then((res:any) => {
      // console.log("GRPS RES: ",res);
      if (res.data) {
        this.groups = res.data.results;
      } else {
        this.groups = [];
      }
    });
  }

  remoteListDroit() {
    const mapId = this.contextService.getCurrentApplication().id;
    // console.log("MAPID: ",mapId);
    if (mapId !== undefined) {
      this.adminService.getMapAccess(mapId).then((res:any) => {
      // console.log("MAP ACCSS: ",res);
        if (res.data) {
          const result = res.data.results;
          for (let index = 0; index < result.length; index += 1) {
          // const grp = result[index].groupId;
          // const acc = result[index].access_type;
            this.ListDroit.push({
              id: this.itemId++,
              access: result[index].access_type,
              group: result[index].group,
            });
          }
        }
      });
    }
  }

  onChangeAccess(event:any) {
    // console.log("ON CHANGE ");
    // console.log(event.target);
    // console.log(event.target.value);
    // console.log(this.ListDroit);
    this.contextService.setAccessList(this.ListDroit);
  }

  get thumbnailSrc():any {
    try {
      if (this.blobCapture !== null) {
        return this.blobCapture;
      }
      if (this.contextService.currentApplication !== null) {
        return this.contextService.getThumbnailUrl(this.contextService.currentApplication);
      }
    } catch (error) {
      console.error(error);
      // EventBus.$emit(EVENTS.ERROR, error);
      return '';
    }
    return '';
  }
}
