/* eslint-disable camelcase */
export default class Group {
    id!:number;

    name!: string;

    constructor(input?: unknown) {
      if (input) {
        Object.assign(this, input);
      }
    }
}
