/* eslint-disable no-continue */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-named-as-default */
/* eslint-disable class-methods-use-this */
import LayerConfig from '@/models/map-context/LayerConfig';
import AbstractSelectionService from './AbstractSelectionService';
import CesiumMapService from './CesiumMapService';
import EventBus, { EVENTS } from './EventBus';

declare let Cesium: any;

export default class CesiumSelectionService extends AbstractSelectionService {
  mapService!: CesiumMapService;

  viewer:any;

  cesiumPickedObject: any;

  selectionDataSource:any;


  constructor(mapService:CesiumMapService) {
    super();
    this.mapService = mapService;
    this.viewer = this.mapService.viewer;

    this.viewer.screenSpaceEventHandler.setInputAction(
      this.pickAndSelectObject.bind(this),
      Cesium.ScreenSpaceEventType.LEFT_CLICK,
    );
  }

  pickAndSelectObject(e:any):void {
    this.selectFeaturesAtPixel(e);
  }

  createNoFeaturesEntity():any {
    return new Cesium.Entity({
      id: 'None',
      description: 'No features found.',
    });
  }

  cleanSelection() {
    if (this.selectionDataSource) {
      this.selectionDataSource.entities.removeAll();
    }
  }

  cleanInfoBulle() {
    // throw new Error('Method not implemented.');
  }

  getFeatureInfoUrl(event: any, wmsOptions: any, layer: LayerConfig) {
    if (layer.isVector() || layer.isWFS()) {
      return undefined;
    }
    const ray = this.viewer.camera.getPickRay(event.position);
    const cartesian = this.viewer.scene.globe.pick(ray, this.viewer.scene);

    const cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian);
    console.log(cartographic);
    const lon = Cesium.Math.toDegrees(cartographic.longitude);
    const lat = Cesium.Math.toDegrees(cartographic.latitude);
    const delta = 0.02;
    const bbox = `${lon - delta},${lat - delta},${lon + delta},${lat + delta}`;

    let url = layer.getFeatureInfoUrl();
    // const viewResolution = /** @type {number} */ (this.mapService.view.getResolution());
    url += `&I=50&J=50&WIDTH=100&HEIGHT=100&STYLES=&CRS=CRS:84&BBOX=${bbox}`;
    /*
    console.log(url);
    Cesium.GeoJsonDataSource.load(url, {
      clampToGround: true,
    }).then(
      (dataSource:any) => {
        console.log(dataSource);
        const p = dataSource.entities.values;
        // const toremove = [];
        for (let i = 0; i < p.length; i += 1) {
        // if (i === 0) {
          p[i].polygon.material = Cesium.Color.WHITE;
        // p[i].polygon.extrudedHeight = 5;
        // } else {
        //   toremove.push(p[i]);
        // }
        }
        // toremove.forEach((pe:any) => dataSource.entities.remove(pe));
        // this.viewer.dataSources.removeAll();
        this.viewer.dataSources.add(dataSource);
        dataSource.show = true;
      },
    );
    */
    return url;
  }

  selectFeatures(allFeatures: any, infobulle: boolean, event: any):void {
    if (!allFeatures || allFeatures.length === 0
        || (!allFeatures[0].layerConfig
          && !allFeatures[0].id.layerConfig)) {
      console.error('could not find layer for this feature');
      return;
    }

    if (!infobulle) {
      this.selectedFeature = allFeatures; // ajoute le feature en surcouche à la carte
      EventBus.$emit(EVENTS.SELECT_FEATURE, allFeatures);
      if (this.selectedFeature.length) {
        this.addToSelection(this.selectedFeature[0]);
      }
    }
  }

  addSelectionLayer():void {
    if (!this.selectionDataSource) {
      this.selectionDataSource = new Cesium.CustomDataSource('datasrc_selection');
      this.viewer.dataSources.add(this.selectionDataSource);
    }
  }

  addToSelection(pickedObject:any):void{
    console.log(pickedObject);
    if (Cesium.defined(pickedObject) && pickedObject.id && pickedObject.id.billboard) {
      if (this.cesiumPickedObject && this.cesiumPickedObject.id !== pickedObject.id) {
        this.cesiumPickedObject.id.billboard.scale = this.cesiumPickedObject.id.billboard.scaleold;
        this.cesiumPickedObject.id.billboard.color = this.cesiumPickedObject.id.billboard.colorold;
        this.cesiumPickedObject = undefined;
      }
      if (this.cesiumPickedObject === undefined) {
        if (!pickedObject.id.billboard.scale) {
          pickedObject.id.billboard.scale = 1.0;
        }
        pickedObject.id.billboard.scaleold = pickedObject.id.billboard.scale;
        pickedObject.id.billboard.scale *= 2.0;
        pickedObject.id.billboard.colorold = pickedObject.id.billboard.color;
        pickedObject.id.billboard.color = Cesium.Color.YELLOW;
        this.cesiumPickedObject = pickedObject;
      }
    } else if (this.cesiumPickedObject) {
      this.cesiumPickedObject.id.billboard.scale = this.cesiumPickedObject.id.billboard.scaleold;
      this.cesiumPickedObject.id.billboard.color = this.cesiumPickedObject.id.billboard.colorold;
      this.cesiumPickedObject = undefined;
    }
    if (pickedObject.layerConfig && !pickedObject.layerConfig.layer3dConfig) {
      pickedObject.layerConfig.layer3dConfig = {
        altitudeType: 'onterrain',
        altitudeOffset: 0,
      };
    }
    if (pickedObject.layerConfig && (pickedObject.layerConfig.isWMS() || pickedObject.layerConfig.isWMTS())) {
      this.mapService.addFeaturetoCesium(pickedObject, this.selectionDataSource, 'EPSG:4326', pickedObject.layerConfig);
    }
  }
}
