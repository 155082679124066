/* eslint-disable */ 
import Filter  from '../filter/Filter';
import LayerStyle from '../LayerStyle';

export default class StyleRule {
    value!: string;

    checked = false;

    active = true;

    refreshToken = 0;

    styleEdit = false;

    minScaleDenom!: number;

    maxScaleDenom!: number;

    label?: string;

    filter?: Filter;

    style: LayerStyle=new LayerStyle();

    constructor(input: any = null) {
      if (input) {
        Object.assign(this, input);
        this.style = new LayerStyle(input.style);
      }
    }

    clone() {
      return new StyleRule(this.toJson());
    }

    toJson(): any {
      return {
        value: this.value,
        minScaleDenom: this.minScaleDenom,
        maxScaleDenom: this.maxScaleDenom,
        label: this.label,
        style: this.style.toJson(),
      };
    }
}
