














/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import BaseComponent from '../BaseComponent';

@Component({
  props: {
    icon: String,
    label: String,
  },
})
export default class ActionButton extends BaseComponent {
  icon!:string;

  label!:string;

  action() {
    this.$emit('click');
  }
}
