import { Feature } from 'ol';

export default class DatavizConfig {
    url = '';

    active = false;

    activePopup = false;

    constructor(json: any = null) {
      if (json) {
        Object.assign(this, json);
      }
    }

    toJson():any {
      return {
        url: this.url,
        active: this.active,
        activePopup: this.activePopup,
      };
    }

    getUrl(feature: Feature<any>):string {
      const regEx = new RegExp(/{(.*?)}/, 'ig');
      return this.url.replace(regEx, (a:any, b:any) => feature.get(b));
    }
}
