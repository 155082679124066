

















/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable class-methods-use-this */
import PluginComponent from '@/components/plugins/PluginComponent';
import { EventBus, EVENTS } from '@/services/EventBus';
import Component from 'vue-class-component';

@Component
export default class CesiumMesurePlugin extends PluginComponent {
  activeDistance = false;

  activeSurface = false;

  showDistanceButton = true;

  showSurfaceButton = true;

  isAreaMode = false;

  mounted(): void {
    console.log('Cesium Mesure Plugin Mounted');
    this.showDistanceButton = this.plugin.getParameterValue('distances');
    this.showSurfaceButton = this.plugin.getParameterValue('surfaces');
  }

  beforeDestroy(): void {
    if (this.activeSurface || this.activeDistance) {
      this.removeInteraction();
    }
  }

  onClickDistance(): void {
    if (this.activeDistance || this.activeSurface) {
      this.removeInteraction();
    }
    this.activeSurface = false;
    this.activeDistance = !this.activeDistance;
    if (this.activeDistance) {
      this.isAreaMode = false;
      this.addInteraction();
    }
  }

  onClickSurface(): void {
    if (this.activeDistance || this.activeSurface) {
      this.removeInteraction();
    }
    this.activeDistance = false;
    this.activeSurface = !this.activeSurface;
    if (this.activeSurface) {
      this.isAreaMode = true;
      this.addInteraction();
    }
  }

  private removeInteraction(): void {
    EventBus.$emit(EVENTS.ENABLE_SELECTION);
  }

  private addInteraction(): void {
    EventBus.$emit(EVENTS.DISABLE_SELECTION);
  }
}
