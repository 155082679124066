/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-named-as-default */
import LayerConfig from '@/models/map-context/LayerConfig';
import MapContext from '@/models/map-context/MapContext';
// eslint-disable-next-line import/extensions
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import LayerGroupConfig from '@/models/map-context/LayerGroupConfig';
import { CapabilitiesService, capabilitiesServiceInstance } from '@/services/CapabilitiesService';
import CapabilitiesParser from '@/models/CapabilitiesParser';
import axios from 'axios';
import { Observable, forkJoin, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { contexteServiceInstance, ContextService } from '@/services/ContextService';
import LayerAttribute from '@/models/map-context/LayerAttribute';
import { transform } from 'ol/proj';
import EventBus, { EVENTS } from './EventBus';
import LayerManager from './LayerManager';
import { Coordinate } from 'ol/coordinate';
import CapabilitiesResponse from '@/models/CapabilitiesResponse';

abstract class AbstractMapService {
    public config!: MapContext;

    contextService=contexteServiceInstance;

    capabilitiesService=capabilitiesServiceInstance;

    layerManager = new LayerManager(this.contextService);

  

    currentScale = 0;

    constructor() {
      proj4.defs('EPSG:2154', '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      proj4.defs('EPSG:3395', '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs ');
      proj4.defs('EPSG:3944', '+proj=lcc +lat_1=43.25 +lat_2=44.75 +lat_0=44 +lon_0=3 +x_0=1700000 +y_0=3200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      proj4.defs('EPSG:3945', '+proj=lcc +lat_1=44.25 +lat_2=45.75 +lat_0=45 +lon_0=3 +x_0=1700000 +y_0=4200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      proj4.defs('EPSG:3946', '+proj=lcc +lat_1=45.25 +lat_2=46.75 +lat_0=46 +lon_0=3 +x_0=1700000 +y_0=5200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      proj4.defs('EPSG:3947', '+proj=lcc +lat_1=46.25 +lat_2=47.75 +lat_0=47 +lon_0=3 +x_0=1700000 +y_0=6200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      proj4.defs('EPSG:3948', '+proj=lcc +lat_1=47.25 +lat_2=48.75 +lat_0=48 +lon_0=3 +x_0=1700000 +y_0=7200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      proj4.defs('EPSG:3949', '+proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
      proj4.defs("EPSG:2972","+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
      
      EventBus.$on(EVENTS.CONFIG_LOADED, (config:any) => this.onConfigLoaded(config));

      
      register(proj4);
    }

    onConfigLoaded(conf:any){
      if(conf.extra_proj){
        conf.extra_proj.forEach((proj: { code: string; def: string | proj4.ProjectionDefinition; }) => {
          console.info("Registering projection:"+proj.code)
          proj4.defs(proj.code, proj.def);
        });
      }
      
      register(proj4);
    }

    reprojectPoint(point:number[], srsSrc:string, dstSrs:string):number[] {
      return transform(point, srsSrc, dstSrs);
    }

    abstract getVectorFeatureAtPixel(event:any, layer:LayerConfig):any;

    abstract removeLayerFromMap(layer: LayerConfig):void;

    abstract addWMSLayer(layer: LayerConfig):void;

    abstract addWFSLayer(layer: LayerConfig):void;

    abstract addWMTSLayerFromCapabilities(layer: LayerConfig, data:any):void;

    abstract addVectorLayer(layer: LayerConfig):void;

    abstract addTMSLayer(layer: LayerConfig):void;

    abstract addVectorTileLayerFromCapabilities(layer: LayerConfig, data:any):void;

    abstract addOSMLayer(layer: LayerConfig):void;

    abstract addOtherLayerType(layer: LayerConfig):void;

    abstract sortByZIndexgetLayersAsArray(): LayerConfig[];

    abstract zoomToExtent(extent:number[]):void;

    abstract zoomTo(location:Coordinate, zoomlevel:number, lon?:number, lat?:number) :void;

    abstract panTo(location:Coordinate, lon?:number, lat?:number) :void;

    abstract zoomToInitialExtent():void;

    abstract getMapExtent():any;

    abstract setNord():void;

    initLayersFromConfig():void {
      const layers = this.config.getLayers();
      for (let i = 0; i < layers.length; i += 1) {
        const lay = layers[i];
        if (lay instanceof LayerGroupConfig) {
          this.addLayerGroup(lay);
        } else {
          this.addLayer(<LayerConfig>lay);
        }
      }
    }
    addLayerGroup(lay: LayerGroupConfig){
      const childs = lay.getAllChildLayers();
      for (let j = 0; j < childs.length; j += 1) {
        this.addLayer(childs[j]);
      }
    }
    addLayer(layercfg: LayerConfig): void {
      this.computeZIndex(layercfg);

      if (layercfg.type === 'WMS') {
        this.addWMSLayer(layercfg);
      } else if (layercfg.type === 'WMTS') {
        this.addWMTSLayer(layercfg);
      } else if (layercfg.type === 'WFS') {
        this.addWFSLayer(layercfg);
      } else if (layercfg.type === 'VectorTile' && layercfg.protocol === 'TMS') {
        this.addTMSLayer(layercfg);
      } else if (layercfg.type === 'VectorTile') {
        this.addVectorTileLayer(layercfg);
      } else if (layercfg.type === 'Vector') {
        this.addVectorLayer(layercfg);
      } else if (layercfg.type === 'OSM') {
        this.addOSMLayer(layercfg);
      } else {
        this.addOtherLayerType(layercfg);
      }

      EventBus.$emit(EVENTS.LAYER_ADDED, layercfg);
    }

    addVectorTileLayer(layerconfig: LayerConfig):void {
      const self = this;
      const url = layerconfig.getWmtsCapabilitiesUrl();
      capabilitiesServiceInstance.getCapabilities(url).subscribe(
        (data) => self.addVectorTileLayerFromCapabilities(layerconfig, data),
        (error) => {
          layerconfig.addError(error.message);
          console.log(error);
        },
      );
    }
    refreshSyles(layerconfig: LayerConfig):void {
      const self = this;
      let url = layerconfig.getCapabilitiesUrl();
      if(layerconfig.isWFS()){
          url = layerconfig.wfsUrlToWms(url);
      }
      capabilitiesServiceInstance.getCapabilities(url).subscribe(
        (data) => {
          console.log(data);
          let reponse:CapabilitiesResponse=data;
          let layerFound = reponse.findLayer(layerconfig.layername);

            if(layerFound){
              layerconfig.styles=layerFound.Style.map((x:any)=>x.Name);
              //remove duplicates
              layerconfig.styles=layerconfig.styles.filter((value:any,index:number)=>layerconfig.styles.indexOf(value)===index);
            }
        },
        (error) => {
          layerconfig.addError(error.message);
          console.log(error);
        },
      );
    }
    /**
    * Ajoute une couche WMTS
    * @param layerconfig
    */
    addWMTSLayer(layerconfig: any) {
      const self = this;
      capabilitiesServiceInstance.getCapabilities(layerconfig.url)
        .subscribe(
          (data:any) => self.addWMTSLayerFromCapabilities(layerconfig, data),
          (error:any) => {
            layerconfig.addError(error.message);
            console.log(error);
          },
        );
    }

    computeZIndex(layercfg: LayerConfig) {
      if (layercfg.layerIndex === undefined && this.sortByZIndexgetLayersAsArray().length > 0) {
        layercfg.layerIndex = this.sortByZIndexgetLayersAsArray()[0].layerIndex + 1;
      }
    }

    getOrderedLayerList(): LayerConfig[] {
      return this.config.getOrderedLayerList();
    }

    removeLayerFromList(layer: LayerConfig):void {
      this.config.removeLayerFromList(layer);
      EventBus.$emit(EVENTS.LAYER_ORDER_CHANGE);
    }

    removeGroupFromList(layerGroup: LayerGroupConfig):void {
      this.removeGroupFromListInternal(layerGroup);
      EventBus.$emit(EVENTS.LAYER_ORDER_CHANGE);
    }

    private removeGroupFromListInternal(layerGroup: LayerGroupConfig) {
      for (const child of layerGroup.childs.slice()) {
        if (child instanceof LayerGroupConfig) {
          this.removeGroupFromListInternal(child);
        } else {
          this.config.removeLayerFromList(child);
          this.removeLayerFromMap(<LayerConfig>child);
        }
      }
      this.config.removeLayerFromList(layerGroup);
    }

    checkBboxValidity(bbox:any):boolean {
      const valid = bbox[0] >= -180 && bbox[0] <= 180
        && bbox[1] >= -90 && bbox[1] <= 90
        && bbox[2] >= -180 && bbox[2] <= 180
        && bbox[3] >= -90 && bbox[3] <= 90;
      return valid;
    }

    getLayerBeingEdited(): LayerConfig|undefined {
      const editingLayerList = this.getOrderedLayerList().filter((x) => x.editing === true);
      if (editingLayerList.length === 1) {
        return editingLayerList[0];
      }
      return undefined;
    }

    getFeaturesTypes(layer: LayerConfig): Observable<any> {
      const headers:any = {};
      if (layer.authenticationInfos
        && layer.authenticationInfos.isFilled()) {
        headers.Authorization = layer.authenticationInfos.getBasicAutorisationValue();
      }
      if (layer.getDescribeFeatureUrl() !== '') {
        let xml=from(axios.get(layer.getDescribeFeatureUrl(), { headers }))
          .pipe(
            map(
              (reponse:any) => {
                if (reponse.data.indexOf && reponse.data.indexOf('ExceptionText') > 0) {
                  console.log(reponse.data);
                  throw new Error('Impossible de récupérer lire la réponse');
                }
                if (reponse.data.featureTypes) {
                  return reponse.data;
                }

                return this.parseFeatureTypeFromXml(reponse.data);
              },
            ),
          );
          let json=from(axios.get(layer.getDescribeFeatureUrl()+'&outputFormat=application/json', { headers })).pipe(
            catchError((error) => {
              console.error('unable to get describe feature infos '+layer.getDescribeFeatureUrl()+'&outputFormat=application/json');
              return of(undefined);
          }))     
        return forkJoin([
          // Get the xml def to get annotations
          xml,
          // Get the json describe to check if attribute name and identifier exist
          json,
        ]).pipe(
          mergeMap((results) => {
            let json:any = results[1];
            let res=results[0];
            if(json && json.data && json.data.featureTypes && json.data.featureTypes.length>0){
              let attributeToAdd=json.data.featureTypes[0].properties.filter((x:any)=>x.name=='identifier'||x.name=='name');
              res.featureTypes[0].properties=attributeToAdd.concat(res.featureTypes[0].properties);
            }
              return of(res);
            }
            )
          );
        
        

        
      }
      if (layer.type === 'Vector' && layer.url !== undefined && layer.getLayerSource()) {
        const feats = layer.getLayerSource().getFeatures();
        if (feats.length > 0) {
          const properties = feats[0].getKeys().map((x:any) => ({ name: x, type: 'xsd:string' }));
          return of({ featureTypes: [{ properties }] });
        }
        return of(undefined);
      }
      if (layer.type === 'VectorTile' && layer.getLayerSource()) {
        const extent = this.getMapExtent();
        const feats = layer.getLayerSource().getFeaturesInExtent(extent);
        if (feats.length > 0) {
          const properties = Object.keys(feats[0].getProperties()).map((x:any) => ({ name: x, type: 'xsd:string' }));
          return of({ featureTypes: [{ properties }] });
        }
        return of(undefined);
      }
      return of(undefined);
    }

    checkWMTSCapacity(layer:LayerConfig) {
      layer.isWmtsAble = false;
      this.capabilitiesService.getCapabilities(layer.getWmtsCapabilitiesUrl())
        .subscribe(
          (data:any) => {
            console.log(data);
            const wmtsLayer = data.layers.find((x:any) => x.Identifier === layer.layername);
            if (wmtsLayer && wmtsLayer != null) {
              layer.isWmtsAble = true;
            }
          },
          (error:any) => {
            // layerconfig.error = error.message;
            console.log(error);
          },
        );
    }

    setAttributeDefinition(layer:LayerConfig, force = false):void {
      if ((!layer.layerProperties || layer.layerProperties.length === 0 || force) && layer.type !== 'Vector') {
        this.getFeaturesTypes(layer)
          .subscribe(
            (reponse: any) => {
              const result: any = reponse;
              if (result === 'WMTS') {
                console.log('pas de service WFS');
              } else if (result) {
                console.log('get layer attributes infos');
                if (result.featureTypes) {
                  layer.layerProperties = result.featureTypes[0].properties.map((x:any) => new LayerAttribute(x));
                  console.log(layer.layerProperties);
                  layer.targetNamespace = result.targetNamespace;
                  layer.targetPrefix = result.targetPrefix;
                  this.computeGeometryType(layer);
                  layer.layerProperties.forEach((field: any) => {
                    field.display = true;
                    field.editable = true;
                  });
                } else {
                  console.error('PB lecture featureType')
                }
                // this.updateProperties();
              }
            },
            (error: any) => {
              console.log('error');
              console.log(error);
              if (error.error) {
                //this.parseFeatureTypeFromXml(error.error.text);
                console.error('PB lecture featureType')
              }
            },
          );
      }
    }

    computeGeometryType(layer:LayerConfig):void {
      layer.layerProperties.forEach((field: any) => {
        if (field.type === 'gml:Point' || field.type === 'gml:MultiPoint') {
          layer.geometryType = 'point';
        }
        if (field.type === 'gml:Polygon' || field.type === 'gml:MultiPolygon' || field.type === 'gml:SurfacePropertyType') {
          layer.geometryType = 'polygone';
        }
        if (field.type === 'gml:LineString' || field.type === 'gml:MultiLineString') {
          layer.geometryType = 'ligne';
        }
      });
    }

    parseFeatureTypeFromXml(xml:any):any {
      let prefix = '';
      if (xml.indexOf('xsd:schema') > 0) {
        prefix = 'xsd:';
      }
      const parser = new CapabilitiesParser();
      const describe = parser.parseDescribeFeature(xml);
      const schemaAttributes = describe[`${prefix}schema`]['@attributes'];
      console.log(describe);
      const proper = describe[`${prefix}schema`][`${prefix}complexType`][`${prefix}complexContent`][`${prefix}extension`][`${prefix}sequence`][`${prefix}element`];
      let attrs =  proper.map((x: any) => {
        const retour = x['@attributes'];
        if (x[`${prefix}annotation`] && x[`${prefix}annotation`][`${prefix}documentation`]) {
          retour.label = x[`${prefix}annotation`][`${prefix}documentation`]['#text'];
        }
        return retour;
      });

      let ret:any ={featureTypes:[{properties:attrs}]};
      if (schemaAttributes && schemaAttributes.targetNamespace) {
        ret.targetNamespace = schemaAttributes.targetNamespace;
        let prefix:any = Object.keys(schemaAttributes).find(key => schemaAttributes[key] === ret.targetNamespace);
        ret.targetPrefix = prefix.split(':')[1];
      }
      return ret;
    }
}
export default AbstractMapService;
