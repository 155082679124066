









import Component from 'vue-class-component';
import PluginComponent from '@/components/plugins/PluginComponent';
import LayerManager from '@/services/LayerManager';

@Component({
  props: {
  },
  components: {
  },
})
export default class LoadingInfosPlugin extends PluginComponent {
  layerManager:LayerManager|null = null;

  mounted():void {
    this.layerManager = this.getMapService().layerManager;
  }

  get show():boolean {
    if (this.layerManager) {
      return this.layerManager.nbtileLoading !== undefined && this.layerManager.nbtileLoading > 0;
    }
    return false;
  }

  get nbtileLoading():string {
    return `${this.layerManager!.nbtileLoading}`;
  }
}
