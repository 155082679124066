











/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Component from 'vue-class-component';
import LayerLegend from '@/components/plugins/legend/LayerLegend.vue';
import PluginComponent from '@/components/plugins/PluginComponent';

@Component({
  props: {
  },
  components: {
    LayerLegend,
  },
})
export default class LegendPlugin extends PluginComponent {
  static ID = 'LegendPlugin';

  mounted() {
    console.log('LegendPlugin Mounted');
  }
}
